const {
  REACT_APP_FLAREON_API_URL: FLAREON_API_URL,
  REACT_APP_QUARTICON_URL: QUARTICON_API_URL,
} = process.env;

const FLAREON_ROUTES = {
  productBySKU: (sku: string) => `${FLAREON_API_URL}/e-products?sku=${sku}`,
  productsBySKU: (queryString: string) =>
    `${FLAREON_API_URL}/e-products?${queryString}`,
};

const QUARTICON_ROUTES = {
  chosenForYou: (deviceId: string) =>
    `${QUARTICON_API_URL}/recommendation?device_id=${deviceId}&page=1`,
};

// eslint-disable-next-line
export { FLAREON_ROUTES, QUARTICON_ROUTES };
