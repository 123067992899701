import { flexRender, Row } from '@tanstack/react-table';
import Tr from 'components/Table/Tr';
import Td from 'pages/Dashboard/Listing/components/Table/components/Td';
import { Dashboard } from 'types/dashboard';

interface TableRowProps {
  row: Row<Dashboard>;
}
function TableRow({ row }: TableRowProps) {
  const { id } = row.original;
  return (
    <Tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell) => {
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
    </Tr>
  );
}

export default TableRow;
