/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup';
import {
  activityDatesSchema,
  linkParametersSchema,
} from 'components/Container/schema';

export const PLATFORM: { [key: string]: string } = {
  android: 'Android',
  ios: 'iOS',
};

export const PLATFORM_OPTIONS = Object.entries(PLATFORM).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
);

export enum SCREEN_KIND {
  UPDATE_APP = 'update_app',
  LINKABLE_BUTTON = 'linkable_button',
  LINKABLE_LINK = 'linkable_link',
}

export const SCREEN_KIND_OPTIONS = [
  { label: 'Zaktualizuj aplikację', value: SCREEN_KIND.UPDATE_APP },
  { label: 'Aktywny 1 przycisk', value: SCREEN_KIND.LINKABLE_BUTTON },
  { label: 'Aktywny link z przyciskiem', value: SCREEN_KIND.LINKABLE_LINK },
];

export const BODY_SCHEMA = yup.object({
  title: yup.string().required('Pole jest wymagane'),
  kind: yup.string().required('Pole jest wymagane'),
  platform: yup
    .string()
    .nullable()
    .when('kind', {
      is: (kind: SCREEN_KIND) => kind === SCREEN_KIND.UPDATE_APP,
      then: (schema) => schema.required('Pole jest wymagane'),
    }),
  platformVersion: yup
    .string()
    .nullable()
    .when('kind', {
      is: (kind: SCREEN_KIND) => kind === SCREEN_KIND.UPDATE_APP,
      then: (schema) => schema.required('Pole jest wymagane'),
    }),
  buttonText: yup.string().nullable().required('Pole jest wymagane'),
  linkText: yup
    .string()
    .nullable()
    .when('kind', {
      is: (kind: SCREEN_KIND) =>
        [SCREEN_KIND.UPDATE_APP, SCREEN_KIND.LINKABLE_LINK].includes(kind),
      then: (schema) => schema.required('Pole jest wymagane'),
    }),
  ...activityDatesSchema,
  dateFrom: yup.date().required('Pole jest wymagane').nullable(),
  elementsAttributes: yup.array().of(
    yup
      .object()
      .shape({
        description: yup.string().required('Pole jest wymagane'),
        image: yup.mixed().nullable(),
      })
      .nullable(),
  ),
  linkParametersAttributes: yup.array().when('kind', {
    is: (kind: SCREEN_KIND) =>
      [SCREEN_KIND.LINKABLE_BUTTON, SCREEN_KIND.LINKABLE_LINK].includes(kind),
    then: (schema) =>
      schema.of(
        yup.object().shape({
          linkType: yup.string().nullable().required('Pole jest wymagane'),
          linkParamValue: linkParametersSchema.linkParamValue,
        }),
      ),
    otherwise: (schema) =>
      schema.of(
        yup.object().shape({
          linkType: yup.string().nullable().notRequired(),
          linkParamValue: linkParametersSchema.linkParamValue,
        }),
      ),
  }),
});

export const DEFAULT_VALUES = {
  title: '',
  platform: '',
  platformVersion: '',
  buttonText: '',
  linkText: '',
  linkParametersAttributes: [{ linkType: null }],
  active: true,
  timeFrom: null,
  dateFrom: new Date(),
  timeTo: null,
  dateTo: null,
  elementsAttributes: [
    {
      description: '',
      image: null,
    },
  ],
};
