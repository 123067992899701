import styled from '@emotion/styled';

const Tr = styled.tr`
  border-color: black;
  border-bottom-width: 1px;
  :first-of-type {
    border-top-width: 1px;
  }
`;

export default Tr;
