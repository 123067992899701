import {
  useCallback,
  useState,
  useRef,
  useEffect,
  SyntheticEvent,
} from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { Box, Heading, Flex, IconButton, Image } from '@chakra-ui/react';
import { MdKeyboardArrowUp, MdDragIndicator } from 'react-icons/md';
import styled from '@emotion/styled';
import Card from 'components/Card';
import Input from 'components/Form/Input';
import SectionTitle from 'components/Form/SectionTitle';
import { DragItem } from 'types/dashboard';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';
import { useUpdateElementMutation } from 'app/services/dashboardApi';

export const ActionButton = styled.button`
  display: block;
  padding: 5px;
  cursor: grab;
`;

interface InstagramElementProps {
  prepareFieldName: (name: string) => string;
  index: number;
  item: DragItem;
  moveItem: (item: DragItem, dragIndex: number, hoverIndex: number) => void;
  onDrop: (
    elementId: string | number,
    placement: number,
    index: number,
  ) => void;
  containerId: number | string;
}

function InstagramElement({
  prepareFieldName,
  index,
  item,
  moveItem,
  onDrop,
  containerId,
}: InstagramElementProps) {
  const [isOpen, setIsOpen] = useState(index === 0);
  const [originalIndex, setOriginalIndex] = useState(index);
  const [updateElement] = useUpdateElementMutation();

  const dragRef = useRef<HTMLButtonElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const fieldname = useCallback(
    (name: string) => prepareFieldName(`elementsAttributes[${index}].${name}`),
    [index, prepareFieldName],
  );

  const toggleSectionHandler = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    {
      handlerId: Identifier | null;
    }
  >({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    drop(element: DragItem) {
      onDrop(element.id, element.index, originalIndex);
      setOriginalIndex(element.index);
    },
    hover(element: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }

      const dragIndex = element.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 5;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(element, dragIndex, hoverIndex);

      element.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'card',
    item: () => {
      return { id: item.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (element: any, monitor) => {
      const { index: currentIndex } = element;
      const didDrop = monitor.didDrop();
      // reset to previous state if not dropped correctly
      if (!didDrop) {
        moveItem(element, currentIndex, originalIndex);
      }
    },
  });

  const updateElementActivity = (v: SyntheticEvent) => {
    updateElement({
      containerId,
      elementId: item.id,
      payload: {
        dashboard_container_element: {
          active: (v.target as HTMLInputElement).checked,
        },
      },
    });
  };

  useEffect(() => {
    if (!isDragging) {
      setOriginalIndex(index);
    }
  }, [index, isDragging]);

  drag(dragRef);
  drop(preview(previewRef));

  return (
    <Card
      isDragging={isDragging}
      ref={previewRef}
      data-handler-id={handlerId}
      key={item.id}
    >
      <Flex position="absolute" zIndex={5} right={8} top={6}>
        <IconButton
          aria-label="toggle section"
          icon={<MdKeyboardArrowUp size={24} />}
          variant="unstyled"
          size="md"
          onClick={toggleSectionHandler}
          sx={{
            transform: `rotate(${isOpen ? 0 : 180}deg)`,
          }}
        />
        <ActionButton ref={dragRef} type="button">
          <MdDragIndicator size={20} />
        </ActionButton>
      </Flex>

      <Heading
        as="h3"
        fontSize={16}
        borderBottom="1px solid"
        borderColor="complementary.grey"
        paddingBottom={2}
        maxW={FORM_BODY_MAX_WIDTH}
        sx={{
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        ELEMENT {item.id}
      </Heading>

      {isOpen ? (
        <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
          <Input
            label="Instagram link"
            name={fieldname('instagramUrl')}
            isReadOnly
          />

          <SectionTitle m="44px 0 20px">Zdjęcie</SectionTitle>

          <Image src={item.image.medium.url} alt="" w={200} />

          <ToggleCheckbox
            label="Aktywność"
            name={fieldname('active')}
            mt={5}
            onChanangeCallback={updateElementActivity}
          />
        </Box>
      ) : null}
    </Card>
  );
}

export default InstagramElement;
