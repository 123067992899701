import { FormControl } from '@chakra-ui/react';
import SectionTitle from 'components/Form/SectionTitle';
import DropzoneField from 'components/Form/Dropzone';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <FormControl as="fieldset">
      <SectionTitle as="legend">Zdjęcie</SectionTitle>
      <DropzoneField
        name={prepareFieldName('image')}
        title="Dodaj zdjęcie w formacie .png, .jpg"
      />
    </FormControl>
  );
}

export default FormBody;
