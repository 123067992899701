import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { MdOutlineCancel } from 'react-icons/md';
import useQueryParams from 'utils/useQueryParams';
import { FOOTER_HEIGHT } from 'components/Footer';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import { SLUG, STATUS } from 'pages/LandingPage/Listing/constants';
import DrawerBody from 'pages/BlogPost/Listing/components/FiltersDrawer/FilterBody';

interface FiltersDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

function FiltersDrawer({ isOpen, onClose }: FiltersDrawerProps) {
  const { deleteParams } = useQueryParams();

  const handleClear = () => {
    deleteParams([SLUG, STATUS]);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay zIndex={1} />
      <DrawerContent
        mt={`calc(${NAVBAR_HEIGHT} - 1px);`}
        mb={`calc(${FOOTER_HEIGHT} + 1px);`}
      >
        <DrawerHeader alignItems="center">
          FILTRY
          <DrawerCloseButton>
            <MdOutlineCancel size="19px" />
          </DrawerCloseButton>
        </DrawerHeader>
        <DrawerBody onClose={onClose} />
        <DrawerFooter gap="16px">
          <Button fontSize="12px" w="100%" type="submit" form="filter-form">
            FILTRUJ
          </Button>
          <Button
            fontSize="12px"
            fontWeight={700}
            variant="outlined"
            minW="125px"
            onClick={handleClear}
            type="reset"
          >
            WYCZYŚĆ
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
export default FiltersDrawer;
