import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import ROUTES from 'app/routes';
import {
  useDeleteLandingPageMutation,
  useLandingPagesQuery,
} from 'app/services/landingPageApi';
import useQueryParams from 'utils/useQueryParams';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/LandingPage/Listing/components/Table';
import SearchIndicator from 'components/Search/SearchIndicator';
import { SEARCH_KEY } from 'pages/LandingPage/Listing/constants';
import FooterButtons from 'pages/LandingPage/Listing/components/FooterButtons';
import useRoute from 'utils/useRoute';

function TeamPages() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
    order: 'DESC',
    type: 'TeamPage',
  });

  const createTeamPagePath = useRoute(ROUTES.landingPage.create) + queryString;

  const [, state] = useDeleteLandingPageMutation({
    fixedCacheKey: 'landingPage',
  });

  const {
    isFetching,
    data: { data: pages, totals } = { data: [], totals: 0 },
  } = useLandingPagesQuery(queryString);

  const { isLoading } = state;
  const showLoading = isFetching || isLoading;

  return (
    <ListingLayout
      title="Strony drużyny"
      hideSearch
      hideFilter
      headerButtons={[
        { label: 'NOWA STRONA', as: Link, to: createTeamPagePath },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Box>
          <SearchIndicator searchKey={SEARCH_KEY} />
          <Table
            data={pages}
            h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 128px));`}
          />
        </Box>
      )}
      <Footer totals={totals} footerButtons={<FooterButtons />} />
    </ListingLayout>
  );
}

export default TeamPages;
