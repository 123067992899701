import { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Basketball/Teams/Listing/components/Table';
import { useTeamsQuery } from 'app/services/basketballApi';

function BasketballTeams() {
  const { isFetching, data: { data: teams } = { data: [] } } = useTeamsQuery();

  return (
    <ListingLayout title="Drużyny" hideSearch hideFilter headerButtons={[]}>
      {isFetching && <LoadingIndicator />}
      {!isFetching && (
        <Table
          data={teams}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 70px));`}
        />
      )}
    </ListingLayout>
  );
}

export default BasketballTeams;
