import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/WeatherCategories/Listing/components/Table';
import { useWeatherCategoriesQuery } from 'app/services/weatherCategoriesApi';

function WeatherCategories() {
  const {
    isFetching: weatherCategoriesLoading,
    data: { data: weatherCategories } = { data: [] },
  } = useWeatherCategoriesQuery();

  return (
    <ListingLayout
      title="Kategoria pogodowa"
      hideSearch
      hideFilter
      headerButtons={[]}
    >
      {weatherCategoriesLoading && <LoadingIndicator />}
      {!weatherCategoriesLoading && (
        <Table
          data={weatherCategories}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + 140px));`}
        />
      )}
    </ListingLayout>
  );
}

export default WeatherCategories;
