import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import {
  useCategoryElementQuery,
  useSubcategoriesQuery,
} from 'app/services/subcategoryApi';
import Table from 'pages/Subcategory/Listing/components/Table';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import HeaderNavigation from 'pages/Subcategory/Listing/components/HeaderNavigation';
import SecondaryTable from 'pages/Subcategory/Listing/components/SecondaryTable';
import FooterButtons from 'pages/Subcategory/Listing/components/FooterButtons';
import LoadingIndicator from 'components/LoadingIndicator';

function SubcategoryListing() {
  const { categoryContainerId = '', taxonomyId = '' } = useParams();

  const { isFetching: isElementLoading, data: { data: categoryElement } = {} } =
    useCategoryElementQuery({
      categoryContainerId,
      taxonomyContainerId: taxonomyId,
    });

  const { data: { data: subCategories } = { data: [] } } =
    useSubcategoriesQuery({
      categoryContainerId,
      taxonomyContainerId: taxonomyId,
    });

  const showLoading = isElementLoading;

  return (
    <Box pt="32px">
      <HeaderNavigation
        name={categoryElement?.title ?? 'Loading...'}
        taxonomyId={taxonomyId}
        categoryContainerId={categoryContainerId}
        gender={categoryElement?.taxonomyContainer?.genderName!}
      />
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <>
          <Table data={[categoryElement!]} />
          <SecondaryTable
            data={subCategories}
            h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 267px));`}
          />
        </>
      )}
      <Footer
        totals={0}
        hidePagination
        footerButtons={
          <FooterButtons
            taxonomyId={taxonomyId}
            categoryContainerId={categoryContainerId}
          />
        }
      />
    </Box>
  );
}

export default SubcategoryListing;
