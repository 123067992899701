import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { useBenefitsScreenElementsQuery } from 'app/services/benefitsScreensApi';
import { BENEFITS_SCREEN_KIND } from 'pages/BenefitsScreens/Form/constants';
import Table from './components/Table';
import HeaderNavigation from './components/HeaderNavigation';

function BenefitsScreenElements() {
  const { benefitsScreenId = '' } = useParams();
  const { data: benefitsScreen, isFetching } = useBenefitsScreenElementsQuery(
    benefitsScreenId!,
  );

  return (
    <Box pt="36px">
      <HeaderNavigation
        name={BENEFITS_SCREEN_KIND[benefitsScreen?.kind || 0] ?? 'Loading...'}
        benefitsScreenId={benefitsScreenId}
      />
      {isFetching && <LoadingIndicator />}
      {!isFetching && benefitsScreen && (
        <Table
          data={benefitsScreen?.elements || []}
          benefitsScreenId={benefitsScreen?.id}
        />
      )}
    </Box>
  );
}

export default BenefitsScreenElements;
