import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';

function GetColumns(): ColumnDef<OnboardScreen, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TYTUŁ',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'OPIS',
      accessorKey: 'description',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TŁO',
      accessorKey: 'background.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
