import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react';
import { BiTimeFive } from 'react-icons/bi';

function CustomInput(props: InputProps, ref: React.Ref<HTMLInputElement>) {
  return (
    <InputGroup>
      <Input ref={ref} {...props} />
      <InputRightElement cursor="pointer" w="72px" zIndex={1} {...props}>
        <BiTimeFive size={19} />
      </InputRightElement>
    </InputGroup>
  );
}

export default CustomInput;
