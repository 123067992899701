import { Flex, Box, Text } from '@chakra-ui/react';
import Card from 'components/Card';
import FormSpinner from 'components/FormSpinner';
import FormBody from 'pages/CategoryTags/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';

interface ComponentWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ComponentWrapperProps) {
  return (
    <Flex gap={4} mb={12} alignItems="flex-start">
      <Box flex={1} maxW={FORM_MAX_WIDTH}>
        <Card>
          {isLoading && <FormSpinner />}

          <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
            <Text variant="boldSectionTitle" mb="36px">
              Tag taksonomii
            </Text>
            <FormBody prepareFieldName={(name: string) => name} isEditMode />
          </Box>
        </Card>
      </Box>
    </Flex>
  );
}

export default ContainerWrapper;
