import { useMemo, useRef, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Previews } from 'components/Container/constants';
import { Dashboard } from 'types/dashboard';

interface ShowPreviewProp {
  data: Dashboard;
  children: string;
}

function ShowPreview({ data, children }: ShowPreviewProp) {
  const [previewIsVisible, setPreviewIsVisible] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  let debounce: ReturnType<typeof setTimeout>;

  const isPreview = Object.keys(Previews).includes(data.kind);
  const PreviewComponent = Previews[data.kind];

  const isParallax = data.kind === 'paralax_banner';
  const parallaxContainer = useMemo(
    () => ({
      current: document.getElementById('dashboard-listing'),
    }),
    [],
  );

  if (!isPreview) {
    return (
      <Text pt={4} textAlign="center">
        {children} niedostępny
      </Text>
    );
  }

  const onHover = () => {
    debounce = setTimeout(() => {
      setPreviewIsVisible(true);
    }, 300);
  };

  const onLeave = () => {
    setPreviewIsVisible(false);
    clearTimeout(debounce);
  };

  return (
    <Box
      pt={4}
      ref={ref}
      cursor="pointer"
      position="relative"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {previewIsVisible && (
        <Box
          boxShadow="0 4px 10px 0 rgba(0, 0, 0, 0.25)"
          transform="translate(-35%, -10%)"
          top={0}
          left={0}
          zIndex={1000}
          position="absolute"
          maxH="fit-content"
          minW="424px"
          maxW="60vw"
          bg="white"
          mt={4}
          p={8}
          overflow="hidden"
        >
          <Box overflowX="auto">
            <Text
              fontSize="16px"
              fontWeight={600}
              pb={2}
              textTransform="uppercase"
            >
              {children}
            </Text>
            <PreviewComponent
              formValues={data}
              innerRef={isParallax ? parallaxContainer : undefined}
              showStats
            />
          </Box>
          {data.margined ? (
            <Box h="16px" backgroundColor={data.marginColor} />
          ) : null}
        </Box>
      )}
      <Text
        color="complementary.blue"
        fontSize="16px"
        textAlign="center"
        textDecoration="underline"
        pos="relative"
        zIndex={0}
      >
        {children}
      </Text>
    </Box>
  );
}

export default ShowPreview;
