import styled from '@emotion/styled';

interface TableProps {
  isEmpty: boolean;
}
const Table = styled.table`
  width: 100%;
  height: ${(props: TableProps) => (props.isEmpty ? '100%' : 'unset')};
`;

export default Table;
