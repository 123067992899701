import { Row, flexRender } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { MdOutlineEdit, MdOutlineDragIndicator } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Tr from 'pages/CategoryTags/Listing/components/TableComponents/Tr';
import Td from 'pages/CategoryTags/Listing/components/TableComponents/Td';
import { CategoryTag } from 'types/categoryTags';
import DndSpacer from 'components/Table/DndSpacer';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import DeleteModal from 'components/DeleteModal';
import { useDeleteTagMutation } from 'app/services/categoryTags';
import { DragItem } from 'types/common';

interface DraggableRowProps {
  row: Row<CategoryTag>;
  handleReorder: (draggedRowIndex: number, targetRowIndex: number) => void;
  categoryId: number;
}

function DraggableRow({ row, handleReorder, categoryId }: DraggableRowProps) {
  const { store } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleteTag] = useDeleteTagMutation();
  const { queryString } = useQueryParams();
  const [{ isOver, targetOffset }, dropRef] = useDrop({
    accept: 'row',
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        targetOffset: monitor.getDifferenceFromInitialOffset()?.y,
      };
    },
    drop: (draggedItem: DragItem) => {
      const sourceIndex = draggedItem.placement;
      const targetIndex = row.original.placement;
      if (sourceIndex !== targetIndex)
        handleReorder(draggedItem.id, targetIndex);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'row',
    item: () => {
      return {
        id: row.original.id,
        placement: row.original.placement,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleDelete = async (id: number) => {
    try {
      await deleteTag({ id, categoryId }).unwrap();
      toast.success('Pomyślnie usunięto tag');
      onClose();
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania tagu');
    }
  };

  const { id } = row.original;
  const showDrop = isOver && !isDragging && targetOffset;

  return (
    <>
      {showDrop && targetOffset < 0 && <DndSpacer />}
      <Tr
        ref={previewRef}
        key={`body_row_${row.original.parentExternalCategoryId}_${id}`}
        isNested
      >
        {row.getVisibleCells().map((cell) => (
          <Td
            key={`body_column_${row.original.parentExternalCategoryId}_${cell.id}`}
            isNested
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        ))}
        <Td ref={dropRef}>
          <>
            <DeleteModal
              onAccept={() => handleDelete(id)}
              onClose={onClose}
              isOpen={isOpen}
            >
              Czy na pewno chcesz trwale usunąć tego taga?
            </DeleteModal>
            <Tooltip label="Edytuj" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<MdOutlineEdit size={20} />}
                aria-label="visiblity"
                variant="ghost"
                as={Link}
                to={
                  generatePath(ROUTES.categoryTag.edit, {
                    id: id.toString(),
                    categoryId: categoryId.toString(),
                    store,
                  }) + queryString
                }
              />
            </Tooltip>
            <Tooltip label="Usuń" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<RiDeleteBin6Line size={20} />}
                aria-label="visiblity"
                variant="ghost"
                onClick={onOpen}
              />
            </Tooltip>
            <IconButton
              width="72px"
              height="56px"
              icon={<MdOutlineDragIndicator size={20} />}
              aria-label="visiblity"
              variant="ghost"
              ref={dragRef}
            />
          </>
        </Td>
      </Tr>
      {showDrop && targetOffset > 0 && <DndSpacer />}
    </>
  );
}

export default DraggableRow;
