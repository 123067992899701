/* eslint-disable import/no-cycle */
import { Box, FormControl, Text, VStack } from '@chakra-ui/react';
import ColorPicker from 'components/Form/Color';
import DropzoneField from 'components/Form/Dropzone';
import Input from 'components/Form/Input';
import SectionTitle from 'components/Form/SectionTitle';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import LinkCreatorSection from 'components/LinkCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';

function ParallaxBannerForm({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  return (
    <FormControl as="fieldset">
      <Box as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Zdjęcie tła</SectionTitle>
        <Text>To zdjęcie zostanie wyświetlone z efektem parallaxy</Text>
        <DropzoneField
          name={prepareFieldName('backgroundImage')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 360px x 638px)"
        />
      </Box>
      <Box as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Zdjęcie przód</SectionTitle>
        <DropzoneField
          name={prepareFieldName('image')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 328px x 430px)"
        />
      </Box>
      <HeaderFormSection
        prepareFieldName={prepareFieldName}
        components={[
          COMPONENTS.TITLE,
          COMPONENTS.SUBTITLE,
          COMPONENTS.COLORTXT,
          COMPONENTS.COLORBG,
        ]}
      />
      <Box as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Przycisk</SectionTitle>
        <VStack spacing="26px">
          <Input
            name={prepareFieldName('buttonText')}
            label="Treść przycisku"
          />
          <ColorPicker
            name={prepareFieldName('buttonTextColor')}
            label="Kolor tekstu"
          />
          <ColorPicker
            name={prepareFieldName('buttonColor')}
            label="Kolor tła przycisku"
          />
        </VStack>
      </Box>

      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </FormControl>
  );
}

export default ParallaxBannerForm;
