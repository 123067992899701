import { Box, Flex, FlexProps, IconButton } from '@chakra-ui/react';
import { ConnectDragSource } from 'react-dnd';
import { FaRegTrashAlt } from 'react-icons/fa';
import {
  MdKeyboardArrowUp,
  MdDragIndicator,
  MdOutlineReportGmailerrorred,
} from 'react-icons/md';

interface ContainerActionsProps extends FlexProps {
  showTrashButton: boolean;
  toggleAction: () => void;
  removeAction: () => void;
  isOpen: boolean;
  dragRef: ConnectDragSource;
  isInvalid?: boolean;
}

function ContainerActions({
  showTrashButton,
  toggleAction,
  removeAction,
  isOpen,
  dragRef,
  isInvalid,
  ...props
}: ContainerActionsProps) {
  return (
    <Flex
      position="absolute"
      alignItems="center"
      zIndex={5}
      right={8}
      top={6}
      {...props}
    >
      {showTrashButton && (
        <IconButton
          aria-label="remove section"
          icon={<FaRegTrashAlt size={20} />}
          variant="unstyled"
          size="md"
          onClick={removeAction}
        />
      )}
      <IconButton
        aria-label="toggle section"
        icon={<MdKeyboardArrowUp size={24} />}
        variant="unstyled"
        size="md"
        onClick={toggleAction}
        sx={{
          transform: `rotate(${isOpen ? 0 : 180}deg)`,
        }}
      />
      <IconButton
        aria-label="drag section"
        icon={<MdDragIndicator size={20} />}
        variant="unstyled"
        size="md"
        ref={dragRef}
      />
      {isInvalid && (
        <Box padding={2} color="complementary.red">
          <MdOutlineReportGmailerrorred size={24} />
        </Box>
      )}
    </Flex>
  );
}

ContainerActions.defaultProps = {
  isInvalid: false,
};

export default ContainerActions;
