import map from 'lodash/map';
import { Flex } from '@chakra-ui/react';
import React from 'react';
import TagButton from 'components/Form/RichText/TagButton';

interface TagButtonListProps {
  tags?: string[];
  onClick: (tag: string) => void;
}

export default function TagButtonList({ tags, onClick }: TagButtonListProps) {
  return (
    <Flex gap="8px" flexWrap="wrap" mb="32px">
      {map(tags, (tag) => (
        <React.Fragment key={`html-tag-${tag}`}>
          <TagButton
            variant="unstyled"
            onClick={() => onClick(`<${tag === 'a' ? 'a href=""' : tag}>`)}
          >{`<${tag === 'a' ? 'a href' : tag}>`}</TagButton>
          {tag !== 'br' && (
            <TagButton
              onClick={() => onClick(`</${tag}>`)}
            >{`</${tag}>`}</TagButton>
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
}

TagButtonList.defaultProps = {
  tags: [],
};
