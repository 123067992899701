import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import FooterButtons from 'components/Layout/WizardLayout/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';

interface WizardLayoutProps {
  children: React.ReactNode;
  backLinkUrl: string;
  isLoading: boolean;
  crumbs: { label: string; to?: string }[];
}

export default function WizardLayout({
  children,
  backLinkUrl,
  isLoading,
  crumbs,
}: WizardLayoutProps) {
  const navigate = useNavigate();

  const onCancelHandler = useCallback(() => {
    navigate(backLinkUrl);
  }, [backLinkUrl, navigate]);

  return (
    <Box pt="32px" paddingBottom={250}>
      <HeaderNavigation
        baseCrumb={{ to: backLinkUrl, label: 'Dashboard' }}
        crumbs={crumbs}
      />
      <FooterButtons onCancel={onCancelHandler} isLoading={isLoading} />
      {children}
    </Box>
  );
}
