import { Flex } from '@chakra-ui/react';

interface FilterBadgeProps {
  amount: number;
}

function FilterBadge({ amount }: FilterBadgeProps) {
  return (
    <Flex
      w="20px"
      h="20px"
      position="absolute"
      backgroundColor="complementary.specialGreen"
      borderRadius="50%"
      justify="center"
      align="center"
      top={0}
      right={0}
      fontSize="12px"
    >
      {amount}
    </Flex>
  );
}

export default FilterBadge;
