import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import get from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import { Editor as TinyMCE } from '@tinymce/tinymce-react';

type EditorProps = {
  name: string;
  label?: string | React.ReactNode;
};

const CONFIG = {
  init: {
    plugins: [
      'fullpage',
      'advlist',
      'autolink',
      'lists',
      'link',
      'image',
      'charmap',
      'preview',
      'anchor',
      'searchreplace',
      'visualblocks',
      'code',
      'fullscreen',
      'insertdatetime',
      'media',
      'table',
      'code',
    ],
    toolbar:
      'undo redo | blocks | ' +
      'bold italic forecolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | removeformat table',
    language: 'pl',
    placeholder: 'Wpisz treść',
    statusbar: false,
    height: '480',
  },
  apiKey: 'd44kw4hmbn1z05kun5icx70s7ixruabcha9f1667bzlxw4nj',
};

function Editor({ label, name }: EditorProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl isInvalid={Boolean(errorMessage)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ...rest } }) => (
          <TinyMCE
            {...rest}
            onEditorChange={(content) => {
              onChange(content);
            }}
            {...CONFIG}
          />
        )}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

Editor.defaultProps = {
  label: '',
};

export default Editor;
