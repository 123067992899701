import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import { LeaderCategory } from 'types/leaderCategories';

export const LEADER_CATEGORY_KIND: { [key: string]: string } = {
  male: 'Mężczyzna',
  female: 'Kobieta',
};

export const LEADER_CATEGORY_KIND_OPTIONS = Object.entries(
  LEADER_CATEGORY_KIND,
).map(([key, value]) => ({
  label: value,
  value: key,
}));

function GetColumns(): ColumnDef<LeaderCategory, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'RODZAJ',
      accessorKey: 'kind',
      cell: (info) => LEADER_CATEGORY_KIND[info.getValue()],
    },
    {
      header: 'ZDJĘCIE',
      accessorKey: 'image.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
