import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Ratings/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import {
  useAverageRatingQuery,
  useRatingsQuery,
} from 'app/services/ratingsApi';
import StatBox from './components/StatBox';

function Ratings() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const {
    isFetching: ratingsLoading,
    data: { data: ratings, totals } = { data: [], totals: 0 },
  } = useRatingsQuery(queryString);

  const {
    isFetching: averageRatingLoading,
    data: { data: { average } } = { data: { average: 0 } },
  } = useAverageRatingQuery();

  const showLoading = ratingsLoading || averageRatingLoading;

  return (
    <ListingLayout
      title="Ocena aplikacji"
      hideSearch
      hideFilter
      headerButtons={[]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <>
          <StatBox totals={totals} average={average} />
          <Table
            data={ratings}
            h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 260px));`}
          />
        </>
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Ratings;
