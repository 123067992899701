import { Checkbox } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { StatusType } from 'types/common';
import StatusBox from 'components/StatusBox';
import { Subcategory } from 'types/subcategory';
import BadgePreview from 'components/BadgePreview';
import ColorPreview from 'components/ColorPreview';
import ImagePreview from 'components/ImagePreview';

function GetColumns(): ColumnDef<Subcategory, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => (
        <Checkbox
          isChecked={info.row.getIsSelected()}
          onChange={info.row.getToggleSelectedHandler()}
        />
      ),
    },
    {
      header: 'NAZWA PODKATEGORII',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'KOLOR TŁA',
      accessorKey: 'backgroundColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'ZDJĘCIE SPECJALNE',
      accessorKey: 'image.url',
      cell: (info) => <ImagePreview url={info.getValue()} pt="12px" />,
    },
    {
      header: 'BADGE SPECJALNY',
      accessorKey: 'badge',
      cell: ({
        row: {
          original: { badgeTextColor, badgeColor, badge },
        },
      }) => (
        <BadgePreview
          backgroundColor={badgeColor || 'black'}
          text={badge}
          textColor={badgeTextColor || 'white'}
        />
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
