import { Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';

function BackNavigationButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const canGoBack = location.key !== 'default';

  return (
    <Text
      variant="historyNavigation"
      onClick={() => navigate(-1)}
      color={canGoBack ? 'inherit' : 'complementary.grey'}
    >
      <MdArrowBack />
      Powrót
    </Text>
  );
}

export default BackNavigationButton;
