import { Box, Text } from '@chakra-ui/react';

interface ButtonProps {
  buttonText?: string;
  buttonTextColor?: string;
  alignment?: 'center' | 'left' | 'right';
  buttonColor?: string;
  position?: 'static' | 'relative' | 'absolute';
  width?: string;
  bottom?: string;
  padding?: string;
}

function Button({
  buttonColor,
  buttonText,
  buttonTextColor,
  alignment,
  position,
  width,
  bottom,
  padding,
}: ButtonProps) {
  return (
    <Box
      position={position}
      left="0"
      bottom={bottom}
      textAlign={alignment}
      w={width}
      padding={padding}
      transition="text-align .3s ease-in-out"
    >
      <Text
        as="span"
        fontSize={12}
        textDecoration="underline"
        color={buttonTextColor}
        fontWeight={700}
        bgColor={buttonColor}
        padding="10px 20px"
        textTransform="uppercase"
      >
        {buttonText}
      </Text>
    </Box>
  );
}

Button.defaultProps = {
  buttonText: null,
  buttonTextColor: '#000',
  buttonColor: 'transparent',
  alignment: 'center',
  position: 'absolute',
  width: '100%',
  bottom: '26px',
  padding: '0 24px',
};

export default Button;
