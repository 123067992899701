import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import BackNavigationButton from 'components/BackNavigationButton';

type HeaderNavigationProps = {
  categoryContainerPath: string;
  genderName: string;
};

function HeaderNavigation({
  categoryContainerPath,
  genderName,
}: HeaderNavigationProps) {
  return (
    <Box mb={6}>
      <BackNavigationButton />
      <Breadcrumb mt={4}>
        {genderName && (
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={categoryContainerPath}
              fontWeight={700}
            >
              {genderName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Edytuj kategorię</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
}

export default HeaderNavigation;
