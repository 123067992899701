import { extendTheme } from '@chakra-ui/react';

import config from 'theme/config';
import colors from 'theme/colors';
import styles from 'theme/styles';
import components from 'theme/components';
import typography from 'theme/typography';

const customTheme = extendTheme({
  config,
  styles,
  colors,
  components,
  ...typography,
});

export default customTheme;
