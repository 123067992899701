import React, { useEffect } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import { BoxProps } from '@chakra-ui/react';
import TabElement from 'components/Table/Table';
import Thead from 'pages/Subcategory/Listing/components/SecondaryTable/Thead';
import Tr from 'components/Table/Tr';
import Tbody from 'components/Table/Tbody';
import Th from 'components/Table/Th';
import columns from 'pages/Subcategory/Listing/components/SecondaryTable/columns';
import { setSelectedRows, resetSelectedRows } from 'slices/ui/uiSlice';
import { useAppDispatch } from 'utils/reduxHooks';
import { Subcategory } from 'types/subcategory';
import TableWrapper from 'components/Table/TableWrapper';
import TableRow from 'pages/Subcategory/Listing/components/SecondaryTable/TableRow';

type TableProps = BoxProps & {
  data: Subcategory[];
};

function Table({ data, ...props }: TableProps) {
  const [rowSelection, setRowSelection] = React.useState({});
  const dispatch = useAppDispatch();

  const table = useReactTable({
    data,
    columns: columns(),
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    dispatch(
      setSelectedRows(
        table.getSelectedRowModel().flatRows.map((item) => item.original.id),
      ),
    );
    return () => {
      dispatch(resetSelectedRows());
    };
    // eslint-disable-next-line
  }, [rowSelection]);

  if (isEmpty(data)) return null;

  return (
    <TableWrapper {...props}>
      <TabElement isEmpty={false}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`header_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`header_cell_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={`table_row_${row.original.id}`} row={row} />
          ))}
        </Tbody>
      </TabElement>
    </TableWrapper>
  );
}

export default Table;
