import { VStack, Text, Box, Flex } from '@chakra-ui/react';
import { PreviewComponentProps } from 'types/dashboardCreator';
import Button from 'components/Container/Previews/Button';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';

function TextBannerPreview({ formValues, showStats }: PreviewComponentProps) {
  const highlightColor = formValues.textHighlight || 'transparent';

  return (
    <VStack spacing={0} w="100%">
      <Flex
        direction="column"
        alignItems="stretch"
        justifyContent="center"
        w="100%"
        bgColor={formValues.backgroundColor || 'complementary.whiteGrey'}
        position="relative"
        flexShrink={0}
        padding="30px 30px 72px"
        gap={1}
      >
        {showStats && <ShowStatsIcon data={formValues} />}
        <Text
          fontSize={formValues.titleFontSize || 12}
          lineHeight={1.5}
          fontWeight={700}
          textAlign={formValues.titleAlignment || 'left'}
          color={formValues.textColor || 'black'}
          mb="10px"
          paddingTop="30px"
          dangerouslySetInnerHTML={{
            __html: formValues.title || '',
          }}
        />
        <Box textAlign={formValues.contentAlignment || 'center'} px={2}>
          <Text
            as="span"
            fontSize={formValues.contentFontSize || 14}
            color={formValues.contentTextColor || 'black'}
            lineHeight={1.71}
            backgroundColor={highlightColor}
            boxDecorationBreak="clone"
            boxShadow={`8px 0 0 ${highlightColor}, -8px 0 0 ${highlightColor}`}
            py={1}
            dangerouslySetInnerHTML={{
              __html: formValues.content || 'Tu pojawi się twój opis',
            }}
          />
        </Box>
        {formValues.buttonText ? (
          <Button
            {...{
              ...formValues,
              alignment: formValues.alignment || 'right',
            }}
          />
        ) : null}
      </Flex>
    </VStack>
  );
}

export default TextBannerPreview;
