import styled from '@emotion/styled';

const Td = styled.td`
  padding-inline: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 136px;
  vertical-align: top;
  padding-top: 16px;

  :nth-of-type(1) {
    width: 64px;
    font-weight: 600;
    text-align: center;
  }
  :nth-of-type(9) {
    padding: 0;
    text-align: right;
  }
`;

export default Td;
