import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Announcements/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA } from 'pages/Announcements/Form/constants';
import { transformErrors } from 'utils/api';
import {
  useAnnouncementQuery,
  useUpdateAnnouncementMutation,
} from 'app/services/announcementsApi';
import parseDefaultValues from 'pages/Announcements/Form/Edit/parseDefaultValues';
import { TRANSFORMED_KEYS } from 'pages/Announcements/Form/Create/index';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';
import getRemovedElements from 'selectors/formSelectors';
import { clearRemovedElements } from 'slices/form/formSlice';
import { transformRemoved } from 'utils/helpers';
import useRoute from 'utils/useRoute';

function AnnouncementEdit() {
  const { id } = useParams();
  const backPath = useRoute(ROUTES.announcements.base);
  const navigate = useNavigate();
  const removedElements = useAppSelector(getRemovedElements);
  const dispatch = useAppDispatch();

  const { data: { data: announcement } = {}, isFetching } =
    useAnnouncementQuery(id);
  const [updateAnnouncement, { isLoading: updateRunning }] =
    useUpdateAnnouncementMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = {
        ...data,
        elementsAttributes: [
          ...(data?.elementsAttributes || []),
          ...transformRemoved(removedElements),
        ],
      };
      await updateAnnouncement(payload)
        .unwrap()
        .then(() => {
          navigate(backPath);
          toast.success(`Zmiany w ekranie CRUD zostały zastosowane`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            const fieldName = TRANSFORMED_KEYS[field] || field;
            methods.setError(fieldName as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (announcement) reset(parseDefaultValues(announcement));
  }, [announcement, reset]);

  useEffect(() => {
    return () => {
      dispatch(clearRemovedElements());
    };
  }, [dispatch]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Ekran CRUD',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj ekran CRUD' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="announcement-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="announcement-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default AnnouncementEdit;
