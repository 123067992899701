import isEmpty from 'lodash/isEmpty';
import { DEFAULT_FORM_VALUES } from 'components/Container/constants';
import { parseDefaultValues as parseContainer } from 'utils/containers';
import { transformLinkParameters } from 'utils/linkCreator';
import { BlogPost } from 'types/blogPost';

const parseDefaultValues = (blogPost: BlogPost) => {
  const result: any = {
    ...blogPost,
    containersAttributes: [],
    countries: [blogPost.language],
  };
  const { containers, linkParameters } = result;

  result.containersAttributes =
    containers?.map((container: any) => {
      const defaultValues = DEFAULT_FORM_VALUES[container.kind];

      const values = {
        ...defaultValues,
        ...container,
      };

      return parseContainer(values);
    }) || [];

  delete result.containers;

  if (isEmpty(linkParameters)) {
    result.linkParametersAttributes = [{ linkType: null }];
  } else {
    result.linkParametersAttributes = blogPost.linkParameters;
  }

  return transformLinkParameters(result);
};

export default parseDefaultValues;
