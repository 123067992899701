import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Administration/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { DEFAULT_VALUES } from 'pages/Administration/Form/constants';
import { transformErrors } from 'utils/api';
import {
  useAdministratorQuery,
  useUpdateAdministratorMutation,
} from 'app/services/administrationApi';
import useRoute from 'utils/useRoute';

const BODY_SCHEMA = yup.object({
  email: yup
    .string()
    .email('Nieprawidłowy adres-email')
    .required('Pole jest wymagane'),
  role: yup.string().nullable(),
  password: yup
    .string()
    .min(6, 'Podane hasło jest za krótkie (przynajmniej 6 znaków)')
    .nullable()
    .transform((value) => value || null),
  passwordConfirmation: yup
    .string()
    .min(6, 'Podane hasło jest za krótkie (przynajmniej 6 znaków)')
    .nullable()
    .oneOf(
      [yup.ref('password'), null],
      'Hasła nie są takie same. Spróbuj ponownie',
    )
    .when('password', {
      is: (password: string) => password?.length > 0,
      then: yup.string().nullable().required('Pole jest wymagane'),
    })
    .transform((value) => value || null),
});

function AdministratorEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.administration.base);

  const { data: { data: administrator } = {}, isFetching } =
    useAdministratorQuery({
      id,
    });
  const [updateAdministrator, { isLoading: updateRunning }] =
    useUpdateAdministratorMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateAdministrator(data)
        .unwrap()
        .then(() => {
          navigate(backPath);
          toast.success(`Zmiany w administratorze zostały zastosowane`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (administrator) reset({ ...DEFAULT_VALUES, ...administrator });
  }, [administrator, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Administrator',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj administratora' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="administrator-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="administrator-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default AdministratorEdit;
