import { format } from 'date-fns';
import isNil from 'lodash/isNil';
import { ContainerObject } from 'types/common';

const datePrettier = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM.yyyy');
  const time = format(dt, 'HH:mm');
  return `${date} godz.${time}`;
};

const datePrettierNoTime = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM.yyyy');
  return `${date}`;
};

export const joinDate = (date: Date, time: Date): string => {
  const stringDate = format(date, 'yyyy-MM-dd');
  const stringTime = format(time, 'HH:mm:00');
  return `${stringDate}T${stringTime}`;
};

function isValidDate(date: any) {
  return new Date(date).toString() !== 'Invalid Date';
}

const transformActivityDates = (object: ContainerObject) => {
  const newObject = { ...object };

  // active_from
  if (object.dateFrom) {
    if (object.timeFrom) {
      newObject.activeFrom = joinDate(object.dateFrom, object.timeFrom);
    } else {
      newObject.activeFrom = `${format(
        object.dateFrom,
        'yyyy-MM-dd',
      )}T00:00:00`;
    }
  } else {
    newObject.activeFrom = ' ';
  }

  // active_to
  if (object.dateTo) {
    if (object.timeTo) {
      newObject.activeTo = joinDate(object.dateTo, object.timeTo);
    } else {
      newObject.activeTo = `${format(object.dateTo, 'yyyy-MM-dd')}T23:59:59`;
    }
  } else {
    newObject.activeTo = ' ';
  }

  delete newObject.dateFrom;
  delete newObject.dateTo;
  delete newObject.timeFrom;
  delete newObject.timeTo;

  return newObject;
};

type TransformedDates = {
  dateFrom?: Date;
  timeFrom?: Date;
  dateTo?: Date;
  timeTo?: Date;
};

const transformStringDatesToDateObject = (object: Record<string, any>) => {
  const newObject: TransformedDates = {};
  const { activeFrom, activeTo } = object;

  if (activeFrom) {
    newObject.dateFrom = new Date(activeFrom);
    newObject.timeFrom = new Date(activeFrom);
  }

  if (activeTo) {
    newObject.dateTo = new Date(activeTo);
    newObject.timeTo = new Date(activeTo);
  }

  return newObject;
};

export {
  datePrettier,
  datePrettierNoTime,
  isValidDate,
  transformActivityDates,
  transformStringDatesToDateObject,
};
