import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { BoxProps } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import TabElement from 'components/Table/Table';
import Thead from 'components/Table/Thead';
import Tr from 'components/Table/Tr';
import Tbody from 'components/Table/Tbody';
import Th from 'components/Table/Th';
import columns from 'pages/Faqs/Listing/components/Table/columns';
import EmptyBody from 'components/Table/EmptyBody';
import TableWrapper from 'components/Table/TableWrapper';
import {
  useDeleteFaqMutation,
  useReorderFaqMutation,
} from 'app/services/faqsApi';
import DraggableRow from './DraggableRow';

type TableProps = BoxProps & {
  data: Faq[];
  isPreview?: boolean;
};

function Table({ data, isPreview, ...props }: TableProps) {
  const [reorderFaq] = useReorderFaqMutation();
  const [deleteFaq] = useDeleteFaqMutation();

  const handleReorder = async (id: number, targetRowIndex: number) => {
    try {
      reorderFaq({
        id,
        body: { id, position: targetRowIndex },
      }).unwrap();
    } catch (error) {
      toast.error('Problem podczas zmiany kolejności');
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteFaq(id).unwrap();
      toast.success('Pomyślnie usunięto FAQ');
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania FAQ');
    }
  };

  const table = useReactTable({
    data,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  const noTableData = isEmpty(data);

  return (
    <TableWrapper {...props}>
      <TabElement isEmpty={noTableData}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`header_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`header_cell_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {noTableData ? (
            <EmptyBody
              columnAmount={table.getAllColumns().length}
              queryParams={[]}
            />
          ) : (
            table
              .getRowModel()
              .rows.map((row) => (
                <DraggableRow
                  key={row.id}
                  row={row}
                  handleReorder={handleReorder}
                  handleDelete={handleDelete}
                />
              ))
          )}
        </Tbody>
      </TabElement>
    </TableWrapper>
  );
}

Table.defaultProps = {
  isPreview: false,
};

export default Table;
