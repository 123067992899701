import { Box, Flex, Text } from '@chakra-ui/react';
import Card from 'components/Card';
import FormSpinner from 'components/FormSpinner';
import FormBody from 'pages/CategoryTags/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

interface ComponentWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ComponentWrapperProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <FormSpinner />}

        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Text variant="boldSectionTitle" mb={8}>
            Tag taksonomii
          </Text>
          <FormBody prepareFieldName={(name: string) => name} />
        </Box>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
