import { useMemo } from 'react';

interface Props {
  elements: any;
  itemsPerGroup: number;
}

function useGroupsWithFallback({ elements, itemsPerGroup }: Props) {
  const groupsQuantity = useMemo(() => {
    const elementsLength = elements?.length;

    if (!Number(elementsLength)) return [];

    if (elementsLength < itemsPerGroup + 1) {
      return [...Array(1)];
    }

    return [
      ...Array(
        Math.floor(elementsLength / itemsPerGroup) +
          (elementsLength % itemsPerGroup ? 1 : 0),
      ),
    ];
  }, [elements?.length, itemsPerGroup]);

  const groupsProps = useMemo(
    () =>
      groupsQuantity.map((_v, idx) => {
        const groupStartIdx = idx * itemsPerGroup;
        const elementFallback = { image: '', title: '' };

        return [...Array(itemsPerGroup)].map((_vV, itemIdx) => ({
          elementAttributes:
            elements[groupStartIdx + itemIdx] ?? elementFallback,
          number: groupStartIdx + itemIdx,
        }));
      }),
    [groupsQuantity, itemsPerGroup, elements],
  );

  return { groupsProps };
}

export default useGroupsWithFallback;
