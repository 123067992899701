import { Box } from '@chakra-ui/react';

type GradientPreviewProps = {
  topColor: string;
  bottomColor: string;
};

function GradientPreview({ topColor, bottomColor }: GradientPreviewProps) {
  return (
    <Box
      width="100px"
      height="200px"
      bgGradient={`linear(to-b, ${topColor}, ${bottomColor})`}
    />
  );
}

export default GradientPreview;
