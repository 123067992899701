const transformErrors: (
  errors: { [key: string]: string[] },
  index: number | null,
) => {
  [key: string]: string;
} = (errors) => {
  try {
    if (errors) {
      const err: { [key: string]: string } = {};

      Object.keys(errors).forEach((field: any) => {
        let fieldName: string = field;

        if (fieldName === 'elements') {
          fieldName = 'elements_attributes';
        }

        if (fieldName === 'activeTo') {
          fieldName = 'active_dates';
        }

        if (fieldName === 'link_parameters') {
          fieldName = 'link_parameters_attributes';
        }

        if (Array.isArray(errors[field])) {
          [err[fieldName]] = errors[field];
        } else {
          Object.keys(errors[field]).forEach((nested: any) => {
            const nestedFields = errors[field][nested];

            if (Array.isArray(nestedFields)) {
              err[`${fieldName}Attributes[0].${nested}`] = nestedFields;
            } else {
              Object.keys(nestedFields).forEach((nestedFieldName: any) => {
                // eslint-disable-next-line prefer-destructuring
                err[
                  `${fieldName}Attributes[0].${nested}Attributes[0].${nestedFieldName}`
                ] = errors[field][nested][nestedFieldName][0];
              });
            }
          });
        }
      });

      return err;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  return {};
};

export default transformErrors;

export { transformErrors };
