import { VStack, Text, Box } from '@chakra-ui/react';
import { useMemo } from 'react';
import { BiImage } from 'react-icons/bi';
import Headers from 'components/Container/Previews/Headers';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';

function SquareBannerPreview({ formValues, showStats }: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.image);
  const bgColor = formValues?.textBackground;

  const shouldRenderHeaders = useMemo(
    () => Boolean(formValues.subtitle || formValues.title),
    [formValues.subtitle, formValues.title],
  );

  return (
    <Box bgColor={bgColor}>
      <Box
        position="relative"
        w="full"
        h="92px"
        top={-6}
        left={-6}
        right={0}
        bottom={0}
        overflow="hidden"
      >
        {shouldRenderHeaders && <Headers {...formValues} />}
      </Box>
      <VStack
        alignItems="center"
        justifyContent="center"
        w={388}
        h={368}
        bgImage={imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
        position="relative"
      >
        {!imagePreview ? (
          <>
            <BiImage size={30} />
            <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
              Tu pojawi się dodane zdjęcie
            </Text>
          </>
        ) : null}
        {showStats && <ShowStatsIcon data={formValues} />}
      </VStack>
    </Box>
  );
}

export default SquareBannerPreview;
