import { Box, FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import ColorPicker from 'components/Form/Color';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import LinkCreatorSection from 'components/LinkCreator';
import { FormComponentProps } from 'types/dashboardCreator';

function FrameBanner({ prepareFieldName, isEditMode }: FormComponentProps) {
  return (
    <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
      <FormControl as="fieldset" mb={9}>
        <SectionTitle as="legend">Zdjęcie</SectionTitle>

        <DropzoneField
          name={prepareFieldName('image')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 312px x 356px)"
        />
      </FormControl>
      <FormControl as="fieldset" mb={9}>
        <SectionTitle as="legend">Kolor tła</SectionTitle>

        <ColorPicker
          name={prepareFieldName('textBackground')}
          label="Kolor tła"
        />
      </FormControl>
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </Box>
  );
}

export default FrameBanner;
