import { MdInfo } from 'react-icons/md';
import { Box, BoxProps } from '@chakra-ui/react';
import ShowStats, { ShowStatsProps } from 'components/Stats/ShowElementStats';

type ShowStatsIconProps = BoxProps &
  ShowStatsProps & {
    size?: number;
  };

function ShowStatsIcon({ data, type, size, ...rest }: ShowStatsIconProps) {
  if (!data?.id) {
    return null;
  }

  return (
    <Box position="absolute" top="4px" right="4px" zIndex={9} {...rest}>
      <ShowStats data={data} type={type}>
        <Box>
          <MdInfo size={size} color="currentColor" />
        </Box>
      </ShowStats>
    </Box>
  );
}

ShowStatsIcon.defaultProps = {
  size: 32,
};

export default ShowStatsIcon;
