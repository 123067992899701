import { serialize } from 'object-to-formdata';
import { preparePayload } from 'utils/blogPost';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { api } from 'app/services/api';
import { OptionTypeResponse } from 'types/common';
import {
  SlugResponse,
  BulkDelete,
  SlugParams,
  LandingPageImportParams,
} from 'types/landingPage';
import { convertKeysToSnakeCase } from 'utils/helpers';
import parseDefaultValues from 'pages/BlogPost/Form/Edit/parseDefaultValues';
import {
  BlogPostResponse,
  BlogPostTransformedResponse,
  BlogPostsResponse,
  TransformedBlogPosts,
} from 'types/blogPost';

export const blogPostApi = api.injectEndpoints({
  endpoints: (build) => ({
    blogPost: build.query<BlogPostTransformedResponse, string | number>({
      query: (id) => `landing_pages/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: BlogPostResponse) {
        return parseDefaultValues(response.data) as BlogPostTransformedResponse;
      },
      providesTags: ['BlogPost'],
    }),
    blogPosts: build.query<TransformedBlogPosts, string>({
      query: (queryString) => `landing_pages${queryString}&editorial=true`,
      keepUnusedDataFor: 10,
      transformResponse(response: BlogPostsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['BlogPost'],
    }),
    blogPostsSlugs: build.query<OptionTypeResponse, SlugParams>({
      query: ({ store }) => {
        if (store) {
          return {
            url: 'landing_pages/slugs?editorial=true',
            headers: { store },
          };
        }
        return 'landing_pages/slugs?editorial=true';
      },
      transformResponse(response: SlugResponse) {
        return {
          data: response.data.map((slug) => ({
            value: slug,
            label: slug,
          })),
        };
      },
    }),
    deleteBlogPost: build.mutation<void, number>({
      query: (id) => ({
        url: `landing_pages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BlogPost'],
    }),
    createBlogPost: build.mutation<any, any>({
      query: (data) => {
        const payload = convertKeysToSnakeCase(preparePayload(data));

        const formData = serialize(
          payload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'landing_page',
        );

        return {
          url: 'landing_pages',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['BlogPost'],
    }),
    createBlogPostAsync: build.mutation<any, any>({
      query: (data) => {
        const { store } = data;

        const formData = serialize(
          convertKeysToSnakeCase(preparePayload(data)),
          SERIALIZER_OPTIONS,
          new FormData(),
          'landing_page',
        );

        return {
          url: 'landing_pages/create_async',
          headers: { store },
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['BlogPost'],
    }),
    blogPostBulkDelete: build.mutation<void, BulkDelete>({
      query: (payload) => {
        return {
          url: 'landing_pages/bulk_delete',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['BlogPost'],
    }),
    updateBlogPost: build.mutation<void, any>({
      query: (formValues) => {
        const { id } = formValues;
        const payload = convertKeysToSnakeCase(preparePayload(formValues));

        const formData = serialize(
          payload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'landing_page',
        );

        return {
          url: `landing_pages/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['BlogPost'],
    }),
    importBlogPost: build.mutation<void, LandingPageImportParams>({
      query: (payload) => {
        return {
          url: 'landing_pages/country_import',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['BlogPost'],
    }),
  }),
});

export const {
  useBlogPostQuery,
  useBlogPostsQuery,
  useBlogPostsSlugsQuery,
  useDeleteBlogPostMutation,
  useCreateBlogPostMutation,
  useCreateBlogPostAsyncMutation,
  useBlogPostBulkDeleteMutation,
  useUpdateBlogPostMutation,
  useImportBlogPostMutation,
} = blogPostApi;
