import { useFormContext, useWatch } from 'react-hook-form';
import { Flex, Heading, Spinner, VStack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card';
import HintCard from 'pages/WeatherConditions/Form/components/Preview/HintCard';

function WeatherConditionPreview() {
  const { control } = useFormContext();
  const formValues = useWatch({ control });

  return (
    <Card
      maxW="452px"
      padding="32px"
      style={{ position: 'sticky', top: '65px' }}
    >
      <Heading as="h4" fontSize={16} mb={8} pt={2}>
        PODGLĄD
      </Heading>
      {isEmpty(formValues) && (
        <Flex w="100%" justify="center">
          <Spinner size="md" />
        </Flex>
      )}
      <VStack spacing={1}>
        {formValues?.weatherCategoryConditionHintsAttributes?.map(
          (hint: WeatherCategoryConditionHint) => (
            <HintCard key={hint?.id} hint={hint} kind={formValues?.kind} />
          ),
        )}
      </VStack>
    </Card>
  );
}

export default WeatherConditionPreview;
