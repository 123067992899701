import { RootState } from 'app/store';

const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;

const selectEmail = (state: RootState) => state.auth.email;

const selectStore = (state: RootState) => state.auth.store;

const selectUser = (state: RootState) => state.auth.user;

export { selectIsAuthenticated, selectEmail, selectStore, selectUser };
