import BadgePreview from 'components/BadgePreview';

interface BadgeProps {
  flag: boolean;
}

function Badge({ flag }: BadgeProps) {
  return (
    <BadgePreview
      backgroundColor="black"
      text={flag ? 'Tak' : 'Nie'}
      textColor="white"
    />
  );
}

export default Badge;
