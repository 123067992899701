import { Box, Flex } from '@chakra-ui/react';
import SectionTitle from 'components/Form/SectionTitle';
import RichText from 'components/Form/RichText';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import ButtonFormSection from 'components/Container/Sections/ButtonFormSection';
import AlignmentSection from 'components/Container/Sections/AlignmentSection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';

function TextBannerForm({ prepareFieldName, isEditMode }: FormComponentProps) {
  return (
    <>
      <Box as="fieldset">
        <SectionTitle as="legend" mb={20}>
          Treść
        </SectionTitle>

        <AlignmentSection
          fieldName={prepareFieldName('contentAlignment')}
          label="Wyrównanie tekstu"
        />

        <Flex pb={9} gap={4}>
          <Box w={FORM_BODY_MAX_WIDTH}>
            <RichText
              label="Treść"
              name={prepareFieldName('content')}
              placeholder="Tu dodaj opis, możesz skorzystać ze znaczników html, które znajdziesz powyżej"
            />
          </Box>
          <Box w={136}>
            <Input
              label="Rozmiar tekstu (w pt)"
              defaultValue={12}
              type="number"
              name={prepareFieldName('contentFontSize')}
            />
          </Box>
        </Flex>
      </Box>

      <ColorPicker
        name={prepareFieldName('contentTextColor')}
        label="Kolor tekstu"
        mb={9}
        maxW={FORM_BODY_MAX_WIDTH}
      />

      <ColorPicker
        name={prepareFieldName('textHighlight')}
        label="Kolor wyróżnienia"
        mb={9}
        maxW={FORM_BODY_MAX_WIDTH}
      />

      <HeaderFormSection
        prepareFieldName={prepareFieldName}
        titleLabel="Treść"
        components={[
          COMPONENTS.TITLE,
          COMPONENTS.COLORTXT,
          COMPONENTS.ALIGNMENT,
        ]}
      />

      <ButtonFormSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
        withLinkCreator
      />

      <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH} mb="8px">
        <SectionTitle as="legend">Tło kontenera</SectionTitle>

        <ColorPicker
          name={prepareFieldName('backgroundColor')}
          label="Kolor tła"
          mb={9}
        />
      </Box>
    </>
  );
}

export default TextBannerForm;
