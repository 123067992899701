import omit from 'lodash/omit';
import { SplashScreen } from 'types/splashScreens';

const PARSED_VALUES: string[] = ['activeFrom, activeTo'];

const parseDefaultValues = (splashScreen: SplashScreen) => {
  const result: Record<string, any> = {
    ...omit(splashScreen, PARSED_VALUES),
  };
  const { activeFrom, activeTo } = splashScreen;

  if (activeFrom) {
    result.dateFrom = new Date(activeFrom);
    result.timeFrom = new Date(activeFrom);
  }

  if (activeTo) {
    result.dateTo = new Date(activeTo);
    result.timeTo = new Date(activeTo);
  }

  return result;
};

export default parseDefaultValues;
