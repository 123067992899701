import { useEffect, useState } from 'react';
import { Box, FormControl, FormControlProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface OptionalFormFieldsetProps extends FormControlProps {
  children: React.ReactNode;
  title: string;
  isActive?: boolean;
  headerWidth?: number | string;
  sectionWidth?: number | string;
  hint?: string;
  name: string;
  isReadOnly?: boolean;
}

function OptionalFormFieldset({
  children,
  title,
  isActive,
  name,
  headerWidth,
  sectionWidth,
  hint,
  isReadOnly,
  ...rest
}: OptionalFormFieldsetProps) {
  const [active, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <FormControl as="fieldset" maxW={sectionWidth} pos="relative" {...rest}>
      {isReadOnly ? (
        <Box
          pos="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          zIndex={10}
          backgroundColor="rgba(255,255,255,0.5)"
        />
      ) : null}
      <ToggleCheckbox
        name={name}
        label={title}
        sectionWidth={headerWidth}
        hint={hint}
      />

      <motion.div
        initial={{
          marginTop: '32px',
          height: active ? 'auto' : 0,
          opacity: active ? 1 : 0,
          overflow: active ? 'visible' : 'hidden',
        }}
        animate={{
          marginTop: '32px',
          height: active ? 'auto' : 0,
          opacity: active ? 1 : 0,
          overflow: active ? 'visible' : 'hidden',
        }}
      >
        {children}
      </motion.div>
    </FormControl>
  );
}

OptionalFormFieldset.defaultProps = {
  isActive: false,
  headerWidth: '100%',
  sectionWidth: '100%',
  hint: null,
  isReadOnly: false,
};

export default OptionalFormFieldset;
