import { MdOutlineCheck } from 'react-icons/md';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { Country } from 'types/common';
import { ICON_DICT, LABEL_DICT } from 'components/CountryChange/dictionary';

type CountryButtonProps = Country & {
  name: string;
};

function CountryButton({ countryCode, name, store }: CountryButtonProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value: formValue } }) => (
        <Box
          px="5px"
          py="10px"
          maxW="208px"
          onClick={() => {
            onChange(store);
          }}
          _hover={{ bg: 'complementary.lightGrey', cursor: 'pointer' }}
        >
          <Flex direction="row" h="48px" w="208px" align="center">
            {ICON_DICT[countryCode]}
            <Text
              w="100%"
              textAlign="left"
              fontSize="16px"
              paddingLeft="16px"
              fontWeight={formValue === store ? 600 : 400}
            >
              {LABEL_DICT[countryCode]}
            </Text>
            {formValue === store && (
              <Box marginRight="12px">
                <MdOutlineCheck />
              </Box>
            )}
          </Flex>
        </Box>
      )}
    />
  );
}

export default CountryButton;
