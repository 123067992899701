import { VStack, Text } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';

import useImage from 'utils/useImage';

interface ImageProps {
  element: { image: any; magentoImageUrl: any };
  number?: number;
  props?: any;
}

function Image({ element, number, props }: ImageProps) {
  const imagePreview = useImage(
    element?.magentoImageUrl ? element : element?.image,
  );

  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      bgColor="complementary.whiteGrey"
      bgImage={imagePreview}
      bgPosition="50% 50%"
      bgRepeat="no-repeat"
      bgSize="contain"
      position="relative"
      {...props}
    >
      {!imagePreview && (
        <>
          {number && (
            <Text position="absolute" top={2} left={4}>
              {number}
            </Text>
          )}
          <BiImage size={30} />
          <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
            Zdjęcie
          </Text>
        </>
      )}
    </VStack>
  );
}

Image.defaultProps = {
  number: undefined,
  props: {},
};

export default Image;
