import { useMemo, useState } from 'react';
import { Box, Stack, Text, Tooltip } from '@chakra-ui/react';
import qs from 'query-string';
import { startOfDay, endOfDay } from 'date-fns';
import { MdInfo } from 'react-icons/md';
import { Dashboard } from 'types/dashboard';
import { useAppstoryClicksQuery } from 'app/services/statisticsApi';
import StatsTooltip from 'components/Stats/StatsTooltip';

export interface ShowAppstoryStatsProps {
  data: Dashboard;
  sku: string;
}

function ShowAppstoryStats({ data, sku }: ShowAppstoryStatsProps) {
  const [previewIsVisible, setPreviewIsVisible] = useState(false);

  const queryString = useMemo(() => {
    const timeFrom = data?.activeFrom
      ? new Date(data?.activeFrom)
      : new Date(data?.createdAt);
    const timeTo = data?.activeTo ? new Date(data.activeTo) : new Date();

    const params = {
      timeFrom: startOfDay(timeFrom).toISOString(),
      timeTo: endOfDay(timeTo).toISOString(),
      refererId: data?.analyticsId,
      sku,
    };

    return qs.stringify(params);
  }, [data, sku]);

  const { data: stats, isFetching } = useAppstoryClicksQuery(queryString, {
    skip: !previewIsVisible,
  });

  return (
    <Tooltip
      onOpen={() => setPreviewIsVisible(true)}
      onClose={() => setPreviewIsVisible(false)}
      padding={0}
      openDelay={500}
      label={
        <StatsTooltip isFetching={isFetching}>
          <Stack overflowX="auto" gap={4}>
            <Text fontSize="16px" fontWeight={600} textTransform="uppercase">
              Statystyki
            </Text>
            <Text fontSize="16px">
              <Text as="span" fontSize="16px" fontWeight={600}>
                Suma kliknięć:{' '}
              </Text>
              {stats}
            </Text>
          </Stack>
        </StatsTooltip>
      }
    >
      <Box>
        <MdInfo size={24} color="#000" />
      </Box>
    </Tooltip>
  );
}

export default ShowAppstoryStats;
