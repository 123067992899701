import { VStack, Text, HStack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { CONDITION_DICT } from 'pages/WeatherCategories/Preview/constants';

function GetColumns(): ColumnDef<WeatherCondition, string>[] {
  return [
    {
      header: 'RODZAJ',
      accessorKey: 'kind',
      cell: (info) => {
        const condition = CONDITION_DICT[info.getValue()];

        return (
          <HStack spacing={2}>
            <Text fontSize="24px">{condition?.emoji}</Text>
            <Text fontSize="16px">{condition?.name}</Text>
          </HStack>
        );
      },
    },
    {
      header: 'PODPOWIEDZI',
      cell: ({
        row: {
          original: { weatherCategoryConditionHints },
        },
      }) => (
        <VStack justify="start" spacing={8}>
          {weatherCategoryConditionHints
            ? weatherCategoryConditionHints?.map((hint) => (
                <Text key={hint.id} w="100%" fontSize="16px">
                  {hint.hintMessage}
                </Text>
              ))
            : '-'}
        </VStack>
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
