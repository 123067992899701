import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'pages/Category/Form/Edit/components/HeaderNavigation';
import parseDefaultValues from 'pages/Category/Form/Edit/parseDefaultValues';
import {
  useCategoryQuery,
  useUpdateCategoryMutation,
} from 'app/services/categoryApi';
import ContainerWrapper from 'pages/Category/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Category/Form/constants';

function DashboardEdit() {
  const { id, store } = useParams();
  const navigate = useNavigate();
  const { queryString } = useQueryParams();
  const categoryPath =
    generatePath(ROUTES.category.base, {
      store,
    }) + queryString;
  const { data: { data: category } = {}, isFetching } = useCategoryQuery(id!);
  const [updateCategory, { isLoading: updateRunning }] =
    useUpdateCategoryMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (!data.image) delete data.image;
      if (!data.logo) delete data.logo;

      await updateCategory(data).unwrap();
      navigate(categoryPath);
      toast.success(`Zmiany w kategorii zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (category) reset({ ...DEFAULT_VALUES, ...parseDefaultValues(category) });
  }, [category, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        gender={category?.genderName!}
        categoryPath={categoryPath}
      />
      <FormProvider {...methods}>
        <Box as="form" id="category-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="category-editor"
        backPath={categoryPath}
      />
    </Box>
  );
}

export default DashboardEdit;
