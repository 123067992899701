/* eslint-disable array-callback-return */
import { useCallback, useEffect, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { differenceBy, find } from 'lodash';
import ROUTES from 'app/routes';
import { transformErrors } from 'utils/api';
import AddButton from 'components/AddButton';
import DndWrapper from 'components/DndWrapper';
import FormSpinner from 'components/FormSpinner';
import { FORM_MAX_WIDTH } from 'utils/constants';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import LoadingIndicator from 'components/LoadingIndicator';
import HeaderNavigation from 'components/HeaderNavigation';
import HeaderForm from 'pages/BlogPost/Form/components/HeaderForm';
import { EDIT_SCHEMA, EMPTY_CONTAINER } from 'pages/LandingPage/Form/constants';
import ContainerWrapper from 'pages/LandingPage/Form/Edit/components/ContainerWrapper';
import { LandingPageTransformed } from 'types/landingPage';
import { transformRemoved } from 'utils/helpers';
import {
  useBlogPostQuery,
  useUpdateBlogPostMutation,
} from 'app/services/blogPostsApi';
import LandingPagePreview from 'pages/LandingPage/components/LandingPagePreview';
import { DEFAULT_VALUES } from 'pages/BlogPost/Form/constants';
import useRoute from 'utils/useRoute';

let debounce: ReturnType<typeof setTimeout>;

function BlogPostEdit() {
  const { id } = useParams();
  const backPath = useRoute(ROUTES.blogPost.base);
  const navigate = useNavigate();
  const [removedContainers, removeContainer] = useState<any[]>([]);

  const timestamp = useMemo(() => Date.now(), []);
  const {
    data: blogPost = {},
    isFetching,
    isSuccess,
  } = useBlogPostQuery(`${id!}?t=${timestamp}`);
  const [updateBlogPost, { isLoading: isUpdating }] =
    useUpdateBlogPostMutation();

  const methods = useFormWithSchema(EDIT_SCHEMA, {
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors: formErrors },
  } = methods;

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      toast.error(
        'Formularz zawiera błędy. Sprawdź poprawność i spróbuj ponownie.',
      );
    }
    // eslint-disable-next-line no-console
    console.debug({ formErrors });
  }, [formErrors]);

  const {
    fields: containers,
    append,
    move,
    remove,
  } = useFieldArray({
    control,
    name: 'containersAttributes',
    keyName: 'fieldId',
  });

  const addContainerHandler = useCallback(() => {
    append({ ...EMPTY_CONTAINER, name: `KONTENER ${containers.length + 1}` });
  }, [append, containers]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = {
        ...data,
        containersAttributes: [
          ...data.containersAttributes!,
          ...transformRemoved(removedContainers),
        ],
      };

      payload.containersAttributes?.map((container) => {
        if (!container.withListingHeader) {
          container.bannerListingsAttributes =
            container.bannerListingsAttributes?.map((attribute: any) => ({
              ...attribute,
              _destroy: true,
            }));
        }

        if (container?.elementsAttributes) {
          const prevContainer = find(
            (blogPost as LandingPageTransformed)?.containersAttributes,
            {
              id: container?.id,
            },
          );
          if (prevContainer) {
            const removedElements = differenceBy(
              prevContainer?.elementsAttributes,
              container.elementsAttributes,
              'id',
            );
            container.elementsAttributes = [
              ...container.elementsAttributes,
              ...transformRemoved(removedElements),
            ];
          }
        }
      });

      const response: any = await updateBlogPost(payload);
      let errorOccured = false;
      if (Object.hasOwnProperty.call(response, 'error')) {
        const {
          error: { data: errors },
        } = response;
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field) => {
          errorOccured = true;
          if (field === 'containers') {
            const {
              containers: containersErrors,
            }: { containers: { [key: string]: string[] }[] } = errors;
            containersErrors.forEach((object, index) => {
              const nestedTransformedErrors = transformErrors(object, null);
              Object.keys(nestedTransformedErrors).forEach((nestedField) => {
                setError(
                  `containersAttributes[${index}].${nestedField}` as any,
                  {
                    type: 'custom',
                    message: nestedTransformedErrors[nestedField],
                  },
                );
              });
            });
          } else {
            setError(field as any, {
              type: 'custom',
              message: transformedErrors[field],
            });
          }
        });
      }
      if (errorOccured === false) {
        toast.success('Blog post został zaktualizowany');
        navigate(backPath);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log({ error });
    }
  });

  const reorderContainers = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      clearTimeout(debounce);
      // eslint-disable-next-line
      debounce = setTimeout(() => move(dragIndex, hoverIndex), 500);
    },
    [move],
  );

  const removeComponentHandler = useCallback(
    (index: number) => {
      const container = containers[index] as any;
      if (container?.id) {
        removeContainer([...removedContainers, container]);
      }
      remove(index);
    },
    [containers, remove, removedContainers],
  );

  useEffect(() => {
    if (isSuccess && blogPost) {
      reset(blogPost);
    }
  }, [isSuccess, blogPost, reset]);

  const isLoading = isFetching || isUpdating;

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Blog Post',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj blog post' }]}
      />
      <FormProvider {...methods}>
        {isFetching && <LoadingIndicator />}
        {!isFetching && (
          <Flex
            as="form"
            id="blog-post-editor"
            onSubmit={onSubmit}
            direction="row"
            gap={4}
            w="100%"
            pb="100px"
          >
            <Box maxW={FORM_MAX_WIDTH} minW={650} w="100%">
              <HeaderForm isEdit />
              {isUpdating && <FormSpinner />}
              {containers.map((container, index) => (
                <DndWrapper
                  key={`blog-post-editor${container.fieldId}`}
                  id={container.fieldId}
                  isLoading={isLoading}
                  index={index}
                  reorderContainers={reorderContainers}
                  removeContainer={removeComponentHandler}
                  ContainerWrapper={ContainerWrapper}
                  multipleContainers
                  isBlogPost
                />
              ))}
              <AddButton addHandler={addContainerHandler} mt="8px" />
            </Box>
            <Box w="100%" gap="10px" pos="sticky">
              <LandingPagePreview />
            </Box>
          </Flex>
        )}
      </FormProvider>
      <FooterButtons
        isLoading={isLoading}
        formId="blog-post-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default BlogPostEdit;
