import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDashboardContainerQuery } from 'app/services/dashboardApi';
import MainTable from 'pages/Dashboard/Preview/components/MainTable';
import HeaderNavigation from 'pages/Dashboard/Preview/components/HeaderNavigation';
import LoadingIndicator from 'components/LoadingIndicator';

function Preview() {
  const { id } = useParams();
  const { data: dashboard, isFetching } = useDashboardContainerQuery(id!);

  return (
    <Box pt="36px">
      <HeaderNavigation
        name={isFetching ? 'Loading...' : dashboard?.title || ''}
        id={dashboard?.id.toString() || ''}
      />
      {isFetching && <LoadingIndicator />}
      {!isFetching && <MainTable data={[dashboard!]} />}
    </Box>
  );
}

export default Preview;
