import * as yup from 'yup';
import { activityDatesSchema } from 'components/Container/schema';

export const BODY_SCHEMA = yup.object({
  title: yup.string().nullable(),
  content: yup.string().nullable(),
  inAppUrl: yup.string().nullable(),
  active: yup.boolean().nullable(),
  ...activityDatesSchema,
});

export const DEFAULT_VALUES = {
  title: '',
  content: '',
  inAppUrl: '',
  active: true,
  timeFrom: null,
  dateFrom: null,
  timeTo: null,
  dateTo: null,
};
