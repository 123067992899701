import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import {
  convertKeysToSnakeCase,
  transformLinksAttributesForPayload,
} from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { transformLinkParameters } from 'utils/linkCreator';

export const aboutsApi = api.injectEndpoints({
  endpoints: (build) => ({
    abouts: build.query<TransformedAboutsResponse, string>({
      query: (queryString) => `abouts${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: AboutsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Abouts'],
    }),
    deleteAbout: build.mutation<void, number>({
      query: (id) => ({
        url: `abouts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Abouts'],
    }),
    reorderAbout: build.mutation<void, ReorderParams>({
      query: ({ body, id }) => ({
        url: `abouts/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Abouts'],
    }),
    createAbout: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(
          transformLinksAttributesForPayload(payload),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'about',
        );
        return {
          url: 'abouts',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Abouts'],
    }),
    about: build.query<TransformedAbout, any>({
      query: (id) => `abouts/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Abouts'],
      transformResponse(response: AboutResponse) {
        const data = transformLinkParameters(response.data);
        return data as TransformedAbout;
      },
    }),
    updateAbout: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(
          transformLinksAttributesForPayload(payload),
        );

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'about',
        );

        return {
          url: `abouts/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Abouts'],
    }),
  }),
});

export const {
  useAboutsQuery,
  useDeleteAboutMutation,
  useReorderAboutMutation,
  useCreateAboutMutation,
  useAboutQuery,
  useUpdateAboutMutation,
} = aboutsApi;
