import { Icon, IconProps } from '@chakra-ui/react';

function CaretDown(props: IconProps) {
  return (
    <Icon
      width="14px"
      height="8px"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.4331 1.18337L6.99974 7.6167L0.566406 1.18337L1.51641 0.233366L6.99974 5.73337L12.4831 0.233366L13.4331 1.18337Z"
        fill="black"
      />
    </Icon>
  );
}

export default CaretDown;
