import { Box, Flex } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import { CategoryContainer } from 'types/categoryContainer';

function GetColumns(): ColumnDef<CategoryContainer, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => (
        <Flex align="center" direction="column">
          {info.getValue()}
        </Flex>
      ),
    },
    {
      header: 'NAZWA KATEGORII',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ID TAKSONOMII',
      accessorKey: 'taxonomyId',
      cell: (info) => <Box textAlign="left">{info.getValue()}</Box>,
    },
    {
      header: 'NAGŁÓWEK',
      accessorKey: 'specialElementsHeader',
      cell: (info) => info.getValue(),
    },
    {
      header: 'IKONA',
      accessorKey: 'specialElementsIcon.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
      cell: () => '',
    },
  ];
}

export default GetColumns;
