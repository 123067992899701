/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Faqs/Form/Create/components/ContainerWrapper';
import ROUTES from 'app/routes';
import { transformErrors } from 'utils/api';
import { FORM_MAX_WIDTH } from 'utils/constants';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Faqs/Form/constants';
import { useCreateFaqMutation } from 'app/services/faqsApi';
import useQueryParams from 'utils/useQueryParams';

function FaqCreate() {
  const { store, parentId } = useParams();
  const { queryString } = useQueryParams();

  const faqsPath = useMemo(() => {
    const route = parentId ? ROUTES.faqs.preview : ROUTES.faqs.base;
    return (
      generatePath(route, {
        store,
        parentId,
      }) + queryString
    );
  }, [parentId, queryString, store]);

  const [createFaq, { isLoading: isCreating }] = useCreateFaqMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const navigate = useNavigate();

  const { handleSubmit, setError } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response: any = await createFaq({ ...data, parentId });
      const hasError = Object.hasOwnProperty.call(response, 'error');
      if (hasError) {
        const {
          error: { data: errors },
        } = response;
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field: string) => {
          setError(field as never, {
            type: 'custom',
            message: transformedErrors[field],
          });
        });
      } else {
        navigate(faqsPath);
        toast.success(`FAQ zostało pomyślnie utworzone!`);
      }
    } catch (err) {
      // eslint-disable-next-line
      console.debug(err);
    }
  });

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'FAQ',
          to: faqsPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj FAQ' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="faq-creator"
          onSubmit={onSubmit}
          pb="120px"
          maxW={FORM_MAX_WIDTH}
        >
          <ContainerWrapper isLoading={isCreating} />
        </Box>
      </FormProvider>
      <FooterButtons
        isLoading={isCreating}
        isCreate
        formId="faq-creator"
        backPath={faqsPath}
      />
    </Box>
  );
}

export default FaqCreate;
