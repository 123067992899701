import { Box } from '@chakra-ui/react';
import SectionTitle from 'components/Form/SectionTitle';
import Select from 'components/Form/Select';
import DropzoneField from 'components/Form/Dropzone';
import { REGULATION_KIND } from 'pages/Regulations/Form/constants';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

const OPTIONS = Object.entries(REGULATION_KIND).map(([key, value]) => ({
  label: value,
  value: key,
}));

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <>
      <Select
        name={prepareFieldName('kind')}
        options={OPTIONS}
        placeholder="Wpisz lub wybierz z listy"
        label="Rodzaj regulaminu"
      />
      <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Załącznik 1</SectionTitle>
        <DropzoneField name={prepareFieldName('attachment1')} type="document" />
      </Box>
      <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Załącznik 2</SectionTitle>
        <DropzoneField name={prepareFieldName('attachment2')} type="document" />
      </Box>
      <ToggleCheckbox label="Aktywność" name={prepareFieldName('active')} />
    </>
  );
}

export default FormBody;
