import {
  Button,
  Collapse,
  List,
  ListItem,
  useDisclosure,
} from '@chakra-ui/react';

type AnswersPreviewProps = {
  answers: SurveyAnswer[];
};

function AnswersPreview({ answers }: AnswersPreviewProps) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Button
        variant="link"
        color="complementary.blue"
        fontSize="inherit"
        width="fit-content"
        paddingX={0}
        onClick={onToggle}
      >
        {`${isOpen ? 'Ukryj' : 'Pokaż'} odpowiedzi`}
      </Button>
      <Collapse in={isOpen}>
        <List>
          {answers.map((answer) => (
            <ListItem
              key={answer.id}
              textDecoration={answer.correct ? 'underline' : 'none'}
            >
              {answer.option}. {answer.title}
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default AnswersPreview;
