import { Link } from 'react-router-dom';
import ROUTES from 'app/routes';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Announcements/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import useRoute from 'utils/useRoute';
import {
  useAnnouncementsQuery,
  useDeleteAnnouncementMutation,
} from 'app/services/announcementsApi';

function Announcements() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newAnnouncementPath = useRoute(ROUTES.announcements.create);

  const [, state] = useDeleteAnnouncementMutation({
    fixedCacheKey: 'announcements',
  });

  const {
    isFetching: announcementsLoading,
    data: { data: announcements, totals } = { data: [], totals: 0 },
  } = useAnnouncementsQuery(queryString);

  const { isLoading } = state;
  const showLoading = announcementsLoading || isLoading;

  return (
    <ListingLayout
      title="Ekran CRUD"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWY EKRAN CRUD',
          as: Link,
          to: newAnnouncementPath,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={announcements}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Announcements;
