import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/ClothingMachines/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/ClothingMachines/Form/constants';
import { transformErrors } from 'utils/api';
import {
  useClothingMachineQuery,
  useUpdateClothingMachineMutation,
} from 'app/services/clothingMachinesApi';
import useRoute from 'utils/useRoute';

function ClothingMachineEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.clothingMachines.base);
  const { data: { data: clothingMachine } = {}, isFetching } =
    useClothingMachineQuery(id);
  const [updateClothingMachine, { isLoading: updateRunning }] =
    useUpdateClothingMachineMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateClothingMachine(data)
        .unwrap()
        .then(() => {
          navigate(backPath);
          toast.success(`Zmiany w ubraniomacie zostały zastosowane`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (clothingMachine) reset({ ...DEFAULT_VALUES, ...clothingMachine });
  }, [clothingMachine, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Ubraniomat',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj ubraniomat' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="clothing-machine-editor"
          onSubmit={onSubmit}
          pb="120px"
        >
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="clothing-machine-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default ClothingMachineEdit;
