import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import StatusBox from 'components/StatusBox';
import { CategoryTag } from 'types/categoryTags';
import { StatusType } from 'types/common';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<CategoryTag, string>[] {
  return [
    {
      header: 'KATEGORIA NADRZĘDNA',
      accessorKey: '1',
      cell: () => <Box minW="78px" />,
    },
    {
      header: 'NAZWA',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KATEGORIA W MAGENTO',
      accessorKey: 'externalCategoryId',
      cell: (info) => info.getValue(),
    },
    {
      header: 'POZYCJA',
      accessorKey: 'placement',
      cell: (info) => info.getValue(),
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: 'activeFrom',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: 'activeTo',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
  ];
}

export default GetColumns;
