import * as yup from 'yup';
import {
  activityDatesSchema,
  linkParametersSchema,
  validateImage,
} from 'components/Container/schema';
import { DEFAULT_FORM_VALUES as CONTAINER_DEFAULT_VALUES } from 'components/Container/constants';

export const BODY_SCHEMA = yup.object({
  kind: yup.string(),
  elementsHeader: yup.string().nullable(),
  textColor: yup
    .string()
    .nullable()
    .when('elementsHeader', {
      is: (elementsHeader: string) => !!elementsHeader,
      then: (schema) => schema.required('Pole jest wymagane'),
    }),
  elementsIcon: yup.mixed().nullable(),
  deleteElementsIcon: yup.mixed().nullable(),
  elementsAttributes: yup.array().of(
    yup.object().shape({
      image: yup.mixed().test({
        test(file) {
          return validateImage(file, 'appstories', this, true);
        },
      }),
      linkParametersAttributes: yup.array().of(
        yup.object().shape({
          ...linkParametersSchema,
          linkType: yup.string().nullable().required('Pole jest wymagane'),
        }),
      ),
      title: yup.string().nullable().required('Pole jest wymagne'),
      titleFontSize: yup.string().nullable().required('Pole jest wymagne'),
      textColor: yup.string().nullable().required('Pole jest wymagne'),
      ...activityDatesSchema,
    }),
  ),
  ...activityDatesSchema,
});

export const DEFAULT_FORM_VALUE = {
  kind: 'appstories',
  elementsHeader: '',
  textColor: null,
  elementsIcon: null,
  deleteElementsIcon: false,
  elementsAttributes: [
    CONTAINER_DEFAULT_VALUES.appstories.elementsAttributes[0],
  ],
};
