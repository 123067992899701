import styled from '@emotion/styled';

const Td = styled.td`
  padding-inline: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 136px;
  vertical-align: top;
  padding-top: 16px;

  :nth-of-type(1) {
    width: 128px;
  }
  :nth-of-type(2) {
    width: 248px;
  }
  :nth-of-type(3) {
    padding-top: 0;
    width: 248px;
  }
  :nth-of-type(4) {
    padding-top: 0;
    width: 248px;
  }
  :nth-of-type(5) {
    width: 128px;
  }
  :nth-of-type(6) {
    width: 128px;
  }
  :nth-of-type(7) {
    width: 128px;
  }
  :nth-of-type(8) {
    width: 128px;
  }
  :nth-of-type(9) {
    width: 128px;
  }
  :nth-of-type(10) {
    width: 128px;
  }
`;

export default Td;
