import type { DeepPartial, Theme } from '@chakra-ui/react';

const extendedColors = {
  complementary: {
    grey: '#A9A9A9',
    lightGrey: '#F9FAF9',
    red: '#F60D45',
    blue: '#0B24FB',
    lightBlack: '#353535',
    whiteGrey: '#EEEEEE',
    lightBlue: '#ced3fe',
    listingGreen: '#66fda8',
    listingGrey: '#cbcbcb',
    listingBlue: '#ced3fe',
    specialGreen: '#00fb6e',
    purpleBlue: '#9da7fd',
  },
};

const overridenChakraColors: DeepPartial<Theme['colors']> = {};

export default {
  ...overridenChakraColors,
  ...extendedColors,
};
