import * as yup from 'yup';

export const BENEFITS_SCREEN_KIND: { [key: string]: string } = {
  registration: 'Logowanie/rejestracja',
  join_app: 'Dołącz do zalogowanych',
  notifications: 'Powiadomienia',
  loyalty_program: 'Program lojalnościowy',
  subscription: 'Subskrypcja',
};

export const BENEFITS_SCREEN_KIND_OPTIONS = Object.entries(
  BENEFITS_SCREEN_KIND,
).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const BODY_SCHEMA = yup.object({
  kind: yup.string().required('Pole jest wymagane'),
  backgroundColor: yup.string().nullable(),
  background: yup.mixed().nullable(),
});

export const DEFAULT_VALUES = {
  kind: '',
  backgroundColor: '#FFFFFF',
  background: null,
};
