import { useCallback, useEffect } from 'react';
import { useFormContext, useWatch, useFieldArray } from 'react-hook-form';
import { Box, HStack, FormControl, Button } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';

import SectionTitle from 'components/Form/SectionTitle';
import ColorPicker from 'components/Form/Color';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import DropzoneField from 'components/Form/Dropzone';
import { FormComponentProps } from 'types/dashboardCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import LinkCreatorSection from 'components/LinkCreator';
import TextElement from 'components/Container/Forms/NewsBannerForm/TextElement';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';

const BANNER_TYPES: { [key: string]: string } = {
  TEXT: 'text',
  IMAGE: 'image',
};

const BANNER_TYPES_LABELS: { [key: string]: string } = {
  text: 'Tekst',
  image: 'Zdjęcie',
};

// kind: text_banner_slim
function NewsBannerForm({ prepareFieldName, isEditMode }: FormComponentProps) {
  const { control, setValue } = useFormContext();
  const dispatch = useAppDispatch();

  const bannerType = useWatch({
    control,
    name: prepareFieldName('type'),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'fieldId',
  });

  const addTextInput = useCallback(() => {
    append({ title: '', active: true });
  }, [append]);

  const removeTextInput = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [dispatch, fields, isEditMode, remove],
  );

  useEffect(() => {
    // for banner preview types on listing
    if (bannerType === BANNER_TYPES.TEXT) {
      setValue(prepareFieldName('image'), null);
    }
  }, [bannerType, prepareFieldName, setValue, isEditMode]);

  useEffect(() => {
    if (bannerType === BANNER_TYPES.IMAGE) {
      for (let i = 0; i < fields.length; i += 1) {
        remove(i);
      }
    } else if (!fields.length) {
      append({ title: '', active: true });
    }
  }, [bannerType, fields, remove, append]);

  return (
    <Box maxW={FORM_BODY_MAX_WIDTH}>
      <FormControl as="fieldset" mb={9}>
        <SectionTitle as="legend">Wybierz typ</SectionTitle>

        <HStack justifyContent="flex-start">
          {Object.values(BANNER_TYPES).map((t: string) => (
            <RadioBoxControl
              key={`banner-type_${t}`}
              label={BANNER_TYPES_LABELS[t]}
              name={prepareFieldName('type')}
              id={`${prepareFieldName('type')}_${t}`}
              value={t}
            />
          ))}
        </HStack>
      </FormControl>

      {bannerType === 'text' && (
        <>
          <FormControl as="fieldset" mb={9}>
            <SectionTitle as="legend">Tekst</SectionTitle>

            {fields.map((field, index) => (
              <TextElement
                key={field.fieldId}
                prepareFieldName={prepareFieldName}
                allowDelete={fields.length > 1}
                index={index}
                onDelete={removeTextInput}
                isEditMode={isEditMode || false}
              />
            ))}

            <Button
              type="button"
              variant="solid"
              mt={4}
              w="100%"
              onClick={addTextInput}
              fontSize="12px"
            >
              DODAJ TEKST
              <Box as="span" ml={2}>
                <BiPlus size={24} />
              </Box>
            </Button>
          </FormControl>
          <FormControl as="fieldset" mb={9}>
            <ColorPicker
              name={prepareFieldName('textColor')}
              label="Kolor tekstu"
            />
          </FormControl>

          <FormControl as="fieldset" mb={9}>
            <ColorPicker
              name={prepareFieldName('backgroundColor')}
              label="Kolor tła"
            />
          </FormControl>
        </>
      )}

      {bannerType === 'image' && (
        <>
          <FormControl as="fieldset" mb={9}>
            <SectionTitle as="legend">Zdjęcie</SectionTitle>
            <DropzoneField
              name={prepareFieldName('image')}
              title="Dodaj zdjęcie w formacie .png, .jpg (min. 160px x 36px)"
            />
          </FormControl>
          <FormControl as="fieldset" mb={9}>
            <ColorPicker
              name={prepareFieldName('backgroundColor')}
              label="Kolor tła"
            />
          </FormControl>
        </>
      )}

      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </Box>
  );
}
export default NewsBannerForm;
