import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { get } from 'lodash';
import LoadingIndicator from 'components/LoadingIndicator';
import { useWeatherCategoryQuery } from 'app/services/weatherCategoriesApi';
import HeaderNavigation from 'pages/WeatherCategories/Preview/components/HeaderNavigation';
import Table from 'pages/WeatherCategories/Preview/components/Table/index';
import ListingTabs from 'components/ListingTabs';
import useQueryParams from 'utils/useQueryParams';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import {
  DEFAULT_SEASON,
  TAB_OPTIONS,
} from 'pages/WeatherCategories/Preview/constants';

function WeatherCategoryPreview() {
  const { id = '' } = useParams();
  const { search } = useQueryParams({
    season: DEFAULT_SEASON,
  });
  const pickedTab = search.get('season');

  const { isFetching, data: { data: weatherCategory } = {} } =
    useWeatherCategoryQuery(id);

  const data = useMemo(() => {
    if (weatherCategory) {
      return get(weatherCategory, pickedTab || DEFAULT_SEASON);
    }
    return [];
  }, [pickedTab, weatherCategory]);

  return (
    <Box pt="36px">
      <HeaderNavigation
        kind={weatherCategory?.dashboard?.kind ?? ''}
        documentUrl={weatherCategory?.document?.url ?? ''}
        id={id}
      />
      <ListingTabs paramName="season" options={TAB_OPTIONS} />
      {isFetching && <LoadingIndicator />}
      {!isFetching && data && (
        <Table data={data} h={`calc(100vh - (${NAVBAR_HEIGHT} + 175px));`} />
      )}
    </Box>
  );
}

export default WeatherCategoryPreview;
