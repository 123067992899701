import { Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Editor from 'components/Form/Editor';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  const { parentId } = useParams();

  return (
    <Stack spacing={8}>
      {parentId && (
        <Editor label="Odpowiedź" name={prepareFieldName('answer')} />
      )}
      <ToggleCheckbox label="Aktywny" name={prepareFieldName('active')} />
    </Stack>
  );
}

export default FormBody;
