import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  title: yup.string().required('Pole jest wymagane'),
  description: yup.string().required('Pole jest wymagane'),
  textColor: yup.string().nullable(),
  button1BackgroundColor: yup.string().nullable(),
  button1TextColor: yup.string().nullable(),
  button2BorderColor: yup.string().nullable(),
  button2TextColor: yup.string().nullable(),
  background: yup.mixed().test({
    test(file, context) {
      if (!file) {
        return context.createError({ message: 'Zdjęcie tła jest wymagane' });
      }
      return true;
    },
  }),
  logoFourf: yup.mixed().nullable(),
});

export const DEFAULT_VALUES = {
  title: '',
  description: '',
  textColor: '#000000',
  button1BackgroundColor: '#000000',
  button1TextColor: '#FFFFFF',
  button2BorderColor: '#000000',
  button2TextColor: '#000000',
  background: null,
  logoFourf: null,
};
