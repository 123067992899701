import { Accordion, Box, Button, Stack } from '@chakra-ui/react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useCallback } from 'react';
import { BiPlus } from 'react-icons/bi';
import ColorPicker from 'components/Form/Color';
import Input from 'components/Form/Input';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';
import { DEFAULT_VALUES } from 'pages/SurveyQuestions/Form/constants';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import SurveyAnswerSection from 'components/Container/Sections/SurveyAnswerSection';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  isEditMode?: boolean;
}

function FormBody({ prepareFieldName, isEditMode }: FormBodyProps) {
  const { control } = useFormContext();
  const dispatch = useAppDispatch();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: prepareFieldName('answersAttributes'),
    keyName: 'fieldId',
  });

  const removeElement = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [isEditMode, remove, dispatch, fields],
  );

  const addElement = useCallback(() => {
    append(DEFAULT_VALUES.answersAttributes[0]);
  }, [append]);

  return (
    <Stack spacing={8} maxW={FORM_BODY_MAX_WIDTH}>
      <Input name={prepareFieldName('title')} label="Tytuł" />
      <Input name={prepareFieldName('header')} label="Nagłówek" />
      <ColorPicker
        name={prepareFieldName('gradientColorTop')}
        label="Górny kolor gradientu"
        showError
      />
      <ColorPicker
        name={prepareFieldName('gradientColorBottom')}
        label="Dolny kolor gradientu"
        showError
      />
      <ColorPicker
        name={prepareFieldName('textColor')}
        label="Kolor tekstu"
        showError
      />
      <Box as="fieldset">
        <Accordion defaultIndex={[0]} allowMultiple overflow="visible">
          {fields.map((field: any, index) => (
            <SurveyAnswerSection
              id={field.fieldId}
              itemIndex={index}
              prepareFieldName={prepareFieldName}
              key={field.fieldId}
              move={move}
              remove={removeElement}
            />
          ))}
          <Button
            type="button"
            variant="solid"
            mt={4}
            w="100%"
            onClick={addElement}
            fontSize="12px"
            maxW={FORM_BODY_MAX_WIDTH}
          >
            DODAJ ODPOWIEDŹ
            <Box as="span" ml={2}>
              <BiPlus size={24} />
            </Box>
          </Button>
        </Accordion>
      </Box>
    </Stack>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
