import omit from 'lodash/omit';
import { BenefitsScreenElement } from 'types/benefitsScreens';

const PARSED_VALUES = ['items'];

const parseDefaultValues = (benefitsScreen: BenefitsScreenElement) => {
  const result: Record<string, any> = {
    ...omit(benefitsScreen, PARSED_VALUES),
  };

  result.itemsAttributes = benefitsScreen.items;
  return result;
};

export default parseDefaultValues;
