import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { BiImage } from 'react-icons/bi';
import { Box, Text, VStack } from '@chakra-ui/react';
import { Image } from 'components/ImagesSlider';

interface ThumbnailsSliderProps {
  elements:
    | {
        image: {} | string;
      }[]
    | undefined[];
  setActiveIndex: (index: number) => void;
}

function ThumbnailsSlider({ elements, setActiveIndex }: ThumbnailsSliderProps) {
  return (
    <Box w="100%">
      <Swiper
        slidesPerView="auto"
        modules={[FreeMode]}
        freeMode
        centeredSlides
        slideToClickedSlide
        loop={elements.length > 3}
        onRealIndexChange={(slide) => {
          setActiveIndex(slide.realIndex);
        }}
      >
        {elements.map((element: any, idx: number) => (
          <SwiperSlide
            key={`color_image_ee${+idx}`}
            style={{ width: '80px', height: '148px' }}
          >
            {element?.image ? (
              <Image element={element} />
            ) : (
              <VStack
                alignItems="center"
                justifyContent="center"
                bgColor="complementary.whiteGrey"
                position="relative"
                h="100%"
              >
                <Text position="absolute" top={2} left={4} fontWeight={700}>
                  {idx + 1}
                </Text>

                <BiImage size={30} />
                <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
                  Zdjęcie
                </Text>
              </VStack>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default ThumbnailsSlider;
