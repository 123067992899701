import { Box, BoxProps, Text } from '@chakra-ui/react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

interface ExternalErrorProps extends BoxProps {
  name: string;
}
function ExternalError({ name }: ExternalErrorProps) {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <Box w="100%">
      <Text
        fontSize="12px"
        lineHeight="20px"
        fontWeight={400}
        letterSpacing="0.004em"
        textAlign="left"
        color="red.500"
      >
        {errorMessage}
      </Text>
    </Box>
  );
}

export default ExternalError;
