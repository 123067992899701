import { Button } from '@chakra-ui/react';
import { useSynchronizeMutation } from 'app/services/weatherCategoriesApi';

interface SynchronizeButtonProps {
  id: string;
  documentUrl: string;
}

function SynchronizeButton({ id, documentUrl }: SynchronizeButtonProps) {
  const [categorySynchronize, { isLoading: isSynchronizing }] =
    useSynchronizeMutation();

  if (!documentUrl) {
    return null;
  }

  return (
    <Button
      fontWeight={700}
      fontSize="12px"
      lineHeight="20px"
      paddingX="32px"
      textTransform="uppercase"
      isLoading={isSynchronizing}
      onClick={() => categorySynchronize(id)}
    >
      Synchronizuj
    </Button>
  );
}

export default SynchronizeButton;
