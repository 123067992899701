import { useMemo } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { MdOutlineEdit } from 'react-icons/md';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { generatePath, Link, useParams } from 'react-router-dom';
import Tr from 'components/Table/Tr';
import Td from 'pages/WeatherCategories/Preview/components/Table/components/Td';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';

interface TableRowProps {
  row: Row<WeatherCondition>;
}
function TableRow({ row }: TableRowProps) {
  const { store, id: categoryId } = useParams();
  const { queryString } = useQueryParams();
  const { id } = row.original;

  const editPath = useMemo(
    () =>
      generatePath(ROUTES.weatherCategories.conditions.edit, {
        id,
        categoryId,
        store,
      }) + queryString,
    [categoryId, id, queryString, store],
  );

  return (
    <Tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <Tooltip label="Edytuj" openDelay={1000}>
          <IconButton
            width="72px"
            height="56px"
            icon={<MdOutlineEdit size={20} />}
            aria-label="visiblity"
            variant="ghost"
            as={Link}
            to={editPath}
          />
        </Tooltip>
      </Td>
    </Tr>
  );
}

export default TableRow;
