import { Box } from '@chakra-ui/react';
import Dropzone from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import LinkCreatorSection from 'components/LinkCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import ButtonFormSection from 'components/Container/Sections/ButtonFormSection';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

function VideoBannerForm({ prepareFieldName, isEditMode }: FormComponentProps) {
  return (
    <>
      <Box as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Plik mp4</SectionTitle>
        <Dropzone name={prepareFieldName('video')} type="video" />
      </Box>
      <ToggleCheckbox
        label="Pokaż ikonę głośnika"
        name={prepareFieldName('hasSound')}
        mb="32px"
        maxW={FORM_BODY_MAX_WIDTH}
      />
      <HeaderFormSection
        prepareFieldName={prepareFieldName}
        components={[
          COMPONENTS.TITLE,
          COMPONENTS.SUBTITLE,
          COMPONENTS.COLORTXT,
          COMPONENTS.COLORBG,
        ]}
      />
      <ButtonFormSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </>
  );
}

export default VideoBannerForm;
