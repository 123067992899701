import 'swiper/css';
import 'swiper/css/free-mode';
import {
  Box,
  Text,
  Image,
  Heading,
  HStack,
  useDisclosure,
  VStack,
  BoxProps,
} from '@chakra-ui/react';
import { Swiper } from 'swiper/react';
import { FreeMode } from 'swiper';
import qs from 'query-string';
import { FiTag } from 'react-icons/fi';
import { AiOutlineHeart } from 'react-icons/ai';
import isEmpty from 'lodash/isEmpty';
import { motion } from 'framer-motion';
import { PreviewComponentProps } from 'types/dashboardCreator';
import { useFetchProductsBySKUQuery } from 'app/services/dashboardApi';
import ProductWrapper from 'components/Container/Previews/ProductCarouselPreview/ProductWrapper';
import { StyledSlide } from 'components/Container/Previews/ProductCarouselPreview';
import LoadingCover from 'components/LoadingCover';
import CartIcon from 'components/Icons/Cart';
import ShowAppstoryStats from 'components/Stats/ShowAppstoryStats';
import { useIsDashboardPreview } from 'app/routes';

const MotionBox = motion(Box);

type TaggedProductsPrevievProps = PreviewComponentProps & BoxProps;

function TaggedProductsPreview({
  formValues,
  showStats,
  ...boxProps
}: TaggedProductsPrevievProps) {
  const isDashboardPreview = useIsDashboardPreview();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const photoTags = isDashboardPreview
    ? formValues?.photoTags
    : formValues?.photoTagsAttributes;

  const linkParamValue = photoTags?.[0]?.linkParamValue;
  const skus = linkParamValue ? linkParamValue?.split(', ') : [];

  const { data: products = [], isFetching } = useFetchProductsBySKUQuery(
    qs.stringify({ skus }, { arrayFormat: 'bracket' }),
    {
      skip: !isOpen || isEmpty(skus),
    },
  );

  return (
    <>
      <HStack
        position="absolute"
        bottom="108px"
        right="24px"
        padding="8px 16px"
        background="#FFF"
        borderRadius="30px"
        spacing="4px"
        cursor="pointer"
        zIndex={1}
        onClick={onOpen}
        {...boxProps}
      >
        <Text fontSize={12} fontWeight={600} lineHeight={1.67}>
          Zobacz produkty
        </Text>
        <FiTag size={16} />
      </HStack>
      <>
        {isOpen && (
          <Box
            height="100%"
            width="100%"
            position="absolute"
            top="0"
            left="0"
            background="blackAlpha.600"
            margin={0}
            zIndex={5}
            onClick={onClose}
          />
        )}
        <MotionBox
          bg="#fff"
          position="absolute"
          left={0}
          bottom={0}
          width="100%"
          maxHeight="90%"
          overflowY="auto"
          px={4}
          pt={6}
          initial={false}
          zIndex={10}
          animate={isOpen ? 'open' : 'closed'}
          variants={{
            closed: {
              y: '100%',
              opacity: 0,
            },
            open: {
              y: 0,
              opacity: 1,
            },
          }}
        >
          {isFetching ? (
            <Box p={8}>
              <LoadingCover position="absolute" />
            </Box>
          ) : (
            <>
              <Heading as="h4" fontSize={16} mb={8} pt={2}>
                ZOBACZ PRODUKTY ZE ZDJĘCIA
              </Heading>
              {isEmpty(skus) || isEmpty(products) ? (
                <Text fontSize="md" fontWeight={700} textAlign="center" p={8}>
                  Uzupełnij listę produktów, aby wygenerować podgląd
                </Text>
              ) : (
                <Swiper
                  spaceBetween={8}
                  modules={[FreeMode]}
                  freeMode={{
                    enabled: false,
                    sticky: true,
                  }}
                  watchSlidesProgress
                  slidesPerView="auto"
                  nested
                >
                  {products?.map((product: any, idx: number) => {
                    const minimalPrice = product.price.minimalPrice.amount;
                    const regularPrice = product.price.regularPrice.amount;
                    const omnibusPrice =
                      product.priceRange.omnibusPrice.finalPrice;
                    const hasDiscount = regularPrice.value < minimalPrice.value;

                    return (
                      <StyledSlide key={`product_image${+idx}`}>
                        <ProductWrapper isEffectActive={false}>
                          <Image
                            src={product.appImage.url}
                            alt={`Zdjęcie produktu ${idx}`}
                            w="full"
                            h="304px"
                            bgColor="complementary.whiteGrey"
                          />
                          <Text fontSize="14px" lineHeight="24px" my={1}>
                            {product.name}
                          </Text>
                          <HStack spacing={1}>
                            <Text
                              fontWeight={hasDiscount ? 400 : 600}
                              fontSize="16px"
                              lineHeight="24px"
                              textDecoration={
                                hasDiscount ? 'line-through' : 'none'
                              }
                            >
                              {regularPrice.value} {regularPrice.currency}
                            </Text>
                            {hasDiscount && (
                              <Text
                                fontWeight={600}
                                fontSize="16px"
                                lineHeight="24px"
                                color="complementary.red"
                              >
                                {minimalPrice.value} {minimalPrice.currency}
                              </Text>
                            )}
                          </HStack>
                          {omnibusPrice.value && (
                            <Text fontSize="10px" lineHeight="16px">
                              Najniższa cena z ostatnich 30 dni przed obniżką:{' '}
                              {omnibusPrice.value} {omnibusPrice.currency}
                            </Text>
                          )}
                          <Text
                            fontWeight={600}
                            px={1}
                            position="absolute"
                            top="0"
                            transform="rotate(-90deg) translateX(-100%)"
                            transformOrigin="left top"
                            background="white"
                          >
                            Nowość
                          </Text>
                          <VStack
                            position="absolute"
                            top="0px"
                            right="0px"
                            spacing={0}
                          >
                            <Box padding="8px">
                              <AiOutlineHeart size={24} />
                            </Box>
                            <CartIcon />
                            {showStats && (
                              <Box padding="8px">
                                <ShowAppstoryStats
                                  data={formValues}
                                  sku={product?.sku}
                                />
                              </Box>
                            )}
                          </VStack>
                        </ProductWrapper>
                      </StyledSlide>
                    );
                  })}
                </Swiper>
              )}
            </>
          )}
        </MotionBox>
      </>
    </>
  );
}

export default TaggedProductsPreview;
