import { VStack, Text, HStack, Box } from '@chakra-ui/react';
import { CONDITION_DICT } from 'pages/WeatherCategories/Preview/constants';

interface HintCardProps {
  hint: WeatherCategoryConditionHint;
  kind: string;
}

function HintCard({ hint, kind }: HintCardProps) {
  const condition = CONDITION_DICT[kind];
  const hintContent = hint?.hintText?.replace(
    '%{link}',
    `<u>${hint?.linkText}</u>`,
  );

  return (
    <VStack
      align="start"
      bgColor="complementary.lightGrey"
      padding={4}
      spacing={1}
      w="100%"
    >
      <HStack spacing={2}>
        <Text fontSize="24px">{condition.emoji}</Text>
        <Text fontSize="14px" fontWeight={600}>
          CZEŚĆ,
        </Text>
      </HStack>

      <Box>
        <Text fontSize="14px" lineHeight="24px">
          Dziś:{' '}
          <Text as="span" color="#8b8c91" fontSize="inherit">
            ([opis pojawi się w aplikacji]) ([temperatura]°C)
          </Text>
        </Text>

        <Text
          fontSize="14px"
          lineHeight="24px"
          dangerouslySetInnerHTML={{
            __html: hintContent,
          }}
        />
      </Box>
    </VStack>
  );
}

export default HintCard;
