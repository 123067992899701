import { Box, VStack, Text } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';

function FrameBannerPreview({ formValues, showStats }: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.image);

  return (
    <Box p={4} bgColor={formValues.textBackground} position="relative">
      <VStack
        alignItems="center"
        justifyContent="center"
        w={356}
        h={356}
        bgColor="complementary.whiteGrey"
        bgImage={imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
        position="relative"
      >
        {!imagePreview && (
          <>
            <BiImage size={30} />
            <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
              Tu pojawi się dodane zdjęcie
            </Text>
          </>
        )}
      </VStack>
      {showStats && <ShowStatsIcon data={formValues} />}
    </Box>
  );
}

export default FrameBannerPreview;
