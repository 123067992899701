/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import { linkParametersSchema } from 'components/Container/schema';
import { LinkParameters } from 'types/linkCreator';
import { transformLinksAttributesForPayload } from 'utils/helpers';

export const BODY_SCHEMA = yup.object({
  active: yup.boolean().nullable(),
  name: yup.string().required('Pole jest wymagane'),
  externalCategoryId: yup.string().required('Pole jest wymagane'),
  dateTo: yup.date().nullable(),
  dateFrom: yup.date().nullable(),
  timeTo: yup.date().nullable(),
  timeFrom: yup.date().nullable(),
  linkParametersAttributes: yup.array().of(
    yup.object().shape({
      linkType: yup.string().nullable(),
      linkParamValue: linkParametersSchema.linkParamValue,
    }),
  ),
});

export const preparePayload = (values: any) => {
  const data = { ...values };

  data.linkParametersAttributes = values?.linkParametersAttributes?.filter(
    (attribute: LinkParameters) => attribute?.linkType,
  );

  return transformLinksAttributesForPayload(data);
};
