import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import {
  convertKeysToSnakeCase,
  transformLinksAttributesForPayload,
} from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import {
  AnnouncementResponse,
  AnnouncementsResponse,
  TransformedAnnouncementsResponse,
} from 'types/announcements';
import { transformActivityDates } from 'utils/date';

export const announcementsApi = api.injectEndpoints({
  endpoints: (build) => ({
    announcements: build.query<TransformedAnnouncementsResponse, string>({
      query: (queryString) => `announcements${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: AnnouncementsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Announcements'],
    }),
    deleteAnnouncement: build.mutation<void, number>({
      query: (id) => ({
        url: `announcements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Announcements'],
    }),
    createAnnouncement: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(transformLinksAttributesForPayload(payload)),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'announcement',
        );
        return {
          url: 'announcements',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Announcements'],
    }),
    announcement: build.query<AnnouncementResponse, any>({
      query: (id) => `announcements/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Announcements'],
      transformResponse(response: AnnouncementResponse) {
        const data = {
          ...response.data,
          elementsAttributes: response.data?.elements,
        };
        delete data?.elements;
        return { data };
      },
    }),
    updateAnnouncement: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(transformLinksAttributesForPayload(payload)),
        );

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'announcement',
        );

        return {
          url: `announcements/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Announcements'],
    }),
  }),
});

export const {
  useAnnouncementsQuery,
  useDeleteAnnouncementMutation,
  useCreateAnnouncementMutation,
  useAnnouncementQuery,
  useUpdateAnnouncementMutation,
} = announcementsApi;
