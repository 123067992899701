import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  document: yup.mixed().test({
    test(file, context) {
      if (!file) {
        return context.createError({
          message: 'Dokument jest wymagany',
        });
      }
      return true;
    },
  }),
  id: yup.string().nullable(),
});

export const DEFAULT_VALUES = {
  document: null,
  id: null,
};
