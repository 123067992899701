import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex, FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import DiscountInput from 'components/Form/DiscountInput';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import LinkCreatorSection from 'components/LinkCreator';

function DiscountBannerForm({ prepareFieldName }: FormComponentProps) {
  const { control } = useFormContext();
  const withDisclaimer = useWatch({
    control,
    name: prepareFieldName('withDisclaimer'),
  });

  return (
    <Box as="fieldset">
      <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Zdjęcie</SectionTitle>

        <DropzoneField
          name={prepareFieldName('image')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 256px x 416px)"
        />
      </FormControl>
      <HeaderFormSection
        prepareFieldName={prepareFieldName}
        components={[
          COMPONENTS.TITLE,
          COMPONENTS.SUBTITLE,
          COMPONENTS.COLORBG,
          COMPONENTS.COLORTXT,
        ]}
      >
        <Flex gap={4}>
          <DiscountInput
            label="Wartość zniżki"
            name={prepareFieldName('titlePrefix')}
            maxW={FORM_BODY_MAX_WIDTH}
          />

          <Box minW={136}>
            <Input
              label="Rozmiar tekstu (w pt)"
              defaultValue={12}
              type="number"
              name={prepareFieldName('titlePrefixFontSize')}
            />
          </Box>
        </Flex>
      </HeaderFormSection>
      <Box as="fieldset" pb="44px">
        <SectionTitle as="legend">Kontener wykorzystaj kod</SectionTitle>
        <ColorPicker
          label="Tło kontenera"
          name={prepareFieldName('insideBoxBackgroundColor')}
          maxW={FORM_BODY_MAX_WIDTH}
          mb="26px"
        />
        <Flex gap="16px">
          <Input label="Treść" name={prepareFieldName('insideBoxText')} />
          <Box minW="136px">
            <Input
              label="Rozmiar tekstu (w pt)"
              type="number"
              defaultValue={24}
              name={prepareFieldName('insideBoxFontSize')}
            />
          </Box>
        </Flex>
      </Box>
      <OptionalFormFieldset
        title="Disclaimer (opcjonalnie)"
        name={prepareFieldName('withDisclaimer')}
        isActive={withDisclaimer}
        headerWidth={FORM_BODY_MAX_WIDTH}
      >
        <Flex gap="16px" mb="44px">
          <Input label="Treść" name={prepareFieldName('disclaimer')} />
          <Box minW="136px">
            <Input
              label="Rozmiar tekstu (w pt)"
              type="number"
              defaultValue={12}
              name={prepareFieldName('disclaimerFontSize')}
            />
          </Box>
        </Flex>
      </OptionalFormFieldset>
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        mb={0}
        optionalField
        isEditMode={false}
      />
      <Box as="fieldset" pb="44px">
        <SectionTitle as="legend">Tło całego komponentu</SectionTitle>
        <ColorPicker
          label="Tło kontenera"
          name={prepareFieldName('backgroundColor')}
          maxW={FORM_BODY_MAX_WIDTH}
        />
      </Box>
      <Box as="fieldset" pb="44px">
        <SectionTitle as="legend">Kolor tekstu całego komponentu</SectionTitle>
        <ColorPicker
          label="Tekst kontenera"
          name={prepareFieldName('contentTextColor')}
          maxW={FORM_BODY_MAX_WIDTH}
          mb="26px"
        />
      </Box>
    </Box>
  );
}

export default DiscountBannerForm;
