import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/SurveyQuestions/Form/Create/components/ContainerWrapper';
import ROUTES from 'app/routes';
import { transformErrors } from 'utils/api';
import { FORM_MAX_WIDTH } from 'utils/constants';
import {
  DEFAULT_VALUES,
  BODY_SCHEMA,
} from 'pages/SurveyQuestions/Form/constants';
import useRoute from 'utils/useRoute';
import { useCreateSurveyQuestionMutation } from 'app/services/surveysApi';

function SurveyQuestionCreate() {
  const { surveyId = '' } = useParams();
  const backPath = useRoute(ROUTES.surveys.questions.base, { surveyId });

  const [createSurveyQuestion, { isLoading: isCreating }] =
    useCreateSurveyQuestionMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const navigate = useNavigate();

  const { handleSubmit, setError } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response: any = await createSurveyQuestion({
        surveyId,
        body: data,
      });
      const hasError = Object.hasOwnProperty.call(response, 'error');
      if (hasError) {
        const {
          error: { data: errors },
        } = response;
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field: string) => {
          setError(field as never, {
            type: 'custom',
            message: transformedErrors[field],
          });
        });
      } else {
        navigate(backPath);
        toast.success(`Pytanie zostało pomyślnie utworzone!`);
      }
    } catch (err) {
      // eslint-disable-next-line
      console.debug(err);
    }
  });

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Quizy',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj pytanie' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="survey-question-creator"
          onSubmit={onSubmit}
          maxW={FORM_MAX_WIDTH}
          pb="120px"
        >
          <ContainerWrapper isLoading={isCreating} />
        </Box>
      </FormProvider>
      <FooterButtons
        isLoading={isCreating}
        isCreate
        formId="survey-question-creator"
        backPath={backPath}
      />
    </Box>
  );
}

export default SurveyQuestionCreate;
