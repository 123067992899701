import { api } from 'app/services/api';
import {
  OptionTypeResponse,
  LinkTypesResponse,
  TabResponse,
  MagentoCategoriesResponse,
  Countries,
  CountriesResponse,
  ComponentTypesResponse,
  ComponentTypesParams,
  LinkTypesParams,
} from 'types/common';
import { Dashboard } from 'types/dashboard';
import { LandingPage } from 'types/landingPage';

type SearchResults = (Dashboard & LandingPage)[];

const HIDDEN_STORES = ['us_four_fstore_com', 'uk_four_fstore_com'];

export const commonApi = api.injectEndpoints({
  endpoints: (build) => ({
    linkTypes: build.query<OptionTypeResponse, LinkTypesParams>({
      query: ({ placement }) => {
        if (placement) return `link_types?placement=${placement}`;
        return 'link_types';
      },
      keepUnusedDataFor: 10,
      transformResponse(response: LinkTypesResponse) {
        return {
          data: response.data.map((lt) => ({
            value: lt.value,
            label: lt.name,
          })),
        };
      },
    }),
    tabs: build.query<OptionTypeResponse, void>({
      query: () => `taxonomy_containers`,
      keepUnusedDataFor: 10,
      transformResponse(response: TabResponse) {
        return {
          data: response.data.map((tab) => ({
            value: tab.taxonomyId,
            label: tab.name,
          })),
        };
      },
    }),
    magentoCategories: build.query<OptionTypeResponse, void>({
      query: () => 'categories/magento_categories',
      keepUnusedDataFor: 10,
      transformResponse(response: MagentoCategoriesResponse) {
        return {
          data: response.data.map((category) => ({
            value: category.id.toString(),
            label: category.name,
          })),
        };
      },
    }),
    countries: build.query<Countries, void>({
      query: () => ({ url: `/stores`, method: 'GET' }),
      transformResponse(response: CountriesResponse) {
        const countries = response.data.filter(
          (country) => !HIDDEN_STORES.includes(country.store),
        );

        return {
          data: countries.map((item) => ({
            countryCode: item.lang,
            store: item.store,
          })),
          stores: countries.map((item) => item.store),
        };
      },
    }),
    componentTypes: build.query<OptionTypeResponse, ComponentTypesParams>({
      query: ({ location }) => {
        if (location) return `ui_components?location=${location}`;
        return 'ui_components';
      },
      keepUnusedDataFor: 10,
      transformResponse(response: ComponentTypesResponse) {
        return {
          data: response.data.map((ct) => ({
            value: ct.kind,
            label: ct.name,
          })),
        };
      },
    }),
    search: build.query<
      SearchResults,
      { queryString: string; isDashboardSearch: boolean }
    >({
      query: ({ queryString, isDashboardSearch }) => {
        return {
          url: `${
            isDashboardSearch ? 'dashboard_containers' : 'landing_pages'
          }/search?${queryString}`,
          method: 'GET',
        };
      },
      keepUnusedDataFor: 10,
      transformResponse({ data }) {
        return data;
      },
    }),
  }),
});

export const {
  useLinkTypesQuery,
  useTabsQuery,
  useMagentoCategoriesQuery,
  useCountriesQuery,
  useLazyCountriesQuery,
  useComponentTypesQuery,
  useSearchQuery,
} = commonApi;
