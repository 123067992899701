import { Icon, IconProps } from '@chakra-ui/react';

function BrandLogo(props: IconProps) {
  return (
    <Icon
      width="245"
      height="245"
      viewBox="0 0 245 245"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.715 91.9379C162.987 91.9379 159.057 95.3089 158.451 101.329L150.775 182.774H115.173L122.444 106.737C125.034 78.0485 139.319 63.0415 165.316 61.7251H244.599C241.834 80.7348 231.545 91.7936 214.991 91.9379H171.715Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.232 107.211C211.528 127.815 201.965 137.593 185.607 137.789H185.526H160.722L163.388 107.211H214.232Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1087 161.579C4.83707 161.579 -0.686879 156.221 0.306181 145.643C0.804033 140.214 2.83509 136.542 11.6784 129.882L94.1235 65.0933C100.522 60.7573 105.947 61.3764 108.908 61.3764C114.829 61.3764 123.115 65.0845 122.464 73.4754L111.922 183.654H80.7467L82.8113 161.579L85.4768 133.08L88.3185 104.307L50.9875 133.08H80.5485L77.8565 161.579H15.1087Z"
        fill="white"
      />
    </Icon>
  );
}

export default BrandLogo;
