import { ColumnDef } from '@tanstack/react-table';
import StatusBox from 'components/StatusBox';
import { CategoryElement, PinningType, StatusType } from 'types/common';
import BadgePreview from 'components/BadgePreview';
import ImagePreview from 'components/ImagePreview';
import ColorPreview from 'components/ColorPreview';
import PinningPreview from 'components/PinningPreview';

function Columns(): ColumnDef<CategoryElement, string>[] {
  return [
    {
      header: 'NAZWA',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'PRZYPIĘCIE',
      accessorKey: 'pinning',
      cell: (info) => <PinningPreview value={info.getValue() as PinningType} />,
    },
    {
      header: 'POZYCJA',
      accessorKey: 'placement',
      cell: (info) => info.getValue(),
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'LINK WEWNĘTRZNY',
      accessorKey: 'queryLink',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KOLOR TŁA',
      accessorKey: 'backgroundColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'KOLOR TEKSTU',
      accessorKey: 'textColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'ZDJĘCIE SPECJALNE',
      accessorKey: 'image.url',
      cell: (info) => <ImagePreview url={info.getValue()} pt="12px" />,
    },
    {
      header: 'LOGO',
      accessorKey: 'logo.url',
      cell: (info) => <ImagePreview url={info.getValue()} pt="12px" />,
    },
    {
      header: 'IKONA',
      accessorKey: 'icon.url',
      cell: (info) => <ImagePreview url={info.getValue()} pt="12px" />,
    },
    {
      header: 'BADGE SPECJALNY',
      accessorKey: 'badge',
      cell: ({
        row: {
          original: { badgeTextColor, badgeColor, badge },
        },
      }) => (
        <BadgePreview
          backgroundColor={badgeColor || 'black'}
          text={badge}
          textColor={badgeTextColor || 'white'}
        />
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default Columns;
