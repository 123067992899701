import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import StatusBox from 'components/StatusBox';
import { BenefitsScreenElement } from 'types/benefitsScreens';
import { StatusType } from 'types/common';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<BenefitsScreenElement, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'LOGO',
      accessorKey: 'image.url',
      cell: (info) => <ImagePreview url={info.getValue()} withBackground />,
    },
    {
      header: 'IKONA',
      accessorKey: 'icon.url',
      cell: (info) => <ImagePreview url={info.getValue()} withBackground />,
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: 'activeFrom',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: 'activeTo',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
