import { VStack, Text, AspectRatio } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';
import { useMemo } from 'react';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';
import Button from 'components/Container/Previews/Button';
import Headers from 'components/Container/Previews/Headers';

function GraphicTileSmallPreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.image);

  const shouldRenderHeaders = useMemo(
    () => Boolean(formValues.subtitle || formValues.title),
    [formValues.subtitle, formValues.title],
  );

  return (
    <AspectRatio w="100%" ratio={3}>
      <VStack
        alignItems="center"
        justifyContent="center"
        w="100%"
        h="100%"
        bgColor="complementary.whiteGrey"
        bgImage={imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
        position="relative"
      >
        {shouldRenderHeaders && <Headers {...formValues} />}
        {!imagePreview ? (
          <>
            <BiImage size={30} />
            <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
              Tu pojawi się dodane zdjęcie
            </Text>
          </>
        ) : null}
        {showStats && <ShowStatsIcon data={formValues} />}
        {formValues.buttonText ? <Button {...formValues} /> : null}
      </VStack>
    </AspectRatio>
  );
}

export default GraphicTileSmallPreview;
