import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { MdOutlineCancel } from 'react-icons/md';
import Form from 'components/GenderCopy/Form';

interface CopyToGenderProps {
  isOpen: boolean;
  onClose: () => void;
}

function CopyToGender({ isOpen, onClose }: CopyToGenderProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent p={0}>
        <ModalHeader textAlign="center" p={0} mt="76px">
          WYBIERZ KARTY, DO KTÓRYCH CHCESZ SKOPIOWAĆ KONTENER
        </ModalHeader>
        <ModalCloseButton>
          <MdOutlineCancel size="19px" />
        </ModalCloseButton>
        <ModalBody pt="76px" pb="218px">
          <Form onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CopyToGender;
