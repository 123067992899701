import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface TableWrapperProps extends BoxProps {
  children: React.ReactNode;
}

function TableWrapper({ children, ...props }: TableWrapperProps) {
  return (
    <Box overflow="auto" {...props}>
      {children}
    </Box>
  );
}

export default TableWrapper;
