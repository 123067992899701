import { flexRender, Row } from '@tanstack/react-table';
import Tr from 'pages/AppStoriesInspirations/Listing/components/NestedTable/components/Tr';
import Td from 'pages/AppStoriesInspirations/Listing/components/NestedTable/components/Td';

interface TableRowProps {
  row: Row<any>;
}

function TableRow({ row }: TableRowProps) {
  const { id } = row.original;

  return (
    <Tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell) => (
        <Td key={`row_cell_${cell.id}_${cell}`}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Td>
      ))}
    </Tr>
  );
}

export default TableRow;
