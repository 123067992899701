import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';

export const FaqsApi = api.injectEndpoints({
  endpoints: (build) => ({
    faqs: build.query<TransformedFaqsResponse, string>({
      query: (queryString) => `faqs${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: FaqsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Faqs'],
    }),
    deleteFaq: build.mutation<void, number>({
      query: (id) => ({
        url: `faqs/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Faqs'],
    }),
    createFaq: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(payload);

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'faq',
        );

        return {
          url: 'faqs',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Faqs'],
    }),
    faq: build.query<FaqResponse, any>({
      query: (id) => `faqs/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Faqs'],
    }),
    updateFaq: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'faq',
        );

        return {
          url: `faqs/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Faqs'],
    }),
    reorderFaq: build.mutation<void, ReorderParams>({
      query: ({ body, id }) => ({
        url: `faqs/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Faqs'],
    }),
  }),
});

export const {
  useFaqsQuery,
  useDeleteFaqMutation,
  useCreateFaqMutation,
  useFaqQuery,
  useUpdateFaqMutation,
  useReorderFaqMutation,
} = FaqsApi;
