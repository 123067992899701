import { Tooltip, IconButton, useDisclosure } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import {
  MdOutlineDragIndicator,
  MdOutlineEdit,
  MdOutlineVisibility,
} from 'react-icons/md';
import { useDrag, useDrop } from 'react-dnd';
import { IoMdAdd } from 'react-icons/io';
import { Link, generatePath, useParams } from 'react-router-dom';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import ROUTES from 'app/routes';
import Tr from 'pages/CategoryContainer/Listing/components/NestedTable/components/Tr';
import Td from 'pages/CategoryContainer/Listing/components/NestedTable/components/Td';
import useQueryParams from 'utils/useQueryParams';
import { CategoryElement, DragItem } from 'types/common';
import DeleteModal from 'components/DeleteModal';
import { useDeleteCategoryContainerMutation } from 'app/services/categoryContainerApi';
import DndSpacer from 'components/Table/DndSpacer';

interface TableRowProps {
  row: Row<CategoryElement>;
  handleReorder: (id: number, dropIndex: number) => void;
}

function TableRow({ row, handleReorder }: TableRowProps) {
  const { store } = useParams();
  const { queryString } = useQueryParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { id, taxonomyContainerId } = row.original;
  const [typeCompatibility, setType] = useState(false);
  const [deleteCategoryContainer] = useDeleteCategoryContainerMutation();
  const [{ targetOffset, isOver }, dropRef] = useDrop({
    accept: 'row',
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        targetOffset: monitor.getDifferenceFromInitialOffset()?.y,
      };
    },
    hover: (item: DragItem) => {
      const targedPlacement = row.original.pinning;
      const sourcePinning = item.pinning;

      setType(targedPlacement === sourcePinning);
      return targedPlacement === sourcePinning;
    },
    drop: (item: DragItem) => {
      const targedPlacement = row.original.placement;
      if (row.original.id === item.id) return;
      if (row.original.pinning !== item.pinning) return;
      handleReorder(item.id, targedPlacement);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'row',
    item: () => {
      return {
        placement: row.original.placement,
        id: row.original.id,
        pinning: row.original.pinning,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      dragPinning: monitor.getItem()?.pinning,
    }),
  });

  const handleDelete = async () => {
    try {
      await deleteCategoryContainer({
        id: id.toString(),
        taxonomyContainerId: taxonomyContainerId.toString(),
      }).unwrap();
      toast.success('Pomyślnie usunięto kontener kategorii');
      onClose();
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania kontenera kategorii');
    }
  };
  const showDrop = isOver && !isDragging && targetOffset && typeCompatibility;

  const subcategoryCreatePath = useMemo(
    () =>
      generatePath(ROUTES.subcategory.create, {
        categoryContainerId: id.toString(),
        taxonomyId: taxonomyContainerId.toString(),
        store,
      }) + queryString,
    [id, queryString, store, taxonomyContainerId],
  );

  const categoryContainerEditPath = useMemo(
    () =>
      generatePath(ROUTES.categoryContainer.edit, {
        id: id.toString(),
        taxonomyContainerId: taxonomyContainerId.toString(),
        store,
      }) + queryString,
    [id, queryString, store, taxonomyContainerId],
  );

  const subcategoryPreviewPath = useMemo(
    () =>
      generatePath(ROUTES.subcategory.base, {
        categoryContainerId: id.toString(),
        taxonomyId: taxonomyContainerId.toString(),
        store,
      }) + queryString,
    [id, queryString, store, taxonomyContainerId],
  );

  return (
    <>
      {showDrop && targetOffset < 0 && <DndSpacer />}

      <Tr key={`row_${id}`} ref={previewRef}>
        {row.getVisibleCells().map((cell, idx, { length }) => {
          if (idx + 1 === length) return null;
          return (
            <Td key={`row_cell_${cell.id}_${cell}`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
        <Td ref={dropRef}>
          <>
            <DeleteModal
              onAccept={handleDelete}
              onClose={onClose}
              isOpen={isOpen}
            >
              Czy na pewno chcesz trwale usunąć ten kontener?
            </DeleteModal>
            <Tooltip label="Podgląd" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<MdOutlineVisibility size={20} />}
                aria-label="visiblity"
                variant="ghost"
                as={Link}
                to={subcategoryPreviewPath}
              />
            </Tooltip>
            <Tooltip label="Edytuj" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<MdOutlineEdit size={20} />}
                aria-label="visiblity"
                variant="ghost"
                as={Link}
                to={categoryContainerEditPath}
              />
            </Tooltip>
            <Tooltip label="Usuń" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<RiDeleteBin6Line size={20} />}
                aria-label="visiblity"
                variant="ghost"
                onClick={onOpen}
              />
            </Tooltip>
            <Tooltip label="Dodaj podkategorię" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<IoMdAdd size={20} />}
                aria-label="visiblity"
                variant="ghost"
                as={Link}
                to={subcategoryCreatePath}
              />
            </Tooltip>
            <IconButton
              width="72px"
              height="56px"
              icon={<MdOutlineDragIndicator size={20} />}
              aria-label="visiblity"
              variant="ghost"
              ref={dragRef}
            />
          </>
        </Td>
      </Tr>
      {showDrop && targetOffset > 0 && <DndSpacer />}
    </>
  );
}

export default TableRow;
