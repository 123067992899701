import { SyntheticEvent } from 'react';
import {
  FormControl,
  FormLabel,
  FormControlProps,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { GrCircleQuestion } from 'react-icons/gr';
import SectionTitle from 'components/Form/SectionTitle';

interface ToggleCheckboxProps extends FormControlProps {
  label: string;
  name: string;
  sectionWidth?: number | string;
  hint?: string;
  onChanangeCallback?: (ev: SyntheticEvent) => void;
}

function ToggleCheckbox({
  label,
  name,
  hint,
  sectionWidth,
  onChanangeCallback,
  ...rest
}: ToggleCheckboxProps) {
  const { control } = useFormContext();

  return (
    <FormControl
      maxW={sectionWidth}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => {
          const changeHandler = (v: SyntheticEvent) => {
            if (typeof onChanangeCallback === 'function') {
              onChanangeCallback(v);
            }
            onChange(v);
          };

          return (
            <>
              <FormLabel htmlFor={name} cursor="pointer">
                <SectionTitle mb="0" display="flex" gap={3} alignItems="center">
                  {label}
                  {hint ? (
                    <Tooltip placement="auto" label={hint}>
                      <span>
                        <GrCircleQuestion size={20} />
                      </span>
                    </Tooltip>
                  ) : null}
                </SectionTitle>
              </FormLabel>
              <Switch id={name} isChecked={!!value} onChange={changeHandler} />
            </>
          );
        }}
      />
    </FormControl>
  );
}

ToggleCheckbox.defaultProps = {
  sectionWidth: '100%',
  hint: null,
  onChanangeCallback: null,
};

export default ToggleCheckbox;
