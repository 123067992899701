import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import { Team } from 'types/basketball';

function GetColumns(): ColumnDef<Team, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Nazwa',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'LOGO',
      accessorKey: 'logo.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
