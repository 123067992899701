import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { Box, HStack, FormErrorMessage, FormControl } from '@chakra-ui/react';
import get from 'lodash/get';
import LinkCreatorSection from 'components/LinkCreator';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import Input from 'components/Form/Input';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';
import { FormComponentProps } from 'types/dashboardCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

export const BUTTON_TYPES: { [key: string]: string } = {
  LABEL: 'label',
  ARROW: 'arrow',
};

export const BUTTON_TYPE_LABEL: { [key: string]: string } = {
  arrow: 'Ikona strzałki',
  label: 'Label',
};

// kind: last_chance
function ProductsCarouselForm({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [selectedButtonType, withHeader, withButton] = useWatch({
    control,
    name: [
      prepareFieldName('buttonKind'),
      prepareFieldName('withHeader'),
      prepareFieldName('withButton'),
    ],
  });

  const buttonKindError = get(errors, prepareFieldName('buttonKind'))
    ?.message as string;

  // reset optional values
  useEffect(() => {
    if (!withHeader) {
      setValue(prepareFieldName('title'), '');
    }
    if (!withButton) {
      setValue(prepareFieldName('buttonKind'), '');
      setValue(prepareFieldName('buttonText'), '');
    }
  }, [prepareFieldName, setValue, withButton, withHeader]);

  return (
    <Box maxW={FORM_BODY_MAX_WIDTH}>
      <ToggleCheckbox
        label="Efekt Zoom-in"
        name={prepareFieldName('zoomIn')}
        mb="44px"
      />

      <OptionalFormFieldset
        title="Tytuł (opcjonalnie)"
        mb="20px"
        isActive={withHeader}
        name={prepareFieldName('withHeader')}
      >
        <Input label="Wpisz tytuł" name={prepareFieldName('title')} />
      </OptionalFormFieldset>

      <OptionalFormFieldset
        title="Przycisk (opcjonalnie)"
        mb="20px"
        isActive={withButton}
        name={prepareFieldName('withButton')}
      >
        Wybierz rodzaj przycisku
        <FormControl isInvalid={Boolean(buttonKindError)}>
          <HStack mt={6} mb={9} position="relative">
            {Object.values(BUTTON_TYPES).map((bt: string) => (
              <RadioBoxControl
                key={bt}
                label={BUTTON_TYPE_LABEL[bt]}
                name={prepareFieldName('buttonKind')}
                id={prepareFieldName(`buttonKind_${bt}`)}
                value={bt}
                group
              />
            ))}
            <FormErrorMessage position="absolute" top="100%" pl={0} m={0}>
              {buttonKindError}
            </FormErrorMessage>
          </HStack>
        </FormControl>
        {selectedButtonType === BUTTON_TYPES.LABEL && (
          <Box mb={3} mt={-3}>
            <Input label="Wpisz tytuł" name={prepareFieldName('buttonText')} />
          </Box>
        )}
      </OptionalFormFieldset>

      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </Box>
  );
}

export default ProductsCarouselForm;
