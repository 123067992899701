import { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import ContainerWrapper from 'pages/WeatherConditions/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA } from 'pages/WeatherConditions/Form/constants';
import { transformErrors } from 'utils/api';
import parseDefaultValues from 'pages/WeatherConditions/Form/Edit/parseDefaultValues';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';
import getRemovedElements from 'selectors/formSelectors';
import { clearRemovedElements } from 'slices/form/formSlice';
import { transformRemoved } from 'utils/helpers';
import {
  useUpdateWeatherConditionMutation,
  useWeatherConditionQuery,
} from 'app/services/weatherCategoriesApi';
import HeaderNavigation from 'pages/WeatherConditions/Form/Edit/components/HeaderNavigation';
import WeatherConditionPreview from 'pages/WeatherConditions/Form/components/Preview';
import { FORM_MAX_WIDTH } from 'utils/constants';
import useRoute from 'utils/useRoute';

function WeatherConditionEdit() {
  const { categoryId = '', id = '' } = useParams();
  const backPath = useRoute(ROUTES.weatherCategories.preview, {
    id: categoryId,
  });
  const navigate = useNavigate();
  const removedElements = useAppSelector(getRemovedElements);
  const dispatch = useAppDispatch();

  const { data: { data: weatherCondition } = {}, isFetching } =
    useWeatherConditionQuery({ categoryId, id });
  const [updateWeatherCondition, { isLoading: updateRunning }] =
    useUpdateWeatherConditionMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = {
        ...data,
        categoryId,
        weatherCategoryConditionHintsAttributes: [
          ...(data?.weatherCategoryConditionHintsAttributes || []),
          ...transformRemoved(removedElements),
        ],
      };
      await updateWeatherCondition(payload)
        .unwrap()
        .then(() => {
          navigate(backPath);
          toast.success(`Zmiany w warunku zostały zastosowane`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (weatherCondition) reset(parseDefaultValues(weatherCondition));
  }, [weatherCondition, reset]);

  useEffect(() => {
    return () => {
      dispatch(clearRemovedElements());
    };
  }, [dispatch]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        backPath={backPath}
        gender={weatherCondition?.dashboardKind}
      />
      <FormProvider {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start">
          <Box
            as="form"
            id="weather-condition-editor"
            onSubmit={onSubmit}
            pb="120px"
            flex={1}
            maxW={FORM_MAX_WIDTH}
          >
            {isFetching && <LoadingIndicator />}
            {!isFetching && (
              <ContainerWrapper isLoading={updateRunning || isFetching} />
            )}
          </Box>
          <WeatherConditionPreview />
        </Flex>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="weather-condition-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default WeatherConditionEdit;
