import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import StatusBox from 'components/StatusBox';
import { PLATFORM } from 'pages/Announcements/Form/constants';
import { Announcement } from 'types/announcements';
import { StatusType } from 'types/common';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<Announcement, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TYTUŁ',
      accessorKey: 'title',
      cell: (info) => (
        <Box
          dangerouslySetInnerHTML={{
            __html: info.getValue(),
          }}
        />
      ),
    },
    {
      header: 'PLATFORMA',
      accessorKey: 'platform',
      cell: (info) => PLATFORM[info.getValue()],
    },
    {
      header: 'WERSJA',
      accessorKey: 'platformVersion',
      cell: (info) => info.getValue(),
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: 'activeFrom',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: 'activeTo',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
