import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { Box, BoxProps } from '@chakra-ui/react';
import TabElement from 'components/Table/Table';
import Thead from 'pages/CategoryContainer/Listing/components/NestedTable/components/Thead';
import Tr from 'pages/CategoryContainer/Listing/components/NestedTable/components/Tr';
import Tbody from 'pages/CategoryContainer/Listing/components/NestedTable/components/Tbody';
import Th from 'components/Table/Th';
import columns from 'pages/CategoryContainer/Listing/components/NestedTable/columns';
import {
  useCategoryElementsQuery,
  useReorderCategoryContainersMutation,
} from 'app/services/categoryContainerApi';
import TableRow from 'pages/CategoryContainer/Listing/components/NestedTable/TableRow';

type TableProps = BoxProps & {
  containerId: number;
};

function Table({ containerId }: TableProps) {
  const { data: { data: elements } = { data: [] } } =
    useCategoryElementsQuery(containerId);
  const [reorderCategoryContainer] = useReorderCategoryContainersMutation();

  const table = useReactTable({
    data: elements,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  if (isEmpty(elements)) return null;

  const handleReorder = async (id: number, targetRowIndex: number) => {
    try {
      reorderCategoryContainer({
        id,
        taxonomyContainerId: containerId,
        body: { id, placement: targetRowIndex },
      }).unwrap();
    } catch (error) {
      toast.error('Problem podczas zmiany kolejności');
    }
  };

  return (
    <Box as="td" colSpan={7} padding={0}>
      <TabElement isEmpty={false}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`thread_row_${containerId}_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`thread_column_${containerId}_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} row={row} handleReorder={handleReorder} />
          ))}
        </Tbody>
      </TabElement>
    </Box>
  );
}

export default Table;
