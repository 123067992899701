import { Flex, Box } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/Nutritions/Form/components/FormBody';
import FormSpinner from 'components/FormSpinner';
import Input from 'components/Form/Input';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <FormSpinner />}
        <Box w="100%">
          <Input name="sku" label="SKU" placeholder="Wpisz SKU" mb={8} />
          <FormBody prepareFieldName={(name: string) => name} />
        </Box>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
