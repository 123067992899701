import * as yup from 'yup';
import { linkParametersSchema } from 'components/Container/schema';

export const BODY_SCHEMA = yup.object({
  title: yup.string().required('Pole jest wymagane'),
  externalUrl: yup.string().nullable(),
  active: yup.boolean().nullable(),
  linkParametersAttributes: yup.array().of(
    yup.object().shape({
      linkType: yup.string().nullable(),
      linkParamValue: linkParametersSchema.linkParamValue,
    }),
  ),
});

export const DEFAULT_VALUES = {
  title: '',
  externalUrl: '',
  active: true,
  linkParametersAttributes: [{ linkType: undefined }],
};
