import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import FormBody from 'pages/NotificationCategories/Form/components/FormBody';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA } from 'pages/NotificationCategories/Form/constants';
import {
  useNotificationCategoryQuery,
  useUpdateNotificationCategoryMutation,
} from 'app/services/notificationsApi';
import useRoute from 'utils/useRoute';

function NotificationCategoryEdit() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.notificationCategories.base);

  const { data: { data: notificationCategory } = {}, isFetching } =
    useNotificationCategoryQuery(id);
  const [updateNotificationCategory, { isLoading: updateRunning }] =
    useUpdateNotificationCategoryMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateNotificationCategory({ ...data, id }).unwrap();
      navigate(backPath);
      toast.success(`Zmiany w kategorii zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (notificationCategory) reset(notificationCategory);
  }, [notificationCategory, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Kategorie wiadomości',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj kategorię' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="notification-category-editor"
          onSubmit={onSubmit}
          pb="120px"
        >
          {isFetching && <LoadingIndicator />}
          {!isFetching && <FormBody isLoading={updateRunning || isFetching} />}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="notification-category-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default NotificationCategoryEdit;
