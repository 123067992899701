import { Box, Flex } from '@chakra-ui/react';
import { PINNING_DICT } from 'pages/CategoryContainer/Elements/Form/constants';
import { PinningType } from 'types/common';

interface PinningPreviewProps {
  value: PinningType;
}

function PinningPreview({ value }: PinningPreviewProps) {
  return (
    <Flex>
      <Box
        padding="4px 16px 4px 16px"
        bg={value === 'special' ? '#f68704' : 'complementary.blue'}
        fontSize="16px"
        fontWeight={400}
        lineHeight="24px"
        textAlign="center"
        color="white"
      >
        {PINNING_DICT[value]}
      </Box>
    </Flex>
  );
}
export default PinningPreview;
