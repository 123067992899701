import {
  Box,
  DrawerBody as ChakraBody,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import Time from 'components/Form/Time';
import {
  ACTIVE_FROM,
  ACTIVE_TO,
  KIND,
  STATUS,
} from 'pages/Dashboard/Listing/constants';
import Select from 'components/Form/Select';
import DatePicker from 'components/Form/Date';
import FilterLabel from 'components/FilterLabel';
import { STATUS_OPTIONS } from 'utils/constants';
import useQueryParams from 'utils/useQueryParams';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import { useFormWithSchema } from 'utils/formHooks';
import { useComponentTypesQuery } from 'app/services/commonApi';
import { isValidDate, transformActivityDates } from 'utils/date';

const schema = yup.object({
  kind: yup.array().of(yup.string()),
  status: yup.string(),
  dateFrom: yup.date().nullable(),
  dateTo: yup.date().nullable(),
  timeFrom: yup.date().nullable(),
  timeTo: yup.date().nullable(),
});

interface FilterBodyProps {
  onClose: () => void;
}

function FilterBody({ onClose }: FilterBodyProps) {
  const { search, setSearch } = useQueryParams();

  const { data: { data: componentTypes } = { data: [] }, isFetching } =
    useComponentTypesQuery({ location: 'dashboard' });

  const getDefaultValues = () => {
    const status = search.get(STATUS);
    const activeFrom = search.get(ACTIVE_FROM);
    const activeTo = search.get(ACTIVE_TO);
    const kind = search.getAll(KIND);

    const result: { [key: string]: any } = {};

    if (activeFrom && isValidDate(activeFrom)) {
      result.dateFrom = new Date(activeFrom);
      result.timeFrom = new Date(activeFrom);
    }

    if (activeTo && isValidDate(activeTo)) {
      result.dateTo = new Date(activeTo);
      result.timeTo = new Date(activeTo);
    }
    result.status = status || undefined;
    result.kind = kind || undefined;

    return result;
  };

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
  });
  const { handleSubmit, setValue, reset, watch } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const transformedData = transformActivityDates(data);

    const {
      kind: newKind,
      status: newStatus,
      activeFrom: newActiveFrom,
      activeTo: newActiveTo,
    } = transformedData;

    search.delete(KIND);
    if (newKind && newKind.length > 0) {
      newKind.forEach((elem: string) => search.append(KIND, elem!));
    }
    if (newStatus) search.set(STATUS, newStatus);
    else search.delete(STATUS);

    if (newActiveFrom) search.set(ACTIVE_FROM, newActiveFrom);
    else search.delete(ACTIVE_FROM);
    if (newActiveTo) search.set(ACTIVE_TO, newActiveTo);
    else search.delete(ACTIVE_TO);

    search.set('page', '1');
    setSearch(search);
    onClose();
  });

  useEffect(() => {
    reset(getDefaultValues());
    // eslint-disable-next-line
  }, []);

  const status = watch('status');
  const kind = watch('kind');
  const activeFrom = watch('dateFrom');
  const activeTo = watch('dateTo');

  return (
    <ChakraBody overflowX="hidden" overflowY="auto">
      <FormProvider {...methods}>
        <Box as="form" id="filter-form" onSubmit={onSubmit} w="452px">
          <Select
            label="Typ komponentu"
            name="kind"
            options={componentTypes}
            isLoading={isFetching}
            isMulti
            placeholder="Wybierz"
            hideSelectedOptions={false}
          />
          <Flex direction="column" pt="26px">
            <Text fontWeight={400} fontSize="14px">
              Status
            </Text>
            <Flex direction="row" p="8px">
              {STATUS_OPTIONS.map((el, idx) => (
                <RadioBoxControl
                  label={el.label}
                  name="status"
                  id={`status_option_${idx}`}
                  key={`status_option_${el.value}`}
                  value={el.value}
                  isClearable
                />
              ))}
            </Flex>
          </Flex>
          <Flex direction="column" pt="26px">
            <Text fontWeight={400} fontSize="14px">
              Aktywność
            </Text>
            <HStack pt="8px" spacing="16px">
              <DatePicker
                name="dateFrom"
                label="Aktywny od"
                showError
                rangeSelect={false}
              />
              <DatePicker
                name="dateTo"
                label="Aktywny do"
                showError
                rangeSelect={false}
              />
            </HStack>
            <HStack spacing="16px" pt="26px">
              <Time
                name="timeFrom"
                label="Godzina rozpoczęcia"
                errorKey="timeFrom"
                showError
              />
              <Time
                name="timeTo"
                label="Godzina zakończenia"
                errorKey="timeTo"
                showError
              />
            </HStack>
          </Flex>
          <Flex direction="column" pt="26px">
            <Text fontWeight={600} fontSize="16px">
              WYBRANE
            </Text>
            <Flex py="28px" flexWrap="wrap" gap="16px" px="8px">
              {(kind || []).map((value) => {
                return (
                  <FilterLabel
                    key={`filter_label_${value}`}
                    name={
                      componentTypes.find((el) => el.value === value)?.label!
                    }
                    onClick={() =>
                      setValue(
                        'kind',
                        kind?.filter((x) => x !== value),
                      )
                    }
                  />
                );
              })}
              {status && (
                <FilterLabel
                  key={`filter_label_${status}`}
                  name={
                    STATUS_OPTIONS.find((el) => el.value === status)?.label!
                  }
                  onClick={() => setValue('status', undefined)}
                />
              )}
              {activeFrom && (
                <FilterLabel
                  key={`filter_label_${activeFrom}`}
                  name="Aktywny od"
                  onClick={() => {
                    setValue('dateFrom', null);
                    setValue('timeFrom', null);
                  }}
                />
              )}
              {activeTo && (
                <FilterLabel
                  key={`filter_label_${activeTo}`}
                  name="Aktywny do"
                  onClick={() => {
                    setValue('dateTo', null);
                    setValue('timeTo', null);
                  }}
                />
              )}
            </Flex>
          </Flex>
        </Box>
      </FormProvider>
    </ChakraBody>
  );
}

export default FilterBody;
