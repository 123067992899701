import { Box, BoxProps } from '@chakra-ui/react';

type SuggestionBoxProps = BoxProps & {
  children: React.ReactNode;
};
function SuggestionBox({ children, ...props }: SuggestionBoxProps) {
  return (
    <Box
      p="16px 12px"
      fontSize="16px"
      fontWeight={400}
      lineHeight="24px"
      textAlign="left"
      bg="complementary.purpleBlue"
      {...props}
    >
      {children}
    </Box>
  );
}

export default SuggestionBox;
