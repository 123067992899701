import * as yup from 'yup';
import { Box, Button, Center, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { GENDER_OPTIONS } from 'utils/constants';
import { useFormWithSchema } from 'utils/formHooks';
import Checkbox from 'components/Form/Checkbox';
import useQueryParams from 'utils/useQueryParams';
import { getSelectedRows } from 'selectors/uiSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import { useCloneToGenderMutation } from 'app/services/dashboardApi';

const schema = yup.object({
  male: yup.bool().required(),
  female: yup.bool().required(),
  girls: yup.bool().required(),
  boys: yup.bool().required(),
});

interface FormProps {
  onClose: () => void;
}

function Form({ onClose }: FormProps) {
  const [cloneToGender] = useCloneToGenderMutation();
  const selectedRows = useAppSelector(getSelectedRows);
  const { search } = useQueryParams();
  const pickedGenderTab = search.get('gender');

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: {
      male: false,
      female: false,
      girls: false,
      boys: false,
    },
  });

  const { handleSubmit, formState, setError } = methods;

  const { isDirty, isValid, isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const promises = [];
      if (data.girls) {
        promises.push(
          cloneToGender({
            gender: 'girls',
            dashboardContainerIds: selectedRows,
          }).unwrap(),
        );
      }
      if (data.boys) {
        promises.push(
          cloneToGender({
            gender: 'boys',
            dashboardContainerIds: selectedRows,
          }).unwrap(),
        );
      }
      if (data.female) {
        promises.push(
          cloneToGender({
            gender: 'female',
            dashboardContainerIds: selectedRows,
          }).unwrap(),
        );
      }
      if (data.male) {
        promises.push(
          cloneToGender({
            gender: 'male',
            dashboardContainerIds: selectedRows,
          }).unwrap(),
        );
      }
      await Promise.all(promises);
      toast.success('Pomyślnie skopiowano do wybranych płci');
      onClose();
    } catch (err) {
      if (data.male)
        setError('male', { message: 'Błąd kopiowania do wybranej płci' });
      if (data.girls)
        setError('girls', { message: 'Błąd kopiowania do wybranej płci' });
      if (data.boys)
        setError('boys', { message: 'Błąd kopiowania do wybranej płci' });
      if (data.female)
        setError('female', { message: 'Błąd kopiowania do wybranej płci' });
      // eslint-disable-next-line no-console
      console.log(err);
    }
  });

  return (
    <Center>
      <FormProvider {...methods}>
        <Box as="form" onSubmit={onSubmit} w="452px">
          <VStack spacing="40px">
            {pickedGenderTab !== GENDER_OPTIONS[0].value && (
              <Checkbox name="female" label="Kobieta" />
            )}
            {pickedGenderTab !== GENDER_OPTIONS[1].value && (
              <Checkbox name="male" label="Mężczyzna" />
            )}
            {pickedGenderTab !== GENDER_OPTIONS[2].value && (
              <Checkbox name="girls" label="Dziewczynka" />
            )}
            {pickedGenderTab !== GENDER_OPTIONS[3].value && (
              <Checkbox name="boys" label="Chłopiec" />
            )}
          </VStack>
          <VStack w="100%" spacing="24px" pt="67px">
            <Button
              isLoading={isSubmitting}
              isDisabled={!isDirty || !isValid}
              type="submit"
              w="100%"
            >
              WYBIERZ
            </Button>
            <Button variant="outlined" w="100%" onClick={onClose}>
              ANULUJ
            </Button>
          </VStack>
        </Box>
      </FormProvider>
    </Center>
  );
}

export default Form;
