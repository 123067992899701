/* eslint-disable react/no-array-index-key */
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/RegulationElements/Form/Create/components/ContainerWrapper';
import { useCreateRegulationElementMutation } from 'app/services/regulationsApi';
import ROUTES from 'app/routes';
import { transformErrors } from 'utils/api';
import { EXTENDED_FORM_MAX_WIDTH } from 'utils/constants';
import {
  DEFAULT_VALUES,
  BODY_SCHEMA,
} from 'pages/RegulationElements/Form/constants';
import useRoute from 'utils/useRoute';

function RegulationElementCreate() {
  const { regulationId = '' } = useParams();
  const backPath = useRoute(ROUTES.regulationElements.base, { regulationId });

  const [createRegulation, { isLoading: isCreating }] =
    useCreateRegulationElementMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const navigate = useNavigate();

  const { handleSubmit, setError } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response: any = await createRegulation({
        regulationId,
        body: data,
      });
      const hasError = Object.hasOwnProperty.call(response, 'error');
      if (hasError) {
        const {
          error: { data: errors },
        } = response;
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field: string) => {
          setError(field as never, {
            type: 'custom',
            message: transformedErrors[field],
          });
        });
      } else {
        navigate(backPath);
        toast.success(`Element regulaminu został pomyślnie utworzony!`);
      }
    } catch (err) {
      // eslint-disable-next-line
      console.debug(err);
    }
  });

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Regulamin',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj element' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="regulation-element-creator"
          onSubmit={onSubmit}
          pb="120px"
          maxW={EXTENDED_FORM_MAX_WIDTH}
        >
          <ContainerWrapper isLoading={isCreating} />
        </Box>
      </FormProvider>
      <FooterButtons
        isLoading={isCreating}
        isCreate
        formId="regulation-element-creator"
        backPath={backPath}
      />
    </Box>
  );
}

export default RegulationElementCreate;
