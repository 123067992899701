import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { Box, Button, VStack } from '@chakra-ui/react';
import { ParsedQuery } from 'query-string';
import { toast } from 'react-toastify';
import { useChangePasswordMutation } from 'app/services/authApi';
import PasswordInput from 'components/Form/PasswordInput';
import Link from 'components/Link';
import { ApiError } from 'types/api';
import { useFormWithSchema } from 'utils/formHooks';
import { parseApiError } from 'utils/helpers';
import ROUTES from 'app/routes';
import useRoute from 'utils/useRoute';

interface ChangeFormParams {
  queryParams: ParsedQuery<string>;
}

const schema = yup.object({
  password: yup.string().required('Hasło jest wymagane'),
  passwordConfirmation: yup
    .string()
    .required('Hasło jest wymagane')
    .oneOf([yup.ref('password'), null], 'Hasła muszą być identyczne'),
});

function ChangeForm({ queryParams }: ChangeFormParams) {
  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
  });
  const navigate = useNavigate();
  const [changePassword] = useChangePasswordMutation();
  const loginPath = useRoute(ROUTES.auth.login);

  const { handleSubmit, formState, setError } = methods;

  const { isDirty, isValid, isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await changePassword({ ...data, queryParams })
        .unwrap()
        .then(({ message }) => {
          navigate(loginPath);
          toast.success(message);
        })
        .catch(
          ({
            data: { errors },
          }: {
            status: number;
            data: { errors: { [key: string]: string[] } };
          }) => {
            setError('password', { message: errors.password[0] });
            toast.error(errors.fullMessages[0]);
          },
        );
    } catch (err) {
      const error = parseApiError(err as ApiError);
      setError('password', { message: error.msg });
    }
  });

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={onSubmit} w="452px">
        <VStack spacing="15px">
          <PasswordInput
            name="password"
            label="Nowe hasło"
            autoComplete="new-password"
          />
          <PasswordInput
            name="passwordConfirmation"
            label="Powtórz nowe hasło"
            autoComplete="new-password"
          />
        </VStack>

        <Button
          isLoading={isSubmitting}
          isDisabled={!isDirty || !isValid}
          type="submit"
          w="100%"
          mt="40px"
        >
          ZMIEŃ HASŁO
        </Button>
        <Box w="100%" pt="32px" textAlign="center">
          <Link to={loginPath}>Zaloguj się</Link>
        </Box>
      </Box>
    </FormProvider>
  );
}

export default ChangeForm;
