import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';

export const MultiValueInputWrapper = styled(Box)`
  border: 1px #000 solid;
  padding: 0 16px;

  .ReactTags__selected {
    width: 100%;
    display: flex;
    min-height: 48px;
    flex-wrap: wrap;
  }

  .ReactTags__tagInput {
    width: 100%;
    margin: 12px 0;
    input {
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }

  .ReactTags__tag {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
    margin: 12px 12px 12px 0;
  }

  .ReactTags__remove {
    border-radius: 100%;
    background-color: #000;
    color: #000;
    width: 12px;
    height: 12px;
    display: flex;
    font-size: 1px;
    margin-right: 5px;
    position: relative;
    flex-shrink: 0;
    &:after {
      content: 'x';
      color: #fff;
      position: absolute;
      left: 0;
      top: -1px;
      font-size: 12px;
      line-height: 12px;
      width: 100%;
      text-align: center;
    }
  }

  .ReactTags__suggestions {
    position: relative;
    padding-top: 48px;
    margin-top: 8px;
    :before {
      position: absolute;
      top: 0;
      width: calc(100% + 32px);
      height: 1px;
      content: '';
      margin: 0 -16px;
      border-top: 1px solid black;
    }
    :after {
      position: absolute;
      top: 16px;
      width: 100%;
      height: 40px;
      content: 'Ostatnio wybierane';
      color: #a9a9a9;
      line-height: 40px;
    }
    * {
      margin: 16px 0;
      list-style-type: none;
      background-color: transparent;
    }
  }

  .ReactTags__activeSuggestion {
    font-weight: bold;
  }
`;

export default MultiValueInputWrapper;
