import { Navigate, useLocation } from 'react-router-dom';
import { Box, Center, Flex, Text } from '@chakra-ui/react';

import { useMemo } from 'react';
import qs, { ParsedQuery } from 'query-string';
import { selectIsAuthenticated } from 'selectors/authSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import BrandLogo from 'components/Icons/Big4F';
import ChangeForm from 'pages/ChangePassword/ChangeForm';
import ROUTES from 'app/routes';
import useRoute from 'utils/useRoute';

function Change() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { search } = useLocation();
  const dashboardPath = useRoute(ROUTES.dashboard.base);

  const queryParams: ParsedQuery<string> = useMemo(
    () => qs.parse(search),
    [search],
  );

  if (isAuthenticated || !queryParams['access-token'] || !queryParams.token) {
    return <Navigate to={dashboardPath} replace />;
  }

  return (
    <Flex direction="row" h="100%" w="100%">
      <Center w="718px" bg="black">
        <BrandLogo />
      </Center>
      <Flex
        w="100%"
        align="center"
        justify="center"
        marginX="30px"
        flex={1}
        direction="column"
      >
        <Box>
          <Text w="100%" variant="h1" pb="16px">
            ZMIEŃ HASŁO
          </Text>
          <ChangeForm queryParams={queryParams} />
        </Box>
      </Flex>
    </Flex>
  );
}

export default Change;
