import { Flex, Icon, Text } from '@chakra-ui/react';
import { MdArrowUpward } from 'react-icons/md';

function PercentBox({ value }: { value: number }) {
  const percentageValue = value * 100;
  const isPositive = percentageValue > 0;
  return (
    <Flex justify="space-between" align="center">
      <Text fontSize={16} fontWeight={isPositive ? 600 : 'normal'}>
        {percentageValue.toFixed(2)}%
      </Text>
      <Icon
        as={MdArrowUpward}
        sx={{
          width: '24px',
          height: '24px',
          color: isPositive ? '#009742' : '#f60d45',
          transform: `rotate(${isPositive ? 0 : 180}deg)`,
        }}
      />
    </Flex>
  );
}

export default PercentBox;
