import { Icon, IconProps } from '@chakra-ui/react';

function EdgeArrow(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 0L13 12H11L11 0H13ZM2 0L8 6L2 12L0.6 10.6L5.2 6L0.6 1.4L2 0Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default EdgeArrow;
