import { generatePath, Link, useParams } from 'react-router-dom';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/CategoryTags/Listing/components/Table';
import ROUTES from 'app/routes';
import { useTabsQuery } from 'app/services/commonApi';
import ListingTabs from 'components/ListingTabs';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import useQueryParams from 'utils/useQueryParams';
import { useCategoriesQuery } from 'app/services/categoryApi';
import LoadingIndicator from 'components/LoadingIndicator';

function Category() {
  const { store } = useParams();
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
    'q[taxonomy_container_id_eq]': '58',
  });

  const {
    isFetching: tabsLoading,
    data: { data: tabsOptions } = { data: [] },
  } = useTabsQuery();

  const {
    isFetching: tagsLoading,
    data: { data: categories, totals } = { data: [], totals: 0 },
  } = useCategoriesQuery(queryString);

  const showLoading = tagsLoading || tabsLoading;

  return (
    <ListingLayout
      title="Tagi taksonomii"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWY TAG',
          as: Link,
          to:
            generatePath(ROUTES.categoryTag.create, {
              store,
            }) + queryString,
        },
      ]}
    >
      <ListingTabs
        paramName="q[taxonomy_container_id_eq]"
        options={tabsOptions}
      />
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={categories}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Category;
