/* eslint-disable no-underscore-dangle */
import { Box, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { PreviewComponentProps } from 'types/dashboardCreator';

import ThumbnailsSlider from 'components/Container/Previews/PlayWithColorsPreview/ThumbnailsSlider';
import ImagesSlider, { Image } from 'components/ImagesSlider';
import Headers from 'components/Container/Previews/Headers';

import 'swiper/css';
import 'swiper/css/free-mode';

function PlayWithColorsPreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  const shouldRenderHeaders = useMemo(
    () => Boolean(formValues.subtitle || formValues.title),
    [formValues.subtitle, formValues.title],
  );

  const specialElements = useMemo(() => {
    const elements = formValues?.specialElementsAttributes ?? [...Array(3)];

    // always 3 elements
    if (elements.length < 3) {
      return [...elements, ...Array(3 - elements.length)];
    }

    return elements;
  }, [formValues]);

  const sliderElements =
    formValues.elementsAttributes?.filter(
      (element: { [key: string]: any; _destroy?: boolean }) =>
        !element._destroy,
    ) || [];

  return (
    <Box p={showStats ? 0 : 4} bgColor="complementary.lightGrey">
      {shouldRenderHeaders && (
        <Box pb={8} pt={6}>
          <Headers position="static" {...formValues} />
        </Box>
      )}

      <VStack spacing={4}>
        <Box bgColor="complementary.whiteGrey" w="full">
          <ImagesSlider
            elements={sliderElements}
            SlideComponent={Image}
            showStats={showStats}
            isPlayWithColorsPreview
          />
        </Box>
        <ThumbnailsSlider elements={specialElements} />
      </VStack>
    </Box>
  );
}

export default PlayWithColorsPreview;
