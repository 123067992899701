import { VStack, Text, Box } from '@chakra-ui/react';
import { useMemo } from 'react';
import { BiImage } from 'react-icons/bi';
import { FaRegCopy } from 'react-icons/fa';
import styled from '@emotion/styled';
import Headers from 'components/Container/Previews/Headers';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';

const StyledCopy = styled(FaRegCopy)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

function DiscountBannerPreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.image);

  const shouldRenderHeaders = useMemo(
    () => Boolean(formValues.subtitle || formValues.title),
    [formValues.subtitle, formValues.title],
  );

  return (
    <VStack
      bgColor={formValues.backgroundColor}
      color={formValues.contentTextColor || '#000000'}
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      w={392}
      h={632}
      pb={6}
    >
      {showStats && <ShowStatsIcon data={formValues} />}
      <Box position="relative" alignSelf="flex-start" w="full">
        {shouldRenderHeaders && (
          <Headers
            {...{
              ...formValues,
              title: `<span style="background-color:${
                formValues.textBackground
              };color:${formValues.textColor};padding:0 8px;font-size:${
                formValues.titlePrefixFontSize
              }px">${formValues.titlePrefix ?? ''}%</span> ${
                formValues.title ?? ''
              }`,
              textColor: formValues.contentTextColor || '#000000',
              textBackground: 'transparent',
            }}
            isRich
          />
        )}
      </Box>
      <VStack
        alignItems="center"
        justifyContent="center"
        bgColor="complementary.whiteGrey"
        bgImage={imagePreview}
        bgPosition="50% 50%"
        bgRepeat="no-repeat"
        bgSize="cover"
        position="absolute"
        top={124}
        left={136}
        w={256}
        h={416}
      >
        {!imagePreview && (
          <>
            <BiImage size={30} color="initial" />
            <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
              Tu pojawi się dodane zdjęcie
            </Text>
          </>
        )}
      </VStack>
      <Box
        position="relative"
        w={328}
        minH={126}
        p={4}
        bgColor={formValues.insideBoxBackgroundColor}
      >
        <Text fontSize="16px" color="inherit">
          Wykorzystaj kod
        </Text>
        <StyledCopy size={14} />
        <Text
          mt={2}
          pr={16}
          fontWeight={700}
          fontSize={formValues.insideBoxFontSize}
          textTransform="uppercase"
          color="inherit"
        >
          {formValues.insideBoxText
            ? formValues.insideBoxText
            : 'tutaj wyświetli się twój tekst'}
        </Text>
        {formValues.disclaimer ? (
          <Text
            fontSize={formValues.disclaimerFontSize || 12}
            mt={2}
            color="inherit"
          >
            * {formValues.disclaimer}
          </Text>
        ) : null}
      </Box>
    </VStack>
  );
}

export default DiscountBannerPreview;
