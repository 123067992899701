import { Box, BoxProps, Center, HStack, Text, VStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { isNull } from 'lodash';
import { MdYoutubeSearchedFor } from 'react-icons/md';
import useQueryParams from 'utils/useQueryParams';

const Tr = styled.tr`
  border-color: black;
  border-inline-width: 1px;
  border-bottom-width: 1px;
`;

type EmptyBodyProps = BoxProps & {
  columnAmount: number;
  searchKey?: string;
  queryParams: string[];
};

function NonFilterContent({ searchValue }: { searchValue: string }) {
  return (
    <>
      <HStack>
        <Text variant="h1">NIE MOŻEMY ZNALEŹĆ FRAZY:</Text>
        <Text variant="h1" fontWeight={400}>
          {searchValue}
        </Text>
      </HStack>
      <Text variant="h2">Sprawdź pisownię lub użyj filtrów</Text>
    </>
  );
}
function FilterContent() {
  return (
    <>
      <HStack>
        <Text variant="h1">WYGLĄDA NA TO, ŻE NIC NIE ZNALEŹLIŚMY</Text>
      </HStack>
      <Text variant="h2">
        Sprawdź wybrane filtry, użyj wyszukiwarki, lub spróbuj ponownie
      </Text>
    </>
  );
}

function EmptyBody({
  searchKey,
  queryParams,
  columnAmount,
  ...props
}: EmptyBodyProps) {
  const { search } = useQueryParams();

  const searchValue = search.get(searchKey || '');

  const isOnlySearchActive =
    queryParams
      .map((param) => search.get(param))
      .every((result) => isNull(result)) && !isNull(searchValue);

  return (
    <Tr>
      <td colSpan={columnAmount}>
        <Box {...props}>
          <Center h="100%">
            <VStack spacing="32px">
              <MdYoutubeSearchedFor size={45} />
              {isOnlySearchActive ? (
                <NonFilterContent searchValue={searchValue || ''} />
              ) : (
                <FilterContent />
              )}
            </VStack>
          </Center>
        </Box>
      </td>
    </Tr>
  );
}

EmptyBody.defaultProps = {
  searchKey: '',
};
export default EmptyBody;
