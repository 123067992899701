import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
} from '@chakra-ui/react';
import { generatePath, Link, useParams } from 'react-router-dom';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import BackNavigationButton from 'components/BackNavigationButton';

interface HeaderNavigationProps {
  name: string;
  benefitsScreenId: string;
}

function HeaderNavigation({ name, benefitsScreenId }: HeaderNavigationProps) {
  const { store } = useParams();
  const { queryString } = useQueryParams();

  return (
    <Flex mb={6} align="center" justify="space-between">
      <Box>
        <BackNavigationButton />
        <Breadcrumb mt={4}>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={generatePath(ROUTES.benefitsScreens.base, { store })}
              fontWeight={700}
            >
              Ekrany korzyści
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
      <Button
        fontWeight={700}
        fontSize="12px"
        lineHeight="20px"
        paddingX="32px"
        as={Link}
        to={
          generatePath(ROUTES.benefitsScreenElements.create, {
            benefitsScreenId,
            store,
          }) + queryString
        }
        textDecoration="none"
      >
        NOWY ELEMENT
      </Button>
    </Flex>
  );
}

export default HeaderNavigation;
