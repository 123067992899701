import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import Badge from 'components/Badge';
import { GENDER } from 'components/Container/constants';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<User, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'E-MAIL',
      accessorKey: 'email',
      cell: (info) => info.getValue(),
    },
    {
      header: 'MAGENTO ID',
      accessorKey: 'externalId',
      cell: (info) => info.getValue(),
    },
    {
      header: 'DATA UTWORZENIA',
      accessorKey: 'createdAt',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'DATA UAKTUALNIENIA',
      accessorKey: 'updatedAt',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'PŁEĆ',
      cell: ({
        row: {
          original: { leaderCategory },
        },
      }) => GENDER[leaderCategory?.kind as keyof typeof GENDER] || '-',
    },
    {
      header: 'PUSH aktywne',
      accessorKey: 'pushActive',
      cell: (info) => <Badge flag={!!info.getValue()} />,
    },
    {
      header: 'Dodano w PPG',
      accessorKey: 'inPgg',
      cell: (info) => <Badge flag={!!info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
