import { ColumnDef } from '@tanstack/react-table';
import { GENDER } from 'components/Container/constants';

function GetColumns(): ColumnDef<WeatherCategory, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'DASHBOARD',
      accessorKey: 'dashboard.kind',
      cell: (info) => GENDER[info.getValue() as keyof typeof GENDER],
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
