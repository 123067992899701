import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

interface PreviewWrapperProps {
  children: ReactElement;
  margined: boolean | null;
  marginColor: string;
}

function PreviewWrapper({
  children,
  margined,
  marginColor,
}: PreviewWrapperProps) {
  if (!margined) {
    return children;
  }

  return (
    <>
      {children}
      <Box h="16px" w="100%" backgroundColor={marginColor} />
    </>
  );
}

export default PreviewWrapper;
