import omit from 'lodash/omit';
import { Category } from 'types/category';

const PARSED_VALUES = ['logo', 'image'];

const parseDefaultValues = (dashboard: Category) => {
  const result: Record<string, any> = { ...omit(dashboard, PARSED_VALUES) };

  result.taxonomyContainerId = result.taxonomyContainerId?.toString();
  result.withBadge = !!dashboard?.badge;
  result.withImage = !!dashboard?.image?.url;
  result.withLogo = !!dashboard?.logo?.url;
  result.withIcon = !!dashboard?.icon?.url;

  return result;
};

export default parseDefaultValues;
