import { Icon, IconProps } from '@chakra-ui/react';

function Link(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 24 24"
      width="24px"
      height="24px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_7827_25517"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_7827_25517)">
        <path
          d="M10.8077 16.5384H7.03845C5.78282 16.5384 4.7125 16.096 3.8275 15.2111C2.9425 14.3262 2.5 13.256 2.5 12.0005C2.5 10.745 2.9425 9.67467 3.8275 8.78942C4.7125 7.90417 5.78282 7.46155 7.03845 7.46155H10.8077V8.9615H7.03845C6.1987 8.9615 5.48235 9.25797 4.8894 9.85092C4.29645 10.4439 3.99998 11.1602 3.99998 12C3.99998 12.8397 4.29645 13.5561 4.8894 14.149C5.48235 14.742 6.1987 15.0384 7.03845 15.0384H10.8077V16.5384ZM8.25 12.7499V11.25H15.75V12.7499H8.25ZM13.1923 16.5384V15.0384H16.9615C17.8013 15.0384 18.5176 14.742 19.1106 14.149C19.7035 13.5561 20 12.8397 20 12C20 11.1602 19.7035 10.4439 19.1106 9.85092C18.5176 9.25797 17.8013 8.9615 16.9615 8.9615H13.1923V7.46155H16.9615C18.2171 7.46155 19.2875 7.90399 20.1725 8.78887C21.0575 9.67376 21.5 10.7439 21.5 11.9994C21.5 13.2549 21.0575 14.3253 20.1725 15.2105C19.2875 16.0958 18.2171 16.5384 16.9615 16.5384H13.1923Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Link;
