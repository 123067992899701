import { useMemo } from 'react';
import Select from 'components/Form/Select';
import { useCountriesQuery } from 'app/services/commonApi';
import { LABEL_DICT } from 'components/CountryChange/dictionary';

interface StoreSelectProps {
  label: string;
}

function StoreSelect({ label }: StoreSelectProps) {
  const { isFetching, data: { data: stores } = { data: [] } } =
    useCountriesQuery();

  const storesOptions = useMemo(
    () => [
      {
        label: 'Wszystkie',
        value: '',
      },
      ...stores.map((store) => ({
        label: LABEL_DICT[store.countryCode],
        value: store.store === 'default' ? 'four_fstore_pl' : store.store,
      })),
    ],
    [stores],
  );

  return (
    <Select
      name="store"
      options={storesOptions}
      placeholder="Wpisz lub wybierz z listy"
      label={label}
      isLoading={isFetching}
    />
  );
}

export default StoreSelect;
