/* eslint-disable new-cap */
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';
import { filter, get, isEmpty, map } from 'lodash';
import { useCallback, useMemo } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Statistics/TopBanners/components/Table';
import useQueryParams from 'utils/useQueryParams';
import { useTopBannersQuery } from 'app/services/statisticsApi';
import FiltersDrawer from 'pages/Statistics/TopBanners/components/FiltersDrawer';
import { RANGE_DICT } from 'pages/Statistics/TopBanners/components/FiltersDrawer/constants';
import columns from 'pages/Statistics/TopBanners/components/Table/columns';
import 'pages/Statistics/assets/CustomFont';

function TopBanners() {
  const today = new Date();
  const { queryString } = useQueryParams({
    timeFrom: startOfMonth(startOfDay(today)).toISOString(),
    timeTo: endOfDay(today).toISOString(),
    range: RANGE_DICT.CUSTOM,
  });

  const handleGeneratePdf = useCallback(() => {
    const doc = new jsPDF('l');
    doc.setFontSize(8);
    doc.setFont('Jost');
    autoTable(doc, {
      html: '#top-banners-table',
      styles: {
        font: 'Jost',
        fontSize: 8,
      },
      headStyles: {
        fillColor: '#000',
      },
    });
    doc.save('raport.pdf');
  }, []);

  const { isFetching: statisticsLoading, data: topBanners } =
    useTopBannersQuery(queryString);

  const csvData = useMemo(() => {
    const cols = filter(columns(), 'header');
    const headers = map(cols, 'header');
    const data = map(topBanners, (banner) =>
      map(cols, (col: { accessorKey: string }) =>
        get(banner, col?.accessorKey),
      ),
    );
    return [headers, ...data];
  }, [topBanners]);

  return (
    <ListingLayout
      title="Top 100 banerów - raport"
      hideSearch
      filterDrawer={FiltersDrawer}
      headerButtons={[
        ...(isEmpty(topBanners)
          ? []
          : [
              {
                label: 'EKSPORTUJ DO PDF',
                onClick: handleGeneratePdf,
              },
              {
                label: 'EKSPORTUJ DO CSV',
                as: CSVLink,
                filename: 'raport.csv',
                data: csvData,
              },
            ]),
      ]}
    >
      {statisticsLoading && <LoadingIndicator />}
      {!statisticsLoading && (
        <Table
          data={topBanners || []}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + 140px));`}
        />
      )}
    </ListingLayout>
  );
}

export default TopBanners;
