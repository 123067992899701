import { Box, Flex, FormControl, FormLabel, VStack } from '@chakra-ui/react';
import Card from 'components/Card';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';
import Input from 'components/Form/Input';
import Textarea from 'components/Form/Textarea';
import ColorPicker from 'components/Form/Color';
import Select from 'components/Form/Select';
import { useNotificationCategoriesOptionsQuery } from 'app/services/notificationsApi';
import ActivityFormSection from 'components/ActivityFieldset';
import LinkCreatorSection from 'components/LinkCreator';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import ExternalError from 'components/Form/ExternalError';
import { RECEIVER } from 'pages/Notifications/Form/constants';

interface FormBodyProps {
  isLoading: boolean;
  isEditMode?: boolean;
}

function FormBody({ isLoading, isEditMode }: FormBodyProps) {
  const {
    data: { data: categoriesOptions } = { data: [] },
    isFetching: fetchingCategoriesOptions,
  } = useNotificationCategoriesOptionsQuery();

  return (
    <Flex gap={4} alignItems="flex-start">
      <Box flex={1} maxW={FORM_MAX_WIDTH}>
        <Card>
          {isLoading && <FormSpinner />}
          <VStack maxW={FORM_BODY_MAX_WIDTH} w="100%" spacing={6}>
            <FormControl maxW={FORM_BODY_MAX_WIDTH}>
              <FormLabel
                htmlFor="gender-selector"
                fontWeight={700}
                fontSize={16}
                lineHeight={6}
              >
                Adresat wiadomości
              </FormLabel>

              <Flex mt={4}>
                {(Object.keys(RECEIVER) as Array<keyof typeof RECEIVER>).map(
                  (key) => (
                    <RadioBoxControl
                      key={key}
                      id={`gender-${key}`}
                      label={RECEIVER[key]}
                      name="gender"
                      value={key}
                      group
                    />
                  ),
                )}
              </Flex>
              <ExternalError name="gender" />
            </FormControl>
            <Input name="title" label="Nazwa" placeholder="Wpisz nazwę" />
            <Textarea name="content" label="Opis" placeholder="Wpisz opis" />
            <ColorPicker name="backgroundColor" label="Kolor tła" showError />
            <ColorPicker name="textColor" label="Kolor tekstu" showError />
            <Input name="emoji" label="Emoji" placeholder="Wpisz emoji" />
            <Select
              label="Kategoria wiadomości"
              name="notificationCategoryId"
              isLoading={fetchingCategoriesOptions}
              options={categoriesOptions}
              placeholder="Wpisz lub wybierz z listy"
            />
            <Input
              name="wwwDeeplink"
              label="Deeplink"
              placeholder="Wpisz adres"
            />
            <Input name="maCampaign" label="Inbox campaign" />
            <LinkCreatorSection
              prepareFieldName={(name) => name}
              isEditMode={isEditMode || false}
              hideListingHeader
            />
            <ActivityFormSection prepareFieldName={(name) => name} />
          </VStack>
        </Card>
      </Box>
    </Flex>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
