import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Navbar, { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import Sidebar from 'components/Sidebar';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';
import {
  SIDEBAR_CLOSED_WIDTH,
  SIDEBAR_OPENED_WIDTH,
  toggleSidebar,
} from 'slices/ui/uiSlice';
import { getSidebarState } from 'selectors/uiSelectors';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  const isSidebarOpened = useAppSelector(getSidebarState);
  const dispatch = useAppDispatch();
  const onToggle = () => dispatch(toggleSidebar());

  return (
    <Box w="100%" pt={NAVBAR_HEIGHT}>
      <Sidebar
        isExpanded={isSidebarOpened}
        expand={onToggle}
        openWidth={SIDEBAR_OPENED_WIDTH}
        closeWidth={SIDEBAR_CLOSED_WIDTH}
      />
      <motion.div
        initial={{
          paddingLeft: SIDEBAR_CLOSED_WIDTH + 16,
          paddingRight: 16,
          backgroundColor: '#e8e9f2',
          minHeight: `calc(100vh - ${NAVBAR_HEIGHT})`,
        }}
        animate={{
          paddingLeft: isSidebarOpened
            ? SIDEBAR_OPENED_WIDTH + 16
            : SIDEBAR_CLOSED_WIDTH + 16,
        }}
      >
        <Navbar />
        <Box>{children}</Box>
      </motion.div>
    </Box>
  );
}

export default Layout;
