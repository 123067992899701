import { FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import LinkCreatorSection from 'components/LinkCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import ButtonFormSection from 'components/Container/Sections/ButtonFormSection';
import TagsFieldset from 'components/TagsFieldset';
import Checkbox from 'components/Form/Checkbox';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

// kind: last_chance
function LastChance({ prepareFieldName, isEditMode }: FormComponentProps) {
  return (
    <>
      <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Zdjęcie</SectionTitle>

        <DropzoneField
          name={prepareFieldName('image')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 360px x 396px)"
        />
      </FormControl>

      <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Plik mp4</SectionTitle>
        <DropzoneField name={prepareFieldName('video')} type="video" />
        <Checkbox
          name={prepareFieldName('deleteVideo')}
          label="Usuń plik mp4"
          mb={9}
        />
        <ToggleCheckbox
          label="Pokaż ikonę głośnika"
          name={prepareFieldName('hasSound')}
          maxW={FORM_BODY_MAX_WIDTH}
          mb={9}
        />
      </FormControl>

      <HeaderFormSection
        prepareFieldName={prepareFieldName}
        components={[
          COMPONENTS.SUBTITLE,
          COMPONENTS.COLORTXT,
          COMPONENTS.COLORBG,
        ]}
      />

      <ButtonFormSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
      <TagsFieldset prepareFieldName={prepareFieldName} />
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </>
  );
}

export default LastChance;
