import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/DhlPoints/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import { useDhlPointsQuery } from 'app/services/dhlPointsApi';

function DhlPoints() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const {
    isFetching,
    data: { data: dhlPoints, totals } = { data: [], totals: 0 },
  } = useDhlPointsQuery(queryString);

  return (
    <ListingLayout title="Punkty DHL" hideSearch hideFilter headerButtons={[]}>
      {isFetching && <LoadingIndicator />}
      {!isFetching && (
        <Table
          data={dhlPoints}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default DhlPoints;
