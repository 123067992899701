import { FunctionComponent } from 'react';
import { Box, Text, Button, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { PreviewComponentProps } from 'types/dashboardCreator';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';

interface AdvancedButtonProps {
  decoration: boolean;
}
// eslint-disable-next-line @typescript-eslint/naming-convention
type variantKeys = 'solid' | 'outlined' | 'label' | 'link';

interface SimpleButtonProps {
  variant: 'solid' | 'outlined';
}

interface ButtonsPreviewProps {
  variant: variantKeys;
}

function AdvancedButton({ decoration }: AdvancedButtonProps) {
  // eslint-disable-next-line func-names
  return function ({ formValues }: PreviewComponentProps) {
    return (
      <Box
        textAlign={formValues.alignment ? formValues.alignment : 'center'}
        w="full"
        transition="text-align .3s ease-in-out"
      >
        <Text
          textDecoration={decoration ? 'underline' : 'unset'}
          textTransform="uppercase"
          as="span"
          fontSize={12}
          color={formValues.buttonTextColor}
          fontWeight={700}
        >
          {formValues.title ? formValues.title : 'treść przycisku'}
        </Text>
      </Box>
    );
  };
}

function SimpleButton({ variant }: SimpleButtonProps) {
  // eslint-disable-next-line func-names
  return function ({ formValues }: PreviewComponentProps) {
    return (
      <Button
        type="button"
        variant={variant}
        fontWeight={700}
        w="full"
        textTransform="uppercase"
        color={formValues.buttonTextColor}
        bgColor={
          variant === 'outlined' ? 'transparent' : formValues.backgroundColor
        }
        borderColor={
          variant === 'outlined' ? formValues.backgroundColor : 'auto'
        }
      >
        {formValues.title ? formValues.title : 'treść przycisku'}
      </Button>
    );
  };
}

const ButtonPreviewWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;

  & + & {
    padding-top: 0;
    margin-top: -8px;
  }
`;

const ButtonVariant: {
  [key in variantKeys]: FunctionComponent<PreviewComponentProps>;
} = {
  solid: SimpleButton({ variant: 'solid' }),
  outlined: SimpleButton({ variant: 'outlined' }),
  label: AdvancedButton({ decoration: false }),
  link: AdvancedButton({ decoration: true }),
};

const ButtonsPreview = ({ variant }: ButtonsPreviewProps) => {
  const ButtonPreview: FunctionComponent<PreviewComponentProps> =
    ButtonVariant[variant];

  // eslint-disable-next-line func-names
  return function ({ formValues, showStats }: PreviewComponentProps) {
    return (
      <ButtonPreviewWrapper bgColor={formValues?.insideBoxBackgroundColor}>
        <Box width="100%" position="relative">
          {showStats && (
            <ShowStatsIcon
              data={formValues}
              color={variant === 'solid' ? '#fff' : '#000'}
              size={18}
            />
          )}
          <ButtonPreview formValues={formValues} />
        </Box>
      </ButtonPreviewWrapper>
    );
  };
};

export default ButtonsPreview;
