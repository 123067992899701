import { serialize } from 'object-to-formdata';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { api } from 'app/services/api';
import { CategoriesResponse } from 'types/category';
import {
  DeleteParams,
  TagsParams,
  TagsResponse,
  TransformedTagsResponse,
  ReorderParams,
  TagParams,
  TagResponse,
} from 'types/categoryTags';
import { OptionTypeResponse } from 'types/common';
import { transformActivityDates } from 'utils/date';
import { convertKeysToSnakeCase } from 'utils/helpers';

export const categoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    tags: build.query<TransformedTagsResponse, TagsParams>({
      query: (payload) => {
        const { categoryId } = payload;
        return `categories/${categoryId}/category_tags`;
      },
      keepUnusedDataFor: 10,
      transformResponse(response: TagsResponse) {
        return {
          data: response.data,
        };
      },
      providesTags: ['CategoryTags'],
    }),
    categoriesOptions: build.query<OptionTypeResponse, string>({
      query: (filterParam) =>
        `categories/categories_for_select?q[taxonomy_container_id_eq]=${filterParam}`,
      keepUnusedDataFor: 10,
      transformResponse(response: CategoriesResponse) {
        return {
          data: response.data.map((category) => ({
            value: category.id.toString(),
            label: category.name,
          })),
        };
      },
    }),
    createTag: build.mutation({
      query: (payload) => {
        const { categoryId, ...values } = payload;
        const data = { ...transformActivityDates(values) };

        const submitBody = serialize(
          convertKeysToSnakeCase({ ...data }),
          SERIALIZER_OPTIONS,
          new FormData(),
          'category_tag',
        );

        return {
          url: `categories/${categoryId}/category_tags`,
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['CategoryTags'],
    }),
    deleteTag: build.mutation<void, DeleteParams>({
      query: ({ id, categoryId }) => ({
        url: `categories/${categoryId}/category_tags/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CategoryTags'],
    }),
    reorderTags: build.mutation<void, ReorderParams>({
      query: ({ body, id, categoryId }) => ({
        url: `categories/${categoryId}/category_tags/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CategoryTags'],
    }),
    tag: build.query<TagResponse, TagParams>({
      query: (payload) => {
        const { id, categoryId } = payload;
        return `categories/${categoryId}/category_tags/${id}`;
      },
      keepUnusedDataFor: 10,
      providesTags: ['CategoryTags'],
    }),
    updateTag: build.mutation<void, any>({
      query: (payload) => {
        const { id, categoryId } = payload;
        const data = { ...transformActivityDates(payload) };

        const formData = serialize(
          convertKeysToSnakeCase({ ...data }),
          SERIALIZER_OPTIONS,
          new FormData(),
          'category_tag',
        );

        return {
          url: `/categories/${categoryId}/category_tags/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['CategoryTags'],
    }),
  }),
});

export const {
  useTagsQuery,
  useCategoriesOptionsQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useReorderTagsMutation,
  useTagQuery,
  useUpdateTagMutation,
} = categoryApi;
