import { serialize } from 'object-to-formdata';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { api } from 'app/services/api';
import {
  BodyParams,
  BulkParams,
  CategoryElementResponse,
  QueryParams,
  SubcategoriesResponse,
  SubcategoryResponse,
} from 'types/subcategory';
import { convertKeysToSnakeCase } from 'utils/helpers';

export const subcategoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    categoryElement: build.query<CategoryElementResponse, QueryParams>({
      query: (params) => {
        const { taxonomyContainerId, categoryContainerId } = params;
        return `taxonomy_containers/${taxonomyContainerId}/special_elements/${categoryContainerId}
        `;
      },
      keepUnusedDataFor: 10,
      providesTags: ['CategoryElement'],
    }),
    subcategories: build.query<SubcategoriesResponse, QueryParams>({
      query: (params) => {
        const { taxonomyContainerId, categoryContainerId } = params;
        return `taxonomy_containers/${taxonomyContainerId}/special_elements/${categoryContainerId}/subcategories
        `;
      },
      keepUnusedDataFor: 10,
      providesTags: () => ['Subcategory'],
    }),
    createSubcategory: build.mutation<void, BodyParams>({
      query: (payload) => {
        const { taxonomyContainerId, categoryContainerId, body } = payload;
        const snakePayload = convertKeysToSnakeCase({ ...body });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'taxonomy_container_element_subcategory',
        );

        return {
          url: `/taxonomy_containers/${taxonomyContainerId}/special_elements/${categoryContainerId}/subcategories`,
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: () => ['Subcategory', 'CategoryElement'],
    }),
    updateSubcategory: build.mutation<void, BodyParams>({
      query: (params) => {
        const { taxonomyContainerId, categoryContainerId, id, body } = params;
        const snakePayload = convertKeysToSnakeCase({
          ...body,
          deleteLogo: +body.deleteLogo,
          deleteImage: +body.deleteImage,
          deleteIcon: +body.deleteIcon,
        });

        const formData = serialize(
          snakePayload,
          { ...SERIALIZER_OPTIONS, indices: true },
          new FormData(),
          'taxonomy_container_element_subcategory',
        );

        return {
          url: `/taxonomy_containers/${taxonomyContainerId}/special_elements/${categoryContainerId}/subcategories/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        {
          type: 'Subcategory',
          taxonomyContainerId: arg.taxonomyContainerId,
          categoryContainerId: arg.categoryContainerId,
        },
      ],
    }),
    subcategory: build.query<SubcategoryResponse, QueryParams>({
      query: (params) => {
        const { taxonomyContainerId, categoryContainerId, id } = params;
        return `taxonomy_containers/${taxonomyContainerId}/special_elements/${categoryContainerId}/subcategories/${id}
        `;
      },
      keepUnusedDataFor: 10,
      providesTags: () => ['Subcategory'],
    }),
    deleteSubcategory: build.mutation<void, QueryParams>({
      query: (params) => {
        const { taxonomyContainerId, categoryContainerId, id } = params;
        return {
          url: `taxonomy_containers/${taxonomyContainerId}/special_elements/${categoryContainerId}/subcategories/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => ['Subcategory'],
    }),
    bulkDeleteSubcategory: build.mutation<void, BulkParams>({
      query: (params) => {
        const { taxonomyContainerId, categoryContainerId, body } = params;
        return {
          url: `taxonomy_containers/${taxonomyContainerId}/special_elements/${categoryContainerId}/subcategories/bulk_delete
          `,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: () => ['Subcategory'],
    }),
  }),
});

export const {
  useCategoryElementQuery,
  useSubcategoriesQuery,
  useCreateSubcategoryMutation,
  useSubcategoryQuery,
  useUpdateSubcategoryMutation,
  useBulkDeleteSubcategoryMutation,
  useDeleteSubcategoryMutation,
} = subcategoryApi;
