import { Flex, Box } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/Sizings/Form/components/FormBody';
import FormSpinner from 'components/FormSpinner';
import Select from 'components/Form/Select';
import { SIZING_OPTIONS } from 'pages/Sizings/Form/constants';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <FormSpinner />}
        <Box w="100%">
          <Box mb={8}>
            <Select
              label="Rodzaj"
              name="kind"
              placeholder="Wybierz"
              options={SIZING_OPTIONS}
              isLoading={false}
            />
          </Box>
          <FormBody prepareFieldName={(name: string) => name} />
        </Box>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
