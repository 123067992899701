import * as yup from 'yup';
import {
  activityDatesSchema,
  linkParametersSchema,
} from 'components/Container/schema';

export const BODY_SCHEMA = yup
  .object()
  .shape({
    name: yup.string().required('Pole jest wymagane'),
    title: yup.string().required('Pole jest wymagane'),
    gradientColorTop: yup.string().nullable().required('Pole jest wymagane'),
    gradientColorBottom: yup.string().nullable().required('Pole jest wymagane'),
    textColor: yup.string().nullable().required('Pole jest wymagane'),
    poolName: yup.string().nullable().required('Pole jest wymagane'),
    chancesCount: yup.string().nullable(),
    couponValidity: yup.string().nullable(),
    couponValue: yup.string().nullable(),
    daysCount: yup.string().nullable(),
    surveysCount: yup.string().nullable(),
    finalDate: yup.string().nullable(),
    linkParametersAttributes: yup.array().of(
      yup.object().shape({
        linkType: yup.string().nullable(),
        linkParamValue: linkParametersSchema.linkParamValue,
      }),
    ),
    buttonText: yup.string().nullable().required('Pole jest wymagane'),
    buttonTextColor: yup.string().nullable().required('Pole jest wymagane'),
    buttonBackgroundColor: yup
      .string()
      .nullable()
      .required('Pole jest wymagane'),
    ...activityDatesSchema,
    dateFrom: yup.date().nullable().required('Pole jest wymagane'),
  })
  .required();

export const DEFAULT_VALUES = {
  kind: 'survey',
  name: '',
  title: '',
  gradientColorTop: null,
  gradientColorBottom: null,
  textColor: null,
  chancesCount: '',
  couponValidity: '',
  couponValue: '',
  daysCount: '',
  surveysCount: '',
  finalDate: '',
  linkParametersAttributes: [{ linkType: null }],
  buttonText: '',
  buttonTextColor: null,
  buttonBackgroundColor: null,
  dateFrom: null,
  dateTo: null,
  timeFrom: null,
  timeTo: null,
};
