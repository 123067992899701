import { Box, Flex, FlexProps, useDisclosure } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
import { compact, find, values } from 'lodash';
import { SelectInstance } from 'react-select';
import qs from 'query-string';
import Search from 'components/Icons/Search';
import Close from 'components/Icons/Close';
import useQueryParams from 'utils/useQueryParams';
import { useSearchQuery } from 'app/services/commonApi';
import SearchSelect from './SearchSelect';

type SearchInputProps = FlexProps & {
  searchKey: string;
  placement: string;
};

function SearchInput({ searchKey, placement, ...props }: SearchInputProps) {
  const { search, setSearch, deleteParams } = useQueryParams();
  const searchPhrase = search.get(searchKey);
  const ref = useRef<SelectInstance<any> | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: !!searchPhrase,
  });
  const [searchValue, setSearchValue] = useState<string>(searchPhrase || '');
  const [querySearchValue, setQuerySearchValue] = useState<string>('');

  const isDashboardSearch = placement === 'dashboard';
  const isBlogPostSearch = placement === 'blog_post';

  const queryString = useMemo(() => {
    const parsedSearchValue = qs.parse(`${searchKey}=${querySearchValue}`);

    return qs.stringify({
      ...parsedSearchValue,
      ...(isDashboardSearch && {
        gender: search.get('gender'),
      }),
      ...(isBlogPostSearch && {
        editorial: true,
      }),
    });
  }, [
    isBlogPostSearch,
    isDashboardSearch,
    querySearchValue,
    search,
    searchKey,
  ]);

  const { data } = useSearchQuery(
    {
      queryString,
      isDashboardSearch,
    },
    {
      skip: !querySearchValue,
    },
  );

  const onDebounce = useMemo(
    () =>
      debounce((value: string) => {
        setQuerySearchValue(value);
      }, 700),
    [],
  );

  const onSubmit = (value: string) => {
    search.set('page', '1');
    search.set(searchKey, value);
    setSearch(search);
    ref?.current?.blur();
  };

  const onCloseInput = () => {
    deleteParams([searchKey]);
    setSearchValue('');
    onClose();
  };

  const onOpenInput = () => {
    onOpen();
    ref?.current?.focus();
  };

  const suggestions = useMemo(
    () =>
      data?.map((suggestion) => {
        const fields = compact(
          isDashboardSearch
            ? [suggestion.title, suggestion.subtitle, suggestion.name]
            : [suggestion.title, suggestion.slug],
        );
        return {
          label: fields.join(', '),
          value: find(values(fields), (text) =>
            text?.toLowerCase()?.includes(searchValue?.toLowerCase()),
          ),
        };
      }),
    [data, isDashboardSearch, searchValue],
  );

  return (
    <motion.div
      initial={{
        width: isOpen ? 400 : 0,
        display: 'flex',
        marginTop: '6px',
        borderBottom: '1px solid',
      }}
      animate={{
        width: isOpen ? 400 : 0,
      }}
    >
      <Flex
        w="100%"
        align="center"
        justify="space-between"
        pb="4px"
        position="relative"
        {...props}
      >
        <Search
          onClick={onOpenInput}
          cursor="pointer"
          position="absolute"
          left={isOpen ? '4px' : '-22px'}
          transition="left 0.15s ease"
        />
        {isOpen && (
          <Box width="100%">
            <SearchSelect
              innerRef={ref}
              searchValue={searchValue}
              value={searchValue}
              placeholder="Szukaj w tabeli"
              options={suggestions}
              onInputChange={(value: string) => {
                setSearchValue(value);
                onDebounce(value);
              }}
              onChange={(option: any) => {
                onSubmit(option.value);
              }}
              onKeyDown={(event: React.KeyboardEvent) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  onSubmit(searchValue);
                }
              }}
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? null : 'Brak wyników'
              }
            />
          </Box>
        )}
        <Close
          cursor="pointer"
          onClick={onCloseInput}
          position="absolute"
          right="4px"
          display={isOpen ? 'block' : 'none'}
        />
      </Flex>
    </motion.div>
  );
}

export default SearchInput;
