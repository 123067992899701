export const ADMINISTRATOR_ROLE: { [key: string]: string } = {
  super_admin: 'Super Admin',
  marketing_admin: 'Marketing Admin',
};

export const ADMINISTRATOR_ROLE_OPTIONS = Object.entries(
  ADMINISTRATOR_ROLE,
).map(([key, value]) => ({
  label: value,
  value: key,
}));

export const DEFAULT_VALUES = {
  email: '',
  role: '',
  password: '',
  passwordConfirmation: '',
};
