import { isEmpty, omit } from 'lodash';
import { SubscriptionRunupScreen } from 'types/subscriptionRunupScreens';
import { transformLinkParameters } from 'utils/linkCreator';

const PARSED_VALUES: string[] = ['linkParameters'];

const parseDefaultValues = (
  subscriptionRunupScreen: SubscriptionRunupScreen,
) => {
  const result: Record<string, any> = {
    ...omit(subscriptionRunupScreen, PARSED_VALUES),
  };

  const { linkParameters } = subscriptionRunupScreen;

  if (isEmpty(linkParameters)) {
    result.linkParametersAttributes = [{ linkType: null }];
  } else {
    result.linkParametersAttributes = subscriptionRunupScreen.linkParameters;
  }

  return transformLinkParameters(result);
};

export default parseDefaultValues;
