import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'app/services/authApi';
import { AuthState } from 'slices/auth/auth';
import {
  addCredentials,
  clearCacheData,
  retrieveStorageData,
  // STORE_KIND_KEY,
  TOKEN_TYPE_KEY,
  UID_KEY,
} from 'utils/auth';
import { getStoreParam } from 'utils/helpers';

const initialState = (): AuthState => {
  const store = getStoreParam();
  const storageData = retrieveStorageData(store);
  return {
    isAuthenticated: !!storageData.accessToken,
    email: storageData.uid,
    authHeaders: {
      client: storageData.client,
      accessToken: storageData.accessToken,
      tokenType: storageData.tokenType,
      uid: storageData.uid,
    },
    store, // storageData.store,
    credentials: storageData.credentials,
    user: undefined,
  };
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState(),
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, action) => {
        const {
          payload: {
            data: { email },
          },
          meta: { baseQueryMeta },
        } = action;

        const {
          response: { headers },
          request: { headers: reqHeaders },
        } = baseQueryMeta as any;

        const store = reqHeaders.get('store');
        const authHeaders = {
          client: headers.get('client'),
          accessToken: headers.get('access-token'),
          tokenType: headers.get('token-type'),
          uid: headers.get('uid'),
        };

        localStorage.setItem(TOKEN_TYPE_KEY, authHeaders.tokenType);
        localStorage.setItem(UID_KEY, authHeaders.uid);
        // localStorage.setItem(STORE_KIND_KEY, store);

        addCredentials(authHeaders.client, authHeaders.accessToken);

        state.credentials = {
          client: authHeaders.client,
          token: authHeaders.accessToken,
          store,
        };

        state.authHeaders = authHeaders;
        state.isAuthenticated = true;
        state.email = email;
      },
    );
    builder.addMatcher(
      authApi.endpoints.getUser.matchFulfilled,
      (state, action) => {
        state.user = action.payload.data;
      },
    );
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.authHeaders = {
        client: '',
        accessToken: '',
        tokenType: '',
        uid: '',
      };
      state.email = '';
      state.credentials = null;
      clearCacheData();
    },
    softLogout: (state) => {
      state.isAuthenticated = false;
      state.authHeaders = {
        client: '',
        accessToken: '',
        tokenType: '',
        uid: '',
      };
      state.email = '';
    },
    changeStore: (state, action: PayloadAction<string>) => {
      // localStorage.setItem(STORE_KIND_KEY, action.payload);
      const credential = state.credentials;
      if (credential) {
        state.authHeaders.accessToken = credential.token;
        state.authHeaders.client = credential.client;
      } else {
        state.isAuthenticated = false;
        state.authHeaders.accessToken = '';
        state.authHeaders.client = '';
        state.authHeaders.uid = '';
      }
      state.store = action.payload;
    },
    // setUser: (state) => ({}),
    reset: () => ({
      ...initialState(),
      authHeaders: {
        client: '',
        accessToken: '',
        tokenType: '',
        uid: '',
      },
    }),
  },
});

export const { logout, softLogout, reset, changeStore } = authSlice.actions;

export default authSlice.reducer;
