import { Link } from 'react-router-dom';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/Category/Listing/components/Table';
import FooterButtons from 'pages/Category/Listing/components/FooterButtons';
import ROUTES from 'app/routes';
import {
  useDeleteCategoryMutation,
  useCategoriesQuery,
} from 'app/services/categoryApi';
import { useTabsQuery } from 'app/services/commonApi';
import ListingTabs from 'components/ListingTabs';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import useQueryParams from 'utils/useQueryParams';
import LoadingIndicator from 'components/LoadingIndicator';
import useRoute from 'utils/useRoute';

function Category() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
    'q[taxonomy_container_id_eq]': '58',
  });
  const newCategoryPath = useRoute(ROUTES.category.create);

  const [, state] = useDeleteCategoryMutation({
    fixedCacheKey: 'categories',
  });

  const {
    isFetching: tabsLoading,
    data: { data: tabsOptions } = { data: [] },
  } = useTabsQuery();

  const {
    isFetching: categoriesLoading,
    data: { data: categories, totals } = { data: [], totals: 0 },
  } = useCategoriesQuery(queryString);

  const { isLoading } = state;
  const showLoading = categoriesLoading || tabsLoading || isLoading;

  return (
    <ListingLayout
      title="Taksonomia"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWA KATEGORIA',
          as: Link,
          to: newCategoryPath,
        },
      ]}
    >
      <ListingTabs
        paramName="q[taxonomy_container_id_eq]"
        options={tabsOptions}
      />
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={categories}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={<FooterButtons />} />
    </ListingLayout>
  );
}

export default Category;
