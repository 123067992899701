import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { useSubscriptionRunupScreenElementsQuery } from 'app/services/subscriptionRunupScreensApi';
import Table from 'pages/SubscriptionRunupScreenElements/Listing/components/Table';
import HeaderNavigation from 'pages/SubscriptionRunupScreenElements/Listing/components/HeaderNavigation';

function SubscriptionRunupScreenElements() {
  const { id = '' } = useParams();
  const { data: screen, isFetching } = useSubscriptionRunupScreenElementsQuery(
    id!,
  );

  return (
    <Box pt="36px">
      <HeaderNavigation screenId={id} />
      {isFetching && <LoadingIndicator />}
      {!isFetching && screen && (
        <Table data={screen.elements} screenId={screen.id} />
      )}
    </Box>
  );
}

export default SubscriptionRunupScreenElements;
