import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { useRegulationElementsQuery } from 'app/services/regulationsApi';
import Table from './components/Table';
import HeaderNavigation from './components/HeaderNavigation';

function RegulationElements() {
  const { regulationId = '' } = useParams();
  const { data: regulation, isFetching } = useRegulationElementsQuery(
    regulationId!,
  );

  return (
    <Box pt="36px">
      <HeaderNavigation
        name={regulation?.title ?? 'Loading...'}
        regulationId={regulationId}
      />
      {isFetching && <LoadingIndicator />}
      {!isFetching && regulation && (
        <Table data={regulation.elements} regulationId={regulation.id} />
      )}
    </Box>
  );
}

export default RegulationElements;
