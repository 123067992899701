import { VStack, Text } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';

interface ProductPlaceholderProps {
  idx: number;
}
function ProductPlaceholder({ idx }: ProductPlaceholderProps) {
  return (
    <VStack
      bgColor="complementary.whiteGrey"
      justifyContent="center"
      position="relative"
      alignItems="center"
      w="full"
      h="full"
    >
      <Text
        position="absolute"
        fontWeight={700}
        fontSize="12px"
        left={4}
        top={2}
      >
        {idx + 1}
      </Text>
      <BiImage size={30} />
      <Text fontSize="14px" fontWeight={700} textAlign="center" pt={3}>
        Zdjęcie produktu
      </Text>
    </VStack>
  );
}

export default ProductPlaceholder;
