import { useEffect } from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  useMagentoCategoriesQuery,
  useTabsQuery,
} from 'app/services/commonApi';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import ColorPicker from 'components/Form/Color';
import Dropzone from 'components/Form/Dropzone';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import OptionalFieldset from 'components/OptionalFieldset';
import SuggestionBox from 'components/SuggestionBox';
import ExternalError from 'components/Form/ExternalError';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import Checkbox from 'components/Form/Checkbox';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  const { data: { data: tabsOptions } = { data: [] } } = useTabsQuery();
  const {
    data: { data: magentoOptions } = { data: [] },
    isFetching: magentoLoading,
  } = useMagentoCategoriesQuery();

  const { control, setValue } = useFormContext();

  const [withBadge, withImage, withLogo, withIcon] = useWatch({
    control,
    name: [
      prepareFieldName('withBadge'),
      prepareFieldName('withImage'),
      prepareFieldName('withLogo'),
      prepareFieldName('withIcon'),
    ],
  });

  useEffect(() => {
    if (!withBadge) {
      setValue(prepareFieldName('badge'), '');
    }
  }, [prepareFieldName, setValue, withBadge]);

  return (
    <>
      <Box mb={9}>
        <Text variant="sectionTitle">Wybierz gdzie dodać kategorię</Text>
        <Flex mt={4}>
          {tabsOptions.map((option, idx) => (
            <RadioBoxControl
              key={option.value}
              id={`${prepareFieldName('taxonomyContainerId')}-option-${idx}`}
              label={option.label}
              name={prepareFieldName('taxonomyContainerId')}
              value={option.value}
              group
            />
          ))}
        </Flex>
        <ExternalError name={prepareFieldName('taxonomyContainerId')} />
      </Box>
      <Box>
        <Text variant="boldSectionTitle" pb="22px">
          Kategoria w Magento
        </Text>
        <Select
          label="Kategoria w Magento"
          name={prepareFieldName('externalCategoryId')}
          placeholder="Wybierz"
          options={magentoOptions}
          isLoading={magentoLoading}
        />
      </Box>
      <Box pt="44px">
        <Text variant="boldSectionTitle" pb="22px">
          Kolor tła
        </Text>
        <ColorPicker
          label="Kolor tła"
          name={prepareFieldName('backgroundColor')}
        />
      </Box>
      <SuggestionBox mt="24px">
        Pamiętaj, że dodanie wielu elementów opcjonalnych może negatywnie
        wpłynąć na wyświetlenie danej kategorii. Rekomendujemy 1 element
        opcjonalny dla danej kategorii
      </SuggestionBox>
      <OptionalFieldset
        title="Zdjęcie (opcjonalnie)"
        pt="20px"
        isActive={withImage}
      >
        <Dropzone name={prepareFieldName('image')} />
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteImage')}
        label="Usuń zdjęcie"
        pb="30px"
        defaultChecked={false}
      />
      <OptionalFormFieldset
        title="Badge (opcjonalnie)"
        name={prepareFieldName('withBadge')}
        pt="20px"
        isActive={withBadge}
      >
        <VStack spacing="26px">
          <ColorPicker
            label="Kolor tła"
            name={prepareFieldName('badgeColor')}
          />
          <Input label="Treść" name={prepareFieldName('badge')} />
          <ColorPicker
            label="Kolor tekstu"
            name={prepareFieldName('badgeTextColor')}
          />
        </VStack>
      </OptionalFormFieldset>
      <OptionalFieldset
        title="Logo (opcjonalnie)"
        pt="20px"
        isActive={withLogo}
      >
        <Dropzone name={prepareFieldName('logo')} />
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteLogo')}
        label="Usuń logo"
        pb="30px"
        defaultChecked={false}
      />
      <OptionalFieldset
        title="Ikona (opcjonalnie)"
        pt="20px"
        isActive={withIcon}
      >
        <Dropzone name={prepareFieldName('icon')} />
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteIcon')}
        label="Usuń ikonę"
        pb="30px"
        defaultChecked={false}
      />
      <ToggleCheckbox name={prepareFieldName('hidden')} label="Czy ukryta" />
    </>
  );
}

export default FormBody;
