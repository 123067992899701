/* eslint-disable react/no-array-index-key */
import ReactSelect, {
  OptionProps,
  Props,
  StylesConfig,
  components,
  SelectInstance,
} from 'react-select';
import { Flex, Text } from '@chakra-ui/react';
import { Fragment } from 'react';

const styles: () => StylesConfig = () => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: 0,
    boxShadow: 'unset',
    backgroundColor: 'transparent',
    fontWeight: 400,
    fontSize: '16px',
    ':hover': {
      borderColor: 'black',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '12px 18px 12px 32px',
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    width: '72px',
    justifyContent: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid black',
    boxShadow: 'unset',
    zIndex: 5,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 10,
  }),
  option: (provided) => ({
    ...provided,
    color: 'inherit',
    backgroundColor: 'inherit',
    paddingRight: 'inherit',
    ':hover': {
      backgroundColor: '#F9FAF9',
    },
  }),
});

function Option(props: OptionProps, searchValue: string) {
  const { label } = props;
  const text = label.split(new RegExp(searchValue, 'i'));

  return (
    <components.Option {...props}>
      <Flex direction="row" w="100%" align="center" justify="space-between">
        <Text fontSize={16}>
          {text?.map((item, index) => (
            <Fragment key={`${label}-${item}_${index}`}>
              {item}
              {index !== text.length - 1 && (
                <Text
                  as="span"
                  fontSize={16}
                  textDecoration="underline"
                  fontWeight={700}
                >
                  {searchValue}
                </Text>
              )}
            </Fragment>
          ))}
        </Text>
      </Flex>
    </components.Option>
  );
}

const getCustomComponents = (searchValue: string) => {
  return {
    DropdownIndicator: null,
    IndicatorSeparator: null,
    Option: (props: OptionProps) => Option(props, searchValue),
  };
};

interface SelectProps extends Props {
  searchValue: string;
  innerRef: React.MutableRefObject<SelectInstance<any> | null>;
}

function SearchSelect(props: SelectProps) {
  const { searchValue, innerRef, ...rest } = props;

  return (
    <ReactSelect
      components={getCustomComponents(searchValue)}
      ref={innerRef}
      styles={styles()}
      {...rest}
    />
  );
}

export default SearchSelect;
