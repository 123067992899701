import { Icon, IconProps } from '@chakra-ui/react';

function Filter(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.37493 15.5C7.12493 15.5 6.9166 15.4167 6.74993 15.25C6.58327 15.0833 6.49993 14.875 6.49993 14.625V8.825L0.899933 1.725C0.7166 1.45833 0.6916 1.18733 0.824933 0.912C0.958266 0.637334 1.18327 0.5 1.49993 0.5H14.4999C14.8166 0.5 15.0416 0.637334 15.1749 0.912C15.3083 1.18733 15.2833 1.45833 15.0999 1.725L9.49993 8.825V14.625C9.49993 14.875 9.4166 15.0833 9.24993 15.25C9.08327 15.4167 8.87493 15.5 8.62493 15.5H7.37493ZM7.99993 8.3L12.9499 2H3.04993L7.99993 8.3Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default Filter;
