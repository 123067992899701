import { Text, VStack } from '@chakra-ui/react';

interface PreviewFallbackProps {
  kind?: string;
  text?: string;
}

function PreviewFallback({ kind, text }: PreviewFallbackProps) {
  return (
    <VStack
      w="100%"
      h={388}
      alignItems="center"
      justifyContent="center"
      bgColor="complementary.whiteGrey"
    >
      <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
        {text} <br /> {kind}
      </Text>
    </VStack>
  );
}

PreviewFallback.defaultProps = {
  kind: '',
  text: '',
};

export default PreviewFallback;
