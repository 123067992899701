import { FormControl } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import SectionTitle from 'components/Form/SectionTitle';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';

// kind: graphic_tile
function ChosenForU({ prepareFieldName }: FormComponentProps) {
  return (
    <>
      <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle>Nagłówek</SectionTitle>
        <Input
          label="Tekst nagłówka"
          type="text"
          name={prepareFieldName('title')}
        />
      </FormControl>

      <ToggleCheckbox
        label="Efekt Zoom-in"
        name={prepareFieldName('zoomIn')}
        mb="44px"
        maxW={FORM_BODY_MAX_WIDTH}
      />
    </>
  );
}

export default ChosenForU;
