import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { BiImage } from 'react-icons/bi';
import { AspectRatio, Box, Text, VStack } from '@chakra-ui/react';
import { Image } from 'components/ImagesSlider';

interface ThumbnailsSliderProps {
  elements:
    | {
        image: {} | string;
        magentoImageUrl: string;
      }[]
    | undefined[];
}

function ThumbnailsSlider({ elements }: ThumbnailsSliderProps) {
  return (
    <Box maxW={388}>
      <Swiper
        slidesPerGroup={3}
        spaceBetween={12}
        slidesPerView={3}
        modules={[FreeMode]}
        freeMode
      >
        {elements.map((element: any, idx: number) => (
          <SwiperSlide key={`color_image_ee${+idx}`}>
            <AspectRatio w={120} ratio={3 / 4}>
              {element ? (
                <Image element={element} />
              ) : (
                <VStack
                  alignItems="center"
                  justifyContent="center"
                  bgColor="complementary.whiteGrey"
                  position="relative"
                >
                  <Text position="absolute" top={2} left={4}>
                    {idx + 1}
                  </Text>

                  <BiImage size={30} />
                  <Text
                    fontSize="md"
                    fontWeight={700}
                    textAlign="center"
                    pt={3}
                  >
                    Zdjęcie
                  </Text>
                </VStack>
              )}
            </AspectRatio>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default ThumbnailsSlider;
