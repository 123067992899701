import omit from 'lodash/omit';

const PARSED_VALUES: string[] = ['title, kinds'];

const parseDefaultValues = (regulation: Regulation) => {
  const result: Record<string, any> = { ...omit(regulation, PARSED_VALUES) };
  return result;
};

export default parseDefaultValues;
