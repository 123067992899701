/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Administration/Form/Create/components/ContainerWrapper';
import ROUTES from 'app/routes';
import { transformErrors } from 'utils/api';
import { FORM_MAX_WIDTH } from 'utils/constants';
import { DEFAULT_VALUES } from 'pages/Administration/Form/constants';
import { useCreateAdministratorMutation } from 'app/services/administrationApi';
import useRoute from 'utils/useRoute';

const BODY_SCHEMA = yup.object({
  email: yup
    .string()
    .email('Nieprawidłowy adres-email')
    .required('Pole jest wymagane'),
  role: yup.string().nullable(),
  password: yup
    .string()
    .required('Pole jest wymagane')
    .min(6, 'Podane hasło jest za krótkie (przynajmniej 6 znaków)'),
  passwordConfirmation: yup
    .string()
    .required('Pole jest wymagane')
    .oneOf(
      [yup.ref('password'), null],
      'Hasła nie są takie same. Spróbuj ponownie',
    )
    .min(6, 'Podane hasło jest za krótkie (przynajmniej 6 znaków)'),
});

function AdministratorCreate() {
  const backPath = useRoute(ROUTES.administration.base);

  const [createAdministrator, { isLoading: isCreating }] =
    useCreateAdministratorMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const navigate = useNavigate();

  const { handleSubmit, setError } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response: any = await createAdministrator(data);
      const hasError = Object.hasOwnProperty.call(response, 'error');
      if (hasError) {
        const {
          error: { data: errors },
        } = response;
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field: string) => {
          setError(field as never, {
            type: 'custom',
            message: transformedErrors[field],
          });
        });
      } else {
        navigate(backPath);
        toast.success(`Administrator został pomyślnie utworzony!`);
      }
    } catch (err) {
      // eslint-disable-next-line
      console.debug(err);
    }
  });

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Administrator',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj administratora' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="administrator-creator"
          onSubmit={onSubmit}
          pb="120px"
          maxW={FORM_MAX_WIDTH}
        >
          <ContainerWrapper isLoading={isCreating} />
        </Box>
      </FormProvider>
      <FooterButtons
        isLoading={isCreating}
        isCreate
        formId="administrator-creator"
        backPath={backPath}
      />
    </Box>
  );
}

export default AdministratorCreate;
