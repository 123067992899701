import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import parseDefaultValues from 'pages/CategoryTags/Form/Edit/parseDefaultValues';
import ContainerWrapper from 'pages/CategoryTags/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import { useTagQuery, useUpdateTagMutation } from 'app/services/categoryTags';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA, preparePayload } from 'pages/CategoryTags/Form/constants';
import useRoute from 'utils/useRoute';

function DashboardEdit() {
  const { id = '', categoryId = '' } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.categoryTag.base);
  const { data: { data: tag } = {}, isFetching } = useTagQuery({
    categoryId,
    id,
  });
  const [updateTag, { isLoading: updateRunning }] = useUpdateTagMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    formState: { errors: formErrors },
  } = methods;

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      toast.error(
        'Formularz zawiera błędy. Sprawdź poprawność i spróbuj ponownie.',
      );
    }
    // eslint-disable-next-line no-console
    console.debug({ formErrors });
  }, [formErrors]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateTag({ ...preparePayload(data), id, categoryId }).unwrap();
      navigate(backPath);
      toast.success(`Zmiany w tagu zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (tag) reset(parseDefaultValues(tag));
  }, [tag, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Tag taksonomii',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj tag' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="tag-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="tag-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default DashboardEdit;
