import omit from 'lodash/omit';

const PARSED_VALUES: string[] = ['activeFrom, activeTo'];

const parseDefaultValues = (minibanner: Minibanner) => {
  const result: Record<string, any> = {
    ...omit(minibanner, PARSED_VALUES),
  };
  const { activeFrom, activeTo, image } = minibanner;

  if (activeFrom) {
    result.dateFrom = new Date(activeFrom);
    result.timeFrom = new Date(activeFrom);
  }

  if (activeTo) {
    result.dateTo = new Date(activeTo);
    result.timeTo = new Date(activeTo);
  }

  result.type = image?.url?.length > 0 ? 'image' : 'text';

  return result;
};

export default parseDefaultValues;
