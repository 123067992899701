import { useMemo } from 'react';
import { BiImage } from 'react-icons/bi';
import { VStack, Text, Box } from '@chakra-ui/react';
import useImage from 'utils/useImage';
import VideoBannerPreview from 'components/Container/Previews/VideoBannerPreview';
import SlideContent from 'components/Container/Previews/PhotoBannerCarouselPreview/SlideContent';
import { SlideProps, Element, ElementType } from 'types/previews';

function Slide({ element, showStats }: SlideProps<Element>) {
  const imagePreview = useImage(element?.image || element.magentoImageUrl);

  const type = useMemo(() => {
    if (element.type) {
      return element.type as ElementType;
    }

    if (element.video?.url) {
      return 'video';
    }

    return 'image';
  }, [element.type, element.video?.url]);

  return (
    <Box>
      {type === 'image' && (
        <VStack
          w="100%"
          h="100%"
          alignItems="center"
          justifyContent="center"
          bgColor="complementary.whiteGrey"
          bgImage={imagePreview}
          bgSize="contain"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        >
          <SlideContent
            element={element}
            showStats={showStats}
            fallback={
              !imagePreview ? (
                <VStack alignItems="center" justifyContent="center">
                  <BiImage size={30} />
                  <Text
                    fontSize="md"
                    fontWeight={700}
                    textAlign="center"
                    pt={3}
                  >
                    Tu pojawi się dodane zdjęcie
                  </Text>
                </VStack>
              ) : null
            }
          />
        </VStack>
      )}

      {type === 'video' && (
        <VideoBannerPreview formValues={element}>
          <SlideContent element={element} props={{ zIndex: 100 }} />
        </VideoBannerPreview>
      )}
    </Box>
  );
}

Slide.defaultValue = {
  number: 0,
  element: {},
  elements: [],
};

export default Slide;
