import { Icon, IconProps } from '@chakra-ui/react';

function Cart(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 40 40"
      width="40px"
      height="40px"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        mask="url(#h4ej4xm38a)"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
      >
        <path d="M26 19.75V16a1 1 0 0 0-1-1H13.5a1 1 0 0 0-1 1v11.5a1 1 0 0 0 1 1h3.75" />
        <path d="M16 18v-4.5a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3V18M23.5 22v8M19.5 26h8" />
      </g>
    </Icon>
  );
}

export default Cart;
