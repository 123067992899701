import { Flex, Icon, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { MdArrowUpward } from 'react-icons/md';

const FooterText = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
`;

interface FooterButtonsProps {
  valuesSum: number;
  percentDeltaSum: number;
}

function FooterButtons({ valuesSum, percentDeltaSum }: FooterButtonsProps) {
  return (
    <Flex align="center" pl={8}>
      <FooterText px={4} width="248px">
        Suma całkowita
      </FooterText>
      <FooterText px={4} width="268px" textAlign="right">
        {valuesSum}
      </FooterText>
      <Flex align="center" justify="right" width="216px" px={4} gap={4}>
        <FooterText>{percentDeltaSum}%</FooterText>
        {percentDeltaSum !== 0 && (
          <Icon
            as={MdArrowUpward}
            sx={{
              width: '24px',
              height: '24px',
              transform: `rotate(${percentDeltaSum > 0 ? 0 : 180}deg)`,
              color: '#fff',
            }}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default FooterButtons;
