import { Box, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Header from 'components/Sidebar/Header';
import Navigation from 'components/Navigation';
import ResizeButton from 'components/Sidebar/ResizeButton';

interface SidebarProps {
  isExpanded: boolean;
  closeWidth: number;
  openWidth: number;
  expand: () => void;
}

function Sidebar({ isExpanded, closeWidth, openWidth, expand }: SidebarProps) {
  return (
    <Box>
      <motion.div
        hidden={false}
        initial={false}
        animate={{ width: isExpanded ? openWidth : closeWidth }}
        style={{
          background: 'white',
          overflow: 'hidden',
          position: 'fixed',
          zIndex: 100,
          left: 0,
          height: '100vh',
          top: 0,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Flex w="100%" h="100%" justify="space-between" direction="column">
          <Header isExpanded={isExpanded} />
          <Navigation isSidebarExpanded={isExpanded} expandSidebar={expand} />
          <ResizeButton isExpanded={isExpanded} onClick={expand} />
        </Flex>
      </motion.div>
    </Box>
  );
}

export default Sidebar;
