import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  logo: yup.mixed().test({
    test(file, context) {
      if (!file) {
        return context.createError({
          message: 'Logo jest wymagane',
        });
      }
      return true;
    },
  }),
  landingPageId: yup.string().nullable(),
});

export const DEFAULT_VALUES = {
  logo: null,
  landingPageId: null,
};
