/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup';
import { linkParametersSchema } from 'components/Container/schema';

export const BODY_SCHEMA = yup.object({
  weatherCategoryConditionHintsAttributes: yup.array().of(
    yup.object().shape({
      linkText: yup.string().nullable().required('Pole jest wymagane'),
      hintText: yup.string().nullable().required('Pole jest wymagane'),
      tempMin: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable(),
      tempMax: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable(),
      linkParametersAttributes: yup.array().of(
        yup.object().shape({
          linkType: yup.string().nullable().required('Pole jest wymagane'),
          linkParamValue: linkParametersSchema.linkParamValue,
        }),
      ),
    }),
  ),
});

export const DEFAULT_VALUES = {
  weatherCategoryConditionHintsAttributes: [
    {
      linkText: '',
      hintText: '',
      tempMin: null,
      tempMax: null,
      linkParametersAttributes: [{ linkType: null }],
    },
  ],
};
