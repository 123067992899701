import { Flex, Box, Text, BoxProps } from '@chakra-ui/react';
import { USER_KIND_OPTIONS } from 'utils/constants';
import ExternalError from 'components/Form/ExternalError';
import { FormComponentProps } from 'types/dashboardCreator';
import RadioBoxControl from 'components/Form/RadioBoxControl';

type UserKindSectionProps = BoxProps &
  FormComponentProps & {
    isReadOnly?: boolean;
  };

function UserKindSection({
  prepareFieldName,
  isReadOnly,
  ...props
}: UserKindSectionProps) {
  return (
    <Box {...props}>
      <Text variant="boldSectionTitle">Rodzaj użytkownika</Text>
      <Flex mt={4}>
        {USER_KIND_OPTIONS.map((option, idx) => (
          <RadioBoxControl
            key={option.value}
            id={`${prepareFieldName('userKind')}-option-${idx}`}
            label={option.label}
            name={prepareFieldName('userKind')}
            value={option.value}
            group
            isReadOnly={isReadOnly}
          />
        ))}
      </Flex>
      <ExternalError name={prepareFieldName('userKind')} />
    </Box>
  );
}

UserKindSection.defaultProps = {
  isReadOnly: false,
};

export default UserKindSection;
