import ReactSelect, {
  DropdownIndicatorProps,
  OptionProps,
  Props,
  StylesConfig,
  components,
  MultiValueGenericProps,
  GroupBase,
} from 'react-select';
import { Flex } from '@chakra-ui/react';
import {
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineCheckBox,
} from 'react-icons/md';
import CaretDown from 'components/Icons/CaretDown';

const styles: (withError?: boolean) => StylesConfig = (withError) => ({
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    border: `1px solid ${withError ? 'red' : 'black'}`,
    borderRadius: 0,
    boxShadow: 'unset',
    backgroundColor: 'white',
    fontWeight: 400,
    fontSize: '16px',
    ':hover': {
      borderColor: 'black',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '12px 0px 12px 16px',
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    width: '72px',
    justifyContent: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid black',
    borderTop: 'unset',
    boxShadow: 'unset',
    zIndex: 5,
    marginTop: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 10,
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    color: 'inherit',
    backgroundColor: 'inherit',

    paddingRight: isSelected ? '24px' : 'inherit',
    ':hover': {
      backgroundColor: '#F9FAF9',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    background: 'inherit',
    fontSize: '16px',
    fontWeight: 400,
  }),
  multiValueRemove: () => ({
    display: 'none',
  }),
});
function MultiValueContainer(
  props: MultiValueGenericProps<any, boolean, GroupBase<any>>,
) {
  const { selectProps, data } = props;
  const values = selectProps.value;
  return (
    <components.MultiValueContainer {...props}>
      {values[values.length - 1]?.label === data.label
        ? data.label
        : `${data.label}, `}
    </components.MultiValueContainer>
  );
}
function DropdownIndicator({
  selectProps: { menuIsOpen },
}: DropdownIndicatorProps) {
  return (
    <CaretDown
      sx={{
        transform: `rotate(${menuIsOpen ? 180 : 0}deg)`,
        transition: 'transform 0.2s',
      }}
    />
  );
}
function IconOption(props: OptionProps) {
  const { label, isSelected } = props;
  return (
    <components.Option {...props}>
      <Flex
        direction="row"
        align="center"
        gap="19px"
        h="100%"
        fontWeight={400}
        fontSize="16px"
      >
        {isSelected ? (
          <MdOutlineCheckBox size={20} />
        ) : (
          <MdOutlineCheckBoxOutlineBlank size={20} />
        )}
        {label}
      </Flex>
    </components.Option>
  );
}
const customComponents = {
  DropdownIndicator,
  Option: IconOption,
  IndicatorSeparator: () => null,
  MultiValueContainer,
};

interface SelectProps extends Props {
  instanceId: string | number;
  withError?: boolean;
}

function MultiSelect(props: SelectProps) {
  const { withError } = props;

  return (
    <ReactSelect
      components={customComponents}
      styles={styles(withError)}
      menuPortalTarget={document.body}
      {...props}
    />
  );
}

MultiSelect.defaultProps = {
  withError: false,
};

export default MultiSelect;
