import { FormProvider, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { useFormWithSchema } from 'utils/formHooks';
import useQueryParams from 'utils/useQueryParams';
import DatePicker, { DateRangePickerWrapper } from 'components/Form/Date';
import { isValidDate } from 'utils/date';
import { schema } from 'pages/Statistics/TopSearch/components/Form/constants';
import StoreSelect from 'components/StoreSelect';
import { DEFAULT_PARAMS } from 'pages/Statistics/TopSearch';

function Search() {
  const { search, setSearch } = useQueryParams();
  const { timeFrom: defaultTimeFrom, timeTo: defaultTimeTo } = DEFAULT_PARAMS;

  const defaultValues = useMemo(() => {
    const timeFrom = search.get('timeFrom');
    const timeTo = search.get('timeTo');
    const store = search.get('store');

    const result = {
      date: {
        start: defaultTimeFrom,
        end: defaultTimeTo,
      },
      store: '',
    };

    if (timeFrom && isValidDate(timeFrom)) {
      result.date.start = new Date(timeFrom);
    }

    if (timeTo && isValidDate(timeTo)) {
      result.date.end = new Date(timeTo);
    }

    if (store) result.store = store;

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
  });
  const { control, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [currentDate, store] = useWatch({
    control,
    name: ['date', 'store'],
  });

  useEffect(() => {
    if (currentDate?.start && currentDate?.end) {
      search.set('timeFrom', currentDate.start.toISOString());
      search.set('timeTo', currentDate.end.toISOString());
      setSearch(search);
    }
  }, [currentDate, search, setSearch]);

  useEffect(() => {
    if (store) {
      search.set('store', store);
    } else {
      search.delete('store');
    }
    setSearch(search);
  }, [store, search, setSearch]);

  return (
    <FormProvider {...methods}>
      <form>
        <HStack spacing={4} mb={4}>
          <DateRangePickerWrapper>
            <DatePicker name="date" label="Wybierz zakres dat" showError />
          </DateRangePickerWrapper>
          <Box w="100%" maxW="300px">
            <StoreSelect label="Kraj" />
          </Box>
        </HStack>
      </form>
    </FormProvider>
  );
}

export default Search;
