import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import {
  TransformedCategoriesResponse,
  CategoriesResponse,
  CategoryResponse,
  BulkCategoryDelete,
} from 'types/category';

import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';

export const categoryApi = api.injectEndpoints({
  endpoints: (build) => ({
    categories: build.query<TransformedCategoriesResponse, string>({
      query: (queryString) => `categories${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: CategoriesResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: (result) =>
        result
          ? result.data.map(({ id }) => ({ type: 'Category', id }))
          : ['Category'],
    }),
    deleteCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Category'],
    }),

    createCategory: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'category',
        );

        return {
          url: 'categories',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Category'],
    }),
    category: build.query<CategoryResponse, string>({
      query: (id) => `categories/${id}`,
      keepUnusedDataFor: 10,
      providesTags: (result) => [{ type: 'Category', id: result?.data.id }],
    }),
    updateCategory: build.mutation<void, any>({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase({
          ...payload,
          deleteLogo: +payload.deleteLogo,
          deleteImage: +payload.deleteImage,
          deleteIcon: +payload.deleteIcon,
        });

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'category',
        );

        return {
          url: `categories/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Category'],
    }),
    bulkDeleteCategory: build.mutation<void, BulkCategoryDelete>({
      query: (params) => {
        const { body } = params;

        return {
          url: `categories/bulk_delete`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Category'],
    }),
  }),
});

export const {
  useDeleteCategoryMutation,
  useCategoriesQuery,
  useCreateCategoryMutation,
  useCategoryQuery,
  useUpdateCategoryMutation,
  useBulkDeleteCategoryMutation,
} = categoryApi;
