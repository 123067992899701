import { api } from 'app/services/api';

export const ratingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    ratings: build.query<TransformedRatingsResponse, string>({
      query: (queryString) => `ratings${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: RatingsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Ratings'],
    }),
    averageRating: build.query<AverageRatingResponse, void>({
      query: () => 'ratings/average',
      keepUnusedDataFor: 10,
    }),
  }),
});

export const { useRatingsQuery, useAverageRatingQuery } = ratingsApi;
