import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { datePrettier } from 'utils/date';
import { StatusType } from 'types/common';
import StatusBox from 'components/StatusBox';
import ShowPreview from 'components/ShowPreview';
import { ComponentItem, Dashboard } from 'types/dashboard';
import ShowStats from 'components/Stats/ShowElementStats';

interface ColumnProps {
  componentTypes: ComponentItem[];
}

function GetColumns({
  componentTypes,
}: ColumnProps): ColumnDef<Dashboard, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => (
        <Flex align="center" direction="column">
          {info.getValue()}
          <Checkbox
            isChecked={info.row.getIsSelected()}
            onChange={info.row.getToggleSelectedHandler()}
            pt="23px"
          />
        </Flex>
      ),
    },
    {
      header: 'TYTUŁ',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'POZYCJA',
      accessorKey: 'position',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ZDJĘCIE',
      cell: (info) => (
        <ShowPreview data={info.row.original}>Podgląd</ShowPreview>
      ),
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: 'activeFrom',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: 'activeTo',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'LINK WEWNĘTRZNY',
      accessorKey: 'queryLink',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KONTENER',
      accessorKey: 'kind',
      cell: (info) =>
        componentTypes.find((el) => el.value === info.getValue())?.label,
    },
    {
      header: 'STATYSTYKI',
      cell: (info) => {
        const data = info.row.original;
        const statType =
          data?.kind === 'stylization' ? 'appstory_click' : 'banner_click';
        return (
          <ShowStats data={data} type={statType}>
            <Text
              color="complementary.blue"
              fontSize="16px"
              textAlign="center"
              textDecoration="underline"
              cursor="pointer"
            >
              Wyświetl
            </Text>
          </ShowStats>
        );
      },
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
