/* eslint-disable no-underscore-dangle */
import { Accordion, Box, Button, FormControl } from '@chakra-ui/react';
import { useCallback } from 'react';
import { BiPlus } from 'react-icons/bi';
import { useFieldArray, useFormContext } from 'react-hook-form';
import ColorPicker from 'components/Form/Color';
import { FormComponentProps } from 'types/dashboardCreator';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';
import AppstoryItemSection from 'components/Container/Sections/AppstoryItemSection';
import SectionTitle from 'components/Form/SectionTitle';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';
import { DEFAULT_FORM_VALUES } from '../constants';

function AppstoriesForm({ prepareFieldName, isEditMode }: FormComponentProps) {
  const { control } = useFormContext();
  const dispatch = useAppDispatch();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'fieldId',
  });

  const removeElement = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [isEditMode, remove, dispatch, fields],
  );

  const addElement = useCallback(() => {
    append({
      ...DEFAULT_FORM_VALUES.appstories.elementsAttributes[0],
      placement: fields.length,
    });
  }, [append, fields]);

  return (
    <Box as="fieldset" maxW={FORM_MAX_WIDTH}>
      <Accordion defaultIndex={[0]} allowMultiple mb={10} overflow="visible">
        {fields.map((field: any, index) => (
          <AppstoryItemSection
            id={field.fieldId}
            itemIndex={index}
            prepareFieldName={prepareFieldName}
            key={field.fieldId}
            isEditMode={isEditMode || false}
            move={move}
            remove={removeElement}
          />
        ))}

        <Button
          type="button"
          variant="solid"
          mt={4}
          w="100%"
          onClick={addElement}
          fontSize="12px"
          maxW={FORM_BODY_MAX_WIDTH}
        >
          DODAJ ELEMENT
          <Box as="span" ml={2}>
            <BiPlus size={24} />
          </Box>
        </Button>
        <FormControl as="fieldset" mt={9} maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend">Kolor tła całego komponentu</SectionTitle>
          <ColorPicker
            name={prepareFieldName('backgroundColor')}
            label="Kolor tła"
          />
        </FormControl>
        <FormControl as="fieldset" mt={9} maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend">Kolor obramowania elementów</SectionTitle>
          <ColorPicker
            name={prepareFieldName('borderColor')}
            label="Kolor obramowania"
          />
        </FormControl>
      </Accordion>
    </Box>
  );
}

export default AppstoriesForm;
