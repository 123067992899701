import { Flex, Box, Text, Image } from '@chakra-ui/react';

interface ProductWrapperProps {
  imageSrc: string;
  imageLabel: string;
  title: string;
  price: {
    value: number;
    currency: string;
  };
  isNew: boolean;
  isBestseller: boolean;
}

function ProductWrapper({
  imageSrc,
  imageLabel,
  title,
  price,
  isNew,
  isBestseller,
}: ProductWrapperProps) {
  return (
    <Flex
      bgColor="complementary.lightGrey"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      w="full"
    >
      <Image
        h={128}
        w={104}
        src={imageSrc}
        alt={imageLabel}
        maxW={36}
        bgColor="complementary.whiteGrey"
      />

      <Box pl={4}>
        <Text fontSize="14px" lineHeight="24px">
          {title}
        </Text>
        <Text fontWeight={600} fontSize="16px" mt={1}>
          {price.value} {price.currency}
        </Text>
        {(isNew || isBestseller) && (
          <Flex>
            {isNew && (
              <Text fontWeight={600} mr={2}>
                Nowość
              </Text>
            )}
            {isBestseller && <Text color="complementary.blue">Bestseller</Text>}
          </Flex>
        )}
      </Box>

      <Text
        fontSize="12px"
        fontWeight={600}
        textDecor="underline"
        position="absolute"
        bottom={0}
        right={2}
      >
        KUP
      </Text>
    </Flex>
  );
}

export default ProductWrapper;
