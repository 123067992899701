import { DeepPartial, Theme } from '@chakra-ui/react';

const Accordion: DeepPartial<Theme['components']['Accordion']> = {
  parts: ['panel', 'button'],
  baseStyle: {
    panel: {
      lineHeight: '20px',
      fontWeight: 400,
      fontSize: '14px',
    },
    button: {
      fontSize: '14px',
      lineHeight: '24px',
      height: '64px',
      padding: '0 24px 0 24px',
      fontWeight: 400,
      borderTopColor: 'complementary.lightGrey',
    },
  },
};

export default Accordion;
