import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import { BoxProps } from '@chakra-ui/react';
import TabElement from 'components/Table/Table';
import Thead from 'components/Table/Thead';
import Tr from 'components/Table/Tr';
import Tbody from 'components/Table/Tbody';
import Th from 'components/Table/Th';
import GetColumns from 'pages/Subcategory/Listing/components/Table/columns';
import { CategoryElement } from 'types/common';
import TableRow from 'pages/Subcategory/Listing/components/Table/TableRow';

type TableProps = BoxProps & {
  data: CategoryElement[];
};

function Table({ data }: TableProps) {
  const table = useReactTable({
    data,
    columns: GetColumns(),
    getCoreRowModel: getCoreRowModel(),
  });

  if (isEmpty(data)) return null;

  return (
    <TabElement isEmpty={false}>
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={`thread_row__${headerGroup.id}`}>
            {headerGroup.headers.map((header) => (
              <Th key={`thread_column_${header.id}`}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={`table_row_${row.original.id}`} row={row} />
        ))}
      </Tbody>
    </TabElement>
  );
}

export default Table;
