import { Flex, Spinner } from '@chakra-ui/react';

interface LoadingCoverProps {
  position?: 'fixed' | 'absolute';
}

function LoadingCover({ position }: LoadingCoverProps) {
  return (
    <Flex
      position={position}
      width="100%"
      height="100%"
      backgroundColor="rgba(255,255,255,0.7)"
      left={0}
      top={0}
      zIndex={3}
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="xl" />
    </Flex>
  );
}

LoadingCover.defaultProps = {
  position: 'fixed',
};

export default LoadingCover;
