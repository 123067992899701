import { Flex, Box } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/AppStoriesInspirations/Form/components/FormBody';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';

interface ComponentWrapperProps {
  isLoading: boolean;
  editMode?: boolean;
}

function ContainerWrapper({ isLoading, editMode }: ComponentWrapperProps) {
  return (
    <Flex gap={4} mb={12} alignItems="flex-start">
      <Box flex={1} maxW={FORM_MAX_WIDTH}>
        <Card>
          {isLoading && <FormSpinner />}

          <Box w="100%">
            <FormBody prepareFieldName={(name) => name} editMode={editMode} />
          </Box>
        </Card>
      </Box>
    </Flex>
  );
}

ContainerWrapper.defaultProps = {
  editMode: false,
};

export default ContainerWrapper;
