import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';
import { MdOutlineCancel } from 'react-icons/md';
import Select from 'components/Form/Select';
import { useFormWithSchema } from 'utils/formHooks';
import { useCountriesQuery } from 'app/services/commonApi';
import CountryButton from 'components/CountryImport/CountryButton';
import {
  useImportBlogPostMutation,
  useBlogPostsSlugsQuery,
} from 'app/services/blogPostsApi';
import { LABEL_DICT } from 'components/CountryChange/dictionary';

const SCHEMA = yup.object({
  store: yup.string().required('Pole jest wymagane'),
  slug: yup.string().required('Pole jest wymagane'),
});

interface CountryImportProps {
  isOpen: boolean;
  onClose: () => void;
}

function CountryImport({ isOpen, onClose }: CountryImportProps) {
  const methods = useFormWithSchema(SCHEMA, { mode: 'onChange' });
  const { formState, watch, setValue, handleSubmit } = methods;
  const storeValue = watch('store');

  const [landingPageImport] = useImportBlogPostMutation();

  const { data: { data: slugOptions } = { data: [] }, isFetching } =
    useBlogPostsSlugsQuery({ store: storeValue });

  const { data: { data: countries } = { data: [] } } = useCountriesQuery();

  const { isDirty, isValid, isSubmitting } = formState;

  useEffect(() => {
    setValue('slug', '');
  }, [storeValue, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    const countryCode = countries.find(
      (country) => country.store === data.store,
    )?.countryCode;
    try {
      await landingPageImport(data).unwrap();
      toast.success(
        `Udało się! Import z maski ${
          LABEL_DICT[countryCode!]
        } został zakończony sukcesem.`,
      );
      onClose();
    } catch (err) {
      toast.error(
        `Niestety, import z maski ${
          LABEL_DICT[countryCode!]
        } zakończył się niepowodzeniem. Spróbuj ponownie, później.`,
      );
      onClose();
      // eslint-disable-next-line
      console.debug(err);
    }
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent p="76px 58px 66px">
        <ModalHeader p={0}>IMPORTUJ Z KRAJU</ModalHeader>
        <ModalCloseButton>
          <MdOutlineCancel size="19px" />
        </ModalCloseButton>
        <ModalBody p="8px 0 0 0">
          <Text fontSize="16px">
            Wybierz kraj, z którego chcesz importować, a następnie podaj slug BP
          </Text>
          <FormProvider {...methods}>
            <Flex
              as="form"
              id="store-import"
              maxH="408px"
              pt="58px"
              onSubmit={onSubmit}
            >
              <Flex direction="column" w="50%" columnGap="150px" wrap="wrap">
                {countries.map(({ store, countryCode }) => (
                  <CountryButton
                    key={`country_button_${store}`}
                    name="store"
                    countryCode={countryCode}
                    store={store}
                  />
                ))}
              </Flex>
              <Flex
                w="50%"
                direction="column"
                paddingInline="60px"
                justify="space-between"
              >
                <Select
                  options={slugOptions}
                  isLoading={isFetching}
                  label="Slug"
                  placeholder="Wpisz slug"
                  name="slug"
                  disabled={!storeValue}
                />
                <Flex w="100%" direction="column" gap="20px">
                  <Button
                    isDisabled={!isDirty || !isValid}
                    type="submit"
                    form="store-import"
                    isLoading={isSubmitting}
                  >
                    IMPORTUJ
                  </Button>
                  <Button variant="outlined" onClick={onClose}>
                    ANULUJ
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CountryImport;
