import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
} from '@chakra-ui/react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import { GENDER } from 'components/Container/constants';
import BackNavigationButton from 'components/BackNavigationButton';

interface HeaderNavigationProps {
  name: string;
  categoryContainerId: string;
  taxonomyId: string;
  gender: string;
}

function HeaderNavigation({
  name,
  categoryContainerId,
  taxonomyId,
  gender,
}: HeaderNavigationProps) {
  const { store } = useParams();
  const { queryString } = useQueryParams();

  const genderName = GENDER[gender as keyof typeof GENDER];
  const categoryContainerPath = useMemo(
    () =>
      generatePath(ROUTES.categoryContainer.base, {
        store,
      }) + queryString,
    [queryString, store],
  );

  return (
    <Flex mb={6} align="center" justify="space-between">
      <Box>
        <BackNavigationButton />
        <Breadcrumb mt={4}>
          {genderName && (
            <BreadcrumbItem>
              <BreadcrumbLink
                as={Link}
                to={categoryContainerPath}
                fontWeight={700}
              >
                {genderName}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={categoryContainerPath}
              fontWeight={700}
            >
              Kontener taksonomii
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{name}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
      <Button
        fontWeight={700}
        fontSize="12px"
        lineHeight="20px"
        paddingX="32px"
        as={Link}
        to={
          generatePath(ROUTES.subcategory.create, {
            categoryContainerId,
            taxonomyId,
            store,
          }) + queryString
        }
        textDecoration="none"
      >
        NOWA PODKATEGORIA
      </Button>
    </Flex>
  );
}

export default HeaderNavigation;
