import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<ClothingMachine, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'MIASTO',
      accessorKey: 'city',
      cell: (info) => info.getValue(),
    },
    {
      header: 'LOKALIZACJA',
      accessorKey: 'location',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ULICA',
      accessorKey: 'street',
      cell: (info) => info.getValue(),
    },
    {
      header: 'GODZINY OTWARCIA',
      accessorKey: 'openingTimes',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
