import { Box } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <Box>
      <SectionTitle as="legend">Dokument</SectionTitle>
      <DropzoneField
        name={prepareFieldName('document')}
        title="Dodaj dokument"
        type="sheet"
      />
    </Box>
  );
}

export default FormBody;
