/* eslint-disable no-underscore-dangle */
import { useMemo, useState } from 'react';
import { VStack, Text, Box } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';

import 'swiper/css';
import 'swiper/css/free-mode';
import useImage from 'utils/useImage';
import { PreviewComponentProps } from 'types/dashboardCreator';
import TaggedProductsPreview from 'components/Container/Previews/TaggedProductsPreview';
import ThumbnailsSlider from 'components/Container/Previews/StylizationPreview/ThumbnailsSlider';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';

function StylizationPreview({ formValues, showStats }: PreviewComponentProps) {
  const [activeIndex, setActiveIndex] = useState(0);

  const sliderElements = useMemo(
    () =>
      formValues?.elementsAttributes?.filter(
        (element: { [key: string]: any; _destroy?: boolean }) =>
          !element._destroy,
      ) || [],
    [formValues?.elementsAttributes],
  );

  const currentItem = useMemo(
    () => sliderElements?.[activeIndex],
    [activeIndex, sliderElements],
  );

  const imagePreview = useImage(currentItem?.image);

  return (
    <Box position="relative">
      <Text
        fontWeight={700}
        fontSize={formValues?.titleFontSize}
        textTransform="uppercase"
        padding={6}
        textAlign="center"
      >
        {formValues?.title}
      </Text>
      <VStack spacing={0} gap={2} mb={6}>
        <TaggedProductsPreview
          formValues={currentItem}
          showStats={showStats}
          right="48px"
          bottom="188px"
        />
        <VStack
          alignItems="center"
          justifyContent="center"
          w={328}
          h={420}
          bgColor={imagePreview ? 'transparent' : 'complementary.whiteGrey'}
          bgImage={imagePreview}
          bgSize="cover"
          bgRepeat="no-repeat"
          bgPosition="50% 50%"
          position="relative"
        >
          {!imagePreview && (
            <>
              <BiImage size={30} />
              <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
                Tu pojawi się dodane zdjęcie
              </Text>
            </>
          )}
          {showStats && (
            <ShowStatsIcon data={formValues} type="appstory_click" />
          )}
        </VStack>
        <ThumbnailsSlider
          elements={sliderElements}
          setActiveIndex={setActiveIndex}
        />
      </VStack>
    </Box>
  );
}

export default StylizationPreview;
