import { Box } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import { FormComponentProps } from 'types/dashboardCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import UserKindSection from 'components/Container/Sections/UserKindSection';

interface LandingPageFieldsSectionProps extends FormComponentProps {
  isEdit?: boolean;
}

function LandingPageFieldsSection({
  prepareFieldName,
  isEdit,
}: LandingPageFieldsSectionProps) {
  return (
    <Box maxW={FORM_BODY_MAX_WIDTH}>
      {isEdit && <Input label="Kotwica" name={prepareFieldName('slug')} />}

      <UserKindSection prepareFieldName={prepareFieldName} mb={9} mt={9} />
    </Box>
  );
}
LandingPageFieldsSection.defaultProps = {
  isEdit: false,
};
export default LandingPageFieldsSection;
