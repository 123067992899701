/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
// import { linkParametersSchema } from 'components/Container/schema';
import {
  CREATE_SCHEMA as LP_CREATE_SCHEMA,
  EDIT_SCHEMA as LP_EDIT_SCHEMA,
} from 'pages/LandingPage/Form/constants';

export const CREATE_SCHEMA = yup
  .object({
    // linkParametersAttributes: yup.array().of(
    //   yup.object().shape({
    //     linkType: yup.string().nullable(),
    //     linkParamValue: linkParametersSchema.linkParamValue,
    //   }),
    // ),
  })
  .concat(LP_CREATE_SCHEMA);

export const EDIT_SCHEMA = yup
  .object({
    // linkParametersAttributes: yup.array().of(
    //   yup.object().shape({
    //     linkType: yup.string().nullable(),
    //     linkParamValue: linkParametersSchema.linkParamValue,
    //   }),
    // ),
  })
  .concat(LP_EDIT_SCHEMA);

export const DEFAULT_VALUES = {
  editorial: true,
  containersAttributes: [],
  // linkParametersAttributes: [{ linkType: null }],
};
