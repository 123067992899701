import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import {
  SplashScreenResponse,
  SplashScreensResponse,
  TransformedSplashScreensResponse,
} from 'types/splashScreens';
import { transformActivityDates } from 'utils/date';

export const splashScreensApi = api.injectEndpoints({
  endpoints: (build) => ({
    splashScreens: build.query<TransformedSplashScreensResponse, string>({
      query: (queryString) => `splash_screens${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: SplashScreensResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['SplashScreens'],
    }),
    deleteSplashScreen: build.mutation<void, number>({
      query: (id) => ({
        url: `splash_screens/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SplashScreens'],
    }),
    createSplashScreen: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(payload),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'splash_screen',
        );
        return {
          url: 'splash_screens',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['SplashScreens'],
    }),
    splashScreen: build.query<SplashScreenResponse, any>({
      query: (id) => `splash_screens/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['SplashScreens'],
    }),
    updateSplashScreen: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(payload),
        );

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'splash_screen',
        );

        return {
          url: `splash_screens/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['SplashScreens'],
    }),
  }),
});

export const {
  useSplashScreensQuery,
  useDeleteSplashScreenMutation,
  useCreateSplashScreenMutation,
  useSplashScreenQuery,
  useUpdateSplashScreenMutation,
} = splashScreensApi;
