import { DeepPartial, SystemStyleObject, Theme } from '@chakra-ui/react';

const ghost: SystemStyleObject = {
  _hover: {
    bg: 'blackAlpha.50',
  },
};

const solid: SystemStyleObject = {
  color: 'white',
  background: 'black',
  _disabled: {
    backgroundColor: 'complementary.grey',
    color: 'white',
    opacity: 'unset',
  },
  _hover: {
    backgroundColor: 'black',
    color: 'white',
    opacity: 0.7,
    _disabled: {
      backgroundColor: 'complementary.grey',
      opacity: 'unset',
      color: 'white',
    },
  },
};

const outlined: SystemStyleObject = {
  borderColor: 'black',
  borderWidth: '1px',
  _hover: {
    opacity: 0.7,
    _disabled: {
      opacity: 'unset',
    },
  },
  _disabled: {
    borderColor: 'complementary.grey',
    color: 'complementary.grey',
    opacity: 'unset',
  },
};

const link: SystemStyleObject = {
  textDecoration: 'underline',
  color: 'white',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '0.004em',
  textAlign: 'center',

  _disabled: {
    color: 'complementary.grey',
  },
  _hover: {
    opacity: 0.7,
    _disabled: {
      textDecoration: 'underline',
      color: 'complementary.grey',
      opacity: 0.4,
    },
  },
};

const Button: DeepPartial<Theme['components']['Button']> = {
  baseStyle: {
    padding: '15px 24px',
    fontSize: '12px',
    borderRadius: 'none',
    lineHeight: '20px',
    fontWeight: 700,
  },
  variants: {
    ghost,
    solid,
    outlined,
    link,
  },
};

export default Button;
