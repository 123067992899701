import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import parseDefaultValues from 'pages/Subcategory/Form/Edit/parseDefaultValues';
import ContainerWrapper from 'pages/Subcategory/Form/Edit/components/ContainerWrapper';
import {
  useSubcategoryQuery,
  useUpdateSubcategoryMutation,
} from 'app/services/subcategoryApi';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA } from 'pages/Subcategory/Form/constants';
import useRoute from 'utils/useRoute';

function SubcategoryEdit() {
  const { id = '', taxonomyId = '', categoryContainerId = '' } = useParams();

  const backPath = useRoute(ROUTES.subcategory.base, {
    categoryContainerId,
    taxonomyId,
  });

  const navigate = useNavigate();
  const { data: { data: subcategory } = {}, isFetching } = useSubcategoryQuery({
    id,
    taxonomyContainerId: taxonomyId,
    categoryContainerId,
  });
  const [updateSubcategory, { isLoading: updateRunning }] =
    useUpdateSubcategoryMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateSubcategory({
        taxonomyContainerId: taxonomyId,
        categoryContainerId,
        id,
        body: data,
      }).unwrap();

      navigate(backPath);

      toast.success(`Zmiany w podkategorii zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (subcategory) reset(parseDefaultValues(subcategory));
  }, [subcategory, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: subcategory?.name || 'Loading...',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj podkategorię' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="subcategory-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>
      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="subcategory-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default SubcategoryEdit;
