import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import VideoPreview from 'pages/Statistics/TopBanners/components/Table/components/VideoPreview';

function GetColumns(): ColumnDef<TopBannersComparisonItem, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'analyticsId',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ZDJĘCIE',
      accessorKey: 'mainImage',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'VIDEO',
      cell: ({
        row: {
          original: { video },
        },
      }) => <VideoPreview {...video} />,
    },
    {
      header: 'POZYCJA',
      accessorKey: 'valuePosition',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ILOŚĆ',
      accessorKey: 'value',
      cell: (info) => info.getValue(),
    },
    {
      header: 'UDZIAŁ',
      accessorKey: 'valuePercent',
      cell: (info) => `${(+info.getValue() * 100).toFixed(2)}%`,
    },
    {
      header: 'POZYCJA',
      accessorKey: 'secondDateRangeValuePosition',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ILOŚĆ',
      accessorKey: 'secondDateRangeValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'UDZIAŁ',
      accessorKey: 'secondDateRangeValuePercent',
      cell: (info) => `${(+info.getValue() * 100).toFixed(2)}%`,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
