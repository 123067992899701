import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import Link from 'components/Link';
import BackNavigationButton from 'components/BackNavigationButton';

interface HeaderNavigationProps {
  baseCrumb: {
    to: string;
    label: string;
  };
  crumbs: { to?: string; label: string }[];
}

function HeaderNavigation({ baseCrumb, crumbs }: HeaderNavigationProps) {
  return (
    <Box mb={6}>
      <BackNavigationButton />

      <Breadcrumb mt={4}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={baseCrumb.to} fontWeight={700}>
            {baseCrumb.label}
          </BreadcrumbLink>
        </BreadcrumbItem>

        {crumbs.map((crumb, index) => (
          <BreadcrumbItem
            key={`breadcrumbs-nav_${crumb.label}`}
            isCurrentPage={index === crumbs.length - 1}
          >
            <BreadcrumbLink>{crumb.label}</BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Box>
  );
}

export default HeaderNavigation;
