import { useEffect, useMemo, useState } from 'react';
import { Accordion, ExpandedIndex } from '@chakra-ui/react';
import { generatePath, useParams } from 'react-router-dom';
import {
  // MdDesktopWindows,
  MdOutlineBorderColor,
  MdOutlineCategory,
  MdOutlineGavel,
  // MdOutlineCampaign,
  MdOutlineLocalShipping,
  MdOutlineAdminPanelSettings,
  MdOutlineCampaign,
  MdQueryStats,
  MdNotificationsNone,
  MdOutlineSportsBasketball,
} from 'react-icons/md';
import { TbPuzzle } from 'react-icons/tb';
import { ExpandableNavOption, NavOption } from 'types/navigation';
import ExpandableNavItem from 'components/Navigation/ExpandableItem';
import NavItem from 'components/Navigation/Item';
import ROUTES from 'app/routes';

interface NavigationProps {
  isSidebarExpanded: boolean;
  expandSidebar: () => void;
}

function Navigation({ isSidebarExpanded, expandSidebar }: NavigationProps) {
  const [index, setIndex] = useState<ExpandedIndex>();
  const { store } = useParams();
  const isDefaultStore = store === 'default';

  const NAV_OPTIONS: Array<NavOption | ExpandableNavOption> = useMemo(
    () => [
      // { title: 'Pulpit', icon: MdDesktopWindows, link: ROUTES.root },
      {
        title: 'Kontent',
        icon: MdOutlineBorderColor,
        options: [
          { title: 'Dashboard', link: ROUTES.dashboard.base },
          { title: 'Landing Page', link: ROUTES.landingPage.base },
          { title: 'Minibanner', link: ROUTES.minibanners.base },
          { title: 'FAQ', link: ROUTES.faqs.base },
          {
            title: 'Kategoria rozbiegówki',
            link: ROUTES.leaderCategories.base,
          },
          {
            title: 'Kategoria pogodowa',
            link: ROUTES.weatherCategories.base,
          },
          { title: 'O nas', link: ROUTES.abouts.base },
          { title: 'Rozmiarówka', link: ROUTES.sizings.base },
          { title: 'Splash screen', link: ROUTES.splashScreens.base },
          { title: 'Wartości odżywcze', link: ROUTES.nutritions.base },
          { title: 'Ubraniomat', link: ROUTES.clothingMachines.base },
          // { title: 'News banner', link: ROUTES.newsBanner.base },
        ],
      },
      {
        title: 'Taksonomia',
        icon: MdOutlineCategory,
        options: [
          { title: 'Taksonomia', link: ROUTES.category.base },
          { title: 'Kontener taksonomii', link: ROUTES.categoryContainer.base },
          { title: 'Tagi taksonomii', link: ROUTES.categoryTag.base },
          {
            title: 'Inspiracje (app stories)',
            link: ROUTES.appStoriesInspirations.base,
          },
        ],
      },
      {
        title: 'Regulaminy',
        icon: MdOutlineGavel,
        link: ROUTES.regulations.base,
      },
      {
        title: 'Marketing',
        icon: MdOutlineCampaign,
        options: [
          { title: 'Blog Post', link: ROUTES.blogPost.base },
          { title: 'Ekran korzyści', link: ROUTES.benefitsScreens.base },
          { title: 'Ekran CRUD', link: ROUTES.announcements.base },
          { title: 'Ekran onboardingu', link: ROUTES.onboardScreens.base },
          {
            title: 'Ekran rozbiegówki',
            link: ROUTES.subscriptionRunupScreens.base,
          },
          { title: 'Ocena aplikacji', link: ROUTES.ratings.base },
        ],
      },
      {
        title: 'Dostawy',
        icon: MdOutlineLocalShipping,
        options: [
          { title: 'Punkty DHL', link: ROUTES.dhlPoints.base },
          { title: 'Punkty InPost', link: ROUTES.inPostPoints.base },
          { title: 'Metody dostawy', link: ROUTES.shipments.base },
        ],
      },
      {
        title: 'Administracja',
        icon: MdOutlineAdminPanelSettings,
        options: [
          ...(isDefaultStore
            ? [{ title: 'Administrator', link: ROUTES.administration.base }]
            : []),
          { title: 'Użytkownik', link: ROUTES.users.base },
        ],
      },
      {
        title: 'Statystyki',
        icon: MdQueryStats,
        options: [
          { title: 'Wyszukiwarka', link: ROUTES.statistics.topSearch },
          {
            title: 'Top 100 banerów - raport',
            link: ROUTES.statistics.topBanners,
          },
          {
            title: 'Top 100 banerów - porównanie',
            link: ROUTES.statistics.topBannersComparison,
          },
        ],
      },
      {
        title: 'Inbox',
        icon: MdNotificationsNone,
        options: [
          { title: 'Kategorie', link: ROUTES.notificationCategories.base },
          { title: 'Wiadomości', link: ROUTES.notifications.base },
        ],
      },
      ...(isDefaultStore
        ? [
            {
              title: 'Koszykówka',
              icon: MdOutlineSportsBasketball,
              options: [
                { title: 'Drużyny', link: ROUTES.basketball.teams.base },
                { title: 'Strony drużyny', link: ROUTES.basketball.pages.base },
              ],
            },
            {
              title: 'Quizy',
              icon: TbPuzzle,
              link: ROUTES.surveys.base,
            },
          ]
        : []),
    ],
    [isDefaultStore],
  );

  useEffect(() => {
    if (!isSidebarExpanded) setIndex(-1);
  }, [isSidebarExpanded]);

  return (
    <Accordion
      allowToggle
      h="100%"
      overflowX="hidden"
      overflowY="auto"
      index={index}
      onChange={(element) => setIndex(element)}
    >
      {NAV_OPTIONS.map((option, idx) => {
        if ('link' in option) {
          return (
            <NavItem
              key={`nav_option_${option.title}`}
              link={generatePath(option.link, { store })}
              title={option.title}
              icon={<option.icon size={24} />}
              idx={idx}
              isSidebarExpanded={isSidebarExpanded}
            />
          );
        }
        return (
          <ExpandableNavItem
            key={`nav_option_${option.title}`}
            options={option.options}
            title={option.title}
            icon={<option.icon size={24} />}
            idx={idx}
            isSidebarExpanded={isSidebarExpanded}
            expandSidebar={expandSidebar}
            store={store!}
          />
        );
      })}
    </Accordion>
  );
}

export default Navigation;
