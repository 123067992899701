import { Link } from 'react-router-dom';
import ROUTES from 'app/routes';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Notifications/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import useRoute from 'utils/useRoute';
import {
  useDeleteNotificationMutation,
  useNotificationsQuery,
} from 'app/services/notificationsApi';
import FooterButtons from 'pages/Notifications/Listing/components/FooterButtons';

function Notifications() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newNotificationPath =
    useRoute(ROUTES.notifications.create) + queryString;

  const [, state] = useDeleteNotificationMutation({
    fixedCacheKey: 'notifications',
  });

  const {
    isFetching: notificationsLoading,
    data: { data: notifications, totals } = { data: [], totals: 0 },
  } = useNotificationsQuery(queryString);

  const { isLoading } = state;
  const showLoading = notificationsLoading || isLoading;

  return (
    <ListingLayout
      title="WIADOMOŚCI"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWA WIADOMOŚĆ',
          as: Link,
          to: newNotificationPath,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={notifications}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={<FooterButtons />} />
    </ListingLayout>
  );
}

export default Notifications;
