import { Link } from 'react-router-dom';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/CategoryContainer/Listing/components/Table';
import { useCategoryContainersQuery } from 'app/services/categoryContainerApi';
import useQueryParams from 'utils/useQueryParams';
import ROUTES from 'app/routes';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';

import LoadingIndicator from 'components/LoadingIndicator';
import useRoute from 'utils/useRoute';

function CategoryContainerListing() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newContainerPath = useRoute(ROUTES.categoryContainer.create);

  const {
    isFetching: categoriesLoading,
    data: { data: containers } = { data: [] },
  } = useCategoryContainersQuery(queryString);

  const showLoading = categoriesLoading;

  return (
    <ListingLayout
      title="Kontener Taksonomii"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWY KONTENER',
          as: Link,
          to: newContainerPath,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={containers}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + 128px));`}
        />
      )}
    </ListingLayout>
  );
}

export default CategoryContainerListing;
