import { BiImage } from 'react-icons/bi';
import { VStack, Text, Box } from '@chakra-ui/react';
import useImage from 'utils/useImage';
import Headers from 'components/Container/Previews/Headers';
import { SlideProps, Element } from 'types/previews';

function Slide({ element }: SlideProps<Element>) {
  const imagePreview = useImage(element?.image);

  return (
    <Box width="224px" height="304px">
      <VStack
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        bgColor="complementary.whiteGrey"
        bgImage={imagePreview}
        bgSize="contain"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
        position="relative"
      >
        <Box>
          {!imagePreview ? (
            <VStack alignItems="center" justifyContent="center">
              <BiImage size={30} />
              <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
                Zdjęcie produktu
              </Text>
            </VStack>
          ) : null}
          <Box position="absolute" left="16px" bottom="16px">
            <Headers position="static" {...element} />
          </Box>
        </Box>
      </VStack>
    </Box>
  );
}

export default Slide;
