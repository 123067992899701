import styled from '@emotion/styled';

interface TrProps {
  isNested?: boolean;
}

const Tr = styled.tr`
  border-color: black;
  border-inline-width: ${(props: TrProps) => (props.isNested ? '0' : '1px')};
  border-bottom-width: 1px;
  border-top-width: ${(props) => (props.isNested ? '1px' : '0')};
`;

export default Tr;
