import { useMemo } from 'react';
import { Tag } from 'react-tag-input';
import { Box } from '@chakra-ui/react';
import TagInput from 'components/Form/TagInput';
import SpecialTagInput from 'components/Form/SpecialTagInput';
import Input from 'components/Form/Input';
import { DropdownOptions } from 'types/linkCreator';

interface ParamsInputProps {
  isMultipleValueInput: boolean;
  name: string;
  suggestions?: DropdownOptions[];
}

function ParamsInput({
  isMultipleValueInput,
  name,
  suggestions,
}: ParamsInputProps) {
  const proposals =
    useMemo(
      () =>
        suggestions?.map((s) => ({
          id: s.value,
          text: s.label,
        })),
      [suggestions],
    ) || [];

  return (
    <Box mt={6}>
      {isMultipleValueInput ? (
        <>
          {proposals.length > 0 ? (
            <SpecialTagInput
              label="Wartość parametru"
              name={name}
              isRequired
              suggestions={proposals as Tag[]}
            />
          ) : (
            <TagInput
              label="Wartość parametru"
              name={name}
              isRequired
              suggestions={[]}
            />
          )}

          <Box
            mt={6}
            backgroundColor="complementary.purpleBlue"
            p="24px 40px 24px 16px"
          >
            Możesz wpisać kilka wartości. Po dodaniu wartości zatwierdź ją
            enterem lub rodziel przecinkiem
          </Box>
        </>
      ) : (
        <Input label="Wartość parametru" name={name} mb={6} />
      )}
    </Box>
  );
}

ParamsInput.defaultProps = {
  suggestions: [],
};

export default ParamsInput;
