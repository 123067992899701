import { Flex, Box, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import Input from 'components/Form/Input';
import FormSpinner from 'components/FormSpinner';
import FormBody from 'pages/Regulations/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';

interface ComponentWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ComponentWrapperProps) {
  return (
    <Flex gap={4} mb={12} alignItems="flex-start">
      <Box flex={1} maxW={FORM_MAX_WIDTH}>
        <Card>
          {isLoading && <FormSpinner />}

          <Stack maxW={FORM_BODY_MAX_WIDTH} w="100%" spacing={8}>
            <Input
              name="title"
              label="Nazwa"
              placeholder="Wpisz nazwę regulaminu"
            />
            <FormBody prepareFieldName={(name: string) => name} />
          </Stack>
        </Card>
      </Box>
    </Flex>
  );
}

export default ContainerWrapper;
