/* eslint-disable react/no-array-index-key */
import { IconButton, Tooltip } from '@chakra-ui/react';
import { flexRender, Row } from '@tanstack/react-table';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MdKeyboardArrowUp } from 'react-icons/md';
import Tr from 'components/Table/Tr';
import Td from 'pages/Statistics/TopSearch/components/Table/components/Td';
import NestedTable from 'pages/Statistics/TopSearch/components/NestedTable';

interface TableRowProps {
  row: Row<SearchPhrase>;
}

const MotionTr = motion(Tr);

function TableRow({ row }: TableRowProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { id } = row.original;

  return (
    <React.Fragment key={`group_row_${row.id}`}>
      <Tr key={`row_${id}`}>
        {row.getVisibleCells().map((cell, idx, { length }) => {
          if (idx + 1 === length) return null;
          return (
            <Td
              key={`row_cell_${idx}_${cell}`}
              style={{
                minWidth: cell.column.columnDef.size,
                width: cell.column.columnDef.size,
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
        <Td>
          <Tooltip label="Kolejne kroki" openDelay={1000}>
            <IconButton
              aria-label="Toggle row"
              variant="ghost"
              h="100%"
              icon={
                <MdKeyboardArrowUp
                  size={24}
                  style={{
                    transition: 'transform .3s ease-in-out',
                    transform: `rotate(${isExpanded ? 0 : 180}deg)`,
                  }}
                />
              }
              onClick={() => setIsExpanded((prevState) => !prevState)}
            />
          </Tooltip>
        </Td>
      </Tr>
      <MotionTr
        key={`table_row_${id}`}
        animate={{
          height: isExpanded ? 'auto' : 0,
          opacity: isExpanded ? 1 : 0,
        }}
      >
        {isExpanded && <NestedTable searchPhrase={id} />}
      </MotionTr>
    </React.Fragment>
  );
}

export default TableRow;
