import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { BoxProps } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import columns from 'pages/SubscriptionRunupScreenElements/Listing/components/Table/columns';
import TabElement from 'components/Table/Table';
import Thead from 'components/Table/Thead';
import Tr from 'components/Table/Tr';
import Tbody from 'components/Table/Tbody';
import Th from 'components/Table/Th';
import EmptyBody from 'components/Table/EmptyBody';
import TableWrapper from 'components/Table/TableWrapper';
import {
  useDeleteSubscriptionRunupScreenElementMutation,
  useReorderSubscriptionRunupScreenElementMutation,
} from 'app/services/subscriptionRunupScreensApi';
import DraggableRow from 'pages/SubscriptionRunupScreenElements/Listing/components/Table/DraggableRow';
import { SubscriptionRunupScreenElement } from 'types/subscriptionRunupScreens';

type TableProps = BoxProps & {
  screenId: number;
  data: SubscriptionRunupScreenElement[];
};

function Table({ data, screenId, ...props }: TableProps) {
  const [reorderSubscriptionRunupScreenElement] =
    useReorderSubscriptionRunupScreenElementMutation();
  const [deleteSubscriptionRunupScreenElement] =
    useDeleteSubscriptionRunupScreenElementMutation();

  const handleReorder = async (id: number, targetRowIndex: number) => {
    try {
      reorderSubscriptionRunupScreenElement({
        id,
        screenId,
        body: { id, position: targetRowIndex },
      }).unwrap();
    } catch (error) {
      toast.error('Problem podczas zmiany kolejności');
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteSubscriptionRunupScreenElement({
        id,
        screenId,
      }).unwrap();
      toast.success('Pomyślnie usunięto element');
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania elementu');
    }
  };

  const table = useReactTable({
    data,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  const noTableData = isEmpty(data);

  return (
    <TableWrapper {...props}>
      <TabElement isEmpty={noTableData}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`header_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`header_cell_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {noTableData ? (
            <EmptyBody
              columnAmount={table.getAllColumns().length}
              queryParams={[]}
            />
          ) : (
            table
              .getRowModel()
              .rows.map((row) => (
                <DraggableRow
                  key={row.id}
                  row={row}
                  handleReorder={handleReorder}
                  handleDelete={handleDelete}
                  screenId={screenId}
                />
              ))
          )}
        </Tbody>
      </TabElement>
    </TableWrapper>
  );
}

export default Table;
