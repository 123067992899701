import { Button, Box, ButtonProps } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';

interface AddButtonProps extends ButtonProps {
  addHandler: () => void;
}

function AddButton({ addHandler, ...props }: AddButtonProps) {
  return (
    <Button
      variant="outlined"
      w="100%"
      bg="white"
      onClick={addHandler}
      {...props}
    >
      DODAJ KONTENER
      <Box as="span" ml={2}>
        <BiPlus size={24} />
      </Box>
    </Button>
  );
}

export default AddButton;
