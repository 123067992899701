import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import { BoxProps } from '@chakra-ui/react';
import TabElement from 'components/Table/Table';
import Thead from 'components/Table/Thead';
import Tr from 'components/Table/Tr';
import Tbody from 'components/Table/Tbody';
import Th from 'components/Table/Th';
import columns from 'pages/Dashboard/Preview/components/MainTable/columns';
import { Dashboard } from 'types/dashboard';
import { useComponentTypesQuery } from 'app/services/commonApi';
import TableWrapper from 'components/Table/TableWrapper';
import TableRow from 'pages/Dashboard/Preview/components/MainTable/TableRow';

type TableProps = BoxProps & {
  data: Dashboard[];
};

function Table({ data, ...props }: TableProps) {
  const { data: { data: componentTypes } = { data: [] } } =
    useComponentTypesQuery({ location: 'dashboard' });

  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns: columns({ componentTypes }),
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
  });

  const noTableData = isEmpty(data);

  return (
    <TableWrapper {...props}>
      <TabElement isEmpty={noTableData}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`header_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`header_cell_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} row={row} />
          ))}
        </Tbody>
      </TabElement>
    </TableWrapper>
  );
}

export default Table;
