import { useEffect } from 'react';
import Select from 'components/Form/Select';
import { useLazyLandingPageLinksQuery } from 'app/services/linkCreatorApi';

interface TeamPageSelectProps {
  prepareFieldName: (name: string) => string;
}

function TeamPageSelect({ prepareFieldName }: TeamPageSelectProps) {
  const [fetchTeamPages, { data: options = [], isFetching }] =
    useLazyLandingPageLinksQuery();

  useEffect(() => {
    fetchTeamPages({ type: 'TeamPage' });
  }, [fetchTeamPages]);

  return (
    <Select
      name={prepareFieldName('landingPageId')}
      options={options}
      placeholder="Powiązana strona drużyny"
      label="Wybierz stronę"
      isLoading={isFetching}
      isClearable
    />
  );
}

export default TeamPageSelect;
