import styled from '@emotion/styled';

const Td = styled.td`
  padding-inline: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 64px;
  vertical-align: top;
  padding-top: 12px;

  :nth-of-type(1) {
    width: 200px;
  }
  :nth-of-type(2) {
    width: 131px;
  }
  :nth-of-type(3) {
    width: 93px;
  }
  :nth-of-type(4) {
    padding-top: 0;
    padding-inline: 0;
    width: 128px;
  }
  :nth-of-type(5) {
    width: 216px;
  }
  :nth-of-type(6) {
    width: 136px;
  }
  :nth-of-type(7) {
    width: 136px;
  }
  :nth-of-type(8) {
    padding-top: 0;
    width: 216px;
  }
  :nth-of-type(9) {
    padding-top: 0;
    width: 216px;
  }
  :nth-of-type(10) {
    padding-top: 0;
    width: 216px;
  }
  :nth-of-type(11) {
    width: 216px;
  }
  :nth-of-type(12) {
    padding-top: 0;
    padding-left: 0;
    text-align: right;
  }
`;

export default Td;
