import { useCallback, useEffect, useState } from 'react';
import { FreeMode } from 'swiper';
import styled from '@emotion/styled';
import { SwiperSlide, Swiper } from 'swiper/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Text, Flex, Box, Image, Button } from '@chakra-ui/react';
import { PreviewComponentProps } from 'types/dashboardCreator';
import { useLazyProductImagesQuery } from 'app/services/dashboardApi';
import ProductWrapper from 'components/Container/Previews/ProductCarouselPreview/ProductWrapper';
import ProductPlaceholder from 'components/Container/Previews/ProductCarouselPreview/ProductPlaceholder';
import 'swiper/css';
import 'swiper/css/free-mode';
import { prepareQueryParamsFromLinkAttributes } from 'utils/helpers';
import { useIsDashboardPreview } from 'app/routes';

export const StyledSlide = styled(SwiperSlide)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0;

  transition: all 0.25s;
  transition-timing-function: ease-in-out;

  width: auto;
`;

enum BUTTON {
  arrow = 'arrow',
  label = 'label',
}

function ProductCarouselPreview({ formValues }: PreviewComponentProps) {
  const isDashboardPreview = useIsDashboardPreview();
  const { zoomIn: isZoomIn, queryLink } = formValues;
  const [activeSlide, setActiveSlide] = useState(0);

  const isEffectActive = useCallback(
    (index: number) => activeSlide === index && isZoomIn,
    [activeSlide, isZoomIn],
  );

  const [fetchProducts, { data, isSuccess, isFetching }] =
    useLazyProductImagesQuery();

  useEffect(() => {
    if (queryLink) {
      fetchProducts(queryLink);
    }
  }, [fetchProducts, queryLink]);

  const updateProducts = useCallback(async () => {
    if (formValues.linkParametersAttributes?.length) {
      const linkParams = prepareQueryParamsFromLinkAttributes(
        formValues.linkParametersAttributes,
      );

      await fetchProducts(linkParams);
    }
  }, [fetchProducts, formValues.linkParametersAttributes]);

  useEffect(() => {
    setActiveSlide(0);
  }, [isZoomIn]);

  return (
    <Box maxW={388} px={4}>
      {!isDashboardPreview ? (
        <Button mb={9} w="100%" onClick={updateProducts} isLoading={isFetching}>
          ODŚWIEŻ PODGLĄD
        </Button>
      ) : null}
      <Flex
        justifyContent="space-between"
        alignItems="center"
        gap="8px"
        pb={6}
        pr={4}
      >
        <Text
          display="inline-block"
          minH={6}
          fontSize="16px"
          fontWeight={700}
          textTransform="uppercase"
          dangerouslySetInnerHTML={{
            __html: formValues.title || '',
          }}
        />
        {formValues.buttonKind === BUTTON.arrow && (
          <AiOutlineArrowRight size={16} />
        )}
        {formValues.buttonKind === BUTTON.label && (
          <Text
            fontSize="12px"
            fontWeight={700}
            textTransform="uppercase"
            textAlign="right"
          >
            {formValues.buttonText}
          </Text>
        )}
      </Flex>
      <Swiper
        spaceBetween={8}
        modules={[FreeMode]}
        freeMode={{
          enabled: false,
          sticky: true,
        }}
        onSlideChange={(s) => {
          setActiveSlide(s.activeIndex);
        }}
        watchSlidesProgress
        slidesPerView="auto"
        centeredSlides
        centeredSlidesBounds
        style={{
          height: isZoomIn ? '494px' : 'auto',
        }}
        key={isZoomIn}
      >
        {isSuccess
          ? data?.products.map((product, idx) => (
              <StyledSlide key={`product_image${+idx}`}>
                <ProductWrapper isEffectActive={isEffectActive(idx)}>
                  <Box position="relative">
                    <Image
                      src={product.appImage.url}
                      alt={`Zdjęcie produktu ${idx}`}
                      w="full"
                      h={isEffectActive(idx) ? '384px' : '304px'}
                      transition="height .4s ease"
                      bgColor="complementary.whiteGrey"
                    />
                    {(product.new || product.bestseller) && (
                      <Text
                        fontWeight={600}
                        px={1}
                        position="absolute"
                        top="0"
                        transform="rotate(-90deg) translateX(-100%)"
                        transformOrigin="left top"
                        background="white"
                      >
                        {product.new ? 'Nowość' : 'Bestseller'}
                      </Text>
                    )}
                  </Box>

                  <Text fontSize="14px" lineHeight="24px">
                    {product.name}
                  </Text>
                  <Text fontWeight={600} fontSize="16px" mt={2}>
                    {product.price.regularPrice.amount.value}{' '}
                    {product.price.regularPrice.amount.currency}
                  </Text>
                </ProductWrapper>
              </StyledSlide>
            ))
          : Array.from({ length: 3 }, (_, idx) => (
              <StyledSlide key={`blank_image_${+idx}`}>
                <ProductWrapper isEffectActive={isEffectActive(idx)}>
                  <ProductPlaceholder idx={idx} />
                </ProductWrapper>
              </StyledSlide>
            ))}
      </Swiper>
    </Box>
  );
}

export default ProductCarouselPreview;
