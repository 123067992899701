import { useEffect } from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import ColorPicker from 'components/Form/Color';
import Input from 'components/Form/Input';
import { OPERATOR_OPTION } from 'pages/Subcategory/Form/constants';
import SuggestionBox from 'components/SuggestionBox';
import OptionalFieldset from 'components/OptionalFieldset';
import Dropzone from 'components/Form/Dropzone';
import LinkCreatorSection from 'components/LinkCreator';
import Switch from 'components/Form/Switch';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import Checkbox from 'components/Form/Checkbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  const { control, setValue } = useFormContext();
  const [withBadge, withImage, withLogo, withIcon] = useWatch({
    control,
    name: [
      prepareFieldName('withBadge'),
      prepareFieldName('withImage'),
      prepareFieldName('withLogo'),
      prepareFieldName('withIcon'),
    ],
  });

  useEffect(() => {
    if (!withBadge) {
      setValue(prepareFieldName('badge'), '');
    }
  }, [prepareFieldName, setValue, withBadge]);

  return (
    <>
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        mb={0}
        isEditMode={false}
      />
      <Box mb={8}>
        <Text variant="sectionTitle">Operator łączący elementy</Text>
        <Flex mt={4}>
          {OPERATOR_OPTION.map((option, idx) => (
            <RadioBoxControl
              key={option.value}
              id={`${prepareFieldName('linkParametersOperator')}-option-${idx}`}
              label={option.label}
              name={prepareFieldName('linkParametersOperator')}
              value={option.value}
              isClearable
            />
          ))}
        </Flex>
      </Box>
      <Box mt={8}>
        <Text variant="boldSectionTitle" pb="22px">
          Kolor tekstu
        </Text>
        <ColorPicker
          label="Kolor tekstu"
          name={prepareFieldName('textColor')}
        />
      </Box>
      <Box mt={8}>
        <Text variant="boldSectionTitle" pb="22px">
          Kolor tła
        </Text>
        <ColorPicker
          label="Kolor tła"
          name={prepareFieldName('backgroundColor')}
        />
      </Box>
      <SuggestionBox mt="24px">
        Pamiętaj, że dodanie wielu elementów opcjonalnych może negatywnie
        wpłynąć na wyświetlenie danej kategorii. Rekomendujemy 1 element
        opcjonalny dla danej kategorii
      </SuggestionBox>
      <OptionalFieldset
        title="Zdjęcie (opcjonalnie)"
        pt="20px"
        isActive={withImage}
      >
        <Box pb="30px">
          <Dropzone name={prepareFieldName('image')} />
        </Box>
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteImage')}
        label="Usuń zdjęcie"
        pb="30px"
        defaultChecked={false}
      />
      <OptionalFormFieldset
        title="Badge (opcjonalnie)"
        name={prepareFieldName('withBadge')}
        pt="10px"
        isActive={withBadge}
      >
        <VStack spacing="26px">
          <ColorPicker
            label="Kolor tła"
            name={prepareFieldName('badgeColor')}
          />
          <Input label="Treść" name={prepareFieldName('badge')} />
          <ColorPicker
            label="Kolor tekstu"
            name={prepareFieldName('badgeTextColor')}
            pb="30px"
          />
        </VStack>
      </OptionalFormFieldset>
      <OptionalFieldset
        title="Logo (opcjonalnie)"
        pt="10px"
        isActive={withLogo}
      >
        <Box pb="30px">
          <Dropzone name={prepareFieldName('logo')} />
        </Box>
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteLogo')}
        label="Usuń logo"
        pb="30px"
        defaultChecked={false}
      />
      <OptionalFieldset
        title="Ikona (opcjonalnie)"
        pt="20px"
        isActive={withIcon}
      >
        <Dropzone name={prepareFieldName('icon')} />
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteIcon')}
        label="Usuń ikonę"
        pb="30px"
        defaultChecked={false}
      />
      <Flex justifyContent="space-between" mt={1}>
        <Text variant="boldSectionTitle">Aktywność</Text>
        <Switch name={prepareFieldName('active')} />
      </Flex>
    </>
  );
}

export default FormBody;
