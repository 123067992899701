import { Row, flexRender } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { MdOutlineEdit, MdOutlineDragIndicator } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { generatePath, Link, useParams } from 'react-router-dom';
import Td from 'pages/CategoryTags/Preview/components/MainTable/components/Td';
import { CategoryTag } from 'types/categoryTags';
import Tr from 'components/Table/Tr';
import DndSpacer from 'components/Table/DndSpacer';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import DeleteModal from 'components/DeleteModal';
import { DragItem } from 'types/common';

interface DraggableRowProps {
  row: Row<CategoryTag>;
  handleReorder: (draggedRowIndex: number, targetRowIndex: number) => void;
  handleDelete: (id: number) => void;
  categoryId: number;
}

function DraggableRow({
  row,
  handleReorder,
  handleDelete,
  categoryId,
}: DraggableRowProps) {
  const { store } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { queryString } = useQueryParams();
  const [{ isOver, targetOffset }, dropRef] = useDrop({
    accept: 'row',
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        targetOffset: monitor.getDifferenceFromInitialOffset()?.y,
      };
    },
    drop: (item: DragItem) => {
      const sourcePlacement = item.placement;
      const targetPlacement = row.original.placement;
      if (sourcePlacement === targetPlacement) return;
      handleReorder(item.id, targetPlacement);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'row',
    item: () => {
      return { id: row.original.id, placement: row.original.placement };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const showDrop = isOver && !isDragging && targetOffset;

  return (
    <>
      {showDrop && targetOffset < 0 && <DndSpacer />}
      <Tr
        ref={previewRef}
        key={`body_row_${row.original.parentExternalCategoryId}_${row.id}`}
      >
        {row.getVisibleCells().map((cell, idx, { length }) => {
          if (idx + 1 === length) return null;
          return (
            <Td
              key={`body_column_${row.original.parentExternalCategoryId}_${cell.id}`}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
        <Td ref={dropRef}>
          <>
            <DeleteModal
              onAccept={() => handleDelete(row.original.id)}
              onClose={onClose}
              isOpen={isOpen}
            >
              Czy na pewno chcesz trwale usunąć tego taga?
            </DeleteModal>
            <Tooltip label="Edytuj" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<MdOutlineEdit size={20} />}
                aria-label="visiblity"
                variant="ghost"
                as={Link}
                to={
                  generatePath(ROUTES.categoryTag.edit, {
                    id: row.original.id.toString(),
                    categoryId: categoryId.toString(),
                    store,
                  }) + queryString
                }
              />
            </Tooltip>
            <Tooltip label="Usuń" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<RiDeleteBin6Line size={20} />}
                aria-label="visiblity"
                variant="ghost"
                onClick={onOpen}
              />
            </Tooltip>
            <IconButton
              width="72px"
              height="56px"
              icon={<MdOutlineDragIndicator size={20} />}
              aria-label="visiblity"
              variant="ghost"
              ref={dragRef}
            />
          </>
        </Td>
      </Tr>
      {showDrop && targetOffset > 0 && <DndSpacer />}
    </>
  );
}

export default DraggableRow;
