import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';

export const onboardScreensApi = api.injectEndpoints({
  endpoints: (build) => ({
    onboardScreens: build.query<TransformedOnboardScreensResponse, string>({
      query: (queryString) => `onboard_screens${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: OnboardScreensResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['OnboardScreens'],
    }),
    deleteOnboardScreen: build.mutation<void, number>({
      query: (id) => ({
        url: `onboard_screens/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OnboardScreens'],
    }),
    createOnboardScreen: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'onboard_screen',
        );
        return {
          url: 'onboard_screens',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['OnboardScreens'],
    }),
    onboardScreen: build.query<OnboardScreenResponse, any>({
      query: (id) => `onboard_screens/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['OnboardScreens'],
    }),
    updateOnboardScreen: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'onboard_screen',
        );

        return {
          url: `onboard_screens/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['OnboardScreens'],
    }),
  }),
});

export const {
  useOnboardScreensQuery,
  useDeleteOnboardScreenMutation,
  useCreateOnboardScreenMutation,
  useOnboardScreenQuery,
  useUpdateOnboardScreenMutation,
} = onboardScreensApi;
