import { Box, HStack, Text, Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { BiImage } from 'react-icons/bi';
import Marquee from 'react-fast-marquee';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';

// eslint-disable-next-line @typescript-eslint/naming-convention
type typeKeys = 'text' | 'image';

function TextBannerSlimPreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  const imagePreview = useImage(formValues.image);

  const type = useMemo(() => {
    if (formValues.type) {
      return formValues.type as typeKeys;
    }

    if (formValues.image?.url) {
      return 'image';
    }

    return 'text';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.type]);

  if (
    type === 'text' &&
    !formValues.elementsAttributes &&
    !formValues.elements
  ) {
    return null;
  }

  return (
    <Box
      maxW={388}
      color={formValues.textColor}
      bgColor={formValues.backgroundColor}
      fontWeight={400}
      whiteSpace="nowrap"
      overflow="hidden"
      fontSize="14px"
      w="full"
      h="52px"
      position="relative"
    >
      {showStats && <ShowStatsIcon data={formValues} top="10px" />}
      <Marquee loop={0} gradient={false} style={{ height: '100%' }}>
        {type === 'text' &&
          (formValues.elementsAttributes ?? formValues.elements).map(
            (item: any, idx: number) => (
              <Flex
                key={`text_item_${+idx}`}
                h="full"
                alignItems="center"
                px={2}
              >
                <Text
                  fontSize="14px"
                  color="inherit"
                  dangerouslySetInnerHTML={{
                    __html: item.title || 'Tutaj pojawi się tekst',
                  }}
                />
              </Flex>
            ),
          )}
        {type === 'image' &&
          [...Array(3)].map((_v, idx) => (
            <Flex
              key={`image_item_${+idx}`}
              h="full"
              alignItems="center"
              px={2}
            >
              <HStack
                border={imagePreview ? 'unset' : 'dashed 0.5px #fff'}
                bgImage={imagePreview}
                bgPosition="50% 50%"
                bgRepeat="no-repeat"
                bgSize="cover"
                spacing={2}
                p={1}
                minH={9}
                minW={40}
              >
                {!imagePreview && (
                  <>
                    <BiImage size={30} color="#fff" />
                    <Text color="#fff">miejsce na obrazek</Text>
                  </>
                )}
              </HStack>
            </Flex>
          ))}
      </Marquee>
    </Box>
  );
}

export default TextBannerSlimPreview;
