import { Link } from 'react-router-dom';
import ROUTES from 'app/routes';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Nutritions/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import useRoute from 'utils/useRoute';
import {
  useDeleteNutritionMutation,
  useNutritionsQuery,
} from 'app/services/nutritionsApi';

function Nutritions() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newNutritionPath = useRoute(ROUTES.nutritions.create);

  const [, state] = useDeleteNutritionMutation({
    fixedCacheKey: 'nutritions',
  });

  const {
    isFetching: nutritionsLoading,
    data: { data: nutritions, totals } = { data: [], totals: 0 },
  } = useNutritionsQuery(queryString);

  const { isLoading } = state;
  const showLoading = nutritionsLoading || isLoading;

  return (
    <ListingLayout
      title="Wartości odżywcze"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWA WARTOŚĆ ODŻYWCZA',
          as: Link,
          to: newNutritionPath,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={nutritions}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Nutritions;
