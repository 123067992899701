import * as yup from 'yup';

export const BODY_SCHEMA = yup
  .object()
  .shape({
    title: yup.string().required('Pole jest wymagane'),
    header: yup.string().nullable(),
    gradientColorTop: yup.string().nullable().required('Pole jest wymagane'),
    gradientColorBottom: yup.string().nullable().required('Pole jest wymagane'),
    textColor: yup.string().nullable().required('Pole jest wymagane'),
    answersAttributes: yup.array().of(
      yup.object({
        title: yup.string().required('Pole jest wymagane'),
        option: yup.string().required('Pole jest wymagane'),
        correct: yup.boolean().required('Pole jest wymagane'),
      }),
    ),
  })
  .required();

export const DEFAULT_VALUES = {
  title: '',
  header: '',
  gradientColorTop: null,
  gradientColorBottom: null,
  textColor: null,
  answersAttributes: [
    {
      title: '',
      option: '',
      correct: false,
    },
  ],
};
