import 'swiper/css/navigation';
import 'swiper/css';

import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Flex, HStack, Heading, Spinner, Stack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { BiImage } from 'react-icons/bi';

import AppstoriesPreview from 'components/Container/Previews/AppstoriesPreview';
import useImage from 'utils/useImage';
import Card from 'components/Card';

function AppStoriesInspirationsPreview() {
  const { control } = useFormContext();
  const formValues = useWatch({ control });

  const elementsIconPreview = useImage(formValues?.elementsIcon);

  return (
    <Card
      maxW="452px"
      padding="32px"
      style={{ position: 'sticky', top: '65px' }}
    >
      <Heading as="h4" fontSize={16} mb={8} pt={2}>
        PODGLĄD
      </Heading>
      {isEmpty(formValues) ? (
        <Flex w="100%" justify="center">
          <Spinner size="md" />
        </Flex>
      ) : (
        <Stack backgroundColor={formValues.backgroundColor} spacing={2}>
          <HStack padding="8px 18px">
            {elementsIconPreview ? (
              <Box
                width="24px"
                height="24px"
                bgImage={elementsIconPreview}
                bgPosition="50% 50%"
                bgRepeat="no-repeat"
                bgSize="contain"
              />
            ) : (
              <BiImage size={24} />
            )}
            <Heading
              as="h4"
              fontSize={14}
              textTransform="uppercase"
              color={formValues?.textColor || '#000000'}
            >
              {formValues?.elementsHeader || 'Tu pojawi się nagłówek karuzeli'}
            </Heading>
          </HStack>
          <AppstoriesPreview formValues={formValues} />
        </Stack>
      )}
    </Card>
  );
}

AppStoriesInspirationsPreview.defaultProps = {
  kind: '',
  componentIndex: null,
  componentTypes: [],
};

export default AppStoriesInspirationsPreview;
