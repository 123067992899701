import { DeepPartial, Theme } from '@chakra-ui/react';

const Input: DeepPartial<Theme['components']['Input']> = {
  baseStyle: {
    field: {
      border: 'none',
      background: 'complementary.red',
    },
  },
  variants: {
    outline: {
      field: {
        py: '12px',
        px: '16px',
        lineHeight: '24px',
        bg: 'white',
        borderColor: 'black',
        borderWidth: '1px',
        color: 'black',
        fontWeight: 400,
        fontSize: '16px',
        borderRadius: 0,
        _focus: { bg: 'white' },
        _placeholder: { color: '#ababab' },
        _hover: {
          borderColor: 'black',
        },
        _invalid: {
          boxShadow: 'unset',
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'lg',
  },
};

export default Input;
