import * as yup from 'yup';
import { activityDatesSchema } from 'components/Container/schema';

export const PINNING_DICT = {
  bottom: 'Dół',
  middle: 'Środek',
  top: 'Góra',
  special: 'Specjalne',
};

export const PINNING_OPTIONS = Object.entries(PINNING_DICT).map(
  ([key, value]) => {
    return {
      label: value,
      value: key,
    };
  },
);

export const BODY_SCHEMA = yup.object({
  taxonomyContainerId: yup.string().required('Pole jest wymagane'),
  title: yup.string().required('Pole jest wymagane'),
  placement: yup.string().required('Pole jest wymagane'),
  image: yup.mixed().nullable(),
  logo: yup.mixed().nullable(),
  badge: yup.string().nullable(),
  badgeColor: yup.string().nullable(),
  badgeTextColor: yup.string().nullable(),
  pinning: yup.string().required('Pole jest wymagane'),
  backgroundColor: yup.string().required('Pole jest wymagane'),
  active: yup.boolean(),
  dateFrom: yup.date().nullable(),
  timeFrom: yup.date().nullable(),
  ...activityDatesSchema,
  linkParametersAttributes: yup.array().of(
    yup.object().shape({
      linkType: yup.string().nullable().required('Wybierz rodzaj linku'),
    }),
  ),
  icon: yup.mixed().nullable(),
});

export const DEFAULT_FORM_VALUE = {
  linkParametersAttributes: [{ linkType: undefined }],
  placement: '1',
  backgroundColor: '#ffffff',
  textColor: '#000000',
  badgeColor: '#ffffff',
  badgeTextColor: '#000000',
  icon: null,
};
