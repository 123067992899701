import { Link } from 'react-router-dom';
import ROUTES from 'app/routes';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Shipments/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import useRoute from 'utils/useRoute';
import {
  useDeleteShipmentMutation,
  useShipmentsQuery,
} from 'app/services/shipmentsApi';

function Shipments() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newShipmentPath = useRoute(ROUTES.shipments.create);

  const [, state] = useDeleteShipmentMutation({
    fixedCacheKey: 'shipments',
  });

  const {
    isFetching: shipmentsLoading,
    data: { data: shipments, totals } = { data: [], totals: 0 },
  } = useShipmentsQuery(queryString);

  const { isLoading } = state;
  const showLoading = shipmentsLoading || isLoading;

  return (
    <ListingLayout
      title="Metody dostawy"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWA METODA DOSTAWY',
          as: Link,
          to: newShipmentPath,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={shipments}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Shipments;
