import { Box, Flex, VStack, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { BiImage } from 'react-icons/bi';
import { Swiper, SwiperSlide } from 'swiper/react';
import useImage from 'utils/useImage';
import { PreviewComponentProps } from 'types/dashboardCreator';
import 'swiper/css/navigation';
import 'swiper/css';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { Dashboard } from 'types/dashboard';

type ElementAttributesProps = { [key: string]: any };

interface SlideProps<T> {
  elementAttributes: T;
  borderColor: string;
}

export interface PhotoBannerCarouselPreviewProps
  extends PreviewComponentProps {}

function Slide({
  elementAttributes,
  borderColor,
}: SlideProps<ElementAttributesProps>) {
  const imagePreview = useImage(elementAttributes?.image);
  return (
    <VStack h="100%" w="100%">
      <Flex
        w="96px"
        h="96px"
        align="center"
        justify="center"
        borderRadius="50%"
        borderColor={borderColor || 'transparent'}
        borderWidth="2px"
        bgImage={imagePreview}
        bgColor="#eee"
        overflow="hidden"
        backgroundSize="cover"
      >
        {!imagePreview ? <BiImage size={20} /> : null}
      </Flex>
      <Text
        fontSize={elementAttributes.titleFontSize}
        color={elementAttributes.textColor}
        textTransform="uppercase"
        textAlign="center"
      >
        {elementAttributes.title}
      </Text>
    </VStack>
  );
}

function AppstoriesPreview({
  formValues,
  showStats,
}: PhotoBannerCarouselPreviewProps) {
  const elements = useMemo(() => {
    const e = formValues.elementsAttributes ?? (formValues.elements || []);
    if (e.length === 0) {
      return null;
    }
    return e;
  }, [formValues.elements, formValues.elementsAttributes]);

  return (
    <Box
      w={388}
      position="relative"
      backgroundColor={formValues.backgroundColor}
      padding="24px 18px"
    >
      <Swiper className="mySwiper" slidesPerView={3} spaceBetween={12}>
        {(elements as ElementAttributesProps[])?.map((values, idx) => (
          <SwiperSlide key={`slide-preview–${+idx}`}>
            <Slide
              elementAttributes={values}
              borderColor={formValues.borderColor}
            />
            {showStats && (
              <ShowStatsIcon data={values as Dashboard} type="appstory_click" />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

AppstoriesPreview.defaultProps = {};

export default AppstoriesPreview;
