import omit from 'lodash/omit';

const PARSED_VALUES: string[] = ['answers'];

const parseDefaultValues = (question: SurveyQuestion) => {
  const result: Record<string, any> = { ...omit(question, PARSED_VALUES) };
  result.answersAttributes = question.answers;
  return result;
};

export default parseDefaultValues;
