import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  title: yup.string().required('Pole jest wymagane'),
  titleBold: yup.boolean(),
  subtitle: yup.string().required('Pole jest wymagane'),
  subtitleBold: yup.boolean(),
  textColor: yup.string().nullable(),
  backgroundColor: yup.string().nullable(),
});

export const DEFAULT_VALUES = {
  title: '',
  titleBold: false,
  subtitle: '',
  subtitleBold: false,
  backgroundColor: '#000000',
  textColor: '#FFFFFF',
};
