import { AccordionButton, AccordionItem, Box, Flex } from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';

interface NavItemProps {
  isSidebarExpanded: boolean;
  icon?: React.ReactNode;
  title: string;
  idx: number;
  link: string;
}

function NavItem({ isSidebarExpanded, icon, title, idx, link }: NavItemProps) {
  const { queryString } = useQueryParams();
  const { pathname } = useLocation();
  const optionLink = link === pathname ? queryString : link;

  return (
    <AccordionItem borderTopWidth={idx === 0 ? 0 : '1px'} position="relative">
      <NavLink to={optionLink}>
        {({ isActive }) => (
          <AccordionButton
            fontSize="16px"
            {...(isActive && {
              _before: {
                width: '4px',
                height: '100%',
                bg: 'complementary.blue',
                position: 'absolute',
                left: 0,
                content: `""`,
              },
            })}
          >
            <Flex
              flex={1}
              gap="24px"
              justifyContent={isSidebarExpanded ? 'flex-start' : 'center'}
            >
              {icon} <Box hidden={!isSidebarExpanded}>{title}</Box>
            </Flex>
          </AccordionButton>
        )}
      </NavLink>
    </AccordionItem>
  );
}
NavItem.defaultProps = {
  icon: undefined,
};

export default NavItem;
