import * as yup from 'yup';
import {
  activityDatesSchema,
  linkParametersSchema,
} from 'components/Container/schema';

export const BODY_SCHEMA = yup
  .object()
  .shape({
    title: yup.string().required('Pole jest wymagane'),
    content: yup.string().required('Pole jest wymagane'),
    backgroundColor: yup.string().nullable().required('Pole jest wymagane'),
    textColor: yup.string().nullable().required('Pole jest wymagane'),
    emoji: yup.string(),
    notificationCategoryId: yup.string().required('Pole jest wymagane'),
    wwwDeeplink: yup
      .string()
      .required('Pole jest wymagane')
      .matches(/https:\/\//, 'Link musi rozpoczynać się od "https://"')
      .url('Nieprawidłowy format linku'),
    maCampaign: yup.string().nullable(),
    active: yup.boolean(),
    ...activityDatesSchema,
    linkParametersAttributes: yup.array().of(
      yup.object().shape({
        linkType: yup.string().nullable(),
        linkParamValue: linkParametersSchema.linkParamValue,
      }),
    ),
    gender: yup.string().nullable().required('Pole jest wymagane'),
  })
  .required();

export const DEFAULT_VALUES = {
  title: '',
  content: '',
  backgroundColor: '',
  textColor: '',
  emoji: '',
  notificationCategoryId: '',
  wwwDeeplink: '',
  maCampaign: '',
  active: true,
  dateFrom: null,
  dateTo: null,
  timeFrom: null,
  timeTo: null,
  linkParametersAttributes: [{ linkType: undefined }],
  gender: null,
};

export enum RECEIVER {
  female = 'Kobieta',
  male = 'Mężczyzna',
  girls = 'Dziewczyna',
  boys = 'Chłopak',
  all = 'Każdy',
}
