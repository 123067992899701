import { Stack, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import StatusBox from 'components/StatusBox';
import { Shipment } from 'types/shipments';

function GetColumns(): ColumnDef<Shipment, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'CENA',
      accessorKey: 'price',
      cell: ({
        row: {
          original: { price, priceUsd, priceGbp },
        },
      }) => (
        <Stack>
          <Text fontSize="inherit">{price}</Text>
          {priceUsd && <Text fontSize="inherit">{priceUsd}</Text>}
          {priceGbp && <Text fontSize="inherit">{priceGbp}</Text>}
        </Stack>
      ),
    },
    {
      header: 'LOGO',
      accessorKey: 'logo.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'STATUS',
      accessorKey: 'active',
      cell: (info) => (
        <StatusBox status={info.getValue() ? 'active' : 'unactive'} />
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
