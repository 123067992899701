import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import {
  convertKeysToSnakeCase,
  transformLinksAttributesForPayload,
} from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import {
  transformActivityDates,
  transformStringDatesToDateObject,
} from 'utils/date';

export const SurveysApi = api.injectEndpoints({
  endpoints: (build) => ({
    surveys: build.query<TransformedSurveysResponse, string>({
      query: (queryString) => `surveys${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: SurveysResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Surveys'],
    }),
    survey: build.query<TransformedSurvey, string>({
      query: (id) => `surveys/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: SurveyResponse) {
        const { data } = response;
        const result = {
          ...data,
          ...transformStringDatesToDateObject(data),
        };
        return result as TransformedSurvey;
      },
      providesTags: ['Surveys'],
    }),
    createSurvey: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(
            transformLinksAttributesForPayload({ ...payload }),
          ),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'survey',
        );

        return {
          url: 'surveys',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Surveys'],
    }),
    updateSurvey: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(
            transformLinksAttributesForPayload({ ...payload }),
          ),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'survey',
        );

        return {
          url: `/surveys/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['Surveys'],
    }),
    deleteSurvey: build.mutation<void, number>({
      query: (id) => ({
        url: `surveys/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Surveys'],
    }),
    surveyQuestions: build.query<Survey, string | number>({
      query: (id) => `surveys/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: SurveyResponse) {
        return response.data;
      },
      providesTags: (result) => [{ type: 'SurveyQuestions', id: result?.id }],
    }),
    reorderSurveyQuestion: build.mutation<void, ReorderSurveyQuestionParams>({
      query: ({ body, id, surveyId }) => ({
        url: `surveys/${surveyId}/questions/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SurveyQuestions'],
    }),
    deleteSurveyQuestion: build.mutation<void, DeleteSurveyQuestionParams>({
      query: ({ id, surveyId }) => ({
        url: `surveys/${surveyId}/questions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SurveyQuestions'],
    }),
    createSurveyQuestion: build.mutation({
      query: (payload) => {
        const { surveyId, body } = payload;
        const snakePayload = convertKeysToSnakeCase(body);

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'question',
        );

        return {
          url: `surveys/${surveyId}/questions`,
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['SurveyQuestions'],
    }),
    surveyQuestion: build.query<SurveyQuestionResponse, any>({
      query: (payload) => {
        const { id, surveyId } = payload;
        return `surveys/${surveyId}/questions/${id}`;
      },
      keepUnusedDataFor: 10,
      providesTags: ['SurveyQuestions'],
    }),
    updateSurveyQuestion: build.mutation({
      query: (payload) => {
        const { id, surveyId } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'question',
        );

        return {
          url: `/surveys/${surveyId}/questions/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['SurveyQuestions'],
    }),
  }),
});

export const {
  useSurveysQuery,
  useSurveyQuery,
  useCreateSurveyMutation,
  useUpdateSurveyMutation,
  useDeleteSurveyMutation,
  useSurveyQuestionsQuery,
  useReorderSurveyQuestionMutation,
  useDeleteSurveyQuestionMutation,
  useCreateSurveyQuestionMutation,
  useSurveyQuestionQuery,
  useUpdateSurveyQuestionMutation,
} = SurveysApi;
