import { useFormContext, useWatch } from 'react-hook-form';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import { FormComponentProps } from 'types/dashboardCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import ColorPicker from 'components/Form/Color';

interface MarginFormSectionProps extends FormComponentProps {
  isReadOnly?: boolean;
}

function MarginFormSection({
  isReadOnly,
  prepareFieldName,
}: MarginFormSectionProps) {
  const { control } = useFormContext();
  const withMargin: boolean = useWatch({
    control,
    name: prepareFieldName('margined'),
  });

  return (
    <OptionalFormFieldset
      title="Margines dolny (opcjonalnie)"
      isActive={withMargin}
      name={prepareFieldName('margined')}
      sectionWidth={FORM_BODY_MAX_WIDTH}
      mb="12px"
      isReadOnly={isReadOnly}
    >
      <ColorPicker
        label="Kolor marginesu"
        name={prepareFieldName('marginColor')}
        maxW={FORM_BODY_MAX_WIDTH}
        mb="26px"
      />
    </OptionalFormFieldset>
  );
}

MarginFormSection.defaultProps = {
  isReadOnly: false,
};

export default MarginFormSection;
