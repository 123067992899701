/* eslint-disable import/prefer-default-export */
import { ContainerObject, LinkParameters } from 'types/common';
import { transformActivityDates } from 'utils/date';
import { transformLinksAttributesForPayload } from 'utils/helpers';

type BlogPostPayload = {
  countries: string[] | null;
  containersAttributes: { [key: string]: any }[];
  title: string;
  linkParameters: LinkParameters[];
  linkParametersAttributes: LinkParameters[];
};

const preparePayload = (data: BlogPostPayload) => {
  const result = { ...data };

  result.linkParametersAttributes = data?.linkParametersAttributes?.filter(
    (attribute) => attribute?.linkType,
  );

  const containersAttributes = data.containersAttributes.map(
    (container, index) => {
      const transformedContainer = {
        ...transformActivityDates(
          transformLinksAttributesForPayload(container),
        ),
      };

      if (transformedContainer.elementsAttributes) {
        transformedContainer.elementsAttributes =
          transformedContainer.elementsAttributes.map(
            (element: ContainerObject, elementIndex: number) => {
              return transformActivityDates(
                transformLinksAttributesForPayload({
                  ...element,
                  position: elementIndex,
                }),
              );
            },
          );
      }

      return {
        ...transformedContainer,
        placement: index,
      };
    },
  );

  return transformLinksAttributesForPayload({
    ...result,
    containersAttributes,
  });
};

export { preparePayload };
