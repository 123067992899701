import { flexRender, Row } from '@tanstack/react-table';
import Tr from 'components/Table/Tr';
import Td from 'pages/Statistics/TopBanners/components/Table/components/Td';

interface TableRowProps {
  row: Row<BannerItem>;
  onClick: () => void;
}
function TableRow({ row, onClick }: TableRowProps) {
  const { position } = row.original;

  return (
    <Tr
      key={`row_${position}`}
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
    >
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td />
    </Tr>
  );
}

export default TableRow;
