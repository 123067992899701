/* eslint-disable @typescript-eslint/naming-convention */
import * as yup from 'yup';

export const GENDER = 'genderType';
export const STORE = 'store';
export const TIME_FROM = 'timeFrom';
export const TIME_TO = 'timeTo';
export const RANGE = 'range';

export enum RANGE_DICT {
  WEEK = 'week',
  MONTH = 'month',
  CUSTOM = 'custom',
}

export const RANGE_OPTIONS = [
  { label: 'Tydzień', value: RANGE_DICT.WEEK },
  { label: 'Miesiąc', value: RANGE_DICT.MONTH },
  { label: 'Zakres użytkownika', value: RANGE_DICT.CUSTOM },
];

export const SCHEMA = yup.object({
  genderType: yup.string().nullable(),
  store: yup.string().nullable(),
  timeFrom: yup.mixed().nullable().required('Pole jest wymagane'),
  timeTo: yup.mixed().nullable().required('Pole jest wymagane'),
  range: yup.string().nullable().required('Pole jest wymagane'),
});
