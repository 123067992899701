import { useEffect } from 'react';
import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import ColorPicker from 'components/Form/Color';
import Input from 'components/Form/Input';
import { PINNING_OPTIONS } from 'pages/CategoryContainer/Elements/Form/constants';
import SuggestionBox from 'components/SuggestionBox';
import OptionalFieldset from 'components/OptionalFieldset';
import Dropzone from 'components/Form/Dropzone';
import ActivityFormSection from 'components/ActivityFieldset';
import LinkCreatorSection from 'components/LinkCreator';
import ExternalError from 'components/Form/ExternalError';
import Checkbox from 'components/Form/Checkbox';
import OptionalFormFieldset from 'components/OptionalFormFieldset';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  editMode?: boolean;
}

function FormBody({ prepareFieldName, editMode }: FormBodyProps) {
  const { control, setValue } = useFormContext();
  const [withBadge, withImage, withLogo, withIcon] = useWatch({
    control,
    name: [
      prepareFieldName('withBadge'),
      prepareFieldName('withImage'),
      prepareFieldName('withLogo'),
      prepareFieldName('withIcon'),
    ],
  });

  useEffect(() => {
    if (!withBadge) {
      setValue(prepareFieldName('badge'), '');
    }
  }, [prepareFieldName, setValue, withBadge]);

  return (
    <>
      <Input name={prepareFieldName('title')} label="Treść" />
      <Box mt={8} mb={8}>
        <Text variant="boldSectionTitle">Przypięcie</Text>
        <Flex mt={4}>
          {PINNING_OPTIONS.map((option, idx) => (
            <RadioBoxControl
              key={option.value}
              id={`${prepareFieldName('pinning')}-option-${idx}`}
              label={option.label}
              name={prepareFieldName('pinning')}
              value={option.value}
              group
            />
          ))}
        </Flex>
        <ExternalError name={prepareFieldName('pinning')} />
      </Box>
      <Input
        name={prepareFieldName('placement')}
        label="Pozycja"
        type="number"
      />
      <Box pt="44px">
        <Text variant="boldSectionTitle" pb="22px">
          Kolor tła
        </Text>
        <ColorPicker
          label="Kolor tła tekstu"
          name={prepareFieldName('backgroundColor')}
        />
      </Box>
      <Box pt="44px">
        <Text variant="boldSectionTitle" pb="22px">
          Kolor tekstu
        </Text>
        <ColorPicker
          label="Kolor tekstu"
          name={prepareFieldName('textColor')}
        />
      </Box>
      <SuggestionBox mt="24px">
        Pamiętaj, że dodanie wielu elementów opcjonalnych może negatywnie
        wpłynąć na wyświetlenie danej kategorii. Rekomendujemy 1 element
        opcjonalny dla danej kategorii
      </SuggestionBox>
      <OptionalFieldset
        title="Zdjęcie (opcjonalnie)"
        pt="20px"
        isActive={withImage}
      >
        <Box pb="30px">
          <Dropzone name={prepareFieldName('image')} />
        </Box>
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteImage')}
        label="Usuń zdjęcie"
        pb="30px"
        defaultChecked={false}
      />
      <OptionalFormFieldset
        title="Badge (opcjonalnie)"
        name={prepareFieldName('withBadge')}
        pt="10px"
        isActive={withBadge}
      >
        <VStack spacing="26px">
          <ColorPicker
            label="Kolor tła"
            name={prepareFieldName('badgeColor')}
          />
          <Input label="Treść" name={prepareFieldName('badge')} />
          <ColorPicker
            label="Kolor tekstu"
            name={prepareFieldName('badgeTextColor')}
            pb="30px"
          />
        </VStack>
      </OptionalFormFieldset>
      <OptionalFieldset
        title="Logo (opcjonalnie)"
        pt="10px"
        isActive={withLogo}
      >
        <Box pb="30px">
          <Dropzone name={prepareFieldName('logo')} />
        </Box>
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteLogo')}
        label="Usuń logo"
        pb="30px"
        defaultChecked={false}
      />
      <OptionalFieldset
        title="Ikona (opcjonalnie)"
        pt="20px"
        isActive={withIcon}
      >
        <Dropzone name={prepareFieldName('icon')} />
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteIcon')}
        label="Usuń ikonę"
        pb="30px"
        defaultChecked={false}
      />
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        mb={0}
        isEditMode={editMode!}
      />
      <ActivityFormSection prepareFieldName={prepareFieldName} />
    </>
  );
}

FormBody.defaultProps = {
  editMode: false,
};

export default FormBody;
