import { Icon, IconProps } from '@chakra-ui/react';

function ThreeDots(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.22461 3.5C1.80794 3.5 1.45394 3.354 1.16261 3.062C0.87061 2.77067 0.724609 2.41667 0.724609 2C0.724609 1.58333 0.87061 1.22933 1.16261 0.938C1.45394 0.646 1.80794 0.5 2.22461 0.5C2.64128 0.5 2.99561 0.646 3.28761 0.938C3.57894 1.22933 3.72461 1.58333 3.72461 2C3.72461 2.41667 3.57894 2.77067 3.28761 3.062C2.99561 3.354 2.64128 3.5 2.22461 3.5ZM7.99961 3.5C7.58294 3.5 7.22894 3.354 6.93761 3.062C6.64561 2.77067 6.49961 2.41667 6.49961 2C6.49961 1.58333 6.64561 1.22933 6.93761 0.938C7.22894 0.646 7.58294 0.5 7.99961 0.5C8.41628 0.5 8.77028 0.646 9.06161 0.938C9.35361 1.22933 9.49961 1.58333 9.49961 2C9.49961 2.41667 9.35361 2.77067 9.06161 3.062C8.77028 3.354 8.41628 3.5 7.99961 3.5ZM13.7746 3.5C13.3579 3.5 13.0039 3.354 12.7126 3.062C12.4206 2.77067 12.2746 2.41667 12.2746 2C12.2746 1.58333 12.4206 1.22933 12.7126 0.938C13.0039 0.646 13.3579 0.5 13.7746 0.5C14.1913 0.5 14.5456 0.646 14.8376 0.938C15.1289 1.22933 15.2746 1.58333 15.2746 2C15.2746 2.41667 15.1289 2.77067 14.8376 3.062C14.5456 3.354 14.1913 3.5 13.7746 3.5Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default ThreeDots;
