import { Box, FormControl } from '@chakra-ui/react';
import ColorPicker from 'components/Form/Color';
import SectionTitle from 'components/Form/SectionTitle';
import DropzoneField from 'components/Form/Dropzone';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <>
      <FormControl as="fieldset">
        <ColorPicker
          name={prepareFieldName('backgroundColor')}
          label="Kolor tła"
          mb={9}
        />
      </FormControl>
      <FormControl as="fieldset">
        <Box mb={9}>
          <SectionTitle as="legend">Zdjęcie tła</SectionTitle>
          <DropzoneField
            name={prepareFieldName('background')}
            title="Dodaj zdjęcie w formacie .png, .jpg"
          />
        </Box>
      </FormControl>
    </>
  );
}

export default FormBody;
