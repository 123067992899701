import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FormState from 'slices/form/form';

const initialState: FormState = {
  removedElements: [],
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    addRemovedElement: (state, action: PayloadAction<any>) => {
      state.removedElements?.push(action.payload);
    },
    clearRemovedElements: (state) => {
      if (state.removedElements.length > 0) {
        state.removedElements = [];
      }
    },
  },
});

export const { addRemovedElement, clearRemovedElements } = formSlice.actions;

export default formSlice.reducer;
