import { Box } from '@chakra-ui/react';
import LinkCreatorSection from 'components/LinkCreator';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import { FormComponentProps } from 'types/dashboardCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import SectionTitle from 'components/Form/SectionTitle';

// kind: products_list
function ProductsListForm({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  return (
    <Box maxW={FORM_BODY_MAX_WIDTH}>
      <SectionTitle mb="20px">Tytuł</SectionTitle>
      <Input label="Wpisz tytuł" name={prepareFieldName('title')} mb="20px" />

      <SectionTitle>Przycisk</SectionTitle>
      <Input
        name={prepareFieldName('buttonText')}
        defaultValue=""
        label="Treść przycisku"
        mb={6}
      />

      <ColorPicker
        name={prepareFieldName('buttonTextColor')}
        label="Kolor tekstu"
        mb={9}
      />

      <ColorPicker
        name={prepareFieldName('buttonColor')}
        label="Kolor tła przycisku"
        mb={9}
      />

      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </Box>
  );
}

export default ProductsListForm;
