import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  city: yup.string().required('Pole jest wymagane'),
  street: yup.string().required('Pole jest wymagane'),
  location: yup.string().required('Pole jest wymagane'),
  openingTimes: yup.string().required('Pole jest wymagane'),
  active: yup.boolean(),
});

export const DEFAULT_VALUES = {
  city: '',
  street: '',
  location: '',
  openingTimes: '',
  active: true,
};
