import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Card = styled.div<{
  maxW?: number | string;
  minH?: number | string;
  padding?: string;
  isDragging?: boolean;
}>`
  background-color: #fff;
  padding: ${({ padding }) => padding || '32px 50px 32px 32px'};
  max-width: ${({ maxW }) => (typeof maxW === 'number' ? `${maxW}px` : maxW)};
  min-height: ${({ minH }) => (typeof minH === 'number' ? `${minH}px` : minH)};
  flex: 1;
  position: relative;
  z-index: 2;
  overflow: visible;

  & + & {
    margin-top: 16px;
  }

  ${({ isDragging }) =>
    isDragging
      ? css`
          opacity: 0;
        `
      : null}
`;

export default Card;
