/* eslint-disable no-underscore-dangle */
import { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  Box,
  HStack,
  Text,
  Button,
  Image,
  Accordion,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import { BsInputCursor } from 'react-icons/bs';
import Dropzone from 'components/Form/Dropzone';
import Input from 'components/Form/Input';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import { useLazyFetchProductBySKUQuery } from 'app/services/dashboardApi';
import FormSpinner from 'components/FormSpinner';
import AccordionButton from 'components/Collapsible/AccordionButton';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';

function PlayWithColorsForm({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  const { control, setValue, setError, clearErrors } = useFormContext();
  const dispatch = useAppDispatch();
  const [productSKU, setProductSKU] = useState<string | null>(null);

  const mainProductSKU = useWatch({
    control,
    name: prepareFieldName('productSKU'),
  });

  const elementsAttributesValues = useWatch({
    control,
    name: prepareFieldName('elementsAttributes'),
  });

  const specialElementsAttributesValues = useWatch({
    control,
    name: prepareFieldName('specialElementsAttributes'),
  });

  const {
    fields: elementsAttributes,
    append: appendElementsAttribute,
    remove: removeElementsAttribute,
    move: moveElementsAttribute,
  } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'elementsAttributesId',
  });

  const {
    fields: specialElementsAttributes,
    append: appendSpecialElementsAttribute,
    remove: removeSpecialElementsAttributes,
  } = useFieldArray({
    control,
    name: prepareFieldName('specialElementsAttributes'),
    keyName: 'specialElementsAttributesId',
  });

  const removeElement = useCallback(
    (index: number) => {
      const element = elementsAttributes[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      removeElementsAttribute(index);
    },
    [isEditMode, removeElementsAttribute, dispatch, elementsAttributes],
  );

  const [fetchProductBySKU, result] = useLazyFetchProductBySKUQuery();

  const fetchBySKU = useCallback(
    async (sku: string) => {
      if (productSKU !== sku && !isEditMode) {
        removeElementsAttribute();
        removeSpecialElementsAttributes();
        setProductSKU(sku);
        setValue('editMode', false);
      }

      await fetchProductBySKU(sku)
        .unwrap()
        .catch(() => {
          setError(prepareFieldName('productSKU'), {
            message: 'Nieprawidłowe SKU',
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchProductBySKU, prepareFieldName, setError, productSKU, isEditMode],
  );

  const mainSKUEnterHandler: KeyboardEventHandler<HTMLInputElement> =
    useCallback(
      async (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          await fetchBySKU(mainProductSKU);
        }
      },
      [mainProductSKU, fetchBySKU],
    );

  const { data: mainProduct, isLoading } = result;

  useEffect(() => {
    if (mainProduct) {
      setValue(prepareFieldName('linkParametersAttributes'), [
        {
          linkType: 'sku=param_value',
          linkParamValue: mainProduct.sku,
        },
      ]);
      mainProduct.mediaGallery?.forEach((mainImage: any, index: number) => {
        appendElementsAttribute({
          magentoImageUrl: mainImage.url,
          active: true,
          placement: index,
        });
      });
      mainProduct.relatedProducts?.forEach(
        (relatedProduct: any, index: number) => {
          appendSpecialElementsAttribute({
            magentoImageUrl: relatedProduct.image.url,
            active: true,
            placement: index,
            linkParametersAttributes: [
              {
                linkType: 'sku=param_value',
                linkParamValue: relatedProduct.sku,
              },
            ],
          });
        },
      );

      clearErrors();
    }

    if (
      !result.isUninitialized &&
      result.isSuccess &&
      (isEmpty(mainProduct) || !mainProduct)
    ) {
      setError(prepareFieldName('productSKU'), {
        message: 'Nieprawidłowe SKU',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainProduct, result.isUninitialized, result.isSuccess]);

  return (
    <>
      <HeaderFormSection
        prepareFieldName={prepareFieldName}
        components={[
          COMPONENTS.TITLE,
          COMPONENTS.SUBTITLE,
          COMPONENTS.COLORTXT,
          COMPONENTS.COLORBG,
        ]}
      />
      <Box as="fieldset" mb={9}>
        <Text fontSize={16} mb={5} fontWeight={600}>
          PRODUKT GŁÓWNY
        </Text>
        <HStack justifyContent="flex-start" alignItems="flex-start">
          <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
            <Input
              label="Wartość parametru SKU"
              name={prepareFieldName('productSKU')}
              onKeyDownCapture={mainSKUEnterHandler}
            />
          </Box>
          <Box pt="24px">
            <Button
              isDisabled={!mainProductSKU || mainProductSKU?.length < 4}
              type="button"
              h="48px"
              isLoading={isLoading}
              onClick={async () => {
                await fetchBySKU(mainProductSKU);
              }}
            >
              WYBIERZ
            </Button>
          </Box>
        </HStack>
      </Box>

      <Box maxW={FORM_BODY_MAX_WIDTH} as="fieldset" mb={9}>
        <Box pos="relative" minH={300}>
          {isLoading ? <FormSpinner /> : null}
          {!mainProduct && !isEditMode && (
            <Box
              pos="absolute"
              top={0}
              left={0}
              bottom={0}
              right={0}
              bgColor="rgba(255,255,255,.9)"
              zIndex={10}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={4}
            >
              <BsInputCursor size={40} />
              Musisz wprowadzić SKU głównego produktu
            </Box>
          )}

          {mainProduct && isEmpty(mainProduct) && (
            <Box
              pos="absolute"
              top={0}
              left={0}
              bottom={0}
              right={0}
              bgColor="rgba(255,255,255,.9)"
              zIndex={10}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              gap={4}
              textColor="complementary.red"
            >
              <BsInputCursor size={40} />
              Nie znaleźliśmy produktu, wprowadź poprawne SKU
            </Box>
          )}

          <Accordion defaultIndex={[0]} allowMultiple mb={10}>
            {elementsAttributes.map((field: any, index: number) => (
              <AccordionItem
                border={0}
                overflow="visible"
                key={field.elementsAttributesId}
              >
                <AccordionButton
                  id={field.fieldId}
                  index={index}
                  move={moveElementsAttribute}
                  label={`Zdjęcie ${index + 1}`}
                  remove={removeElement}
                  isRemovable={false}
                />
                <AccordionPanel pl={0} pr={0} pt={12} pb={0} overflow="visible">
                  <Box position="relative">
                    {!elementsAttributesValues[index]?.image && (
                      <Image alt="" src={field.magentoImageUrl} mb={4} />
                    )}
                    {field.image?.medium?.url && (
                      <Image alt="" src={field.image.medium.url} mb={4} />
                    )}

                    <Text>Chcesz zmienić zdjęcie główne? Dodaj je poniżej</Text>
                    <Dropzone
                      name={prepareFieldName(
                        `elementsAttributes[${index}].image`,
                      )}
                      title="Dodaj zdjęcie w formacie .png, .jpg (min. 120px x 200px)"
                    />
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>

          <Text fontSize={16} mb={2} fontWeight={600}>
            Wersje kolorystyczne
          </Text>
          <Text fontSize={14} mb={2}>
            Musisz dodać przynajmniej 3 produkty
          </Text>

          {specialElementsAttributes.map((field: any, index: number) => (
            <Box
              key={`product-variant_${field.specialElementsAttributesId}`}
              mb={10}
            >
              <Input
                label={`Wersja produktu ${index + 1}`}
                name={prepareFieldName(
                  `specialElementsAttributes[${index}].linkParametersAttributes[0].linkParamValue`,
                )}
                mb={4}
              />

              {!specialElementsAttributesValues[index]?.image && (
                <Image alt="" src={field.magentoImageUrl} mb={4} />
              )}

              <Text>Chcesz zmienić zdjęcie? Dodaj je poniżej</Text>

              <Dropzone
                name={prepareFieldName(
                  `specialElementsAttributes[${index}].image`,
                )}
                title="Dodaj zdjęcie w formacie .png, .jpg (min. 120px x 200px)"
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default PlayWithColorsForm;
