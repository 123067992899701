import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import {
  convertKeysToSnakeCase,
  transformLinksAttributesForPayload,
} from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { OptionTypeResponse } from 'types/common';
import { transformActivityDates } from 'utils/date';

export const notificationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    notificationCategories: build.query<
      TransformedNotificationCategoriesResponse,
      string
    >({
      query: (queryString) => `notification_categories${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: NotificationCategoriesResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['NotificationCategories'],
    }),
    notificationCategory: build.query<NotificationCategoryResponse, string>({
      query: (id) => `notification_categories/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['NotificationCategories'],
    }),
    createNotificationCategory: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'notification_category',
        );

        return {
          url: 'notification_categories',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['NotificationCategories'],
    }),
    updateNotificationCategory: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'notification_category',
        );

        return {
          url: `/notification_categories/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['NotificationCategories'],
    }),
    deleteNotificationCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `notification_categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['NotificationCategories'],
    }),
    reorderNotificationCategory: build.mutation<void, ReorderParams>({
      query: ({ body, id }) => ({
        url: `notification_categories/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['NotificationCategories'],
    }),
    notificationCategoriesOptions: build.query<OptionTypeResponse, void>({
      query: () => 'notification_categories?page_size=all',
      keepUnusedDataFor: 10,
      transformResponse(response: NotificationCategoriesResponse) {
        return {
          data: response.data.map((category) => ({
            value: category.id.toString(),
            label: category.name,
          })),
        };
      },
    }),
    notifications: build.query<TransformedNotificationsResponse, string>({
      query: (queryString) => `notifications${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: NotificationsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Notifications'],
    }),
    notification: build.query<NotificationResponse, string>({
      query: (id) => `notifications/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Notifications'],
    }),
    createNotification: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(transformLinksAttributesForPayload(payload)),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'notification',
        );

        return {
          url: 'notifications',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Notifications'],
    }),
    updateNotification: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(transformLinksAttributesForPayload(payload)),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'notification',
        );

        return {
          url: `/notifications/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['Notifications'],
    }),
    deleteNotification: build.mutation<void, number>({
      query: (id) => ({
        url: `notifications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Notifications'],
    }),
    bulkClone: build.mutation<void, { notificationIds: number[] }>({
      query: (payload) => {
        return {
          url: '/notifications/clone',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useNotificationCategoriesQuery,
  useNotificationCategoryQuery,
  useCreateNotificationCategoryMutation,
  useUpdateNotificationCategoryMutation,
  useDeleteNotificationCategoryMutation,
  useReorderNotificationCategoryMutation,
  useNotificationCategoriesOptionsQuery,
  useNotificationsQuery,
  useNotificationQuery,
  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
  useBulkCloneMutation,
} = notificationsApi;
