import { useCallback, useMemo } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import Card from 'components/Card';
import FormBody from 'pages/Category/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import Input from 'components/Form/Input';
import FormSpinner from 'components/FormSpinner';
import ContainerActions from 'components/ContainerActions';

interface ContainerWrapperProps {
  index: number;
  isLoading: boolean;
  multipleContainers: boolean;
  isOpen: boolean;
  dragRef: ConnectDragSource;
  previewRef: ConnectDragPreview;
  removeContainer: (index: number) => void;
  toggleSectionHandler: () => void;
}

function ContainerWrapper({
  index,
  isLoading,
  multipleContainers,
  removeContainer,
  isOpen,
  dragRef,
  previewRef,
  toggleSectionHandler,
}: ContainerWrapperProps) {
  const fieldName = useMemo(() => `containers[${index}]`, [index]);

  const prepareFieldName = useCallback(
    (name: string) => `${fieldName}.${name}`,
    [fieldName],
  );

  const removeContainerHandler = useCallback(() => {
    removeContainer(index);
  }, [index, removeContainer]);

  return (
    <Flex gap={4} alignItems="flex-start">
      <Card ref={previewRef}>
        {isLoading && <FormSpinner />}
        <ContainerActions
          showTrashButton={multipleContainers}
          toggleAction={toggleSectionHandler}
          removeAction={removeContainerHandler}
          isOpen={isOpen}
          dragRef={dragRef}
          top={8}
        />
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Input name={prepareFieldName('name')} placeholder="Wpisz nazwę" />
          <motion.div
            initial={{
              height: isOpen ? 'auto' : 0,
              overflow: isOpen ? 'visible' : 'hidden',
              paddingTop: isOpen ? '36px' : 0,
            }}
            animate={{
              height: isOpen ? 'auto' : 0,
              overflow: isOpen ? 'visible' : 'hidden',
              paddingTop: isOpen ? '36px' : 0,
            }}
          >
            <FormBody prepareFieldName={prepareFieldName} />
          </motion.div>
        </Box>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
