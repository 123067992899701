import { ColumnDef } from '@tanstack/react-table';
import PercentBox from 'pages/Statistics/TopSearch/components/Table/components/PercentBox';

function GetColumns(): ColumnDef<SearchPhrase, string>[] {
  return [
    {
      header: 'ID',
      cell: (info) => info.row.index + 1,
      size: 64,
    },
    {
      header: 'WYSZUKIWANE HASŁO',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
      size: 248,
    },
    {
      header: 'UNIKALNE WYSZUKIWANIA',
      accessorKey: 'value',
      cell: (info) => info.getValue(),
      size: 268,
    },
    {
      header: '%',
      accessorKey: 'percentDelta',
      cell: (info) =>
        info.getValue() === '-' ? '-' : <PercentBox value={+info.getValue()} />,
      size: 216,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
