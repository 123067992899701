export const FORM_BODY_MAX_WIDTH = 452;

export const FORM_MAX_WIDTH = 686;

export const EXTENDED_FORM_MAX_WIDTH = 1024;

export const GENDER_OPTIONS = [
  { label: 'Kobieta', value: 'female' },
  { label: 'Mężczyzna', value: 'male' },
  { label: 'Dziewczyna', value: 'girls' },
  { label: 'Chłopak', value: 'boys' },
];

export const STATUS_OPTIONS = [
  { value: 'active', label: 'Aktywny' },
  { value: 'planned', label: 'Zaplanowany' },
  { value: 'unactive', label: 'Nieaktywny' },
];

export const USER_KIND_OPTIONS = [
  {
    label: 'Zalogowany',
    value: 'logged',
  },
  {
    label: 'Niezalogowany',
    value: 'anonymous',
  },
  {
    label: 'Każdy',
    value: 'both',
  },
];
