import { DeepPartial, Theme } from '@chakra-ui/react';

const FormLabel: DeepPartial<Theme['components']['FormLabel']> = {
  baseStyle: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.004em',
    textAlign: 'left',
    marginY: 0,
    marginRight: 0,
  },
};

export default FormLabel;
