import { Box, Spinner } from '@chakra-ui/react';

function FormSpinner() {
  return (
    <Box
      position="absolute"
      left={0}
      top={0}
      right={0}
      bottom={0}
      zIndex={10}
      display="flex"
      bgColor="whiteAlpha.700"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size="xl" />
    </Box>
  );
}

export default FormSpinner;
