import { Link, useParams } from 'react-router-dom';
import { Box, Button, Flex } from '@chakra-ui/react';
import Table from 'pages/Faqs/Preview/components/Table';
import ROUTES from 'app/routes';
import { useDeleteCategoryMutation } from 'app/services/categoryApi';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import { FOOTER_HEIGHT } from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import useRoute from 'utils/useRoute';
import { useFaqQuery } from 'app/services/faqsApi';
import HeaderNavigation from 'components/HeaderNavigation';

function FaqsPreview() {
  const { parentId = '' } = useParams();
  const newFaqPath = useRoute(ROUTES.faqs.create, { parentId });

  const [, state] = useDeleteCategoryMutation({
    fixedCacheKey: 'faqs',
  });

  const {
    isFetching: faqsLoading,
    data: { data: faqs } = { data: { question: '', children: [] } },
  } = useFaqQuery(parentId);

  const { isLoading } = state;
  const showLoading = faqsLoading || isLoading;

  return (
    <Box pt="36px">
      <Flex justifyContent="space-between" alignItems="center" gap={4}>
        <HeaderNavigation
          baseCrumb={{
            label: 'FAQ',
            to: useRoute(ROUTES.faqs.base),
          }}
          crumbs={[{ to: '', label: faqs?.question || 'Loading...' }]}
        />
        <Button
          fontWeight={700}
          fontSize="12px"
          lineHeight="20px"
          paddingX="32px"
          as={Link}
          to={newFaqPath}
        >
          NOWY FAQ
        </Button>
      </Flex>
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={faqs.children}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
    </Box>
  );
}

export default FaqsPreview;
