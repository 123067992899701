import { motion } from 'framer-motion';
import { Box, Heading, Text } from '@chakra-ui/react';
import { useWatch, useFormContext } from 'react-hook-form';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { useCallback, useMemo, FunctionComponent } from 'react';
import Card from 'components/Card';
import {
  COMPONENT_HINTS,
  DEFAULT_FORM_VALUES,
  FormComponents,
} from 'components/Container/constants';
import Select from 'components/Form/Select';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import ContainerActions from 'components/ContainerActions';
import { FormComponentProps } from 'types/dashboardCreator';
import { useComponentTypesQuery } from 'app/services/commonApi';
import ActivityFormSection from 'components/Container/Sections/ActivityFormSection';
import LandingPageFieldsSection from 'components/Container/Sections/LandingPageFieldsSection';
import MarginFormSection from 'components/Container/Sections/MarginSection';
import NameInput from 'components/NameInput';
import useQueryParams from 'utils/useQueryParams';
import AppVersionSection from 'components/Container/Sections/AppVersionSection';

interface ContainerWrapperProps {
  index: number;
  multipleContainers: boolean;
  isOpen: boolean;
  dragRef: ConnectDragSource;
  previewRef: ConnectDragPreview;
  removeContainer: (index: number) => void;
  toggleSectionHandler: () => void;
  isInvalid?: boolean;
  isBlogPost?: boolean;
}

function ContainerWrapper({
  index,
  multipleContainers,
  removeContainer,
  isOpen,
  dragRef,
  previewRef,
  toggleSectionHandler,
  isInvalid,
  isBlogPost,
}: ContainerWrapperProps) {
  const { search } = useQueryParams();
  const isTeamPage = search.get('type') === 'TeamPage';

  const { control, setValue, clearErrors, getValues, register } =
    useFormContext();
  const { data: { data: componentTypes } = { data: [] }, isFetching } =
    useComponentTypesQuery({
      location: isTeamPage ? 'team_page' : 'landing_page',
    });

  const fieldName = useMemo(() => `containersAttributes[${index}]`, [index]);

  const containerKind = useWatch({
    control,
    name: `containersAttributes[${index}].kind`,
    defaultValue: '',
  });

  const prepareFieldName: (name: string) => string = useCallback(
    (name) => `${fieldName}.${name}`,
    [fieldName],
  );

  const removeContainerHandler = useCallback(() => {
    removeContainer(index);
  }, [index, removeContainer]);

  const FormComponent: FunctionComponent<FormComponentProps> =
    FormComponents[containerKind];

  const prepareFormWithDefaultValues = useCallback(
    (kind: string) => {
      clearErrors();
      const containers = getValues(`containersAttributes`);
      if (containers) {
        setValue(`containersAttributes[${index}]`, {
          ...(DEFAULT_FORM_VALUES[kind] || {}),
          name: containers[index].name,
          userKind: containers[index].userKind,
          kind,
        });
      }
    },
    [clearErrors, getValues, index, setValue],
  );

  return (
    <Box flex={1}>
      <Card ref={previewRef}>
        <ContainerActions
          showTrashButton={multipleContainers}
          toggleAction={toggleSectionHandler}
          removeAction={removeContainerHandler}
          dragRef={dragRef}
          isOpen={isOpen}
          isInvalid={isInvalid}
        />

        <Heading
          as="h3"
          fontSize={16}
          borderBottom="1px solid"
          borderColor="complementary.grey"
          paddingY={2}
          maxW={FORM_BODY_MAX_WIDTH - (isInvalid ? 60 : 20)}
          sx={{
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <NameInput type="text" {...register(prepareFieldName('name'))} />
        </Heading>

        <motion.div
          initial={{
            height: isOpen ? 'auto' : 0,
            overflow: isOpen ? 'visible' : 'hidden',
            paddingTop: isOpen ? '36px' : 0,
          }}
          animate={{
            height: isOpen ? 'auto' : 0,
            overflow: isOpen ? 'visible' : 'hidden',
            paddingTop: isOpen ? '36px' : 0,
          }}
        >
          <Box mb={4} maxW={FORM_BODY_MAX_WIDTH}>
            <Select
              label="Typ komponentu"
              name={prepareFieldName('kind')}
              options={componentTypes}
              isLoading={isFetching}
              onChangeCallback={prepareFormWithDefaultValues}
            />
            {Object.keys(COMPONENT_HINTS).includes(containerKind) ? (
              <Text color="complementary.grey">
                {COMPONENT_HINTS[containerKind as keyof typeof COMPONENT_HINTS]}
              </Text>
            ) : null}
          </Box>
          <LandingPageFieldsSection prepareFieldName={prepareFieldName} />

          {FormComponent && (
            <FormComponent prepareFieldName={prepareFieldName} />
          )}

          {!isBlogPost && (
            <AppVersionSection prepareFieldName={prepareFieldName} />
          )}
          <MarginFormSection prepareFieldName={prepareFieldName} />
          <ActivityFormSection prepareFieldName={prepareFieldName} />
        </motion.div>
      </Card>
    </Box>
  );
}

ContainerWrapper.defaultProps = {
  isInvalid: false,
  isBlogPost: false,
};

export default ContainerWrapper;
