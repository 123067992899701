import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import Select from 'components/Form/Select';
import { useLandingPageAnchorsQuery } from 'app/services/linkCreatorApi';

interface LandingPageAnchorSelectProps {
  prepareFieldName: (name: string) => string;
  id: number;
}

function LandingPageAnchorSelect({
  prepareFieldName,
  id,
}: LandingPageAnchorSelectProps) {
  const { setValue } = useFormContext();

  const { data: anchors = [], isFetching } = useLandingPageAnchorsQuery(id);

  useEffect(() => {
    setValue(prepareFieldName('relatedLandingPageId'), id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, prepareFieldName, setValue]);

  return (
    <Select
      name={prepareFieldName('relatedLpContainerElement')}
      options={anchors}
      placeholder="Wybierz element"
      label="Element LP"
      isLoading={isFetching}
      isClearable
    />
  );
}

export default LandingPageAnchorSelect;
