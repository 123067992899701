import { Flex, Text } from '@chakra-ui/react';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import { ALIGNMENTS } from 'components/Container/constants';

interface AlignmentSectionProps {
  fieldName: string;
  label: string;
}

function AlignmentSection({ fieldName, label }: AlignmentSectionProps) {
  return (
    <>
      <Text fontSize={14} lineHeight="24px" mb={4}>
        {label}
      </Text>

      <Flex mb={9}>
        {Object.keys(ALIGNMENTS).map((key, index) => (
          <RadioBoxControl
            key={`button-text-align_${key}`}
            label={ALIGNMENTS[key]}
            name={fieldName}
            id={`${fieldName}_button-text-align_${key}`}
            value={key}
            group={index !== 0}
          />
        ))}
      </Flex>
    </>
  );
}

export default AlignmentSection;
