import {
  FormControl,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

type InputProps = ChakraInputProps & {
  name: string;
  label?: string | React.ReactNode;
  defaultValue?: number | string;
};

function DiscountInput({
  label,
  name,
  isRequired,
  defaultValue,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl isRequired={isRequired} isInvalid={Boolean(errorMessage)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        <InputLeftElement w="60px">%</InputLeftElement>
        <ChakraInput
          type="number"
          {...register(name)}
          defaultValue={defaultValue}
          paddingLeft="50px"
          {...rest}
        />
      </InputGroup>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

DiscountInput.defaultProps = {
  label: '',
  defaultValue: null,
};

export default DiscountInput;
