export const TAB_OPTIONS = [
  {
    value: 'springConditions',
    label: 'Wiosna',
  },
  {
    value: 'summerConditions',
    label: 'Lato',
  },
  {
    value: 'autumnConditions',
    label: 'Jesień',
  },
  {
    value: 'winterConditions',
    label: 'Zima',
  },
];

export const DEFAULT_SEASON = TAB_OPTIONS[0].value;

export const CONDITION_DICT: {
  [key: string]: { name: string; emoji: string };
} = {
  thunderstorm: {
    name: 'Burza',
    emoji: '🌩️',
  },
  drizzle: { name: 'Mżawka', emoji: '🌧️' },
  rain: { name: 'Deszcz', emoji: '💧' },
  snow: { name: 'Śnieg', emoji: '🌨️' },
  mist: { name: 'Mgła', emoji: '☁️' },
  clear: { name: 'Bezchmurnie', emoji: '☀️' },
  clouds: { name: 'Zachmurzenie', emoji: '⛅' },
};
