import qs from 'query-string';
import {
  MagentoBadgesResponse,
  LandingPagesResponse,
  CategoriesResponse,
  DropdownOptions,
  RegulationElementsResponse,
} from 'types/linkCreator';
import { api } from 'app/services/api';
import { LandingPageAnchorsResponse } from 'types/landingPage';

type LandingPageParams = { [key: string]: unknown } | void;

export const linkCreatorApi = api.injectEndpoints({
  endpoints: (build) => ({
    magentoFilters: build.query<DropdownOptions[], 'gender' | 'badge'>({
      query: (kind) => `magento_filters?kind=${kind}`,
      keepUnusedDataFor: 10,
      transformResponse(response: MagentoBadgesResponse) {
        return response.data.map((d) => ({ ...d, value: d.value.toString() }));
      },
      providesTags: () => [{ type: 'Dashboards', id: 'magentoBadges' }],
    }),
    landingPageLinks: build.query<DropdownOptions[], LandingPageParams>({
      query: (params) =>
        `landing_pages/dropdown_list${
          params ? `?${qs.stringify(params)}` : ''
        }`,
      keepUnusedDataFor: 10,
      transformResponse(response: LandingPagesResponse) {
        return response.data.map((data) => ({
          value: data.id.toString(),
          label: data.title,
        }));
      },
      providesTags: () => [{ type: 'Dashboards', id: 'landingPages' }],
    }),
    blogPostsLinks: build.query<DropdownOptions[], void>({
      query: () => `landing_pages/dropdown_list?editorial=true`,
      keepUnusedDataFor: 10,
      transformResponse(response: LandingPagesResponse) {
        return response.data.map((data) => ({
          value: data.id.toString(),
          label: data.title,
        }));
      },
      providesTags: () => [{ type: 'Dashboards', id: 'blogPosts' }],
    }),
    categoryLinks: build.query<DropdownOptions[], void>({
      query: () => `categories/magento_categories`,
      keepUnusedDataFor: 10,
      transformResponse(response: CategoriesResponse) {
        return response.data.map((data) => ({
          value: data.id.toString(),
          label: data.name,
        }));
      },
      providesTags: () => [{ type: 'Dashboards', id: 'landingPages' }],
    }),
    regulationsElements: build.query<DropdownOptions[], void>({
      query: () => `regulations/elements`,
      keepUnusedDataFor: 10,
      transformResponse(response: RegulationElementsResponse) {
        return response.data.map((data) => ({
          value: data.id.toString(),
          label: data.title,
        }));
      },
      providesTags: () => [{ type: 'Regulations', id: 'elements' }],
    }),
    faqElements: build.query<DropdownOptions[], void>({
      query: () => `faqs?page_size=all`,
      keepUnusedDataFor: 10,
      transformResponse(response: FaqsResponse) {
        return response.data.map((data) => ({
          value: data.id.toString(),
          label: data.question,
        }));
      },
      providesTags: () => [{ type: 'Faqs', id: 'elements' }],
    }),
    landingPageAnchors: build.query<DropdownOptions[], number>({
      query: (id) => `landing_pages/${id}/anchors`,
      keepUnusedDataFor: 10,
      transformResponse(response: LandingPageAnchorsResponse) {
        return response.data.map((data) => ({
          value: data.slug,
          label: data.slug,
        }));
      },
      providesTags: () => [{ type: 'LandingPage', id: 'anchors' }],
    }),
  }),
});

export const {
  useLazyMagentoFiltersQuery,
  useLazyCategoryLinksQuery,
  useLazyLandingPageLinksQuery,
  useLazyRegulationsElementsQuery,
  useLazyBlogPostsLinksQuery,
  useLazyFaqElementsQuery,
  useLandingPageAnchorsQuery,
} = linkCreatorApi;
