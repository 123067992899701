import { useCallback, memo, useState, useEffect } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import {
  Button,
  FormControl,
  Text,
  FormErrorMessage,
  Box,
} from '@chakra-ui/react';
import { DropzoneFileType } from 'types/common';

interface DropzoneProps {
  name: string;
  onChange: (...event: any[]) => void;
  type: DropzoneFileType;
  error: FieldError | undefined;
  title?: string;
}

function Dropzone({ name, onChange, type, error, title }: DropzoneProps) {
  const [file, setFile] = useState<File | null>(null);
  const { setValue, getValues } = useFormContext();
  const currentValue = getValues(name);

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setValue(name, acceptedFiles[0], { shouldValidate: true });
    },
    [name, setValue],
  );

  useEffect(() => {
    if (currentValue?.url) setFile(null);
  }, [currentValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      ...(type === 'image' && { 'image/*': ['.jpeg', '.png', '.jpg'] }),
      ...(type === 'video' && { 'video/*': ['.mp4'] }),
      ...(type === 'sheet' && {
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx',
        ],
      }),
      ...(type === 'document' && {
        'application/pdf': ['.pdf'],
      }),
    },
  });

  return (
    <FormControl isInvalid={Boolean(error)} pb="10px" {...getRootProps()}>
      <Box
        border={`1px #000 ${isDragActive ? 'dashed' : 'solid'}`}
        textAlign="center"
        borderColor={error ? 'complementary.red' : '#000'}
        height={213}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <input {...getInputProps({ onChange })} />
        {file ? (
          <Text fontWeight={700} fontSize={14}>
            {file.name}
          </Text>
        ) : (
          <>
            <Text
              fontWeight={700}
              fontSize={14}
              lineHeight={6}
              mb={3}
              dangerouslySetInnerHTML={{
                __html:
                  title || (type === 'image' ? 'Dodaj zdjęcie' : 'Dodaj plik'),
              }}
            />

            {isDragActive ? (
              <Text fontSize={14} lineHeight={6}>
                Upuść plik tutaj...
              </Text>
            ) : (
              <>
                <Text fontSize={14} lineHeight={6}>
                  Przeciągnij i upuść lub dodaj z dysku
                </Text>
                <Button type="button" pl={50} pr={50} fontWeight={700} mt={6}>
                  DODAJ Z DYSKU
                </Button>
              </>
            )}
          </>
        )}
      </Box>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

Dropzone.defaultProps = {
  title: null,
};

interface DropzoneFieldProps {
  name: string;
  type?: DropzoneFileType;
  title?: string;
}

function DropzoneField({ name, type = 'image', title }: DropzoneFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Dropzone
          name={name}
          onChange={(e: any) => onChange(e.target.files[0])}
          type={type}
          error={error}
          title={title}
        />
      )}
    />
  );
}
DropzoneField.defaultProps = {
  type: 'image',
  title: null,
};
export default memo(DropzoneField);
