/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, HStack, Text, Button, Accordion } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';

import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import { FormComponentProps } from 'types/dashboardCreator';
import ImageGalleryItemSection from 'components/Container/Sections/ImageGalleryItemSection';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';
import { DEFAULT_FORM_VALUES } from '../constants';

function ImageGalleryForm({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  const { control, setValue } = useFormContext();
  const dispatch = useAppDispatch();

  const type = useWatch({ control, name: prepareFieldName('type') });
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'fieldId',
  });

  const resetElementTitle = useCallback(
    (index: number) => {
      setValue(prepareFieldName(`elementsAttributes[${index}].title`), '');
    },
    [prepareFieldName, setValue],
  );

  const removeElement = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [isEditMode, remove, dispatch, fields],
  );

  const addElement = useCallback(() => {
    append({
      ...DEFAULT_FORM_VALUES.appstories.elementsAttributes[0],
      placement: fields.length,
    });
  }, [append, fields]);

  useEffect(() => {
    if (type === 'noHeaders') {
      fields.forEach((field, index) => {
        resetElementTitle(index);
      }, []);
    }
  }, [type, fields, resetElementTitle]);

  const shouldRenderHeaders = useMemo(() => type === 'withHeaders', [type]);

  return (
    <Box mb={7}>
      <Box as="fieldset" mb={9}>
        <Text fontSize={14} lineHeight="24px" mb={4}>
          Wybierz typ karuzeli
        </Text>
        <HStack>
          <RadioBoxControl
            label="Z nagłówkami"
            name={prepareFieldName('type')}
            id={prepareFieldName('type-1')}
            value="withHeaders"
          />
          <RadioBoxControl
            label="Bez nagłówków"
            name={prepareFieldName('type')}
            id={prepareFieldName('type-2')}
            value="noHeaders"
          />
        </HStack>
      </Box>

      <Box maxW={FORM_BODY_MAX_WIDTH}>
        <Accordion defaultIndex={[0]} allowMultiple mb={10}>
          {fields.map((field: any, index) => (
            <ImageGalleryItemSection
              id={field.fieldId}
              key={field.fieldId}
              isEditMode={isEditMode || false}
              elementIndex={index}
              prepareFieldName={prepareFieldName}
              shouldRenderHeaders={shouldRenderHeaders}
              move={move}
              remove={removeElement}
            />
          ))}
        </Accordion>
      </Box>

      <Button
        type="button"
        variant="solid"
        mt={4}
        w="100%"
        onClick={addElement}
        fontSize="12px"
        maxW={FORM_BODY_MAX_WIDTH}
      >
        DODAJ ELEMENT
        <Box as="span" ml={2}>
          <BiPlus size={24} />
        </Box>
      </Button>
    </Box>
  );
}

export default ImageGalleryForm;
