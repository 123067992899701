import { AccordionItem, AccordionPanel, FormControl } from '@chakra-ui/react';
import { useCallback } from 'react';
import { UseFieldArrayMove } from 'react-hook-form';
import DropzoneField from 'components/Form/Dropzone';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import SectionTitle from 'components/Form/SectionTitle';
import AccordionButton from 'components/Collapsible/AccordionButton';
import TagInput from 'components/Form/TagInput';

interface StylizationItemSectionProps {
  id: string;
  itemIndex: number;
  prepareFieldName: (name: string) => string;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
}

function StylizationItemSection({
  id,
  itemIndex,
  prepareFieldName,
  move,
  remove,
}: StylizationItemSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`elementsAttributes[${itemIndex}].${name}`),
    [itemIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0} overflow="visible">
      <AccordionButton
        id={id}
        index={itemIndex}
        move={move}
        label={`${itemIndex + 1} stylizacja`}
        remove={remove}
      />
      <AccordionPanel pl={0} pr={0} pt={8} pb={6} overflow="visible">
        <FormControl as="fieldset" mb={6} maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend">Zdjęcie</SectionTitle>
          <DropzoneField
            name={fieldname('image')}
            title="Dodaj zdjęcia w formacie .png, .jpg (min. 984px x 1260px)"
          />
        </FormControl>
        <FormControl as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend">Otaguj stylizację</SectionTitle>
          <TagInput
            label="Lista produktów"
            name={fieldname('photoTagsAttributes[0].linkParamValue')}
            suggestions={[]}
          />
        </FormControl>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default StylizationItemSection;
