/* eslint-disable new-cap */
import { useCallback } from 'react';
import { sub, startOfDay } from 'date-fns';
import jsPDF from 'jspdf';
import { isEmpty } from 'lodash';
import autoTable from 'jspdf-autotable';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Statistics/TopSearch/components/Table';
import useQueryParams from 'utils/useQueryParams';
import FooterButtons from 'pages/Statistics/TopSearch/components/FooterButtons';
import Search from 'pages/Statistics/TopSearch/components/Form/Search';
import { useTopSearchPhrasesQuery } from 'app/services/statisticsApi';
import 'pages/Statistics/assets/CustomFont';

const today = new Date();

export const DEFAULT_PARAMS = {
  timeFrom: startOfDay(
    sub(today, {
      weeks: 1,
    }),
  ),
  timeTo: startOfDay(today),
};

function TopSearch() {
  const { timeFrom, timeTo } = DEFAULT_PARAMS;
  const { queryString } = useQueryParams({
    timeFrom: timeFrom.toISOString(),
    timeTo: timeTo.toISOString(),
  });

  const {
    isFetching: statisticsLoading,
    data: { data: statistics, valuesSum, percentDeltaSum } = {
      statistics: [],
      valuesSum: 0,
      percentDeltaSum: 0,
    },
  } = useTopSearchPhrasesQuery(queryString);

  const handleGeneratePdf = useCallback(() => {
    const doc: any = new jsPDF();
    doc.setFontSize(12);
    doc.setFont('Jost');
    autoTable(doc, {
      html: '#top-search-table',
      styles: {
        font: 'Jost',
      },
      headStyles: {
        fillColor: '#000',
      },
    });

    const { finalY } = doc.lastAutoTable;
    doc.text(
      15,
      finalY + 10,
      `Suma całkowita: ${valuesSum} (${percentDeltaSum}%)`,
    );
    doc.save('raport.pdf');
  }, [percentDeltaSum, valuesSum]);

  return (
    <ListingLayout
      title="Wyszukiwarka"
      hideSearch
      hideFilter
      headerButtons={[
        ...(isEmpty(statistics)
          ? []
          : [
              {
                label: 'EKSPORTUJ WYNIKI DO PDF',
                onClick: handleGeneratePdf,
              },
            ]),
      ]}
    >
      <Search />
      {statisticsLoading && <LoadingIndicator />}
      {!statisticsLoading && (
        <Table
          data={statistics || []}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 225px));`}
        />
      )}
      <Footer
        totals={0}
        footerButtons={
          <FooterButtons
            valuesSum={valuesSum}
            percentDeltaSum={percentDeltaSum}
          />
        }
        hidePagination
      />
    </ListingLayout>
  );
}

export default TopSearch;
