/* eslint-disable react/no-array-index-key */
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/ClothingMachines/Form/Create/components/ContainerWrapper';
import ROUTES from 'app/routes';
import { FORM_MAX_WIDTH } from 'utils/constants';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/ClothingMachines/Form/constants';
import { useCreateClothingMachineMutation } from 'app/services/clothingMachinesApi';
import { transformErrors } from 'utils/api';
import useRoute from 'utils/useRoute';

function ClothingMachineCreate() {
  const backPath = useRoute(ROUTES.clothingMachines.base);

  const [createClothingMachine, { isLoading: isCreating }] =
    useCreateClothingMachineMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const navigate = useNavigate();

  const { handleSubmit, setError } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const response: any = await createClothingMachine(data);
      const hasError = Object.hasOwnProperty.call(response, 'error');
      if (hasError) {
        const {
          error: { data: errors },
        } = response;
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field: string) => {
          setError(field as never, {
            type: 'custom',
            message: transformedErrors[field],
          });
        });
      } else {
        navigate(backPath);
        toast.success(`Ubraniomat został pomyślnie utworzony!`);
      }
    } catch (err) {
      // eslint-disable-next-line
      console.debug(err);
    }
  });

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Ubraniomat',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj ubraniomat' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="clothing-machine-creator"
          onSubmit={onSubmit}
          pb="120px"
          maxWidth="100%"
          maxW={FORM_MAX_WIDTH}
        >
          <ContainerWrapper isLoading={isCreating} />
        </Box>
      </FormProvider>
      <FooterButtons
        isLoading={isCreating}
        isCreate
        formId="clothing-machine-creator"
        backPath={backPath}
      />
    </Box>
  );
}

export default ClothingMachineCreate;
