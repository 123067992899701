import { CountryCodes } from 'types/common';
import {
  Poland,
  Lithuania,
  Lativia,
  Slovakia,
  Germany,
  Romania,
  Portugal,
  Estonia,
  Ukraine,
  Spain,
  CzechRepublic,
  EuropeanUnion,
  Croatia,
} from 'components/Icons/Flags';

export const LABEL_DICT: {
  [K in CountryCodes]: string;
} = {
  pl: 'Polska',
  en: 'Unia Europejska',
  lt: 'Litwa',
  lv: 'Łotwa',
  sk: 'Słowacja',
  de: 'Niemcy',
  cs: 'Czechy',
  ro: 'Rumunia',
  pt: 'Portugalia',
  et: 'Estonia',
  uk: 'Ukraina',
  es: 'Hiszpania',
  hr: 'Chorwacja',
};

export const ICON_DICT: {
  [K in CountryCodes]: React.ReactNode;
} = {
  pl: <Poland />,
  en: <EuropeanUnion />,
  lt: <Lithuania />,
  lv: <Lativia />,
  sk: <Slovakia />,
  de: <Germany />,
  cs: <CzechRepublic />,
  ro: <Romania />,
  pt: <Portugal />,
  et: <Estonia />,
  uk: <Ukraine />,
  es: <Spain />,
  hr: <Croatia />,
};
