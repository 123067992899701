/* eslint-disable react/no-array-index-key */
import { flexRender, Row } from '@tanstack/react-table';
import React from 'react';
import Tr from 'components/Table/Tr';
import Td from 'pages/Statistics/TopBannersComparison/components/Table/components/Td';

interface TableRowProps {
  row: Row<TopBannersComparisonItem>;
}

function TableRow({ row }: TableRowProps) {
  const { valuePosition: id } = row.original;

  return (
    <React.Fragment key={`group_row_${row.id}`}>
      <Tr key={`row_${id}`}>
        {row.getVisibleCells().map((cell, idx, { length }) => {
          if (idx + 1 === length) return null;
          return (
            <Td key={`row_cell_${idx}_${cell}`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
        <Td />
      </Tr>
    </React.Fragment>
  );
}

export default TableRow;
