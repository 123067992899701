import { Flex, Text, useDisclosure } from '@chakra-ui/react';
import { GrLanguage } from 'react-icons/gr';
import { FiArrowRight } from 'react-icons/fi';
import { useEffect } from 'react';
import BrandLogo from 'components/Icons/4F';
import CountrySelect from 'components/CountryChange';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';
import { getCountryCode } from 'selectors/appSelectors';
import { LABEL_DICT as COUNTRY_LABEL } from 'components/CountryChange/dictionary';
import { selectStore } from 'selectors/authSelectors';
import { useCountriesQuery } from 'app/services/commonApi';
import { setCountry } from 'slices/app/appSlice';

interface HeaderProps {
  isExpanded: boolean;
}
function Header({ isExpanded }: HeaderProps) {
  const dispatch = useAppDispatch();
  const countryCode = useAppSelector(getCountryCode);
  const pickedStore = useAppSelector(selectStore);
  const { data: { data: countries } = { data: [] } } = useCountriesQuery();
  const {
    isOpen: countrySelectOpen,
    onOpen: openCountrySelect,
    onClose: closeCountrySelect,
  } = useDisclosure();

  useEffect(() => {
    const newCountryCode = countries.find(
      (country) => country.store === pickedStore,
    )?.countryCode;

    dispatch(setCountry(newCountryCode!));
  }, [pickedStore, dispatch, countries]);

  return (
    <Flex
      w="100%"
      align="center"
      justify="space-between"
      pt={isExpanded ? '0' : '46px'}
      px={isExpanded ? '36px' : '0'}
      pb={isExpanded ? '0' : '12px'}
      minH={isExpanded ? '128px' : '208px'}
      direction={isExpanded ? 'row' : 'column'}
    >
      <BrandLogo />
      <Flex w="100%" justify="flex-end">
        <Flex
          py="12px"
          align="center"
          cursor="pointer"
          _hover={{ bg: 'blackAlpha.50' }}
          w={isExpanded ? 'unset' : '100%'}
          px={isExpanded ? '10px' : 'unset'}
          direction={isExpanded ? 'row' : 'column'}
          onClick={openCountrySelect}
        >
          <GrLanguage size={20} />
          <Text px="22px" fontSize="16px" pt={isExpanded ? 0 : '18px'}>
            {COUNTRY_LABEL[countryCode]}
          </Text>
          {isExpanded && <FiArrowRight size={16} />}
        </Flex>
      </Flex>
      <CountrySelect onClose={closeCountrySelect} isOpen={countrySelectOpen} />
    </Flex>
  );
}

export default Header;
