import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import { Box, BoxProps } from '@chakra-ui/react';
import TabElement from 'components/Table/Table';
import Thead from 'pages/AppStoriesInspirations/Listing/components/NestedTable/components/Thead';
import Tr from 'pages/AppStoriesInspirations/Listing/components/NestedTable/components/Tr';
import Tbody from 'pages/AppStoriesInspirations/Listing/components/NestedTable/components/Tbody';
import Th from 'components/Table/Th';
import columns from 'pages/AppStoriesInspirations/Listing/components/NestedTable/columns';
import TableRow from 'pages/AppStoriesInspirations/Listing/components/NestedTable/TableRow';
import { ContainerElement } from 'types/dashboard';

type TableProps = BoxProps & {
  elements: ContainerElement[];
};

function Table({ elements }: TableProps) {
  const table = useReactTable({
    data: elements,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  if (isEmpty(elements)) return null;

  return (
    <Box as="td" colSpan={5} padding={0}>
      <TabElement isEmpty={false}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`thread_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`thread_column_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} row={row} />
          ))}
        </Tbody>
      </TabElement>
    </Box>
  );
}

export default Table;
