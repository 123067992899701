import { generatePath } from 'react-router-dom';
import {
  LoginSuccededPayload,
  LoginPayload,
  RecoverPasswordPayload,
  ChangePasswordResponse,
} from 'types/auth';
import { api } from 'app/services/api';
import ROUTES from 'app/routes';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginSuccededPayload, LoginPayload>({
      query: ({ email, password }) => ({
        url: `auth/sign_in`,
        method: 'POST',
        body: { email, password },
      }),
      invalidatesTags: ['User'],
    }),
    logout: build.mutation<null, void>({
      query: () => ({
        url: `auth/sign_out`,
        method: 'DELETE',
      }),
    }),
    resetPassword: build.mutation<any, RecoverPasswordPayload>({
      query: ({ email, store }) => ({
        url: `auth/password`,
        method: 'POST',
        body: {
          email,
          redirectUrl: `${process.env.REACT_APP_CMS_URL}${generatePath(
            ROUTES.auth.change,
            { store },
          )}`,
        },
      }),
    }),
    changePassword: build.mutation<ChangePasswordResponse, any>({
      query: ({ password, passwordConfirmation, queryParams }) => ({
        url: `auth/password`,
        method: 'PUT',
        headers: { ...queryParams },
        body: {
          password,
          passwordConfirmation,
        },
      }),
    }),
    getUser: build.query<any, void>({
      query: () => `admin_users/current`,
      keepUnusedDataFor: 10,
      providesTags: ['User'],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
} = authApi;
