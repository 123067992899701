import { Box, Text } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import ActivityFieldset from 'components/ActivityFieldset';
import { useMagentoCategoriesQuery } from 'app/services/commonApi';
import LinkCreatorSection from 'components/LinkCreator';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  isEditMode?: boolean;
}

function FormBody({ prepareFieldName, isEditMode }: FormBodyProps) {
  const { data: { data: magentoOptions } = { data: [] }, isFetching } =
    useMagentoCategoriesQuery();

  return (
    <>
      <Text variant="boldSectionTitle" mb="22px">
        Kategoria w Magento
      </Text>
      <Box mb="26px">
        <Select
          label="Kategoria w Magento"
          options={magentoOptions}
          isLoading={isFetching}
          name={prepareFieldName('externalCategoryId')}
        />
      </Box>
      <Box mb="32px">
        <Input name={prepareFieldName('name')} label="Nazwa" />
      </Box>
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
        placement="tags"
      />
      <ActivityFieldset prepareFieldName={prepareFieldName} />
    </>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
