import { Box, Stack } from '@chakra-ui/react';
import { FormComponentProps } from 'types/dashboardCreator';
import Input from 'components/Form/Input';
import SectionTitle from 'components/Form/SectionTitle';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

function AppVersionSection({ prepareFieldName }: FormComponentProps) {
  return (
    <Box as="fieldset" pb={8} maxW={FORM_BODY_MAX_WIDTH}>
      <SectionTitle as="legend">Wersja aplikacji</SectionTitle>
      <Stack spacing={4}>
        <Input name={prepareFieldName('androidVersion')} label="Android" />
        <Input name={prepareFieldName('iosVersion')} label="iOS" />
      </Stack>
    </Box>
  );
}

export default AppVersionSection;
