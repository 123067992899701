import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { getSelectedRows } from 'selectors/uiSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import { useBulkDeleteCategoryMutation } from 'app/services/categoryApi';
import DeleteModal from 'components/DeleteModal';

function FooterButtons() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const selectedRows = useAppSelector(getSelectedRows);
  const isDisabled = isEmpty(selectedRows);
  const [bulkDeleteCategory] = useBulkDeleteCategoryMutation();

  const handleBulkDelete = async () => {
    try {
      await bulkDeleteCategory({
        body: { categoryIds: selectedRows },
      }).unwrap();
      onClose();
      toast.success('Kategorie zostały usunięte');
    } catch (err) {
      toast.error('Wystąpił problem podczas usuwania kategorii');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <HStack>
      <DeleteModal
        onAccept={handleBulkDelete}
        onClose={onClose}
        isOpen={isOpen}
      >
        Czy na pewno chcesz trwale usunąć wybrane kategorie?
      </DeleteModal>
      <Button variant="link" isDisabled={isDisabled} onClick={onOpen}>
        USUŃ
      </Button>
    </HStack>
  );
}

export default FooterButtons;
