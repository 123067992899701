import {
  Button,
  FormControl,
  HStack,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import { FaRegTrashAlt } from 'react-icons/fa';
import ActivityFormSection from 'components/ActivityFieldset';
import Input from 'components/Form/Input';
import SectionTitle from 'components/Form/SectionTitle';
import DropzoneField from 'components/Form/Dropzone';
import { DEFAULT_VALUES } from 'pages/BenefitsScreensElements/Form/constants';

function FormBody() {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemsAttributes',
    keyName: 'fieldId',
  });

  return (
    <>
      <FormControl as="fieldset" mb={8}>
        <SectionTitle as="legend">Ikona</SectionTitle>
        <DropzoneField
          name="icon"
          title="Dodaj zdjęcie w formacie .png, .jpg"
        />
      </FormControl>
      <FormControl as="fieldset" mb={8}>
        <Input label="Treść" name="title" />
      </FormControl>
      <FormControl as="fieldset" mb={8}>
        <Input label="Podtytuł" name="subtitle" />
      </FormControl>
      <FormControl as="fieldset" mb={12}>
        <SectionTitle as="legend">Elementy</SectionTitle>
        <VStack spacing={6}>
          {fields.map((field, index) => (
            <HStack key={field.fieldId} spacing={2} w="100%">
              <Input name={`itemsAttributes.${index}.title`} />
              <IconButton
                aria-label="remove element"
                icon={<FaRegTrashAlt size={16} />}
                variant="unstyled"
                onClick={() => remove(index)}
              />
            </HStack>
          ))}
          <Button
            variant="solid"
            w="100%"
            onClick={() =>
              append({
                ...DEFAULT_VALUES.itemsAttributes[0],
                position: fields.length,
              })
            }
            fontSize="12px"
            rightIcon={<BiPlus size={24} />}
          >
            DODAJ ELEMENT
          </Button>
        </VStack>
      </FormControl>
      <ActivityFormSection prepareFieldName={(name: string) => name} />
    </>
  );
}

export default FormBody;
