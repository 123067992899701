import { useMemo, useEffect, useState, useCallback } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import qs from 'query-string';
import { format, differenceInDays } from 'date-fns';
import { Tag } from 'react-tag-input';
import uniqBy from 'lodash/uniqBy';
import { Box, Flex, HStack } from '@chakra-ui/react';
import ROUTES from 'app/routes';
import {
  useDashboardsQuery,
  useShareDashboardMutation,
} from 'app/services/dashboardApi';
import Card from 'components/Card';
import { GENDER } from 'components/Container/constants';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import DateInput from 'components/Form/Date';
import SectionTitle from 'components/Form/SectionTitle';
import HeaderNavigation from 'components/HeaderNavigation';
import LoadingCover from 'components/LoadingCover';
import MobilePreview from 'pages/Dashboard/Edit/components/MobilePreview';
import { Dashboard } from 'types/dashboard';
import { FORM_MAX_WIDTH } from 'utils/constants';
import { transformActivityDates } from 'utils/date';
import { useFormWithSchema } from 'utils/formHooks';
import useQueryParams from 'utils/useQueryParams';
import FooterButtons from 'components/FooterButtons';
import TagInput from 'components/Form/TagInput';
import RichText from 'components/Form/RichText';
import { transformErrors } from 'utils/api';
import { useAppSelector } from 'utils/reduxHooks';
import { selectStore } from 'selectors/authSelectors';
import useRoute from 'utils/useRoute';

const TRANSFORMED_KEYS: { [key: string]: string } = {
  activeFrom: 'date_from',
  activeTo: 'date_to',
};

const schema = yup.object({
  gender: yup.mixed().oneOf(['male', 'female', 'girls', 'boys']),
  dateTo: yup.date().required('Pole jest wymagane'),
  dateFrom: yup.date().required('Pole jest wymagane'),
  content: yup.string().nullable(),
  emails: yup
    .string()
    .nullable()
    .required('Wprowadź co najmniej jeden adres e-mail'),
});

const now: Date = new Date();

const STORAGE_EMAILS_KEY = '4f:sharedEmails';

function ShareDashboard() {
  const { search } = useQueryParams();
  const navigate = useNavigate();
  const [suggestedEmails, setSuggestedEmails] = useState<Tag[] | null>(null);
  const currentStore: string = useAppSelector(selectStore);
  const dashboardPath = useRoute(ROUTES.dashboard.base);
  const dashboardSharePath = useRoute(ROUTES.dashboard.share);

  const defaultValues = {
    gender: search.get('gender') || 'male',
    dateFrom: now,
    dateTo: now,
    store: currentStore,
  };

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues,
  });

  const [gender, dateFrom, dateTo, emails] = useWatch({
    control: methods.control,
    name: ['gender', 'dateFrom', 'dateTo', 'emails'],
  });

  useEffect(() => {
    if (differenceInDays(dateTo, dateFrom) < 0) {
      methods.setValue('dateTo', dateFrom);
    }
  }, [dateFrom, dateTo, methods]);

  const queryString: string = useMemo(() => {
    let to = dateTo;
    if (differenceInDays(to, dateFrom) < 0) {
      to = dateFrom;
    }
    const f = transformActivityDates({ dateFrom, dateTo: to, gender });
    const filtersTransformed: { [key: string]: string | number } = {};
    Object.keys(f).forEach((key) => {
      const k = TRANSFORMED_KEYS[key] || key;
      if (f[key] !== ' ') {
        filtersTransformed[k] = f[key];
      }
    });

    return `?${qs.stringify(
      filtersTransformed,
    )}&q[status_in][]=active&q[status_in][]=planned`;
  }, [dateFrom, dateTo, gender]);

  const [shareDashboard, { isLoading: shareRunning }] =
    useShareDashboardMutation();

  const { handleSubmit } = methods;
  const onSubmit = handleSubmit(async (data: any) => {
    try {
      await shareDashboard(data)
        .unwrap()
        .then(() => {
          navigate(`${dashboardPath}?${search.toString()}`);
          toast.success(`Wiadomość została wysłana`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  const { isFetching, data: { data: containers } = { data: [], totals: 0 } } =
    useDashboardsQuery(queryString);

  // update gender query parameter
  useEffect(() => {
    navigate(`${dashboardSharePath}?gender=${gender}`, {
      replace: true,
    });
  }, [dashboardSharePath, gender, navigate]);

  useEffect(() => {
    const storedEmails = localStorage.getItem(STORAGE_EMAILS_KEY)
      ? JSON.parse(localStorage.getItem(STORAGE_EMAILS_KEY) as string)
      : [];

    if (emails || storedEmails) {
      const uniqueEmails: Tag[] = uniqBy(
        [
          ...storedEmails,
          ...(emails
            ? emails.split(', ').map((email) => ({ id: email, text: email }))
            : []),
        ],
        (email) => email.id,
      );
      localStorage.setItem(STORAGE_EMAILS_KEY, JSON.stringify(uniqueEmails));
      setSuggestedEmails(uniqueEmails);
    }
  }, [emails]);

  const validateEmail: (email: string) => boolean = useCallback((email) => {
    const validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return Boolean(email.match(validRegex));
  }, []);

  const previewSubtitle = useMemo(
    () =>
      dateFrom && dateTo
        ? `od ${format(dateFrom, 'dd.MM.yyyy')} do ${format(
            dateTo,
            'dd.MM.yyyy',
          )}`
        : ``,
    [dateFrom, dateTo],
  );

  return (
    <Box pt="32px" paddingBottom={88}>
      <HeaderNavigation
        baseCrumb={{ to: dashboardPath, label: 'Dashboard' }}
        crumbs={[{ label: 'Udostępnij dashboard' }]}
      />
      <FormProvider {...methods}>
        <form id="share-dashboard" onSubmit={onSubmit}>
          <Flex gap={4} mb={4} alignItems="flex-start">
            <Box flex={1} maxW={FORM_MAX_WIDTH}>
              <Card>
                {isFetching || shareRunning ? (
                  <LoadingCover position="absolute" />
                ) : null}
                <Box as="fieldset">
                  <SectionTitle as="legend" mb="0">
                    Generowany dashboard
                  </SectionTitle>
                  <Flex mt={4} mb={9}>
                    {(Object.keys(GENDER) as Array<keyof typeof GENDER>).map(
                      (key) =>
                        key !== 'all' ? (
                          <RadioBoxControl
                            key={key}
                            id={`gender-${key}`}
                            label={GENDER[key]}
                            name="gender"
                            value={key}
                          />
                        ) : null,
                    )}
                  </Flex>
                </Box>
                <Box as="fieldset">
                  <SectionTitle as="legend" mb={22}>
                    Zakres dat dashboardu
                  </SectionTitle>
                  <HStack gap={4} mb="44px">
                    <DateInput
                      name="dateFrom"
                      label="Aktywny od"
                      showError
                      rangeSelect={false}
                      required
                      isClearable={false}
                    />
                    <DateInput
                      name="dateTo"
                      label="Aktywny do"
                      showError
                      rangeSelect={false}
                      required
                      isClearable={false}
                    />
                  </HStack>
                </Box>
                <Box as="fieldset" mb="20px">
                  <SectionTitle as="legend" mb={22}>
                    Udostępnianie dashboardu
                  </SectionTitle>
                  <TagInput
                    label="E-mail"
                    name="emails"
                    validate={validateEmail}
                    suggestions={suggestedEmails || []}
                    autocomplete
                  />
                  <Box
                    mt={6}
                    backgroundColor="complementary.purpleBlue"
                    p="24px 40px 24px 16px"
                  >
                    Możesz wpisać kilka wartości. Po dodaniu wartości zatwierdź
                    ją enterem lub rodziel przecinkiem
                  </Box>
                </Box>
                <Box as="fieldset">
                  <RichText
                    label="Wiadomość (opcjonalnie)"
                    name="content"
                    placeholder=""
                  />
                </Box>
              </Card>
            </Box>

            <MobilePreview
              containers={containers as Dashboard[]}
              name="gender"
              sectionTitle="Podgląd"
              sectionSubtitle={previewSubtitle}
            />
          </Flex>

          <FooterButtons
            isLoading={shareRunning || isFetching}
            formId="share-dashboard"
            okButtonText="WYŚLIJ DO AKCEPTACJI"
            backPath={dashboardPath}
          />
        </form>
      </FormProvider>
    </Box>
  );
}

export default ShareDashboard;
