import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { FC } from 'react';
import { isEmpty, map } from 'lodash';
import { To } from 'react-router-dom';
import Filter from 'components/Icons/Filter';
import SearchInput from 'components/Search';

type CSVLinkProps = {
  filename?: string;
  data?: any[];
};

export type ListingActionButton = ButtonProps &
  CSVLinkProps & {
    label: string;
    to?: To;
  };

interface HeaderFiltersProps {
  onFiltersOpen: () => void;
  title: string;
  buttons: ListingActionButton[];
  searchKey: string;
  placement: string;
  hideSearch: boolean;
  hideFilter: boolean;
  FilterBox?: FC<BoxProps>;
}

function HeaderFilters({
  title,
  buttons,
  onFiltersOpen,
  searchKey,
  placement,
  hideSearch,
  hideFilter,
  FilterBox = Box,
}: HeaderFiltersProps) {
  return (
    <Flex justify="space-between" mb="32px" align="center">
      <Text variant="h1" textTransform="uppercase">
        {title}
      </Text>
      <HStack spacing="56px">
        <HStack spacing="56px">
          <SearchInput
            searchKey={searchKey}
            placement={placement}
            hidden={hideSearch}
          />
          <FilterBox>
            <IconButton
              onClick={onFiltersOpen}
              variant="ghost"
              aria-label="Filter"
              icon={<Filter />}
              hidden={hideFilter}
            />
          </FilterBox>
        </HStack>
        {!isEmpty(buttons) && (
          <HStack spacing="8px">
            {map(buttons, ({ label, ...buttonProps }, idx) => (
              <Button
                key={`button-${label}-${idx}`}
                fontWeight={700}
                fontSize="12px"
                lineHeight="20px"
                paddingX="32px"
                {...buttonProps}
              >
                {label}
              </Button>
            ))}
          </HStack>
        )}
      </HStack>
    </Flex>
  );
}
HeaderFilters.defaultProps = {
  FilterBox: Box,
};

export default HeaderFilters;
