import { useCallback, useMemo, useRef } from 'react';
import qs from 'query-string';
import { useParams } from 'react-router-dom';
import { Box, Center, HStack, VStack, Flex, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import { AiOutlineHeart, AiOutlineShopping } from 'react-icons/ai';
import { TbGridDots, TbListSearch } from 'react-icons/tb';
import Search from 'components/Icons/Search';
import SectionTitle from 'components/Form/SectionTitle';
import {
  Previews,
  GENDER_BY_STORE,
  GENDER,
  CONTAINERS_WITHOUT_STATS,
} from 'components/Container/constants';
import LoadingCover from 'components/LoadingCover';

import BrandLogo from 'components/Icons/Big4F';
import BrandLogoNav from 'components/Icons/4F';
import { usePreviewDashboardQuery } from 'app/services/dashboardApi';
import PreviewFallback from 'pages/Dashboard/Edit/components/PreviewFallback';
import { datePrettierNoTime } from 'utils/date';

function DashboardPreview() {
  const { dateFrom, dateTo, gender, store } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);

  const queryString = useMemo(() => {
    const params = {
      date_from: dateFrom,
      date_to: dateTo,
      gender,
      page: 1,
      'q[status_in][]': ['active', 'planned'],
      page_size: 1000,
    };

    return `?${qs.stringify(params)}`;
  }, [dateFrom, dateTo, gender]);

  const formattedDateFrom = useMemo(
    () => datePrettierNoTime(dateFrom),
    [dateFrom],
  );
  const formattedDateTo = useMemo(() => datePrettierNoTime(dateTo), [dateTo]);

  const { isFetching, data: { data } = { data: [], totals: 0 } } =
    usePreviewDashboardQuery({ queryString, store: store || 'default' });

  const containers = useMemo(() => data || [], [data]);

  const fontWeight = useCallback(
    (key: string) => ({
      fontWeight: gender === key ? 600 : 400,
    }),
    [gender],
  );

  return (
    <Flex direction="row" h="100%" w="100%">
      <Center w="418px" bg="black">
        <BrandLogo />
      </Center>
      <Flex
        w="100%"
        align="center"
        justify="center"
        marginX="30px"
        flex={1}
        direction="column"
      >
        <SectionTitle as="h1" mb="0" textTransform="uppercase">
          {gender
            ? `Podgląd dashboardu ${GENDER[gender as keyof typeof GENDER]}`
            : null}
        </SectionTitle>
        <Text fontSize={16}>
          {formattedDateFrom} - {formattedDateTo}
        </Text>

        <Box
          ref={containerRef}
          mt={8}
          w={388}
          overflowX="hidden"
          overflowY="auto"
          maxH="calc(100vh - 180px)"
          borderRadius="16px"
          border="solid 1px #000"
          position="relative"
        >
          <Flex
            bgColor="#fff"
            zIndex={10}
            position="sticky"
            alignItems="center"
            justifyContent="space-between"
            top={0}
            p={4}
          >
            <HStack spacing={4}>
              {(
                Object.keys(GENDER_BY_STORE[store as string]) as Array<
                  keyof typeof GENDER
                >
              ).map((key) =>
                key !== 'all' ? (
                  <Text
                    key={key}
                    fontSize="16px"
                    {...fontWeight(key as string)}
                  >
                    {GENDER_BY_STORE[store as string][key]}
                  </Text>
                ) : null,
              )}
            </HStack>
            <Search />
          </Flex>

          <VStack
            cursor="pointer"
            minH={400}
            bgColor="#fff"
            pos="relative"
            spacing={0}
          >
            {isFetching ? <LoadingCover position="absolute" /> : null}
            {!isEmpty(containers) ? (
              (containers as any[]).map((item, idx: number) => {
                if (!Object.keys(Previews).includes(item.kind)) {
                  return (
                    <PreviewFallback
                      key={`preview-default-edit-${+idx}`}
                      kind={item.kind}
                      text="Brak Podglądu"
                    />
                  );
                }

                const PreviewComponent = Previews[item.kind];

                if (item.kind === 'image_gallery') {
                  return (
                    <PreviewComponent
                      key={`preview-image-gallery${+idx}`}
                      formValues={item}
                      maxW={386}
                      showStats
                    />
                  );
                }

                if (item.kind === 'paralax_banner') {
                  return (
                    <PreviewComponent
                      key={`preview-edit-parallax${+idx}`}
                      formValues={item}
                      innerRef={containerRef}
                      showStats
                    />
                  );
                }

                return (
                  <PreviewComponent
                    key={`preview-edit-${+idx}`}
                    formValues={item}
                    showStats={!CONTAINERS_WITHOUT_STATS.includes(item.kind)}
                  />
                );
              })
            ) : (
              <PreviewFallback text="Brak Podglądu" />
            )}
          </VStack>

          <Flex
            position="sticky"
            zIndex={10}
            bottom={0}
            bgColor="#fff"
            justifyContent="space-between"
            alignItems="stretch"
            px={8}
            pt={6}
            pb="2px"
          >
            <Box borderBottom="solid 2px #000" pb="22px">
              <BrandLogoNav boxSize="26px" />
            </Box>
            <TbListSearch size={24} color="#a9a9a9" />
            <AiOutlineHeart size={24} color="#a9a9a9" />
            <AiOutlineShopping size={24} color="#a9a9a9" />
            <TbGridDots size={24} color="#a9a9a9" />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default DashboardPreview;
