import { Box } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import TagInput from 'components/Form/TagInput/index';

interface TagsFieldsetProps {
  prepareFieldName: (name: string) => string;
}

function TagsFieldset({ prepareFieldName }: TagsFieldsetProps) {
  const { control, setValue } = useFormContext();
  const withTags: boolean = useWatch({
    control,
    name: prepareFieldName('withTags'),
  });

  useEffect(() => {
    if (!withTags) {
      setValue(prepareFieldName('photoTagsAttributes[0].linkParamValue'), '');
    }
  }, [prepareFieldName, setValue, withTags]);

  return (
    <OptionalFormFieldset
      title="Otaguj zdjęcie (opcjonalnie)"
      isActive={withTags}
      name={prepareFieldName('withTags')}
      sectionWidth={FORM_BODY_MAX_WIDTH}
    >
      <Box mb={6}>
        <TagInput
          label="Lista produktów"
          name={prepareFieldName('photoTagsAttributes[0].linkParamValue')}
          suggestions={[]}
        />
      </Box>
    </OptionalFormFieldset>
  );
}

export default TagsFieldset;
