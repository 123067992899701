import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<DhlPoint, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TYP PUNKTU',
      accessorKey: 'pointType',
      cell: (info) => info.getValue(),
    },
    {
      header: 'OPIS',
      accessorKey: 'description',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KRAJ',
      accessorKey: 'country',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KOD POCZTOWY',
      accessorKey: 'postcode',
      cell: (info) => info.getValue(),
    },
    {
      header: 'MIASTO',
      accessorKey: 'city',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ULICA',
      accessorKey: 'street',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NUMER LOKALU',
      accessorKey: 'houseNumber',
      cell: (info) => info.getValue(),
    },
    {
      header: 'SZEROKOŚĆ GEOGRAFICZNA',
      accessorKey: 'latitude',
      cell: (info) => info.getValue(),
    },
    {
      header: 'DŁUGOŚĆ GEOGRAFICZNA',
      accessorKey: 'longitude',
      cell: (info) => info.getValue(),
    },
  ];
}

export default GetColumns;
