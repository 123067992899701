import { DeepPartial, Theme } from '@chakra-ui/react';

const Tooltip: DeepPartial<Theme['components']['Tooltip']> = {
  baseStyle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.004em',
    textAlign: 'center',

    backgroundColor: 'complementary.whiteGrey',
    color: 'black',
    padding: '2px 8px',
  },
};

export default Tooltip;
