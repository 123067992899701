import { Box, FormControl } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import { FormComponentProps } from 'types/dashboardCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import LinkCreatorSection from 'components/LinkCreator';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import ButtonFormSection from 'components/Container/Sections/ButtonFormSection';

function GraphicTileSmall({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  const { control } = useFormContext();
  const withButton = useWatch({
    control,
    name: prepareFieldName('withButton'),
  });

  return (
    <>
      <FormControl as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
        <Box mb={8}>
          <SectionTitle as="legend">Zdjęcie</SectionTitle>

          <DropzoneField
            name={prepareFieldName('image')}
            title="Dodaj zdjęcie w formacie .png, .jpg (min. 360px x 120px)"
          />
        </Box>
      </FormControl>

      <HeaderFormSection
        prepareFieldName={prepareFieldName}
        components={[
          COMPONENTS.TITLE,
          COMPONENTS.SUBTITLE,
          COMPONENTS.COLORTXT,
          COMPONENTS.COLORBG,
        ]}
      />

      <FormControl as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
        <ButtonFormSection
          prepareFieldName={prepareFieldName}
          isEditMode={isEditMode || false}
        />

        <LinkCreatorSection
          prepareFieldName={prepareFieldName}
          mb={withButton ? 8 : 0}
          optionalField={!withButton}
          isEditMode={isEditMode || false}
        />
      </FormControl>
    </>
  );
}

export default GraphicTileSmall;
