import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import ColorPreview from 'components/ColorPreview';
import ImagePreview from 'components/ImagePreview';
import StatusBox from 'components/StatusBox';
import { SplashScreen } from 'types/splashScreens';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<SplashScreen, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KOLOR TŁA ANIMACJI',
      accessorKey: 'animationBackgroundColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'TŁO',
      accessorKey: 'background.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'STATUS',
      accessorKey: 'active',
      cell: (info) => (
        <StatusBox status={info.getValue() ? 'active' : 'unactive'} />
      ),
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: 'activeFrom',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: 'activeTo',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
