import { Box, FormControl, Stack } from '@chakra-ui/react';
import ColorPicker from 'components/Form/Color';
import SectionTitle from 'components/Form/SectionTitle';
import DropzoneField from 'components/Form/Dropzone';
import Textarea from 'components/Form/Textarea';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Textarea
        name={prepareFieldName('description')}
        label="Opis"
        placeholder="Opis"
      />
      <ColorPicker
        name={prepareFieldName('textColor')}
        label="Kolor tekstu"
        mb={8}
      />
      <FormControl as="fieldset">
        <SectionTitle as="legend">Pierwszy przycisk</SectionTitle>
        <ColorPicker
          name={prepareFieldName('button1BackgroundColor')}
          label="Kolor tła"
          mb={8}
        />
        <ColorPicker
          name={prepareFieldName('button1TextColor')}
          label="Kolor tekstu"
        />
      </FormControl>
      <FormControl as="fieldset">
        <SectionTitle as="legend">Drugi przycisk</SectionTitle>
        <ColorPicker
          name={prepareFieldName('button2BorderColor')}
          label="Kolor obramowania"
          mb={8}
        />
        <ColorPicker
          name={prepareFieldName('button2TextColor')}
          label="Kolor tekstu"
        />
      </FormControl>
      <FormControl as="fieldset">
        <Box mb={9}>
          <SectionTitle as="legend">Zdjęcie tła</SectionTitle>
          <DropzoneField
            name={prepareFieldName('background')}
            title="Dodaj zdjęcie w formacie .png, .jpg"
          />
        </Box>
        <Box mb={9}>
          <SectionTitle as="legend">Logo 4F</SectionTitle>
          <DropzoneField
            name={prepareFieldName('logoFourf')}
            title="Dodaj zdjęcie w formacie .png, .jpg"
          />
        </Box>
      </FormControl>
    </Stack>
  );
}

export default FormBody;
