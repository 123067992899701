import { Stack } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Input name={prepareFieldName('street')} label="Ulica" />
      <Input name={prepareFieldName('location')} label="Lokalizacja" />
      <Input name={prepareFieldName('openingTimes')} label="Godziny otwarcia" />
      <ToggleCheckbox name={prepareFieldName('active')} label="Aktywny" />
    </Stack>
  );
}

export default FormBody;
