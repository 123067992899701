import { Box } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/SubscriptionRunupScreens/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';

interface ContainerWrapperProps {
  isLoading: boolean;
  isEditMode?: boolean;
}

function ContainerWrapper({ isLoading, isEditMode }: ContainerWrapperProps) {
  return (
    <Card>
      {isLoading && <FormSpinner />}
      <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
        <FormBody
          prepareFieldName={(name: string) => name}
          isEditMode={isEditMode}
        />
      </Box>
    </Card>
  );
}

ContainerWrapper.defaultProps = {
  isEditMode: false,
};

export default ContainerWrapper;
