import { Box, FormControl } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';

// kind: external_link
function ExternalLinkForm({ prepareFieldName }: FormComponentProps) {
  return (
    <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
      <Box mb={9}>
        <Input
          label="Tekst linku"
          type="text"
          name={prepareFieldName('title')}
        />
      </Box>
      <Box mb={9}>
        <Input
          label="Adres URL linku"
          type="text"
          name={prepareFieldName('externalUrl')}
        />
      </Box>
      <Box mb={9}>
        <ColorPicker
          name={prepareFieldName('textColor')}
          label="Kolor tekstu"
        />
      </Box>
      <Box mb={9}>
        <ColorPicker
          name={prepareFieldName('backgroundColor')}
          label="Kolor tła"
        />
      </Box>
    </FormControl>
  );
}

export default ExternalLinkForm;
