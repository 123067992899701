import { Link } from 'react-router-dom';
import ROUTES from 'app/routes';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Abouts/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import useRoute from 'utils/useRoute';
import { useAboutsQuery, useDeleteAboutMutation } from 'app/services/aboutsApi';

function Abouts() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newAboutPath = useRoute(ROUTES.abouts.create);

  const [, state] = useDeleteAboutMutation({
    fixedCacheKey: 'abouts',
  });

  const {
    isFetching: aboutsLoading,
    data: { data: abouts, totals } = { data: [], totals: 0 },
  } = useAboutsQuery(queryString);

  const { isLoading } = state;
  const showLoading = aboutsLoading || isLoading;

  return (
    <ListingLayout
      title="O nas"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWY O NAS',
          as: Link,
          to: newAboutPath,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={abouts}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Abouts;
