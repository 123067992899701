import * as yup from 'yup';

export const SIZING_KIND: { [key: string]: string } = {
  MAN_false: 'Mężczyzna odzież',
  MAN_true: 'Mężczyzna buty',
  WOMAN_false: 'Kobieta odzież',
  WOMAN_true: 'Kobieta buty',
  KIDS_false: 'Dzieci odzież',
  KIDS_true: 'Dzieci buty',
  UNISEX_false: 'Unisex odzież',
  UNISEX_true: 'Unisex buty',
  MAN_accessories: 'Mężczyzna akcesoria',
  WOMAN_accessories: 'Kobieta akcesoria',
  KIDS_accessories: 'Dzieci akcesoria',
  UNISEX_accessories: 'Unisex akcesoria',
};

export const SIZING_OPTIONS = Object.entries(SIZING_KIND).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
);

export const BODY_SCHEMA = yup.object({
  kind: yup.string().required('Pole jest wymagane'),
  body: yup.string().required('Pole jest wymagane'),
});

export const DEFAULT_VALUES = {
  kind: '',
  body: '',
};
