import { useMemo } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { MdOutlineEdit } from 'react-icons/md';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { generatePath, Link, useParams } from 'react-router-dom';
import Tr from 'components/Table/Tr';
import Td from 'pages/Minibanners/Listing/components/Table/components/Td';
import DeleteModal from 'components/DeleteModal';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import { useDeleteMinibannerMutation } from 'app/services/minibannersApi';

interface TableRowProps {
  row: Row<Minibanner>;
}
function TableRow({ row }: TableRowProps) {
  const { store } = useParams();
  const { id } = row.original;
  const { queryString } = useQueryParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleteMinibanner] = useDeleteMinibannerMutation();

  const editPath = useMemo(
    () =>
      generatePath(ROUTES.minibanners.edit, {
        id: id.toString(),
        store,
      }) + queryString,
    [id, queryString, store],
  );

  const handleDelete = async () => {
    try {
      await deleteMinibanner(id).unwrap();
      toast.success('Pomyślnie usunięto minibanner');
      onClose();
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania minibanneru');
    }
  };

  return (
    <Tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <DeleteModal
            onAccept={handleDelete}
            onClose={onClose}
            isOpen={isOpen}
          >
            Czy na pewno chcesz trwale usunąć ten minibanner?
          </DeleteModal>
          <Tooltip label="Edytuj" openDelay={1000}>
            <IconButton
              width="72px"
              height="56px"
              icon={<MdOutlineEdit size={20} />}
              aria-label="visiblity"
              variant="ghost"
              as={Link}
              to={editPath}
            />
          </Tooltip>
          <Tooltip label="Usuń" openDelay={1000}>
            <IconButton
              width="72px"
              height="56px"
              icon={<RiDeleteBin6Line size={20} />}
              aria-label="visiblity"
              variant="ghost"
              onClick={onOpen}
            />
          </Tooltip>
        </>
      </Td>
    </Tr>
  );
}

export default TableRow;
