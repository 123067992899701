import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, Button } from '@chakra-ui/react';
import {
  useSetElementPositionMutation,
  useLazyImportInstaFeedQuery,
} from 'app/services/dashboardApi';
import { FormComponentProps } from 'types/dashboardCreator';
import Portal from 'components/Portal';
import InstagramElement from 'components/Container/Forms/InstaFeedForm/InstagramElement';
import { DragItem } from 'types/dashboard';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

function InstaFeedForm({ prepareFieldName }: FormComponentProps) {
  const [setElementPosition] = useSetElementPositionMutation();
  const { control } = useFormContext();
  const formValues = useWatch({ control });
  const [elements, setElements] = useState<Record<'fieldId', string>[]>([]);
  const [importInstaFeed, result] = useLazyImportInstaFeedQuery();

  const fetchButtonClickHandler = useCallback(async () => {
    if (formValues?.id) {
      await importInstaFeed({
        containerId: formValues.id,
        gender: formValues.gender,
      });
      toast.info('Trwa importowanie insta feedu');
    }
  }, [formValues, importInstaFeed]);

  const { fields, move } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'fieldId',
  });

  const synchronizeApi = useCallback(
    (elementId: string | number, placement: number, index: number) => {
      setElementPosition({
        containerId: formValues.id,
        elementId,
        placement,
      });
      move(index, placement);
    },
    [formValues.id, setElementPosition, move],
  );

  useEffect(() => {
    setElements(fields);
  }, [fields, setElements]);

  const moveItem = useCallback(
    (item: DragItem, dragIndex: number, hoverIndex: number) => {
      setElements((prevElements: Record<'fieldId', string>[]) =>
        update(prevElements, {
          $splice: [
            [dragIndex, 1],
            [
              hoverIndex,
              0,
              prevElements[dragIndex] as Record<'fieldId', string>,
            ],
          ],
        }),
      );
    },
    [],
  );

  if (!formValues?.id) {
    return null;
  }

  return (
    <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH} m="44px 0">
      <Button
        isLoading={result.isLoading}
        isDisabled={result.isLoading}
        type="button"
        onClick={fetchButtonClickHandler}
        w="100%"
      >
        AKTUALIZUJ FEED
      </Button>

      <Portal wrapperId="left-column-below">
        <DndProvider backend={HTML5Backend}>
          <div>
            {elements.map((element, index) => (
              <InstagramElement
                key={element.fieldId}
                item={element as DragItem}
                index={index}
                prepareFieldName={prepareFieldName}
                moveItem={moveItem}
                onDrop={synchronizeApi}
                containerId={formValues.id}
              />
            ))}
          </div>
        </DndProvider>
      </Portal>
    </Box>
  );
}

export default InstaFeedForm;
