import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Users/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import { useUsersQuery, useDeleteUserMutation } from 'app/services/usersApi';
import FiltersDrawer from 'pages/Users/Listing/components/FiltersDrawer';

function Users() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const [, state] = useDeleteUserMutation({
    fixedCacheKey: 'users',
  });

  const {
    isFetching: usersLoading,
    data: { data: users, totals } = { data: [], totals: 0 },
  } = useUsersQuery(queryString);

  const { isLoading } = state;
  const showLoading = usersLoading || isLoading;

  return (
    <ListingLayout
      title="Użytkownik"
      hideSearch
      filterDrawer={FiltersDrawer}
      headerButtons={[]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={users}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default Users;
