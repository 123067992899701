import isEmpty from 'lodash/isEmpty';
import { useFormContext, useWatch } from 'react-hook-form';
import { FunctionComponent } from 'react';
import { Flex, Spinner, Box } from '@chakra-ui/react';
import { Previews } from 'components/Container/constants';
import { PreviewComponentProps } from 'types/dashboardCreator';

interface ContainerPreviewProps {
  containerIndex: number;
  showStats: boolean;
}

function ContainerPreview({
  containerIndex,
  showStats,
}: ContainerPreviewProps) {
  const { control } = useFormContext();

  const formValues = useWatch({
    control,
    name: `containersAttributes[${containerIndex}]`,
  });

  const { kind } = formValues;

  const PreviewComponent: FunctionComponent<PreviewComponentProps> =
    Previews[kind];

  return (
    <>
      {isEmpty(formValues) && (
        <Flex w="100%" justify="center">
          <Spinner size="md" />
        </Flex>
      )}
      {!isEmpty(formValues) && PreviewComponent && (
        <>
          <PreviewComponent formValues={formValues} showStats={showStats} />
          {formValues.margined ? (
            <Box h="16px" backgroundColor={formValues.marginColor} />
          ) : null}
        </>
      )}
    </>
  );
}

export default ContainerPreview;
