import { DeepPartial, Theme } from '@chakra-ui/react';

const FormError: DeepPartial<Theme['components']['FormError']> = {
  parts: ['text'],
  baseStyle: {
    text: {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
      letterSpacing: '0.004em',
      textAlign: 'left',
      marginY: 0,
    },
  },
};

export default FormError;
