import { DeepPartial, Theme } from '@chakra-ui/react';

const Link: DeepPartial<Theme['components']['Link']> = {
  baseStyle: {
    color: 'complementary.blue',
    cursor: 'pointer',
    outline: 'none',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.004em',
    textAlign: 'center',
    textDecoration: 'underline',
  },
};

export default Link;
