import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';

export const sizingsAPI = api.injectEndpoints({
  endpoints: (build) => ({
    sizings: build.query<TransformedSizingsResponse, string>({
      query: (queryString) => `sizings${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: SizingsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Sizings'],
    }),
    deleteSizing: build.mutation<void, number>({
      query: (id) => ({
        url: `sizings/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sizings'],
    }),
    createSizing: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'sizing',
        );
        return {
          url: 'sizings',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Sizings'],
    }),
    sizing: build.query<SizingResponse, any>({
      query: (payload) => {
        const { id } = payload;
        return `sizings/${id}`;
      },
      keepUnusedDataFor: 10,
      providesTags: ['Sizings'],
    }),
    updateSizing: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'sizing',
        );

        return {
          url: `sizings/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Sizings'],
    }),
  }),
});

export const {
  useSizingsQuery,
  useDeleteSizingMutation,
  useCreateSizingMutation,
  useSizingQuery,
  useUpdateSizingMutation,
} = sizingsAPI;
