import { Flex, Box } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/Category/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';
import Input from 'components/Form/Input';
import FormSpinner from 'components/FormSpinner';

interface ComponentWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ComponentWrapperProps) {
  return (
    <Flex gap={4} mb={12} alignItems="flex-start">
      <Box flex={1} maxW={FORM_MAX_WIDTH}>
        <Card>
          {isLoading && <FormSpinner />}

          <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
            <Input name="name" placeholder="Wpisz nazwę" mb="36px" />
            <FormBody prepareFieldName={(name: string) => name} />
          </Box>
        </Card>
      </Box>
    </Flex>
  );
}

export default ContainerWrapper;
