import { VStack, Text, Flex } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';

function TextBannerImagePreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.image);

  return (
    <VStack minH={400} spacing={0} w="full">
      <VStack
        alignItems="center"
        justifyContent="center"
        w="100%"
        bgColor="complementary.whiteGrey"
        bgImage={imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
        position="relative"
        flexShrink={0}
        h="296px"
      >
        {!imagePreview ? (
          <>
            <BiImage size={30} />
            <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
              Tu pojawi się dodane zdjęcie
            </Text>
          </>
        ) : null}
        {showStats && <ShowStatsIcon data={formValues} />}
      </VStack>
      <Flex
        flexDirection="row"
        w="100%"
        bgColor="complementary.lightGrey"
        minH="104px"
        p="16px"
        position="relative"
        justifyContent="space-between"
      >
        <Text
          fontSize={14}
          lineHeight="24px"
          color={formValues.textColor || '#000000'}
          dangerouslySetInnerHTML={{
            __html: formValues.content || 'Tu pojawi się twój opis',
          }}
        />

        {formValues.buttonText ? (
          <Text
            as="span"
            fontSize={12}
            textDecoration="underline"
            color={formValues.buttonTextColor}
            fontWeight={700}
            padding="10px 20px"
            textTransform="uppercase"
            height="auto"
            alignSelf="center"
          >
            {formValues.buttonText}
          </Text>
        ) : null}
      </Flex>
    </VStack>
  );
}

export default TextBannerImagePreview;
