import { useMemo, useState } from 'react';
import { Text, VStack, Flex, Box, Image } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { PreviewComponentProps } from 'types/dashboardCreator';

import 'swiper/css';
import 'swiper/css/free-mode';

import { getCredentials } from 'utils/auth';
import { useFetchChosenForYouQuery } from 'app/services/dashboardApi';
import LoadingCover from 'components/LoadingCover';

const StyledSlide = styled(SwiperSlide)<{
  width: string | number;
}>`
  align-self: flex-end;
  width: ${({ width }) => width}px;
`;

const zoomInAnimation = {
  start: { height: 223, width: 223 },
  onZoom: {
    height: 390,
    width: 272,
    transition: {
      height: {
        duration: 0.4,
      },
    },
  },
  ofZoom: {
    height: 304,
    width: 223,

    transition: {
      height: {
        duration: 0.2,
      },
    },
  },
};

enum BUTTON {
  arrow = 'arrow',
  label = 'label',
}

function ChosenForYouPreview({ formValues }: PreviewComponentProps) {
  const isZoomIn = formValues?.zoomIn;
  const [isActiveSlide, setActiveSide] = useState(0);
  const { token } = useMemo(() => getCredentials(), []);
  const { data: items, isFetching } = useFetchChosenForYouQuery(token);

  return (
    <Box maxW={388} pos="relative">
      {isFetching ? <LoadingCover /> : ''}
      {formValues.title ? (
        <Flex
          justifyContent="space-between"
          alignItems="center"
          pb={6}
          pl={8}
          pr={4}
        >
          <Text
            display="inline-block"
            minH={6}
            fontSize="16px"
            fontWeight={700}
            maxW="50%"
            textTransform="uppercase"
            dangerouslySetInnerHTML={{
              __html: formValues.title || '',
            }}
          />
          {formValues.buttonKind === BUTTON.arrow && (
            <AiOutlineArrowRight size={16} />
          )}
          {formValues.buttonKind === BUTTON.label && (
            <Text fontSize="12px" fontWeight={700} textTransform="uppercase">
              {formValues.buttonText}
            </Text>
          )}
        </Flex>
      ) : null}
      <Swiper
        onSlideChange={(s) => {
          setActiveSide(s.activeIndex);
        }}
        watchSlidesProgress
        slidesPerView="auto"
        height={462}
        observer
        observeSlideChildren
      >
        {items?.length
          ? items.map((item, idx) => (
              <StyledSlide
                key={`slide_${+item.id}`}
                width={isActiveSlide === idx && isZoomIn ? 281 : 231}
              >
                <Flex
                  flexDir="column"
                  justifyContent="flex-end"
                  padding={
                    isActiveSlide === idx && isZoomIn
                      ? '86px 8px 0 0'
                      : '0 8px 0 0'
                  }
                >
                  <motion.div
                    key={`slider-image_${item.id}`}
                    variants={zoomInAnimation}
                    initial="start"
                    animate={
                      isActiveSlide === idx && isZoomIn ? 'onZoom' : 'ofZoom'
                    }
                  >
                    <Image
                      src={item.image.url}
                      alt={item.image.label}
                      w="100%"
                      h="100%"
                      objectFit="cover"
                      zIndex={1}
                    />
                  </motion.div>
                  <Text fontSize={14} h="48px" lineHeight="24px">
                    {item.name}
                  </Text>
                  <Text fontSize={16} lineHeight="24px" fontWeight={600}>
                    {item.price.regularPrice.amount.value}
                    {item.price.regularPrice.amount.currency}
                  </Text>
                </Flex>
              </StyledSlide>
            ))
          : [...Array(3)].map((_v, idx) => (
              <StyledSlide
                key={`blank_image_${+idx}`}
                width={isActiveSlide === idx && isZoomIn ? 281 : 231}
              >
                <Flex
                  flexDir="column"
                  justifyContent="flex-end"
                  padding={
                    isActiveSlide === idx && isZoomIn
                      ? '86px 8px 0 0'
                      : '0 8px 0 0'
                  }
                >
                  <motion.div
                    key={`slider-image_${+idx}`}
                    variants={zoomInAnimation}
                    initial="start"
                    animate={
                      isActiveSlide === idx && isZoomIn ? 'onZoom' : 'ofZoom'
                    }
                  >
                    <VStack
                      bgColor="complementary.whiteGrey"
                      justifyContent="center"
                      position="relative"
                      alignItems="center"
                      w="full"
                      h="full"
                    >
                      <Text
                        position="absolute"
                        fontWeight={700}
                        fontSize="12px"
                        left={4}
                        top={2}
                      >
                        {idx + 1}
                      </Text>
                      <BiImage size={30} />
                      <Text
                        fontSize="14px"
                        fontWeight={700}
                        textAlign="center"
                        pt={3}
                      >
                        Zdjęcie produktu
                      </Text>
                    </VStack>
                  </motion.div>
                </Flex>
              </StyledSlide>
            ))}
      </Swiper>
    </Box>
  );
}

export default ChosenForYouPreview;
