/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Box, Flex } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useFieldArray } from 'react-hook-form';
import AddButton from 'components/AddButton';
import ROUTES from 'app/routes';

import {
  CREATE_SCHEMA,
  EMPTY_CONTAINER,
} from 'pages/LandingPage/Form/constants';
import { transformErrors } from 'utils/api';
import DndWrapper from 'components/DndWrapper';
import { FORM_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import HeaderForm from 'pages/BlogPost/Form/components/HeaderForm';
import ContainerWrapper from 'pages/LandingPage/Form/Create/components/ContainerWrapper';
import { useCountriesQuery } from 'app/services/commonApi';
import { LABEL_DICT } from 'components/CountryChange/dictionary';
import {
  useCreateBlogPostAsyncMutation,
  useCreateBlogPostMutation,
} from 'app/services/blogPostsApi';
import LandingPagePreview from 'pages/LandingPage/components/LandingPagePreview';
import { DEFAULT_VALUES } from 'pages/BlogPost/Form/constants';
import useRoute from 'utils/useRoute';

function BlogPostCreate() {
  const { data: { data: stores } = { data: [] } } = useCountriesQuery();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.blogPost.base);

  let debounce: ReturnType<typeof setTimeout>;
  const [createBlogPost, { isLoading: isCreating }] =
    useCreateBlogPostMutation();
  const [createBlogPostAsync] = useCreateBlogPostAsyncMutation();

  const methods = useFormWithSchema(CREATE_SCHEMA, {
    defaultValues: DEFAULT_VALUES,
  });

  const { handleSubmit, control, setError } = methods;

  const {
    fields: containers,
    append,
    remove: removeComponentHandler,
    move,
  } = useFieldArray({
    control,
    name: 'containersAttributes',
    keyName: 'fieldId',
    shouldUnregister: true,
  });

  const addContainerHandler = useCallback(() => {
    append({ ...EMPTY_CONTAINER, name: `KONTENER ${containers.length + 1}` });
  }, [append, containers]);

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const { countries: countriesToBeAdded } = data;

      data.containersAttributes?.map((container: any) => {
        if (!container.withListingHeader) {
          container.bannerListingsAttributes =
            container.bannerListingsAttributes?.map((attribute: any) => ({
              ...attribute,
              _destroy: true,
            }));
        }
      });

      const response: any = await createBlogPost(data);

      let errorOccured = false;
      if (Object.hasOwnProperty.call(response, 'error')) {
        const {
          error: { data: errors },
        } = response;
        const transformedErrors = transformErrors(errors, null);
        Object.keys(transformedErrors).forEach((field) => {
          errorOccured = true;
          if (field === 'containers') {
            const {
              containers: containersErrors,
            }: { containers: { [key: string]: string[] }[] } = errors;
            containersErrors.forEach((object, index) => {
              const nestedTransformedErrors = transformErrors(object, null);
              Object.keys(nestedTransformedErrors).forEach((nestedField) => {
                setError(
                  `containersAttributes[${index}].${nestedField}` as any,
                  {
                    type: 'custom',
                    message: nestedTransformedErrors[nestedField],
                  },
                );
              });
            });
          } else {
            setError(field as any, {
              type: 'custom',
              message: transformedErrors[field],
            });
          }
        });
      }
      if (errorOccured === false) {
        if (countriesToBeAdded && countriesToBeAdded.length) {
          countriesToBeAdded!.forEach((countryCode: string) => {
            const { store: storeItem } = stores.find(
              (s) => s.countryCode === countryCode,
            )!;
            createBlogPostAsync({ ...data, store: storeItem }).unwrap();
          });
          toast.success(
            `Blog post został pomyślnie utworzony i jest obecnie klonowany do: ${countriesToBeAdded.map(
              (c: keyof typeof LABEL_DICT) => ` ${LABEL_DICT[c]}`,
            )}`,
          );
        } else {
          toast.success('Blog post został pomyślnie utworzony');
        }

        navigate(backPath);
      }
    } catch (error) {
      toast.error('Wystąpił problem podczas tworzenia blog post');
      // eslint-disable-next-line
      console.log({ error });
    }
  });

  const reorderContainers = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      clearTimeout(debounce);
      // eslint-disable-next-line
      debounce = setTimeout(() => move(dragIndex, hoverIndex), 500);
    },
    [move],
  );

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Blog Post',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj blog post' }]}
      />
      <FormProvider {...methods}>
        <Flex
          as="form"
          id="blog-post-editor"
          onSubmit={onSubmit}
          direction="row"
          gap={4}
          w="100%"
          pb="100px"
        >
          <Box maxW={FORM_MAX_WIDTH} minW={650} w="100%">
            <HeaderForm />
            {isCreating && <FormSpinner />}
            {containers.map((container, index) => (
              <DndWrapper
                key={`blog-post-editor-${container.fieldId}`}
                id={container.fieldId}
                isLoading={isCreating}
                index={index}
                reorderContainers={reorderContainers}
                removeContainer={removeComponentHandler}
                ContainerWrapper={ContainerWrapper}
                multipleContainers
                isBlogPost
              />
            ))}
            <AddButton addHandler={addContainerHandler} mt="8px" />
          </Box>
          <Box w="100%" gap="10px">
            <LandingPagePreview />
          </Box>
        </Flex>
      </FormProvider>
      <FooterButtons
        isCreate
        isLoading={isCreating}
        formId="blog-post-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default BlogPostCreate;
