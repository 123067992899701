import { useCallback, useEffect } from 'react';
import { Box, Text, VStack, Button } from '@chakra-ui/react';
import { useLazyProductImagesQuery } from 'app/services/dashboardApi';
import { PreviewComponentProps } from 'types/dashboardCreator';
import ProductPlaceholder from 'components/Container/Previews/ProductsListPreview/ProductPlaceholder';
import ProductWrapper from 'components/Container/Previews/ProductsListPreview/ProductWrapper';
import { prepareQueryParamsFromLinkAttributes } from 'utils/helpers';
import { useIsDashboardPreview } from 'app/routes';

function ProductsListPreview({ formValues }: PreviewComponentProps) {
  const isDashboardPreview = useIsDashboardPreview();

  const { queryLink } = formValues;

  const [fetchProducts, { data, isSuccess, isFetching }] =
    useLazyProductImagesQuery();

  useEffect(() => {
    if (queryLink) {
      fetchProducts(queryLink);
    }
  }, [fetchProducts, queryLink]);

  const updateProducts = useCallback(async () => {
    if (formValues.linkParametersAttributes?.length) {
      const linkParams = prepareQueryParamsFromLinkAttributes(
        formValues.linkParametersAttributes,
      );

      await fetchProducts(linkParams);
    }
  }, [fetchProducts, formValues.linkParametersAttributes]);

  return (
    <Box minW={200} w="full" px={4}>
      {!isDashboardPreview ? (
        <Button mb={9} w="100%" onClick={updateProducts} isLoading={isFetching}>
          ODŚWIEŻ PODGLĄD
        </Button>
      ) : null}
      <Box minH={6} mb={4}>
        <Text
          fontWeight={700}
          fontSize="16px"
          textTransform="uppercase"
          dangerouslySetInnerHTML={{
            __html: formValues.title || '',
          }}
        />
      </Box>

      <VStack spacing={4}>
        {isSuccess
          ? data?.products.map((product, idx) => (
              <ProductWrapper
                key={`product_image${+idx}`}
                imageSrc={product.appImage.url}
                imageLabel={product.appImage.label}
                title={product.name}
                price={product.price.regularPrice.amount}
                isNew={product.new}
                isBestseller={product.bestseller}
              />
            ))
          : Array.from({ length: 3 }, (_, idx) => (
              <ProductPlaceholder idx={idx} key={`blank_image${+idx}`} />
            ))}
      </VStack>
      {(formValues.buttonText || formValues.buttonColor) && (
        <Button
          mt={4}
          w="full"
          type="button"
          variant="solid"
          fontWeight={700}
          textTransform="uppercase"
          color={formValues.buttonTextColor}
          bgColor={formValues.buttonColor || 'transparent'}
        >
          {formValues.buttonText}
        </Button>
      )}
    </Box>
  );
}
export default ProductsListPreview;
