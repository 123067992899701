import { IconButton } from '@chakra-ui/react';
import { MdOutlineCancel } from 'react-icons/md';
import { ToastContentProps } from 'react-toastify';

function CloseButton({ closeToast }: Partial<ToastContentProps>) {
  return (
    <IconButton onClick={closeToast} aria-label="close_button" variant="ghost">
      <MdOutlineCancel size="19px" />
    </IconButton>
  );
}
export default CloseButton;
