import { Routes, Route } from 'react-router-dom';
import PrivateRoutes from 'components/PrivateRoutes';
import { PRIVATE_ROUTES, PUBLIC_ROUTES, renderRoutes } from 'app/routesConfig';
import ToastContainer from 'components/Toast';
import CloseButton from 'components/Toast/CloseButton';

function App() {
  return (
    <>
      <Routes>
        <Route element={<PrivateRoutes />}>
          {renderRoutes(PRIVATE_ROUTES)}
        </Route>
        {renderRoutes(PUBLIC_ROUTES)}
      </Routes>
      <ToastContainer
        style={{ width: '75%' }}
        closeButton={<CloseButton />}
        hideProgressBar
        position="bottom-center"
        icon={false}
      />
    </>
  );
}

export default App;
