import { FormControl, Stack } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import SectionTitle from 'components/Form/SectionTitle';
import DropzoneField from 'components/Form/Dropzone';
import Checkbox from 'components/Form/Checkbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Input
        name={prepareFieldName('specialElementsHeader')}
        label="Nagłówek"
      />
      <FormControl as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Ikona</SectionTitle>

        <DropzoneField
          name={prepareFieldName('specialElementsIcon')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 360px x 396px)"
        />
        <Checkbox
          name={prepareFieldName('deleteSpecialElementsIcon')}
          label="Usuń ikonę"
          pb="30px"
          defaultChecked={false}
        />
      </FormControl>
    </Stack>
  );
}

export default FormBody;
