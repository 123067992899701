import { FormControl, HStack, Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import ActivityFormSection from 'components/ActivityFieldset';
import ColorPicker from 'components/Form/Color';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import RichText from 'components/Form/RichText';
import SectionTitle from 'components/Form/SectionTitle';
import DropzoneField from 'components/Form/Dropzone';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

const BANNER_TYPES: { [key: string]: string } = {
  TEXT: 'text',
  IMAGE: 'image',
};

const BANNER_TYPES_LABELS: { [key: string]: string } = {
  text: 'Tekst',
  image: 'Zdjęcie',
};

function FormBody({ prepareFieldName }: FormBodyProps) {
  const { control, setValue } = useFormContext();

  const bannerType = useWatch({
    control,
    name: prepareFieldName('type'),
  });

  useEffect(() => {
    if (bannerType === BANNER_TYPES.TEXT) {
      setValue(prepareFieldName('image'), null);
    }
    if (bannerType === BANNER_TYPES.IMAGE) {
      setValue(prepareFieldName('title'), null);
      setValue(prepareFieldName('textColor'), null);
    }
  }, [bannerType, prepareFieldName, setValue]);

  return (
    <Stack spacing={8}>
      <FormControl as="fieldset">
        <SectionTitle as="legend">Wybierz typ</SectionTitle>
        <HStack justifyContent="flex-start">
          {Object.values(BANNER_TYPES).map((t: string) => (
            <RadioBoxControl
              key={`banner-type_${t}`}
              label={BANNER_TYPES_LABELS[t]}
              name={prepareFieldName('type')}
              id={`${prepareFieldName('type')}_${t}`}
              value={t}
            />
          ))}
        </HStack>
      </FormControl>
      {bannerType === BANNER_TYPES.TEXT && (
        <>
          <RichText name="title" label="Tytuł" placeholder="Wpisz tytuł" />
          <ColorPicker
            name={prepareFieldName('textColor')}
            label="Kolor tekstu"
            showError
          />
        </>
      )}
      {bannerType === BANNER_TYPES.IMAGE && (
        <FormControl as="fieldset">
          <SectionTitle as="legend">Zdjęcie</SectionTitle>
          <DropzoneField
            name={prepareFieldName('image')}
            title="Dodaj zdjęcie w formacie .png, .jpg (min. 160px x 36px)"
          />
        </FormControl>
      )}
      <ColorPicker
        name={prepareFieldName('backgroundColor')}
        label="Kolor tła"
        showError
      />
      <ActivityFormSection prepareFieldName={prepareFieldName} />
    </Stack>
  );
}

export default FormBody;
