/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

export const schema = yup.object({
  date: yup.object({
    start: yup.date().nullable(),
    end: yup.date().nullable(),
  }),
  store: yup.string().nullable(),
});
