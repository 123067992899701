import { useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { TbGridDots, TbListSearch } from 'react-icons/tb';
import { AiOutlineHeart, AiOutlineShopping } from 'react-icons/ai';
import { Box, Flex, HStack, VStack, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Search from 'components/Icons/Search';
import Card from 'components/Card';
import SectionTitle from 'components/Form/SectionTitle';
import {
  Previews,
  GENDER,
  CONTAINERS_WITHOUT_STATS,
} from 'components/Container/constants';

import BrandLogo from 'components/Icons/4F';
import PreviewFallback from 'pages/Dashboard/Edit/components/PreviewFallback';
import PreviewWrapper from 'pages/Dashboard/Edit/components/PreviewWrapper';
import { Dashboard } from 'types/dashboard';

interface MobilePreviewProps {
  containers: Dashboard[];
  name: string;
  sectionTitle?: string;
  sectionSubtitle?: string;
}

function MobilePreview({
  containers,
  name,
  sectionTitle,
  sectionSubtitle,
}: MobilePreviewProps) {
  const { control } = useFormContext();
  const currentGender = useWatch({ control, name });
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Card maxW={452} padding="32px" style={{ position: 'sticky', top: '65px' }}>
      <SectionTitle as="legend" mb="0" textTransform="uppercase">
        {sectionTitle}
      </SectionTitle>
      {sectionSubtitle && (
        <Text
          mb="40px"
          mt="24px"
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
        >
          {sectionSubtitle}
        </Text>
      )}

      <Box
        ref={containerRef}
        mt={8}
        maxW={388}
        maxH="calc(100vh - 180px)"
        borderRadius="16px"
        border="solid 1px #000"
        position="relative"
        overflowX="hidden"
        overflowY="auto"
      >
        <Flex
          bgColor="#fff"
          zIndex={10}
          position="sticky"
          alignItems="center"
          justifyContent="space-between"
          top={0}
          p={4}
        >
          <HStack spacing={4}>
            {(Object.keys(GENDER) as Array<keyof typeof GENDER>).map((key) =>
              key !== 'all' ? (
                <Text
                  key={`_${key}`}
                  fontSize="16px"
                  fontWeight={currentGender === key ? 600 : 400}
                >
                  {GENDER[key]}
                </Text>
              ) : null,
            )}
          </HStack>
          <Search />
        </Flex>

        <VStack cursor="pointer" spacing={0}>
          {!isEmpty(containers) ? (
            containers.map((item, idx: number) => {
              if (!Object.keys(Previews).includes(item.kind)) {
                return (
                  <PreviewWrapper
                    margined={item.margined}
                    marginColor={item.marginColor || '#ffffff'}
                    key={`preview-${item.id}`}
                  >
                    <PreviewFallback
                      key={`preview-default-edit-${+idx}`}
                      kind={item.kind}
                      text="Brak Podglądu"
                    />
                  </PreviewWrapper>
                );
              }

              const PreviewComponent = Previews[item.kind];

              if (item.kind === 'image_gallery') {
                return (
                  <PreviewWrapper
                    margined={item.margined}
                    marginColor={item.marginColor || '#ffffff'}
                    key={`preview-${item.id}`}
                  >
                    <PreviewComponent
                      key={`preview-image-gallery${+idx}`}
                      formValues={item}
                      maxW={386}
                      showStats
                    />
                  </PreviewWrapper>
                );
              }

              if (item.kind === 'paralax_banner') {
                return (
                  <PreviewWrapper
                    margined={item.margined}
                    marginColor={item.marginColor || '#ffffff'}
                    key={`preview-${item.id}`}
                  >
                    <PreviewComponent
                      key={`preview-edit-parallax${+idx}`}
                      formValues={item}
                      innerRef={containerRef}
                      showStats
                    />
                  </PreviewWrapper>
                );
              }

              return (
                <PreviewWrapper
                  margined={item.margined}
                  marginColor={item.marginColor || '#ffffff'}
                  key={`preview-${item.id}`}
                >
                  <PreviewComponent
                    key={`preview-edit-${+idx}`}
                    formValues={item}
                    showStats={!CONTAINERS_WITHOUT_STATS.includes(item.kind)}
                  />
                </PreviewWrapper>
              );
            })
          ) : (
            <PreviewFallback text="Brak Podglądu" />
          )}
        </VStack>

        <Flex
          position="sticky"
          zIndex={10}
          bottom={0}
          bgColor="#fff"
          justifyContent="space-between"
          alignItems="stretch"
          px={8}
          pt={6}
          pb="2px"
        >
          <Box borderBottom="solid 2px #000" pb="22px">
            <BrandLogo boxSize="26px" />
          </Box>
          <TbListSearch size={24} color="#a9a9a9" />
          <AiOutlineHeart size={24} color="#a9a9a9" />
          <AiOutlineShopping size={24} color="#a9a9a9" />
          <TbGridDots size={24} color="#a9a9a9" />
        </Flex>
      </Box>
    </Card>
  );
}

MobilePreview.defaultProps = {
  sectionTitle: 'Podgląd aktualnego dashboardu',
  sectionSubtitle: '',
};

export default MobilePreview;
