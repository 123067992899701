/* eslint-disable func-names, no-underscore-dangle */
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import { matchRoutes } from 'react-router-dom';
import { compact, isString, round } from 'lodash';
import dot from 'dot-object';
import { ApiError } from 'types/api';
import { ContainerObject, LinkParameters } from 'types/common';
import { ROUTES_FOR_MATCH } from 'app/routes';

function parseApiError(error: ApiError) {
  const firstError = error.data.errors[0];
  return { status: error.status, msg: firstError };
}

const convertKeysToSnakeCase = (obj: any): any => {
  if (isArray(obj)) {
    return obj.map((v) => convertKeysToSnakeCase(v));
  }
  if (isPlainObject(obj)) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [`${key.startsWith('_') ? '_' : ''}${snakeCase(key)}`]:
          convertKeysToSnakeCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const convertKeysToCamelCase = (obj: any): any => {
  if (isString(obj)) {
    return obj;
  }
  if (isArray(obj)) {
    return obj.map((v) => convertKeysToCamelCase(v));
  }
  if (isPlainObject(obj)) {
    const convertedObject = dot.object(obj);
    return Object.keys(convertedObject).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: convertKeysToCamelCase(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const objectHasProperty = (
  obj: { [key: string | number]: any },
  property: string,
): boolean => Object.prototype.hasOwnProperty.call(obj, property);

const fetchFileFromUrl = async (url: string) => {
  const blob = await fetch(url)
    .then((res) => res.blob())
    .then((file) => URL.createObjectURL(file))
    .catch(() => {
      // eslint-disable-next-line
      console.log('Failed to download file!');
    });

  return blob;
};

const prepareQueryParamsFromLinkAttributes = (attributes: LinkParameters[]) => {
  const link: string[] = [];
  attributes.forEach((linkAttribute: LinkParameters) => {
    const { linkType, linkParamValue } = { ...linkAttribute };

    // eslint-disable-next-line no-underscore-dangle
    if (linkType && !linkAttribute._destroy) {
      if (linkType.includes('param_value') && linkParamValue) {
        let linkParams = '';
        const [paramName] = linkType.split('=');

        if (Array.isArray(linkParamValue)) {
          linkParams = `${paramName}=${linkParamValue.join(`&${paramName}=`)}`;
        } else {
          linkParams = linkType.replace('param_value', linkParamValue);
        }

        link.push(linkParams);
      } else {
        link.push(linkType);
      }
    }
  });

  return link.join('&');
};

const transformLinksAttributesForPayload = (object: ContainerObject) => {
  const newObject = { ...object };

  if (object.linkParametersAttributes) {
    if (object?._destroy) {
      newObject.linkParametersAttributes = compact(
        newObject.linkParametersAttributes,
      );
    }

    // remove empty link parameters attributes
    newObject.linkParametersAttributes?.forEach(
      (linkParameters: any, idx: number) => {
        if (!linkParameters.linkType) {
          if (linkParameters?.id) {
            newObject.linkParametersAttributes[idx]._destroy = true;
          } else {
            delete newObject.linkParametersAttributes[idx];
          }
        }
      },
    );

    newObject.linkParametersAttributes = object.linkParametersAttributes.map(
      (linkParameters: LinkParameters) => {
        if (Array.isArray(linkParameters.linkParamValue)) {
          return {
            ...linkParameters,
            linkParamValue: linkParameters.linkParamValue.join(','),
          };
        }

        return linkParameters;
      },
    );
  }

  if (
    object.bannerListingsAttributes &&
    object.bannerListingsAttributes.length
  ) {
    if (object.bannerListingsAttributes[0]?.linkParametersAttributes) {
      newObject.bannerListingsAttributes[0].linkParametersAttributes =
        object.bannerListingsAttributes[0].linkParametersAttributes.map(
          (linkParameters: LinkParameters) => {
            if (Array.isArray(linkParameters.linkParamValue)) {
              return {
                ...linkParameters,
                linkParamValue: linkParameters.linkParamValue.join(','),
              };
            }

            return linkParameters;
          },
        );
    } else {
      // if threre's no more banner listing - remove it
      newObject.bannerListingsAttributes[0] = {
        ...object.bannerListingsAttributes[0],
        _destroy: true,
      };
    }
  }

  if (object?.photoTagsAttributes && object?.photoTagsAttributes?.length) {
    if (object.photoTagsAttributes[0]?.linkParamValue) {
      newObject.photoTagsAttributes[0].linkType = 'skus[]=param_value';
    } else {
      newObject.photoTagsAttributes[0]._destroy = true;
    }
  }

  return newObject;
};

const getStoreParam: () => string = () => {
  const match = matchRoutes(ROUTES_FOR_MATCH, window.location.pathname);

  if (match && match.length) {
    return match[0].params.store || 'default';
  }

  return 'default';
};

const getVideoRatio = (file: File) => {
  return new Promise((resolve) => {
    const url = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.src = url;
    video.addEventListener('loadedmetadata', function () {
      const ratio = round(this.videoWidth / this.videoHeight, 2);
      resolve(ratio);
    });
  });
};

const transformRemoved = (containers: any[]) => {
  return containers.map((container) => {
    return {
      ...container,
      _destroy: true,
    };
  });
};

export {
  convertKeysToSnakeCase,
  convertKeysToCamelCase,
  parseApiError,
  objectHasProperty,
  fetchFileFromUrl,
  prepareQueryParamsFromLinkAttributes,
  transformLinksAttributesForPayload,
  getStoreParam,
  getVideoRatio,
  transformRemoved,
};
