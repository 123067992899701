import { memo } from 'react';
import get from 'lodash/get';
import { MdOutlineCheck } from 'react-icons/md';
import { Box, Flex, Text } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { Country } from 'types/common';
import { ICON_DICT, LABEL_DICT } from 'components/CountryChange/dictionary';

interface CountryItemProps {
  country: Country;
}

function CountryItem({ country }: CountryItemProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = get(errors, 'targetStore')?.message as string;

  return (
    <Controller
      control={control}
      name="targetStore"
      render={({ field: { onChange, value: formValue = [] } }) => (
        <Flex
          direction="row"
          h="48px"
          w="208px"
          align="center"
          mb="28px"
          onClick={() => {
            if (formValue.includes(country.store))
              onChange(
                formValue.filter((stores: string) => country.store !== stores),
              );
            else onChange([...formValue, country.store]);
          }}
        >
          {ICON_DICT[country.countryCode]}
          <Text
            w="100%"
            textAlign="left"
            fontSize="16px"
            paddingLeft="16px"
            fontWeight={formValue.includes(country.countryCode) ? 600 : 400}
            color={errorMessage ? 'complementary.red' : '#000000'}
          >
            {LABEL_DICT[country.countryCode]}
          </Text>
          {formValue.includes(country.store) && (
            <Box marginRight="12px">
              <MdOutlineCheck size={18} />
            </Box>
          )}
        </Flex>
      )}
    />
  );
}

export default memo(CountryItem);
