import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link, generatePath, useParams } from 'react-router-dom';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import BackNavigationButton from 'components/BackNavigationButton';

type HeaderNavigationProps = {
  name?: string;
};

function HeaderNavigation({ name }: HeaderNavigationProps) {
  const { store } = useParams();
  const { queryString } = useQueryParams();

  const backPath =
    generatePath(ROUTES.appStoriesInspirations.base, {
      store,
    }) + queryString;

  return (
    <Box mb={6}>
      <BackNavigationButton />
      <Breadcrumb mt={4}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={backPath} fontWeight={700}>
            Inspiracje (app stories)
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>
            Edytuj {name && `(${name?.toLowerCase()})`}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
}

HeaderNavigation.defaultProps = {
  name: '',
};

export default HeaderNavigation;
