import { Link } from 'react-router-dom';
import ROUTES from 'app/routes';
import {
  useDeleteSubscriptionRunupScreenMutation,
  useSubscriptionRunupScreensQuery,
} from 'app/services/subscriptionRunupScreensApi';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/SubscriptionRunupScreens/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import useRoute from 'utils/useRoute';

function SubscriptionRunupScreens() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newSubscriptionRunupScreen = useRoute(
    ROUTES.subscriptionRunupScreens.create,
  );

  const [, state] = useDeleteSubscriptionRunupScreenMutation({
    fixedCacheKey: 'subscriptionRunupScreens',
  });

  const {
    isFetching: subscriptionRunupScreensLoading,
    data: { data: subscriptionRunupScreens, totals } = { data: [], totals: 0 },
  } = useSubscriptionRunupScreensQuery(queryString);

  const { isLoading } = state;
  const showLoading = subscriptionRunupScreensLoading || isLoading;

  return (
    <ListingLayout
      title="Ekran rozbiegówki"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWY EKRAN ROZBIEGÓWKI',
          as: Link,
          to: newSubscriptionRunupScreen,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={subscriptionRunupScreens}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default SubscriptionRunupScreens;
