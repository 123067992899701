import { Box, Flex } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { Category } from 'types/category';

function Columns(): ColumnDef<Category, string>[] {
  return [
    {
      header: 'KATEGORIA NADRZĘDNA',
      accessorKey: 'externalCategoryId',
      cell: ({ row: { original } }) => (
        <Flex gap="8px">
          <Flex fontSize="16px" fontWeight={600} color="complementary.grey">
            {original.name}
          </Flex>
          {original.id}
        </Flex>
      ),
    },
    {
      header: 'NAZWA',
      accessorKey: '1',
      cell: () => <Box minW="100px" />,
    },
    {
      header: 'KATEGORIA W MAGENTO',
      accessorKey: '2',
      cell: () => <Box minW="83px" />,
    },
    {
      header: 'POZYCJA',
      accessorKey: '3',
      cell: () => '',
    },
    {
      header: 'STATUS',
      accessorKey: '4',
      cell: () => <Box minW="128px" />,
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: '5',
      cell: () => <Box minW="118px" />,
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: '6',
      cell: () => <Box minW="118px" />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default Columns;
