import { Flex, IconButton } from '@chakra-ui/react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { MdKeyboardArrowUp } from 'react-icons/md';

interface ComponentActionsProps {
  showTrashButton: boolean;
  toggleAction: () => void;
  removeAction: () => void;
  isOpen: boolean;
}

function ComponentActions({
  showTrashButton,
  toggleAction,
  removeAction,
  isOpen,
}: ComponentActionsProps) {
  return (
    <Flex position="absolute" zIndex={5} right={8} top={6}>
      {showTrashButton && (
        <IconButton
          aria-label="remove section"
          icon={<FaRegTrashAlt size={20} />}
          variant="unstyled"
          size="md"
          onClick={removeAction}
        />
      )}
      <IconButton
        aria-label="toggle section"
        icon={<MdKeyboardArrowUp size={24} />}
        variant="unstyled"
        size="md"
        onClick={toggleAction}
        sx={{
          transform: `rotate(${isOpen ? 0 : 180}deg)`,
        }}
      />
    </Flex>
  );
}

export default ComponentActions;
