import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Surveys/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Surveys/Form/constants';
import useRoute from 'utils/useRoute';
import {
  useSurveyQuery,
  useUpdateSurveyMutation,
} from 'app/services/surveysApi';
import { transformLinkParameters } from 'utils/linkCreator';

function SurveyEdit() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.surveys.base);

  const { data: survey = {}, isFetching, isSuccess } = useSurveyQuery(id);

  const [updateSurvey, { isLoading: updateRunning }] =
    useUpdateSurveyMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateSurvey({ ...data, id }).unwrap();
      navigate(backPath);
      toast.success(`Zmiany w quizie zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (isSuccess) {
      reset(transformLinkParameters({ ...DEFAULT_VALUES, ...survey }));
    }
  }, [isSuccess, reset, survey]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Quizy',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj quiz' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="survey-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="survey-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default SurveyEdit;
