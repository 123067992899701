import { Text, Box } from '@chakra-ui/react';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { PreviewComponentProps } from 'types/dashboardCreator';

function CounterPreview({ formValues, showStats }: PreviewComponentProps) {
  return (
    <Box
      bgColor={formValues?.backgroundColor || 'complementary.whiteGrey'}
      padding="16px"
      position="relative"
    >
      {showStats && <ShowStatsIcon data={formValues} />}
      <Box
        w="100%"
        textAlign={formValues?.titleAlignment || 'center'}
        fontSize={formValues?.titleFontSize || 12}
      >
        {formValues?.content && (
          <Text
            fontSize="inherit"
            mb={2}
            color={formValues?.contentTextColor || 'inherit'}
          >
            {formValues.content}
          </Text>
        )}
        <Text
          as="span"
          bgColor={formValues?.textBackground || 'transparent'}
          paddingX="8px"
          fontWeight={700}
          color={formValues?.textColor || 'inherit'}
          fontSize="inherit"
        >
          DD : MM : SS
        </Text>
      </Box>
    </Box>
  );
}

export default CounterPreview;
