import { HStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import Date from 'components/Form/Date';
import Time from 'components/Form/Time';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

interface ActivityFormSectionProps {
  prepareFieldName: (name: string) => string;
  fullWidth?: boolean;
}

function ActivityFormSection({
  prepareFieldName,
  fullWidth,
}: ActivityFormSectionProps) {
  const { control } = useFormContext();
  const isActive: boolean = useWatch({
    control,
    name: prepareFieldName('active'),
  });

  return (
    <OptionalFormFieldset
      title="Aktywność"
      isActive={isActive}
      name={prepareFieldName('active')}
      sectionWidth={fullWidth ? '100%' : FORM_BODY_MAX_WIDTH}
      mt="20px"
    >
      <HStack spacing={4} mb={6}>
        <Date
          name={prepareFieldName('dateFrom')}
          label="Aktywny od"
          showError
          rangeSelect={false}
        />
        <Date
          name={prepareFieldName('dateTo')}
          label="Aktywny do"
          showError
          rangeSelect={false}
        />
      </HStack>
      <HStack spacing={4} mb={6}>
        <Time
          name={prepareFieldName('timeFrom')}
          label="Godzina rozpoczęcia"
          errorKey={prepareFieldName('activeDates')}
          showError
        />
        <Time
          name={prepareFieldName('timeTo')}
          label="Godzina zakończenia"
          errorKey={prepareFieldName('activeDates')}
          showError
        />
      </HStack>
    </OptionalFormFieldset>
  );
}

ActivityFormSection.defaultProps = {
  fullWidth: false,
};

export default ActivityFormSection;
