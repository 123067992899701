import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { GENDER } from 'components/Container/constants';
import BackNavigationButton from 'components/BackNavigationButton';

type HeaderNavigationProps = {
  gender: string;
  categoryPath: string;
};

function HeaderNavigation({ gender, categoryPath }: HeaderNavigationProps) {
  const genderName = GENDER[gender as keyof typeof GENDER];

  return (
    <Box mb={6}>
      <BackNavigationButton />
      <Breadcrumb mt={4}>
        {genderName && (
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={categoryPath} fontWeight={700}>
              {genderName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={categoryPath} fontWeight={700}>
            Kategorie
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Edytuj kontener</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
}

export default HeaderNavigation;
