import { useCallback } from 'react';
import { FiLogOut } from 'react-icons/fi';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useLogoutMutation } from 'app/services/authApi';
import { selectEmail, selectIsAuthenticated } from 'selectors/authSelectors';
import { logout } from 'slices/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';

export const NAVBAR_HEIGHT = '64px';

function Navbar() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const email = useAppSelector(selectEmail);
  const [apiLogout] = useLogoutMutation();

  const logoutClickHandler = useCallback(async () => {
    try {
      await apiLogout().unwrap();
      dispatch(logout());
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  }, [apiLogout, dispatch]);

  return (
    <Flex
      as="nav"
      h={NAVBAR_HEIGHT}
      w="100%"
      wrap="wrap"
      columnGap="24px"
      align="center"
      position="fixed"
      left={0}
      top={0}
      paddingX="40px"
      justify="flex-end"
      borderColor="black"
      borderBottomWidth="1px"
      backgroundColor="white"
      zIndex={10}
    >
      <Text variant="header">{email}</Text>
      <Button
        h="100%"
        variant="ghost"
        hidden={!isAuthenticated}
        leftIcon={<FiLogOut size={18} />}
        onClick={logoutClickHandler}
      />
    </Flex>
  );
}

export default Navbar;
