import { Flex, Box, FormControl } from '@chakra-ui/react';
import Card from 'components/Card';
import SectionTitle from 'components/Form/SectionTitle';
import FormSpinner from 'components/FormSpinner';
import DropzoneField from 'components/Form/Dropzone';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';
import FormBody from 'pages/BenefitsScreensElements/Form/components/FormBody';

interface ComponentWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ComponentWrapperProps) {
  return (
    <Flex gap={4} mb={12} alignItems="flex-start">
      <Box flex={1} maxW={FORM_MAX_WIDTH}>
        <Card>
          {isLoading && <FormSpinner />}

          <Box w="100%" maxW={FORM_BODY_MAX_WIDTH}>
            <FormControl as="fieldset" mb={4}>
              <SectionTitle as="legend">Logo</SectionTitle>
              <DropzoneField
                name="image"
                title="Dodaj zdjęcie w formacie .png, .jpg"
              />
            </FormControl>
            <FormBody />
          </Box>
        </Card>
      </Box>
    </Flex>
  );
}

export default ContainerWrapper;
