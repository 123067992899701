import { ColumnDef } from '@tanstack/react-table';
import ColorPreview from 'components/ColorPreview';
import ImagePreview from 'components/ImagePreview';
import { BENEFITS_SCREEN_KIND } from 'pages/BenefitsScreens/Form/constants';
import { BenefitsScreen } from 'types/benefitsScreens';

function GetColumns(): ColumnDef<BenefitsScreen, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'RODZAJ',
      accessorKey: 'kind',
      cell: (info) => BENEFITS_SCREEN_KIND[info.getValue()],
    },
    {
      header: 'TŁO',
      accessorKey: 'background.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'KOLOR TŁA',
      accessorKey: 'backgroundColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'SUMA ELEMENTÓW',
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
