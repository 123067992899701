import { api } from 'app/services/api';

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    users: build.query<TransformedUsersResponse, string>({
      query: (queryString) => `users${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: UsersResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Users'],
    }),
    deleteUser: build.mutation<void, number>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
});

export const { useUsersQuery, useDeleteUserMutation } = usersApi;
