import {
  FormControl,
  CheckboxProps as ChakraCheckboxProps,
  FormLabel,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';
import get from 'lodash/get';

interface CheckboxBoxControlProps extends ChakraCheckboxProps {
  name: string;
  label?: string | React.ReactNode;
  group?: boolean;
  isReadOnly?: boolean;
}

const Label = styled(FormLabel)`
  position: relative;
  cursor: pointer;

  > input {
    position: absolute;
    width: 1px;
    height: 1px;
    left: -9999px;

    &:checked {
      & + span {
        background-color: #0b24fb;
        color: #fff;
      }
    }
  }

  > span {
    display: block;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  }
`;

function CheckboxBoxControl({
  id,
  label,
  name,
  isRequired,
  defaultChecked,
  value,
  group,
}: CheckboxBoxControlProps) {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={Boolean(errorMessage)}
      w="auto"
    >
      <Label htmlFor={id || name} paddingRight="16px">
        <>
          <input
            id={id || name}
            type="checkbox"
            value={value}
            defaultChecked={defaultChecked}
            {...register(name)}
          />
          <Text
            as="span"
            padding="4px 16px"
            fontSize={16}
            display="block"
            border="1px #0b24fb solid"
            borderColor={
              errorMessage ? 'complementary.red' : 'complementary.blue'
            }
          >
            {label}
          </Text>
        </>
      </Label>
      {!group && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}

CheckboxBoxControl.defaultProps = {
  label: '',
  group: false,
  isReadOnly: false,
};

export default CheckboxBoxControl;
