/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';
import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Subcategory/Form/Create/components/ContainerWrapper';
import ROUTES from 'app/routes';
import { transformErrors } from 'utils/api';
import AddButton from 'components/AddButton';
import { FORM_MAX_WIDTH } from 'utils/constants';
import DndWrapper from 'components/DndWrapper';
import {
  BODY_SCHEMA,
  DEFAULT_FORM_VALUE,
} from 'pages/Subcategory/Form/constants';
import {
  useCategoryElementQuery,
  useCreateSubcategoryMutation,
} from 'app/services/subcategoryApi';
import useRoute from 'utils/useRoute';

const schema = yup.object({
  containers: yup.array().of(BODY_SCHEMA),
});

function SubcategoryCreate() {
  const { categoryContainerId = '', taxonomyId = '' } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.subcategory.base, {
    categoryContainerId,
    taxonomyId,
  });

  let debounce: ReturnType<typeof setTimeout>;
  const { data: { data: categoryElement } = {} } = useCategoryElementQuery({
    categoryContainerId,
    taxonomyContainerId: taxonomyId,
  });
  const [createSubcategory, { isLoading: isCreating }] =
    useCreateSubcategoryMutation();

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: { containers: [DEFAULT_FORM_VALUE] },
  });

  const {
    fields: containers,
    append,
    remove: removeContainerHandler,
    move,
  } = useFieldArray({
    control: methods.control,
    name: 'containers',
  });

  const addContainerHandler = useCallback(() => {
    return append(DEFAULT_FORM_VALUE as any);
  }, [append]);

  const { handleSubmit, setError } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await Promise.all(
        data
          .containers!.filter((v: any) => v)
          .map(async (formValues, index) => {
            const response = await createSubcategory({
              taxonomyContainerId: taxonomyId,
              categoryContainerId,
              body: formValues,
            });
            return { ...response, index };
          }),
      );

      let errorOccured = false;
      result.forEach((response: any) => {
        const hasError = Object.hasOwnProperty.call(response, 'error');
        if (hasError) {
          errorOccured = true;
          const {
            error: { data: errors },
            index,
          } = response;
          const transformedErrors = transformErrors(errors, index);
          Object.keys(transformedErrors).forEach((field: string) => {
            setError(`containers[${index}].${field}` as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        }
      });

      if (!errorOccured) {
        navigate(backPath);
        toast.success(
          containers.length > 1
            ? `Podkategorie zostały pomyślnie utworzone!`
            : `Podkategoria została pomyślnie utworzona!`,
        );
      }
    } catch (err) {
      // eslint-disable-next-line
      console.debug(err);
    }
  });

  const reorderContainers = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      clearTimeout(debounce);
      // eslint-disable-next-line
      debounce = setTimeout(() => move(dragIndex, hoverIndex), 500);
    },
    [move],
  );

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: categoryElement?.title || 'Loading...',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Dodaj podkategorię' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="subcategory-creator"
          onSubmit={onSubmit}
          pb="120px"
          maxW={FORM_MAX_WIDTH}
        >
          {containers.map((container, index) => (
            <DndWrapper
              key={`subcategory-creator-container_${container.id}`}
              id={container.id}
              isLoading={isCreating}
              index={index}
              reorderContainers={reorderContainers}
              multipleContainers={containers.length > 1}
              removeContainer={removeContainerHandler}
              ContainerWrapper={ContainerWrapper}
            />
          ))}
          <AddButton addHandler={addContainerHandler} mt="8px" />
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={isCreating}
        formId="subcategory-creator"
        backPath={backPath}
        isCreate
      />
    </Box>
  );
}

export default SubcategoryCreate;
