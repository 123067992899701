import { ColumnDef } from '@tanstack/react-table';

import { CategoryTag } from 'types/categoryTags';
import { StatusType } from 'types/common';
import { datePrettier } from 'utils/date';
import StatusBox from 'components/StatusBox';

function GetColumns(): ColumnDef<CategoryTag, string>[] {
  return [
    {
      header: 'NAZWA',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KATEGORIA W MAGENTO',
      accessorKey: 'externalCategoryId',
      cell: (info) => info.getValue(),
    },
    {
      header: 'POZYCJA',
      accessorKey: 'placement',
      cell: (info) => info.getValue(),
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: 'activeFrom',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: 'activeTo',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
