import { Flex } from '@chakra-ui/react';
import ArrowIcon from 'components/Icons/ArrowX';

interface ResizeButtonProps {
  onClick: () => void;
  isExpanded: boolean;
}

function ResizeButton({ isExpanded, onClick }: ResizeButtonProps) {
  return (
    <Flex
      as="button"
      display="flex"
      minH="88px"
      borderTopWidth={1}
      borderTopColor="black"
      align="center"
      justify={isExpanded ? 'flex-end' : 'center'}
      transition="all 0.25s ease"
      onClick={onClick}
    >
      <ArrowIcon
        transform={isExpanded ? '' : 'rotateZ(-180deg)'}
        transition="all 0.275s ease"
        marginX="34px"
      />
    </Flex>
  );
}

export default ResizeButton;
