import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { useSurveyQuestionsQuery } from 'app/services/surveysApi';
import Table from 'pages/SurveyQuestions/Listing/components/Table';
import HeaderNavigation from 'pages/SurveyQuestions/Listing/components/HeaderNavigation';

function SurveyQuestions() {
  const { surveyId = '' } = useParams();
  const { data: survey, isFetching } = useSurveyQuestionsQuery(surveyId!);

  return (
    <Box pt="36px">
      <HeaderNavigation
        name={survey?.name ?? 'Loading...'}
        surveyId={surveyId}
      />
      {isFetching && <LoadingIndicator />}
      {!isFetching && survey && (
        <Table data={survey?.questions} surveyId={survey?.id} />
      )}
    </Box>
  );
}

export default SurveyQuestions;
