import { Stack, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import ColorPreview from 'components/ColorPreview';
import AnswersPreview from 'pages/SurveyQuestions/Listing/components/Table/components/AnswersPreview';
import GradientPreview from 'pages/SurveyQuestions/Listing/components/Table/components/GradientPreview';

function GetColumns(): ColumnDef<SurveyQuestion, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAGŁÓWEK',
      accessorKey: 'header',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      cell: ({
        row: {
          original: { title, answers },
        },
      }) => (
        <Stack spacing={2}>
          <Text fontSize="inherit">{title}</Text>
          {!isEmpty(answers) && <AnswersPreview answers={answers} />}
        </Stack>
      ),
    },
    {
      header: 'GRADIENT',
      cell: ({
        row: {
          original: { gradientColorTop, gradientColorBottom },
        },
      }) => (
        <GradientPreview
          topColor={gradientColorTop}
          bottomColor={gradientColorBottom}
        />
      ),
    },
    {
      header: 'KOLOR TEKSTU',
      accessorKey: 'textColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
