import { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import LoadingIndicator from 'components/LoadingIndicator';
import FooterButtons from 'components/FooterButtons';
import ContainerWrapper from 'pages/AppStoriesInspirations/Form/Edit/components/ContainerWrapper';
import {
  useTaxonomyContainerQuery,
  useUpdateTaxonomyContainerMutation,
} from 'app/services/categoryContainerApi';
import {
  BODY_SCHEMA,
  DEFAULT_FORM_VALUE,
} from 'pages/AppStoriesInspirations/Form/constants';
import ROUTES from 'app/routes';
import HeaderNavigation from 'pages/AppStoriesInspirations/Form/Edit/components/HeaderNavigation';
import parseDefaultValues from 'pages/AppStoriesInspirations/Form/Edit/parseDefaultValues';
import { transformRemoved } from 'utils/helpers';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';
import getRemovedElements from 'selectors/formSelectors';
import { clearRemovedElements } from 'slices/form/formSlice';
import AppStoriesInspirationsPreview from 'pages/AppStoriesInspirations/Form/components/Preview';
import { FORM_MAX_WIDTH } from 'utils/constants';
import useRoute from 'utils/useRoute';
import { hasInvalidElementsLength } from 'components/Container/schema';

function AppStoriesInspirationEdit() {
  const { id = '' } = useParams();
  const backPath = useRoute(ROUTES.appStoriesInspirations.base);

  const navigate = useNavigate();
  const removedElements = useAppSelector(getRemovedElements);
  const dispatch = useAppDispatch();

  const {
    data: { data: categoryContainer } = {},
    isFetching,
    isSuccess,
  } = useTaxonomyContainerQuery(id);
  const [updateTaxonomyContainer, { isLoading: updateRunning }] =
    useUpdateTaxonomyContainerMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_FORM_VALUE,
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (
        hasInvalidElementsLength(
          data,
          3,
          'Musisz dodać przynajmniej trzy elementy',
        )
      )
        return;

      const payload = { ...data };
      const { elementsAttributes = [] } = payload;

      payload.elementsAttributes = [
        ...elementsAttributes,
        ...transformRemoved(removedElements),
      ];

      payload.deleteElementsIcon = Number(data?.deleteElementsIcon);

      await updateTaxonomyContainer(payload).unwrap();
      navigate(backPath);
      toast.success(`Zmiany w inspiracji zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (isSuccess && categoryContainer) {
      reset({
        ...DEFAULT_FORM_VALUE,
        ...parseDefaultValues(categoryContainer),
      });
    }
  }, [isSuccess, categoryContainer, reset]);

  useEffect(() => {
    return () => {
      dispatch(clearRemovedElements());
    };
  }, [dispatch]);

  return (
    <Box pt="32px">
      <HeaderNavigation name={categoryContainer?.name} />
      <FormProvider {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start">
          <Box
            as="form"
            id="app-stories-inspiration-editor"
            onSubmit={onSubmit}
            pb="120px"
            width="100%"
            maxW={FORM_MAX_WIDTH}
          >
            {isFetching && <LoadingIndicator />}
            {!isFetching && (
              <ContainerWrapper
                isLoading={updateRunning || isFetching}
                editMode
              />
            )}
          </Box>
          <AppStoriesInspirationsPreview />
        </Flex>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="app-stories-inspiration-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default AppStoriesInspirationEdit;
