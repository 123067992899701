import { startOfMonth, endOfMonth, subMonths } from 'date-fns';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/Statistics/TopBannersComparison/components/Table';
import useQueryParams from 'utils/useQueryParams';
import Search from 'pages/Statistics/TopBannersComparison/components/Form/Search';
import { useTopBannersComparisonQuery } from 'app/services/statisticsApi';
import 'pages/Statistics/assets/CustomFont';

const today = new Date();

export const DEFAULT_PARAMS = {
  timeFrom: startOfMonth(subMonths(today, 1)),
  timeTo: endOfMonth(subMonths(today, 1)),
  secondTimeFrom: startOfMonth(today),
  secondTimeTo: endOfMonth(today),
};

function TopBannersComparison() {
  const { timeFrom, timeTo, secondTimeFrom, secondTimeTo } = DEFAULT_PARAMS;
  const { queryString } = useQueryParams({
    timeFrom: timeFrom.toISOString(),
    timeTo: timeTo.toISOString(),
    secondTimeFrom: secondTimeFrom.toISOString(),
    secondTimeTo: secondTimeTo.toISOString(),
  });

  const { isFetching: topBannersLoading, data: topBanners } =
    useTopBannersComparisonQuery(queryString);

  return (
    <ListingLayout
      title="Top 100 banerów - porównanie"
      hideSearch
      hideFilter
      headerButtons={[]}
    >
      <Search />
      {topBannersLoading && <LoadingIndicator />}
      {!topBannersLoading && (
        <Table
          data={topBanners || []}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + 225px));`}
        />
      )}
    </ListingLayout>
  );
}

export default TopBannersComparison;
