import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS as DEFAULT_SERIALIZER_OPTIONS } from 'app/constants';
import { transformActivityDates } from 'utils/date';

const SERIALIZER_OPTIONS = {
  ...DEFAULT_SERIALIZER_OPTIONS,
  indices: false,
  allowEmptyArrays: true,
};

export const minibannersApi = api.injectEndpoints({
  endpoints: (build) => ({
    minibanners: build.query<TransformedMinibannersResponse, string>({
      query: (queryString) => `minibanners${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: MinibannersResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Minibanners'],
    }),
    deleteMinibanner: build.mutation<void, number>({
      query: (id) => ({
        url: `minibanners/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Minibanners'],
    }),
    createMinibanner: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(payload),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'minibanner',
        );

        return {
          url: 'minibanners',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Minibanners'],
    }),
    minibanner: build.query<MinibannerResponse, any>({
      query: (id) => `minibanners/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Minibanners'],
    }),
    updateMinibanner: build.mutation({
      query: (payload) => {
        const { id } = payload;

        const snakePayload = convertKeysToSnakeCase(
          transformActivityDates(payload),
        );

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'minibanner',
        );

        return {
          url: `minibanners/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Minibanners'],
    }),
  }),
});

export const {
  useMinibannersQuery,
  useDeleteMinibannerMutation,
  useCreateMinibannerMutation,
  useMinibannerQuery,
  useUpdateMinibannerMutation,
} = minibannersApi;
