import { omit } from 'lodash';
import { CONDITION_DICT } from 'pages/WeatherCategories/Preview/constants';
import { transformLinkParameters } from 'utils/linkCreator';

const PARSED_VALUES: string[] = ['weatherCategoryConditionHints'];

const parseDefaultValues = (weatherCondition: WeatherCondition) => {
  const result: Record<string, any> = {
    ...omit(weatherCondition, PARSED_VALUES),
  };

  result.kindName = CONDITION_DICT[weatherCondition?.kind]?.name;
  result.weatherCategoryConditionHintsAttributes =
    weatherCondition?.weatherCategoryConditionHints?.map((hint) =>
      transformLinkParameters({ ...hint }),
    );

  return result;
};

export default parseDefaultValues;
