import { Box } from '@chakra-ui/react';
import { Link, generatePath, useParams } from 'react-router-dom';
import ROUTES from 'app/routes';
import {
  useDashboardsQuery,
  useDeleteDashboardMutation,
} from 'app/services/dashboardApi';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import ListingTabs from 'components/ListingTabs';
import LoadingIndicator from 'components/LoadingIndicator';
import SearchIndicator from 'components/Search/SearchIndicator';
import FooterButtons from 'pages/Dashboard/Listing/components/FooterButtons';
import Table from 'pages/Dashboard/Listing/components/Table';
import { SEARCH_KEY } from 'pages/Dashboard/Listing/constants';
import { GENDER_OPTIONS } from 'utils/constants';
import useQueryParams from 'utils/useQueryParams';

function DashboardArchive() {
  const { queryString, isSearchActive, search } = useQueryParams({
    page_size: '10',
    page: '1',
    'q[status_eq]': 'archived',
  });
  const gender = search.get('gender');
  const { store } = useParams();

  const [, state] = useDeleteDashboardMutation({
    fixedCacheKey: 'dashboard',
  });

  const {
    isFetching,
    data: { data: containers, totals } = { data: [], totals: 0 },
  } = useDashboardsQuery(`${queryString}&main_list=true`);

  const { isLoading } = state;
  const showLoading = isFetching || isLoading;

  const additionalPadding = isSearchActive(SEARCH_KEY) ? '226px' : '170px';

  return (
    <ListingLayout
      title="Archiwum dashboardu"
      searchKey={SEARCH_KEY}
      placement="dashboard"
      hideFilter
      headerButtons={[
        {
          label: 'WRÓĆ NA DASHBOARD',
          as: Link,
          to: {
            pathname: generatePath(ROUTES.dashboard.base, {
              store,
            }),
            search: gender ? `?gender=${gender}` : '',
          },
        },
      ]}
    >
      <ListingTabs paramName="gender" options={GENDER_OPTIONS} />
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Box>
          <SearchIndicator searchKey={SEARCH_KEY} />
          <Table
            data={containers}
            h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + ${additionalPadding}));`}
          />
        </Box>
      )}
      <Footer totals={totals} footerButtons={<FooterButtons />} />
    </ListingLayout>
  );
}

export default DashboardArchive;
