import { Link } from 'react-router-dom';
import { Box, useDisclosure } from '@chakra-ui/react';
import ROUTES from 'app/routes';
import {
  useBlogPostsQuery,
  useDeleteBlogPostMutation,
} from 'app/services/blogPostsApi';
import useQueryParams from 'utils/useQueryParams';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/BlogPost/Listing/components/Table';
import SearchIndicator from 'components/Search/SearchIndicator';
import { SEARCH_KEY } from 'pages/LandingPage/Listing/constants';
import FilterBox from 'pages/LandingPage/Listing/components/FilterBox';
import FooterButtons from 'pages/BlogPost/Listing/components/FooterButtons';
import FiltersDrawer from 'pages/BlogPost/Listing/components/FiltersDrawer';
import useRoute from 'utils/useRoute';
import CountryImport from 'pages/BlogPost/Listing/components/CountryImport';

function BlogPosts() {
  const {
    isOpen: isImportOpen,
    onOpen: openImport,
    onClose: closeImport,
  } = useDisclosure();
  const createBlogPostPath = useRoute(ROUTES.blogPost.create);

  const { queryString, isSearchActive } = useQueryParams({
    page_size: '10',
    page: '1',
    order: 'DESC',
  });

  const [, state] = useDeleteBlogPostMutation({
    fixedCacheKey: 'blogPost',
  });

  const {
    isFetching,
    data: { data: blogPosts, totals } = { data: [], totals: 0 },
  } = useBlogPostsQuery(queryString);

  const { isLoading } = state;
  const showLoading = isFetching || isLoading;

  const additionalPadding = isSearchActive(SEARCH_KEY) ? '226px' : '136px';

  return (
    <ListingLayout
      title="blog post"
      filterDrawer={FiltersDrawer}
      searchKey={SEARCH_KEY}
      placement="blog_post"
      headerButtons={[
        {
          label: 'IMPORTUJ Z KRAJU',
          variant: 'outlined',
          onClick: openImport,
        },
        { label: 'NOWY BP', as: Link, to: createBlogPostPath },
      ]}
      FilterBox={FilterBox}
    >
      <CountryImport isOpen={isImportOpen} onClose={closeImport} />
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Box>
          <SearchIndicator searchKey={SEARCH_KEY} />
          <Table
            data={blogPosts}
            h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + ${additionalPadding}));`}
          />
        </Box>
      )}
      <Footer totals={totals} footerButtons={<FooterButtons />} />
    </ListingLayout>
  );
}

export default BlogPosts;
