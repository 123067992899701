import { Flex, Box } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/Sizings/Form/components/FormBody';
import { EXTENDED_FORM_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';
import { SIZING_OPTIONS } from 'pages/Sizings/Form/constants';
import Select from 'components/Form/Select';

interface ComponentWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ComponentWrapperProps) {
  return (
    <Flex gap={4} mb={12} alignItems="flex-start">
      <Box flex={1} maxW={EXTENDED_FORM_MAX_WIDTH}>
        <Card>
          {isLoading && <FormSpinner />}

          <Box w="100%">
            <Box mb="36px">
              <Select
                label="Rodzaj"
                name="kind"
                placeholder="Wybierz"
                options={SIZING_OPTIONS}
                isLoading={false}
              />
            </Box>
            <FormBody prepareFieldName={(name: string) => name} />
          </Box>
        </Card>
      </Box>
    </Flex>
  );
}

export default ContainerWrapper;
