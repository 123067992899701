import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  kind: yup.string().required('Pole jest wymagane'),
  image: yup.mixed().test({
    test(file, context) {
      if (!file) {
        return context.createError({ message: 'Zdjęcie jest wymagane' });
      }
      return true;
    },
  }),
});

export const DEFAULT_VALUES = {
  kind: '',
  image: null,
};
