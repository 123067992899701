import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import AppstoriesForm from 'components/Container/Forms/AppstoriesForm';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import OptionalFieldset from 'components/OptionalFieldset';
import Input from 'components/Form/Input';
import DropzoneField from 'components/Form/Dropzone';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import Checkbox from 'components/Form/Checkbox';
import ColorPicker from 'components/Form/Color';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  editMode?: boolean;
}

function FormBody({ prepareFieldName, editMode }: FormBodyProps) {
  const { control, setValue } = useFormContext();

  const [withHeader, withIcon] = useWatch({
    control,
    name: [prepareFieldName('withHeader'), prepareFieldName('withIcon')],
  });

  useEffect(() => {
    if (!withHeader) {
      setValue(prepareFieldName('elementsHeader'), '');
      setValue(prepareFieldName('textColor'), null);
    }
  }, [prepareFieldName, setValue, withHeader]);

  return (
    <>
      <OptionalFormFieldset
        title="Nagłówek karuzeli (opcjonalnie)"
        pt="20px"
        isActive={withHeader}
        name={prepareFieldName('withHeader')}
        maxW={FORM_BODY_MAX_WIDTH}
      >
        <Input
          label="Nagłówek"
          name={prepareFieldName('elementsHeader')}
          mb={6}
        />
        <ColorPicker
          name={prepareFieldName('textColor')}
          label="Kolor tekstu"
          showError
          mb={6}
        />
      </OptionalFormFieldset>
      <OptionalFieldset
        title="Ikona karuzeli (opcjonalnie)"
        pt="20px"
        maxW={FORM_BODY_MAX_WIDTH}
        isActive={withIcon}
      >
        <DropzoneField
          name={prepareFieldName('elementsIcon')}
          title="Dodaj ikonę w formacie .png (min. 72px x 72px)"
        />
      </OptionalFieldset>
      <Checkbox
        name={prepareFieldName('deleteElementsIcon')}
        label="Usuń ikonę"
        pb="30px"
        defaultChecked={false}
      />
      <AppstoriesForm
        prepareFieldName={prepareFieldName}
        isEditMode={editMode}
      />
    </>
  );
}

FormBody.defaultProps = {
  editMode: false,
};

export default FormBody;
