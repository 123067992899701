import { useCallback } from 'react';
import { Accordion, Box, Button, FormControl, Stack } from '@chakra-ui/react';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import ActivityFieldset from 'components/ActivityFieldset';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import {
  DEFAULT_VALUES,
  PLATFORM_OPTIONS,
  SCREEN_KIND,
  SCREEN_KIND_OPTIONS,
} from 'pages/Announcements/Form/constants';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import AnnouncementsElementSection from 'components/Container/Sections/AnnouncementsElementSection';
import LinkCreatorSection from 'components/LinkCreator';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  isEditMode?: boolean;
}

function FormBody({ prepareFieldName, isEditMode }: FormBodyProps) {
  const { control } = useFormContext();
  const dispatch = useAppDispatch();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'fieldId',
  });
  const kind = useWatch({
    control,
    name: prepareFieldName('kind'),
  });

  const removeElement = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [isEditMode, remove, dispatch, fields],
  );

  const addElement = useCallback(() => {
    append(DEFAULT_VALUES.elementsAttributes[0]);
  }, [append]);

  return (
    <Stack spacing={8}>
      <Select
        label="Rodzaj ekranu"
        name="kind"
        options={SCREEN_KIND_OPTIONS}
        placeholder="Wpisz lub wybierz z listy"
      />
      <Select
        label="Platforma"
        name={prepareFieldName('platform')}
        placeholder="Wybierz"
        options={PLATFORM_OPTIONS}
        isLoading={false}
      />
      <Input
        name={prepareFieldName('platformVersion')}
        label="Wersja"
        placeholder="Wersja"
      />
      <Input
        name={prepareFieldName('buttonText')}
        placeholder="Wpisz tekst"
        label="Treść przycisku"
      />
      {[SCREEN_KIND.UPDATE_APP, SCREEN_KIND.LINKABLE_LINK].includes(kind) && (
        <Input
          name={prepareFieldName('linkText')}
          placeholder="Wpisz link"
          label="Treść linku"
        />
      )}
      <FormControl as="fieldset">
        <Accordion defaultIndex={[0]} allowMultiple mb={10} overflow="visible">
          {fields.map((field: any, index) => (
            <AnnouncementsElementSection
              id={field.fieldId}
              itemIndex={index}
              prepareFieldName={prepareFieldName}
              key={field.fieldId}
              move={move}
              remove={removeElement}
            />
          ))}
          <Button
            type="button"
            variant="solid"
            mt={4}
            w="100%"
            onClick={addElement}
            fontSize="12px"
            maxW={FORM_BODY_MAX_WIDTH}
          >
            DODAJ ELEMENT
            <Box as="span" ml={2}>
              <BiPlus size={24} />
            </Box>
          </Button>
        </Accordion>
      </FormControl>
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
      <ActivityFieldset prepareFieldName={prepareFieldName} />
    </Stack>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
