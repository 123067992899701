import {
  AccordionButton,
  AccordionIcon,
  AccordionItem as ChakraAccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react';
import find from 'lodash/find';
import noop from 'lodash/noop';
import { useMemo } from 'react';
import { NavLink, generatePath, useLocation } from 'react-router-dom';
import { Option } from 'types/navigation';
import useQueryParams from 'utils/useQueryParams';

interface ExpandableNavItemProps {
  isSidebarExpanded: boolean;
  icon: React.ReactNode;
  title: string;
  idx: number;
  options: Option[];
  expandSidebar: () => void;
  store: string;
}

function ExpandableNavItem({
  isSidebarExpanded,
  icon,
  title,
  idx,
  options,
  expandSidebar,
  store,
}: ExpandableNavItemProps) {
  const { pathname } = useLocation();
  const { queryString } = useQueryParams();
  const isSubItemActive = useMemo(
    () => !!find(options, (opt) => pathname.includes(opt.link)),
    [pathname, options],
  );

  return (
    <ChakraAccordionItem position="relative" borderTopWidth={idx === 0 ? 0 : 1}>
      <AccordionButton
        fontSize="16px"
        onClick={isSidebarExpanded ? noop : expandSidebar}
        _expanded={{ fontWeight: 600 }}
        {...(isSubItemActive && {
          _before: {
            width: '4px',
            height: '64px',
            bg: 'complementary.blue',
            position: 'absolute',
            left: 0,
            content: `""`,
          },
        })}
      >
        <Flex
          flex={1}
          gap="24px"
          justifyContent={isSidebarExpanded ? 'flex-start' : 'center'}
        >
          {icon} <Box hidden={!isSidebarExpanded}>{title}</Box>
        </Flex>
        {isSidebarExpanded && <AccordionIcon />}
      </AccordionButton>

      <AccordionPanel p={0}>
        <Box>
          {options.map((option, id) => {
            const link = generatePath(option.link, { store });
            const optionLink = link === pathname ? queryString : link;

            return (
              <NavLink to={optionLink} key={`expanded_option_${option.title}`}>
                {({ isActive }) => (
                  <AccordionButton
                    borderTopWidth={id === 0 ? 0 : '1px'}
                    paddingLeft="72px"
                    fontWeight={isActive ? 600 : 400}
                  >
                    {option.title}
                  </AccordionButton>
                )}
              </NavLink>
            );
          })}
        </Box>
      </AccordionPanel>
    </ChakraAccordionItem>
  );
}

export default ExpandableNavItem;
