import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  Button,
  Center,
  Text,
} from '@chakra-ui/react';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  children?: React.ReactNode;
}

function DeleteModal({
  isOpen,
  onClose,
  onAccept,
  children,
}: DeleteModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton>
          <MdOutlineCancel size="19px" />
        </ModalCloseButton>
        <ModalBody paddingY="242px">
          <Center>
            <VStack w="452px" spacing="24px">
              <Text
                fontSize="16px"
                fontWeight={600}
                letterSpacing="0.06px"
                textAlign="center"
                marginY="24px"
              >
                {children}
              </Text>
              <Button w="100%" onClick={onAccept}>
                USUŃ
              </Button>
              <Button w="100%" variant="outlined" onClick={onClose}>
                ANULUJ
              </Button>
            </VStack>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

DeleteModal.defaultProps = {
  children: null,
};

export default DeleteModal;
