import {
  Flex,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Box,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import EdgeArrow from 'components/Icons/EdgeArrow';
import SimpleArrow from 'components/Icons/SimpleArrow';
import useQueryParams from 'utils/useQueryParams';
import { useAppSelector } from 'utils/reduxHooks';
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPENED_WIDTH } from 'slices/ui/uiSlice';
import { getSidebarState } from 'selectors/uiSelectors';

export const FOOTER_HEIGHT = '65px';
interface PaginantionFooterProps {
  totals: number;
  footerButtons: React.ReactNode;
  hidePagination?: boolean;
}
function PaginationFooter({
  totals,
  hidePagination = false,
  footerButtons,
}: PaginantionFooterProps) {
  const { search, setSearch } = useQueryParams();

  const page = Number(search.get('page'));
  const pageSize = Number(search.get('page_size'));

  const lastPage = Math.ceil(totals / +pageSize);

  const isSidebarOpened = useAppSelector(getSidebarState);

  const onPageSizeChange = (ps: string) => {
    search.set('page', '1');
    search.set('page_size', ps);
    setSearch(search);
  };

  const handleClick = (name: string, value: string) => {
    search.set(name, value);
    setSearch(search);
  };

  return (
    <motion.div
      animate={{
        left: isSidebarOpened ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH,
        width: isSidebarOpened
          ? `calc(100vw - ${SIDEBAR_OPENED_WIDTH}px`
          : `calc(100vw - ${SIDEBAR_CLOSED_WIDTH}px`,
      }}
      hidden={false}
      style={{
        display: 'flex',
        width: 'calc(100vw - 133px)',
        height: FOOTER_HEIGHT,
        background: 'black',
        position: 'fixed',
        bottom: 0,
        left: '133px',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <Flex paddingInline="48px" w="100%">
        {footerButtons}
        <Box w="100%" />
        <HStack spacing="50px" hidden={hidePagination}>
          <Flex align="center">
            <Text color="white" fontSize={16} mr="16px">
              {pageSize}
            </Text>
            <Menu>
              <MenuButton
                as={IconButton}
                variant="unstyled"
                aria-label="Page size"
                icon={<SimpleArrow color="#fff" transform="rotate(90deg)" />}
              />
              <MenuList minW="80px">
                <MenuItem
                  isDisabled={+pageSize === 10}
                  onClick={() => onPageSizeChange('10')}
                >
                  10
                </MenuItem>
                <MenuItem
                  isDisabled={+pageSize === 25}
                  onClick={() => onPageSizeChange('25')}
                >
                  25
                </MenuItem>
                <MenuItem
                  isDisabled={+pageSize === 50}
                  onClick={() => onPageSizeChange('50')}
                >
                  50
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <Text color="white" fontSize={16} whiteSpace="nowrap">
            {totals === 0 && '0 - 0 z 0'}
            {totals !== 0 &&
              `${(+page - 1) * +pageSize || 1} - ${
                +page * +pageSize > totals ? totals : +page * +pageSize
              } z ${totals}`}
          </Text>
          <Flex alignItems="center">
            <IconButton
              aria-label="First page"
              variant="unstyled"
              icon={<EdgeArrow color="white" transform="rotate(180deg)" />}
              onClick={() => handleClick('page', '1')}
            />
            <IconButton
              aria-label="Prev page"
              variant="unstyled"
              icon={<SimpleArrow color="white" transform="rotate(180deg)" />}
              onClick={() => {
                if (page > 1) handleClick('page', (+page - 1).toString());
              }}
            />
            <IconButton
              aria-label="Next page"
              variant="unstyled"
              onClick={() => {
                if (+page + 1 <= lastPage)
                  handleClick('page', (+page + 1).toString());
              }}
              icon={<SimpleArrow color="white" />}
            />
            <IconButton
              aria-label="Last page"
              variant="unstyled"
              onClick={() => handleClick('page', lastPage.toString())}
              icon={<EdgeArrow color="white" />}
            />
          </Flex>
        </HStack>
      </Flex>
    </motion.div>
  );
}

PaginationFooter.defaultProps = {
  hidePagination: false,
};
export default PaginationFooter;
