import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink, LinkProps as RouterProps } from 'react-router-dom';

type LinkProps = RouterProps & {
  children: React.ReactNode;
};
function Link({ children, to, ...props }: LinkProps) {
  return (
    <ChakraLink as={RouterLink} to={to} {...props}>
      {children}
    </ChakraLink>
  );
}

export default Link;
