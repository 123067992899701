import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Sizings/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Sizings/Form/constants';
import {
  useSizingQuery,
  useUpdateSizingMutation,
} from 'app/services/sizingsApi';
import { transformErrors } from 'utils/api';
import useRoute from 'utils/useRoute';

function SizingEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.sizings.base);

  const { data: { data: sizing } = {}, isFetching } = useSizingQuery({ id });
  const [updateSizing, { isLoading: updateRunning }] =
    useUpdateSizingMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateSizing(data)
        .unwrap()
        .then(() => {
          navigate(backPath);
          toast.success(`Zmiany w rozmiarówce zostały zastosowane`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (sizing) reset({ ...DEFAULT_VALUES, ...sizing });
  }, [sizing, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Rozmiarówka',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj rozmiarówkę' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="sizing-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="sizing-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default SizingEdit;
