import { AccordionItem, AccordionPanel, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { UseFieldArrayMove } from 'react-hook-form';
import AccordionButton from 'components/Collapsible/AccordionButton';
import Input from 'components/Form/Input';
import LinkCreatorSection from 'components/LinkCreator';

interface AnnouncementElementSectionProps {
  id: string;
  itemIndex: number;
  isEditMode: boolean;
  prepareFieldName: (name: string) => string;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
}

function AnnouncementsElementSection({
  id,
  itemIndex,
  isEditMode,
  prepareFieldName,
  move,
  remove,
}: AnnouncementElementSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(
        `weatherCategoryConditionHintsAttributes[${itemIndex}].${name}`,
      ),
    [itemIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0} overflow="visible">
      <AccordionButton
        id={id}
        index={itemIndex}
        move={move}
        label={`${itemIndex + 1} sugestia`}
        remove={remove}
        isDraggable={false}
      />
      <AccordionPanel pl={0} pr={0} pt={8} pb={8} overflow="visible">
        <VStack spacing={8}>
          <Input label="Treść linku" name={fieldname('linkText')} />
          <Input label="Treść podpowiedzi" name={fieldname('hintText')} />
          <Input
            label="Temperatura minimalna"
            name={fieldname('tempMin')}
            type="number"
          />
          <Input
            label="Temperatura maksymalna"
            name={fieldname('tempMax')}
            type="number"
          />
          <LinkCreatorSection
            prepareFieldName={fieldname}
            isEditMode={isEditMode || false}
          />
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default AnnouncementsElementSection;
