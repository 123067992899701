import { useMemo, useState } from 'react';
import { Stack, Text, Tooltip } from '@chakra-ui/react';
import qs from 'query-string';
import { startOfDay, endOfDay } from 'date-fns';
import { matchPath, useLocation } from 'react-router-dom';
import { Dashboard } from 'types/dashboard';
import { useStatisticsQuery } from 'app/services/statisticsApi';
import StatsTooltip from 'components/Stats/StatsTooltip';
import ROUTES from 'app/routes';
import { LandingPage } from 'types/landingPage';

export interface ShowStatsProps {
  data: Dashboard | LandingPage;
  type?: 'appstory_click' | 'banner_click' | 'landing_page_click';
  children?: React.ReactNode;
}

const LP_ROUTES = [ROUTES.landingPage.base, ROUTES.landingPage.edit];

function ShowStats({ data, type, children }: ShowStatsProps) {
  const { pathname } = useLocation();
  const isLandingPageType = LP_ROUTES.some((path) => matchPath(path, pathname));
  const [previewIsVisible, setPreviewIsVisible] = useState(false);

  const queryString = useMemo(() => {
    const timeFrom = data?.activeFrom
      ? new Date(data?.activeFrom)
      : new Date(data?.createdAt);
    const timeTo = data?.activeTo ? new Date(data.activeTo) : new Date();

    const params = {
      timeFrom: timeFrom ? startOfDay(timeFrom).toISOString() : undefined,
      timeTo: timeTo ? endOfDay(timeTo).toISOString() : undefined,
      analyticsId: data?.analyticsId,
      type: isLandingPageType ? 'landing_page_click' : type,
    };

    return qs.stringify(params);
  }, [data, type, isLandingPageType]);

  const { data: stats, isFetching } = useStatisticsQuery(queryString, {
    skip: !previewIsVisible,
  });

  return (
    <Tooltip
      onOpen={() => setPreviewIsVisible(true)}
      onClose={() => setPreviewIsVisible(false)}
      padding={0}
      openDelay={500}
      label={
        <StatsTooltip isFetching={isFetching}>
          <Stack overflowX="auto" gap={4}>
            <Text fontSize="16px" fontWeight={600} textTransform="uppercase">
              Statystyki
            </Text>
            <Text fontSize="16px">
              <Text as="span" fontSize="16px" fontWeight={600}>
                Suma kliknięć:{' '}
              </Text>
              {stats?.clicks}
            </Text>
            <Text fontSize="16px">
              <Text as="span" fontSize="16px" fontWeight={600}>
                Przychód:{' '}
              </Text>
              {stats?.conversion?.toFixed(2)}
            </Text>
          </Stack>
        </StatsTooltip>
      }
    >
      {children}
    </Tooltip>
  );
}

ShowStats.defaultProps = {
  type: 'banner_click',
  children: null,
};

export default ShowStats;
