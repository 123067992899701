import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    text-align: left;
    align-items: center;
    padding: 4px 32px;
    gap: 10px;
  }
  .Toastify__toast-body {
    font-family: Jost;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.004em;
    padding: 0;
  }
  .Toastify__toast--success {
    background-image: none;
    color: black;
    background: #66fda8;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .Toastify__toast--error {
    background-image: none;
    color: black;
    background: #fb9eb5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .Toastify__toast--info {
    background-image: none;
    color: black;
    background: #9da7fd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .Toastify__toast--warning {
    background-image: none;
    color: black;
    background: #fab16e;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
`;
export default StyledContainer;
