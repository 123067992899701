import omit from 'lodash/omit';
import { BenefitsScreen } from 'types/benefitsScreens';

const PARSED_VALUES: string[] = ['activeFrom, activeTo'];

const parseDefaultValues = (benefitsScreen: BenefitsScreen) => {
  const result: Record<string, any> = { ...benefitsScreen };

  result.elementsAttributes = benefitsScreen.elementsAttributes?.map(
    (element: any) => {
      const parsedElement: Record<string, any> = {
        ...omit(element, PARSED_VALUES),
      };
      const { activeFrom, activeTo } = element;

      if (activeFrom) {
        parsedElement.dateFrom = new Date(activeFrom);
        parsedElement.timeFrom = new Date(activeFrom);
      }

      if (activeTo) {
        parsedElement.dateTo = new Date(activeTo);
        parsedElement.timeTo = new Date(activeTo);
      }

      return parsedElement;
    },
  );

  return result;
};

export default parseDefaultValues;
