import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { differenceBy } from 'lodash';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/BenefitsScreensElements/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA } from 'pages/BenefitsScreensElements/Form/constants';
import {
  useBenefitsScreenElementQuery,
  useUpdateBenefitsScreenElementMutation,
} from 'app/services/benefitsScreensApi';
import parseDefaultValues from 'pages/BenefitsScreensElements/Form/Edit/parseDefaultValues';
import { transformRemoved } from 'utils/helpers';
import useRoute from 'utils/useRoute';

function BenefitsScreenElementEdit() {
  const { id = '', benefitsScreenId = '' } = useParams();
  const backPath = useRoute(ROUTES.benefitsScreenElements.base, {
    benefitsScreenId,
  });
  const navigate = useNavigate();

  const { data: { data: benefitsScreensElement } = {}, isFetching } =
    useBenefitsScreenElementQuery({
      id,
      benefitsScreenId,
    });
  const [updateBenefitsScreenElement, { isLoading: updateRunning }] =
    useUpdateBenefitsScreenElementMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const payload = { ...data };
      const { itemsAttributes = [] } = payload;

      const removedItems = differenceBy(
        benefitsScreensElement?.items,
        itemsAttributes,
        'id',
      );
      payload.itemsAttributes = [
        ...itemsAttributes,
        ...transformRemoved(removedItems),
      ];

      await updateBenefitsScreenElement({
        ...payload,
        id,
        benefitsScreenId,
      }).unwrap();
      navigate(backPath);
      toast.success(`Zmiany w elemencie zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (benefitsScreensElement)
      reset(parseDefaultValues(benefitsScreensElement));
  }, [benefitsScreensElement, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Ekran korzyści',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj element' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="benefits-screen-element-editor"
          onSubmit={onSubmit}
          pb="120px"
        >
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="benefits-screen-element-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default BenefitsScreenElementEdit;
