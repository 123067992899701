import { Flex, Box } from '@chakra-ui/react';

function DndSpacer() {
  return (
    <tr>
      <td colSpan={30}>
        <Flex h="16px" bg="#E8E9F2" paddingInline="7px" align="center">
          <Box h="2px" bg="#00FB6E" w="100%" />
        </Flex>
      </td>
    </tr>
  );
}

export default DndSpacer;
