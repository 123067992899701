/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import {
  useDashboardContainerQuery,
  useUpdateDashboardMutation,
} from 'app/services/dashboardApi';
import FooterButtons from 'pages/Dashboard/Containers/Edit/components/FooterButtons';
import HeaderNavigation from 'pages/Dashboard/Containers/Edit/components/HeaderNavigation';
import ComponentWrapper from 'pages/Dashboard/Containers/Edit/components/ComponentWrapper';
import ROUTES from 'app/routes';
import {
  componentSchema,
  hasInvalidElementsLength,
} from 'components/Container/schema';
import LoadingIndicator from 'components/LoadingIndicator';
import { DEFAULT_FORM_VALUES } from 'components/Container/constants';
import { transformErrors } from 'utils/api';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';
import getRemovedElements from 'selectors/formSelectors';
import { clearRemovedElements } from 'slices/form/formSlice';
import useQueryParams from 'utils/useQueryParams';

const transformRemoved = (elements: any[]) => {
  return elements.map((element) => {
    return {
      ...element,
      _destroy: true,
    };
  });
};

function DashboardContainerEdit() {
  const navigate = useNavigate();
  const { id, store } = useParams();
  const { search, queryString } = useQueryParams();
  const isArchive = search.get('q[status_eq]') === 'archived';
  const dashboardPath =
    generatePath(isArchive ? ROUTES.dashboard.archive : ROUTES.dashboard.base, {
      store,
    }) + queryString;

  const removedElements = useAppSelector(getRemovedElements);
  const dispatch = useAppDispatch();
  const [dataHasBeenParsed, setDataHasBeenParsed] = useState(false);
  const timestamp = useMemo(() => Date.now(), []);

  const { data: dashboard, isFetching } = useDashboardContainerQuery(
    `${id!}?t=${timestamp}`,
  );

  const [updateDashboard, { isLoading: updateRunning }] =
    useUpdateDashboardMutation();

  const methods = useFormWithSchema(componentSchema, {
    mode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (Object.keys(errors).length) {
      toast.error(
        'Formularz zawiera błędy. Sprawdź poprawność i spróbuj ponownie.',
      );
    }
  }, [errors]);

  useEffect(() => {
    return () => {
      dispatch(clearRemovedElements());
    };
  }, [dispatch]);

  const onSubmit = handleSubmit(async (data: any) => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (hasInvalidElementsLength(data)) return;

    const payload = {
      ...data,
      elementsAttributes: [
        ...(data?.elementsAttributes || []),
        ...transformRemoved(removedElements),
      ],
    };

    if (payload.linkParametersAttributes) {
      payload.linkParametersAttributes.map((element: any) => {
        if (element.linkType) {
          return {
            linkType: element.linkType,
          };
        }
        delete element.linkType;
        return element;
      });
    }

    if (!payload.withListingHeader) {
      payload.bannerListingsAttributes = payload.bannerListingsAttributes?.map(
        (attribute: any) => ({
          ...attribute,
          _destroy: true,
        }),
      );
    }

    try {
      await updateDashboard(payload)
        .unwrap()
        .then(() => {
          navigate(dashboardPath);
          const { name } = getValues();
          toast.success(
            `Zmiany w kontenerze o id ${dashboard?.id}${
              name ? `(${name})` : ''
            } zostały zastosowane`,
          );
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  const onCancel = useCallback(() => {
    reset(dashboard!);
    navigate(dashboardPath);
  }, [reset, dashboard, navigate, dashboardPath]);

  useEffect(() => {
    if (dashboard) {
      const defaultValues = DEFAULT_FORM_VALUES[dashboard.kind];
      const values = { ...defaultValues, ...dashboard };

      reset(values);
      setDataHasBeenParsed(true);
    }
  }, [dashboard, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        gender={dashboard?.gender!}
        dashboardPath={dashboardPath}
      />
      <FormProvider {...methods}>
        <Box as="form" id="dashboard-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && dataHasBeenParsed && dashboard && (
            <ComponentWrapper
              isLoading={updateRunning}
              kind={dashboard.kind}
              componentName={dashboard.name || 'KONTENER'}
            />
          )}
        </Box>
      </FormProvider>
      <FooterButtons
        isLoading={updateRunning || isFetching}
        onCancel={onCancel}
      />
    </Box>
  );
}

export default DashboardContainerEdit;
