import { Box, Checkbox, Flex } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import StatusBox from 'components/StatusBox';
import { RECEIVER } from 'pages/Notifications/Form/constants';
import { StatusType } from 'types/common';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<Notification, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => (
        <Flex align="center" direction="column">
          {info.getValue()}
          <Checkbox
            isChecked={info.row.getIsSelected()}
            onChange={info.row.getToggleSelectedHandler()}
            pt="23px"
          />
        </Flex>
      ),
    },
    {
      header: 'NAZWA',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TREŚĆ',
      accessorKey: 'content',
      cell: (info) => info.getValue(),
    },
    {
      header: 'BADGE',
      accessorKey: 'badge',
      cell: (info) => info.getValue(),
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'EMOJI',
      accessorKey: 'emoji',
      cell: (info) => info.getValue(),
    },
    {
      header: 'AKTYWNA OD',
      accessorKey: 'activeFrom',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'AKTYWNA DO',
      accessorKey: 'activeTo',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'ADRESAT',
      accessorKey: 'gender',
      cell: (info) => RECEIVER[info.getValue() as keyof typeof RECEIVER],
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
