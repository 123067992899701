import { api } from 'app/services/api';

export const inPostPointsApi = api.injectEndpoints({
  endpoints: (build) => ({
    inPostPoints: build.query<TransformedInPostPointsResponse, string>({
      query: (queryString) => `inpost_points${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: InPostPointsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['InPostPoints'],
    }),
  }),
});

export const { useInPostPointsQuery } = inPostPointsApi;
