import type { DeepPartial, Theme } from '@chakra-ui/react';
import Button from './button';
import Text from './text';
import Accordion from './accordion';
import Input from './input';
import FormLabel from './formLabel';
import FormError from './formError';
import Checkbox from './checkbox';
import Link from './link';
import Menu from './menu';
import Tabs from './tabs';
import Modal from './modal';
import Tooltip from './tooltip';
import Drawer from './drawer';
import Switch from './switch';
import Textarea from './textarea';

const components: DeepPartial<Theme['components']> = {
  Button,
  Text,
  Accordion,
  Input,
  FormLabel,
  FormError,
  Checkbox,
  Link,
  Menu,
  Tabs,
  Modal,
  Tooltip,
  Drawer,
  Switch,
  Textarea,
};

export default components;
