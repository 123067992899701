import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { Box, BoxProps } from '@chakra-ui/react';
import TabElement from 'components/Table/Table';
import columns from 'pages/CategoryTags/Listing/components/NestedTable/columns';
import {
  useReorderTagsMutation,
  useTagsQuery,
} from 'app/services/categoryTags';
import useQueryParams from 'utils/useQueryParams';
import Tbody from 'components/Table/Tbody';
import DraggableRow from 'pages/CategoryTags/Listing/components/NestedTable/DraggableRow';

type TableProps = BoxProps & {
  categoryId: number;
  rowId: string;
};

function Table({ categoryId, rowId }: TableProps) {
  const { queryString } = useQueryParams();
  const [reorderTag] = useReorderTagsMutation();

  const { data: { data: elements } = { data: [] } } = useTagsQuery({
    categoryId,
    queryString,
  });

  const handleReorder = async (id: number, targetRowIndex: number) => {
    try {
      await reorderTag({
        id,
        categoryId,
        body: {
          placement: targetRowIndex,
        },
      }).unwrap();
    } catch (error) {
      toast.error('Problem podczas zmiany kolejności');
    }
  };

  const table = useReactTable({
    data: elements,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  if (isEmpty(elements)) return null;

  return (
    <Box as="td" colSpan={8} key={`table_row_cell_${rowId}`} padding={0}>
      <TabElement isEmpty={false}>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <DraggableRow
              key={row.id}
              row={row}
              handleReorder={handleReorder}
              categoryId={categoryId}
            />
          ))}
        </Tbody>
      </TabElement>
    </Box>
  );
}

export default Table;
