/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';
import { componentSchema } from 'components/Container/schema';
import { EMPTY_COMPONENT } from 'components/Container/constants';
import ROUTES from 'app/routes';

const LANDING_PAGE_FIELDS = yup.object({
  margined: yup.boolean(),
  userKind: yup.string().required('Pole jest wymagane'),
  slug: yup.string(), // ADD WHEN CONDITION FOR EDIT TO REQUIRE THIS FIELD
});

export const CREATE_SCHEMA = yup.object({
  type: yup.string().nullable(),
  title: yup.string().required('Pole jest wymagane'),
  countries: yup.array().of(yup.string()).nullable(),
  editorial: yup.boolean().nullable(),
  active: yup.string(),
  containersAttributes: yup
    .array()
    .of(componentSchema.concat(LANDING_PAGE_FIELDS)),
});

export const EDIT_SCHEMA = yup.object({
  type: yup.string().nullable(),
  title: yup.string().required('Pole jest wymagane'),
  countries: yup.array().of(yup.string()).nullable(),
  editorial: yup.boolean().nullable(),
  active: yup.string(),
  containersAttributes: yup
    .array()
    .of(componentSchema.concat(LANDING_PAGE_FIELDS)),
});

export const EMPTY_CONTAINER = {
  ...EMPTY_COMPONENT,
  margined: false,
  userKind: 'both',
  slug: undefined,
  containersAttributes: [],
};

export const PAGE_TYPES = {
  TeamPage: {
    backPath: ROUTES.basketball.pages.base,
    label: 'Strony drużyny',
    crumbLabel: 'Dodaj stronę',
    successMessage: 'Strona została pomyślnie utworzona',
    errorMessage: 'Wystąpił problem podczas tworzenia strony',
    updateSuccessMessage: 'Strona została zaktualizowana',
    formHeader: 'Kraj/e, do których ma zostać dodana strona',
  },
  LandingPage: {
    backPath: ROUTES.landingPage.base,
    label: 'Landing Page',
    crumbLabel: 'Dodaj landing page',
    successMessage: 'Landing page został pomyślnie utworzony',
    errorMessage: 'Wystąpił problem podczas tworzenia landing page',
    updateSuccessMessage: 'Landing page został zaktualizowany',
    formHeader: 'Kraj/e, do których ma zostać dodany landing page',
  },
};
