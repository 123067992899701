import { FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import HeaderFormSection from 'components/Container/Sections/HeaderFormSection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import LinkCreatorSection from 'components/LinkCreator';
import { FormComponentProps } from 'types/dashboardCreator';

function SquareBannerForm({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  return (
    <>
      <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Zdjęcie</SectionTitle>

        <DropzoneField
          name={prepareFieldName('image')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 360px x 290px)"
        />
      </FormControl>

      <HeaderFormSection prepareFieldName={prepareFieldName} />

      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </>
  );
}

export default SquareBannerForm;
