const LINK_PARAMETERS_WITH_ARRAY = [
  'badge[]=param_value',
  'gender[]=param_value',
  'categoryIds[]=param_value',
];

const transformLinkParameters = (object: Record<string, any>) => {
  const { linkParameters } = object;

  if (linkParameters && linkParameters.length > 0) {
    const newObject = { ...object };
    newObject.linkParametersAttributes = linkParameters.map(
      (linkParameter: any) => {
        if (
          LINK_PARAMETERS_WITH_ARRAY.includes(linkParameter?.linkType) &&
          linkParameter?.linkParamValue?.includes(',')
        ) {
          return {
            ...linkParameter,
            linkParamValue: linkParameter.linkParamValue
              .replace(/\s/g, '')
              .split(','),
          };
        }

        return linkParameter;
      },
    );
    // disable setting withLinkParameters for text_banner & titled carousel (there's button with link, no standalone link)
    if (!['text_banner', 'titled_carousel'].includes(newObject.kind)) {
      newObject.withLinkParameters = true;
    }
    delete newObject.linkParameters;

    // eslint-disable-next-line no-console
    console.debug('@ transforming link parameters', {
      linkParametersAttributes: newObject.linkParametersAttributes,
    });
    return newObject;
  }

  if (object?.linkParameters) {
    object.linkParametersAttributes = [{ linkType: null }];
  }
  return object;
};

export default transformLinkParameters;

export { transformLinkParameters };
