import omit from 'lodash/omit';
import { Dashboard, ContainerElement } from 'types/dashboard';
import { BUTTON_TYPES } from 'components/Container/Forms/ProductsCarouselForm';
import { DEFAULT_FORM_VALUES } from 'components/Container/constants';
import { transformLinkParameters } from 'utils/linkCreator';
import { transformStringDatesToDateObject } from 'utils/date';

const PARSED_VALUES = ['elements'];

const transformPhotoTags = (object: Record<string, any>) => {
  const { photoTags } = object;

  if (photoTags && photoTags.length > 0) {
    const newObject = { ...object };
    newObject.photoTagsAttributes = photoTags;
    newObject.withTags = true;

    delete newObject.photoTags;
    return newObject;
  }

  return object;
};

const parseNestedAttributes = (object: Record<string, any>) =>
  ({
    ...transformPhotoTags(object),
    ...transformLinkParameters(object),
    ...transformStringDatesToDateObject(object),
    withHeader: object?.title || object?.subtitle,
    withSlideTitle: object?.description,
    withButton: object?.buttonText,
    withContent: object?.content,
  } as { [key: string]: any });

// eslint-disable-next-line import/prefer-default-export
export const parseDefaultValues = (dashboard: Dashboard) => {
  const {
    bannerListings,
    buttonText,
    kind,
    elements,
    queryLink,
    title,
    subtitle,
    specialElements,
    buttonKind,
    photoTags,
  } = dashboard;

  const result = parseNestedAttributes({
    ...(DEFAULT_FORM_VALUES[kind] || {}),
    ...omit(dashboard, PARSED_VALUES),
  });

  result.editMode = true;

  // get sku from query_link
  if (queryLink && queryLink.includes('sku')) {
    [, result.productSKU] = queryLink.split('=');
  }

  if (buttonText) {
    result.buttonType = BUTTON_TYPES.LABEL;
    result.withButton = true;
  }

  if (buttonKind === BUTTON_TYPES.ARROW) {
    result.withButton = true;
  }

  if (title || subtitle) {
    result.withHeader = true;
  }

  if (elements && elements.length) {
    // result.elementsAttributes = [];
    result.elementsAttributes = elements.map((element) => {
      return parseNestedAttributes({ ...element });
    });
    delete result.elements;
  }

  if (specialElements && specialElements.length) {
    result.specialElementsAttributes = [];
    result.specialElementsAttributes = specialElements.map((element) => {
      return parseNestedAttributes({ ...element });
    });

    delete result.specialElements;
  }

  if (bannerListings && bannerListings.length > 0) {
    result.bannerListingsAttributes = bannerListings.map((element) =>
      parseNestedAttributes({ ...element }),
    );
    result.withListingHeader = true;
    delete result.bannerListings;
  }

  if (photoTags && photoTags.length > 0) {
    result.photoTagsAttributes = photoTags;
    result.withTags = true;
    delete result.photoTags;
  }

  if (result.kind === 'image_gallery' && !result.elementsAttributes[0]?.title) {
    result.type = 'noHeaders';
  }

  if (result.disclaimer) {
    result.withDisclaimer = true;
  }

  if (result.image?.url?.length > 0) {
    result.type = 'image';
  }

  if (!result.image?.url) {
    delete result.image;
  }

  if (result.kind === 'graphic_tile_carousel') {
    result.elementsAttributes.forEach((element: ContainerElement) => {
      element.type = element.image.url ? 'image' : 'video';
    });
  }

  return result;
};
