import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import LoadingIndicator from 'components/LoadingIndicator';
import FooterButtons from 'components/FooterButtons';
import ContainerWrapper from 'pages/CategoryContainer/Form/Edit/components/ContainerWrapper';
import {
  useTaxonomyContainerQuery,
  useUpdateTaxonomyContainerMutation,
} from 'app/services/categoryContainerApi';
import {
  BODY_SCHEMA,
  DEFAULT_FORM_VALUE,
} from 'pages/CategoryContainer/Form/constants';
import ROUTES from 'app/routes';
import useRoute from 'utils/useRoute';
import HeaderNavigation from 'pages/CategoryContainer/Form/Edit/components/HeaderNavigation';

function TaxonomyContainerEdit() {
  const { id = '' } = useParams();
  const backPath = useRoute(ROUTES.categoryContainer.base);

  const navigate = useNavigate();
  const {
    data: { data: taxonomyContainer } = {},
    isFetching,
    isSuccess,
  } = useTaxonomyContainerQuery(id);

  const [updateTaxonomyContainer, { isLoading: updateRunning }] =
    useUpdateTaxonomyContainerMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateTaxonomyContainer(data).unwrap();
      navigate(backPath);
      toast.success(`Zmiany w kategorii zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (isSuccess && taxonomyContainer) {
      reset({ ...DEFAULT_FORM_VALUE, ...taxonomyContainer });
    }
  }, [isSuccess, taxonomyContainer, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        categoryContainerPath={backPath}
        genderName={taxonomyContainer?.name!}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="taxonomy-container-editor"
          onSubmit={onSubmit}
          pb="120px"
        >
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="taxonomy-container-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default TaxonomyContainerEdit;
