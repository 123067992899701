import { Flex } from '@chakra-ui/react';
import { StatusType } from 'types/common';

interface StatusBoxProps {
  status: StatusType;
}

const STATUS: {
  [key in StatusType]: {
    bgColor: string;
    label: string;
    color?: string;
  };
} = {
  active: {
    bgColor: 'complementary.listingGreen',
    label: 'Aktywny',
  },
  planned: {
    bgColor: 'complementary.listingBlue',
    label: 'Zaplanowany',
  },
  archived: {
    bgColor: 'complementary.whiteGrey',
    label: 'Zarchiwizowany',
    color: '#8b8c91',
  },
  unactive: {
    bgColor: 'complementary.listingGrey',
    label: 'Nieaktywny',
  },
};

function StatusBox({ status }: StatusBoxProps) {
  const currentStatus = STATUS[status];

  return (
    <Flex
      w="128px"
      h="56px"
      bg={currentStatus.bgColor}
      color={currentStatus?.color || '#000000'}
      align="center"
      justify="center"
    >
      {currentStatus.label}
    </Flex>
  );
}

export default StatusBox;
