import { useEffect, useState } from 'react';
import {
  FormControl,
  InputProps as ChakraInputProps,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import get from 'lodash/get';

import { toast } from 'react-toastify';
import { MultiValueInputWrapper } from 'components/Form/TagInput/style';

const Keys = {
  TAB: 9,
  SPACE: 32,
  ENTER: 13,
  COMMA: 188,
};

type InputProps = ChakraInputProps & {
  name: string;
  label?: string | React.ReactNode;
  defaultValue?: Tag[];
  validate?: (value: string) => boolean;
  suggestions?: Tag[];
  autocomplete?: boolean;
  validationMessage?: string;
};

function TagInput({
  autocomplete,
  label,
  name,
  isRequired,
  defaultValue,
  validate,
  validationMessage,
  suggestions,
}: InputProps) {
  const {
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [tags, setTags] = useState<Tag[]>(defaultValue || []);

  const errorMessage = get(errors, name)?.message as string;

  const handleAddition = (tag: Tag) => {
    if (typeof validate === 'function') {
      if (validate(tag.text)) {
        setTags([...tags, tag]);
      } else {
        toast.warn(validationMessage);
      }
    } else {
      setTags([...tags, tag]);
    }
  };
  const handleDelete = (i: number) => {
    setTags(tags?.filter((tag, index) => index !== i));
  };

  useEffect(() => {
    const value = getValues(name);

    if (value && typeof value === 'string') {
      const t: Tag[] = value
        ?.split(', ')
        ?.map((v: string) => ({ id: v, text: v }));

      setTags(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const t = tags?.map((tag) => tag?.text);
    setValue(name, t?.join(', '));
  }, [tags, name, setValue]);

  return (
    <FormControl isRequired={isRequired} isInvalid={Boolean(errorMessage)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <MultiValueInputWrapper>
        <ReactTags
          allowDragDrop={false}
          tags={tags}
          handleAddition={handleAddition}
          handleDelete={handleDelete}
          delimiters={[Keys.COMMA, Keys.ENTER]}
          placeholder=""
          autocomplete={autocomplete}
          suggestions={suggestions}
        />
      </MultiValueInputWrapper>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

TagInput.defaultProps = {
  label: '',
  defaultValue: null,
  validate: null,
  suggestions: null,
  autocomplete: false,
  validationMessage: 'Wprowadź poprawny adres email',
};

export default TagInput;
