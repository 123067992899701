import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<InPostPoint, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'OPIS',
      accessorKey: 'description',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KOD POCZTOWY',
      accessorKey: 'postCode',
      cell: (info) => info.getValue(),
    },
    {
      header: 'WOJEWÓDZTWO',
      accessorKey: 'province',
      cell: (info) => info.getValue(),
    },
    {
      header: 'MIASTO',
      accessorKey: 'city',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ULICA',
      accessorKey: 'street',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NUMER BUDYNKU',
      accessorKey: 'buildingNumber',
      cell: (info) => info.getValue(),
    },
    {
      header: 'SZEROKOŚĆ GEOGRAFICZNA',
      accessorKey: 'lat',
      cell: (info) => info.getValue(),
    },
    {
      header: 'DŁUGOŚĆ GEOGRAFICZNA',
      accessorKey: 'lng',
      cell: (info) => info.getValue(),
    },
  ];
}

export default GetColumns;
