import { Link } from 'react-router-dom';
import ROUTES from 'app/routes';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/LeaderCategories/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import useRoute from 'utils/useRoute';
import {
  useDeleteLeaderCategoryMutation,
  useLeaderCategoriesQuery,
} from 'app/services/leaderCategoriesApi';

function LeaderCategories() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newLeaderCategoryPath = useRoute(ROUTES.leaderCategories.create);

  const [, state] = useDeleteLeaderCategoryMutation({
    fixedCacheKey: 'leaderCategories',
  });

  const {
    isFetching: leaderCategoriesLoading,
    data: { data: leaderCategories, totals } = { data: [], totals: 0 },
  } = useLeaderCategoriesQuery(queryString);

  const { isLoading } = state;
  const showLoading = leaderCategoriesLoading || isLoading;

  return (
    <ListingLayout
      title="Kategoria rozbiegówki"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWA KATEGORIA ROZBIEGÓWKI',
          as: Link,
          to: newLeaderCategoryPath,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={leaderCategories}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default LeaderCategories;
