import { ColumnDef } from '@tanstack/react-table';

function Columns(): ColumnDef<NextStepItem, string>[] {
  return [
    { header: 'ID', cell: (info) => info.row.index + 1 },
    {
      header: 'WYNIK KOLEJNEGO KROKU',
      accessorKey: 'nextStepValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TYP KOLEJNEGO KROKU',
      accessorKey: 'type',
      cell: (info) => info.getValue(),
    },
    {
      header: 'LICZBA WYSTĄPIEŃ',
      accessorKey: 'numberOfOccurrences',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default Columns;
