import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react';
import { generatePath, Link, useParams } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import { GENDER_OPTIONS } from 'utils/constants';

interface HeaderNavigationProps {
  name: string;
  id: string;
}

function HeaderNavigation({ name, id }: HeaderNavigationProps) {
  const { store } = useParams();
  const { queryString, search } = useQueryParams();
  const pickedGender = search.get('gender');
  const dashboardPath =
    generatePath(ROUTES.dashboard.base, {
      store,
    }) + queryString;

  const pickedGenderLabel = GENDER_OPTIONS.find(
    (option) => option.value === pickedGender,
  )?.label;

  return (
    <Flex justify="space-between" align="center">
      <Box mb={6}>
        <Text variant="historyNavigation" as={Link} to={dashboardPath}>
          <MdArrowBack />
          Powrót
        </Text>
        <Breadcrumb mt={4}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={dashboardPath} fontWeight={700}>
              Dashboard
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>
              {name} ({pickedGenderLabel})
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
      <Button
        fontWeight={700}
        fontSize="12px"
        lineHeight="20px"
        paddingX="32px"
        as={Link}
        to={
          generatePath(ROUTES.dashboard.containers.edit, {
            id,
            store,
          }) + queryString
        }
      >
        EDYTUJ
      </Button>
    </Flex>
  );
}

export default HeaderNavigation;
