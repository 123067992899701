import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Box, BoxProps } from '@chakra-ui/react';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import TabElement from 'components/Table/Table';
import Th from 'components/Table/Th';
import columns from 'pages/Statistics/TopSearch/components/NestedTable/columns';
import TableRow from 'pages/Statistics/TopSearch/components/NestedTable/TableRow';
import { useNextStepsQuery } from 'app/services/statisticsApi';
import useQueryParams from 'utils/useQueryParams';
import Thead from 'pages/Statistics/TopSearch/components/NestedTable/components/Thead';
import Tr from 'pages/Statistics/TopSearch/components/NestedTable/components/Tr';
import Tbody from 'pages/Statistics/TopSearch/components/NestedTable/components/Tbody';

type TableProps = BoxProps & {
  searchPhrase: string;
};

function Table({ searchPhrase }: TableProps) {
  const { queryString } = useQueryParams();

  const queryParams = useMemo(
    () => qs.stringify({ searchPhrase, ...qs.parse(queryString) }),
    [queryString, searchPhrase],
  );

  const { data: elements = [] } = useNextStepsQuery(`?${queryParams}`);

  const table = useReactTable({
    data: elements,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  if (isEmpty(elements)) return null;

  return (
    <Box as="td" colSpan={5} padding={0}>
      <TabElement isEmpty={false}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`thread_row_${searchPhrase}_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`thread_column_${searchPhrase}_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} row={row} />
          ))}
        </Tbody>
      </TabElement>
    </Box>
  );
}

export default Table;
