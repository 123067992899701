/* eslint-disable func-names */
import * as yup from 'yup';
import { Box, Button, Center, HStack, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import { getSelectedRows } from 'selectors/uiSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import DatePicker from 'components/Form/Date';
import Time from 'components/Form/Time';
import { transformActivityDates } from 'utils/date';
import { useBulkUpdateMutation } from 'app/services/dashboardApi';
import { validateActivityDates } from 'components/Container/schema';

const schema = yup.object({
  timeTo: yup
    .mixed()
    .test(
      'dateToLaterThanFrom',
      'Data końcowa musi być późniejsza od początkowej',
      function (value, testContext) {
        const { dateFrom, timeFrom, dateTo } = testContext.parent;

        if (dateFrom && value) {
          return validateActivityDates(dateFrom, timeFrom, dateTo, value);
        }
        return true;
      },
    )
    .nullable(),
  dateTo: yup
    .mixed()
    .test(
      'dateToLaterThanFrom',
      'Data końcowa musi być późniejsza od początkowej',
      function (value, testContext) {
        const { dateFrom, timeFrom, timeTo } = testContext.parent;

        if (dateFrom && value) {
          return validateActivityDates(dateFrom, timeFrom, value, timeTo);
        }
        return true;
      },
    )
    .nullable(),
  dateFrom: yup.date().nullable(),
  timeFrom: yup.date().nullable(),
});

interface FormProps {
  onClose: () => void;
}

function Form({ onClose }: FormProps) {
  const [bulkUpdate] = useBulkUpdateMutation();
  const selectedRows = useAppSelector(getSelectedRows);

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: {},
  });

  const { handleSubmit, formState } = methods;

  const { isDirty, isValid, isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { activeTo, activeFrom } = transformActivityDates(data);
      await bulkUpdate({
        dashboardContainer: {
          activeTo,
          activeFrom,
        },
        dashboardContainerIds: selectedRows,
      }).unwrap();
      toast.success('Zmiany zostały zastosowane');
      onClose();
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  return (
    <Center>
      <FormProvider {...methods}>
        <Box as="form" onSubmit={onSubmit} w="452px">
          <VStack spacing="56px">
            <VStack w="100%" spacing="24px">
              <HStack spacing="16px">
                <DatePicker
                  name="dateFrom"
                  label="Aktywny od"
                  showError
                  rangeSelect={false}
                />
                <DatePicker
                  name="dateTo"
                  label="Aktywny do"
                  showError
                  rangeSelect={false}
                />
              </HStack>
              <HStack spacing="16px" pt="8px">
                <Time
                  name="timeFrom"
                  label="Godzina rozpoczęcia"
                  errorKey="timeFrom"
                  showError
                />
                <Time
                  name="timeTo"
                  label="Godzina zakończenia"
                  errorKey="timeTo"
                  showError
                />
              </HStack>
            </VStack>
            <VStack w="100%" spacing="24px">
              <Button
                isLoading={isSubmitting}
                isDisabled={!isDirty || !isValid}
                type="submit"
                w="100%"
              >
                WYBIERZ
              </Button>
              <Button variant="outlined" w="100%" onClick={onClose}>
                ANULUJ
              </Button>
            </VStack>
          </VStack>
        </Box>
      </FormProvider>
    </Center>
  );
}

export default Form;
