import { DeepPartial, SystemStyleObject, Theme } from '@chakra-ui/react';

const bold: SystemStyleObject = {
  fontWeight: 600,
};
const header: SystemStyleObject = {
  fontSize: '16px',
};

const h1: SystemStyleObject = {
  fontFamily: 'Montserrat',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '32px',
};

const h2: SystemStyleObject = {
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
};

const sectionTitle: SystemStyleObject = {
  fontFamily: 'Jost',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '24px',
};

const boldSectionTitle: SystemStyleObject = {
  fontFamily: 'Jost',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
};

const historyNavigation: SystemStyleObject = {
  fontSize: '14px',
  color: 'black',
  display: 'inline-flex',
  alignItems: 'center',
  gap: '12px',
  textDecoration: 'none',
  cursor: 'pointer',
};

const Text: DeepPartial<Theme['components']['Text']> = {
  baseStyle: {
    color: 'black',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
  },
  variants: {
    bold,
    header,
    h1,
    h2,
    sectionTitle,
    boldSectionTitle,
    historyNavigation,
  },
};

export default Text;
