import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { GENDER } from 'components/Container/constants';
import useQueryParams from 'utils/useQueryParams';
import { TAB_OPTIONS } from 'pages/WeatherCategories/Preview/constants';
import BackNavigationButton from 'components/BackNavigationButton';

type HeaderNavigationProps = {
  backPath: string;
  gender: string;
};

function HeaderNavigation({ backPath, gender }: HeaderNavigationProps) {
  const { search } = useQueryParams();
  const genderName = GENDER[gender as keyof typeof GENDER];
  const seasonName = search.get('season');
  const season = TAB_OPTIONS.find((option) => option.value === seasonName);

  return (
    <Box mb={6}>
      <BackNavigationButton />
      <Breadcrumb mt={4}>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={backPath} fontWeight={700}>
            Kategoria pogodowa
          </BreadcrumbLink>
        </BreadcrumbItem>
        {genderName && (
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={backPath} fontWeight={700}>
              {genderName}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
        {season && (
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={backPath} fontWeight={700}>
              {season.label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>Edytuj warunek</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Box>
  );
}

export default HeaderNavigation;
