import { VStack, Text, AspectRatio } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';

interface ImagePlaceholderProps {
  imageRatio?: number;
}

function ImagePlaceholder({ imageRatio }: ImagePlaceholderProps) {
  return (
    <AspectRatio w="100%" ratio={imageRatio}>
      <VStack alignItems="center" justifyContent="center">
        <BiImage size={30} />
        <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
          Tu pojawi się dodane zdjęcie
        </Text>
      </VStack>
    </AspectRatio>
  );
}

ImagePlaceholder.defaultProps = {
  imageRatio: 1,
};

export default ImagePlaceholder;
