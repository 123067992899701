import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { HStack, Text, Flex } from '@chakra-ui/react';
import { MdDragIndicator } from 'react-icons/md';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useUpdateContainerPositionMutation } from 'app/services/dashboardApi';
import { DragItem } from 'types/dashboard';

export const Item = styled.div<{ isDragging: boolean }>`
  border: 1px #000 solid;
  padding: 3px 12px 3px 16px;
  width: 100%;
  align-items: center;
  min-height: 48px;
  display: flex;
  align-items: center;
  margin: 14px 0;

  ${({ isDragging }) =>
    isDragging
      ? css`
          border: none;

          > div {
            opacity: 0;
          }
        `
      : null}
`;

export const ActionButton = styled.button`
  display: block;
  padding: 5px;
  cursor: grab;
`;

type Container = {
  id: number;
  name: string | null;
};

export interface CardProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DashboardContainerProps {
  container: Container;
  moveItem: (item: DragItem, dragIndex: number, hoverIndex: number) => void;
  index: number;
  onDelete: (id: number) => void;
  gender: 'male' | 'female' | 'girls' | 'boys';
}

let hoverDebounce: ReturnType<typeof setTimeout>;

function DashboardContainer({
  container,
  moveItem,
  index,
  onDelete,
  gender,
}: DashboardContainerProps) {
  const dragRef = useRef<HTMLButtonElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [originalIndex, setOriginalIndex] = useState(index);
  const [updatePosition] = useUpdateContainerPositionMutation();

  const synchronizeApi = (item: DragItem) => {
    clearTimeout(hoverDebounce);
    hoverDebounce = setTimeout(() => {
      updatePosition({
        id: item.id,
        position: item.index,
        gender,
      });
    }, 500);
  };

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(element: DragItem) {
      synchronizeApi(element);
      setOriginalIndex(element.index);
    },
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 5;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(item, dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'card',
    item: () => {
      return { id: container.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (element: any, monitor) => {
      const { index: currentIndex } = element;
      const didDrop = monitor.didDrop();
      // reset to previous state if not dropped correctly
      if (!didDrop) {
        moveItem(element, currentIndex, originalIndex);
      }
    },
  });

  useEffect(() => {
    if (!isDragging) {
      setOriginalIndex(index);
    }
  }, [isDragging, index]);

  drag(dragRef);
  drop(preview(previewRef));

  return (
    <Item
      ref={previewRef}
      key={container.id}
      data-handler-id={handlerId}
      isDragging={isDragging}
    >
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Text fontSize={16} fontWeight={700}>
          {container.name || `Kontener id: ${container.id}`}
        </Text>

        <HStack>
          <ActionButton type="button" onClick={() => onDelete(container.id)}>
            <FaRegTrashAlt size={20} />
          </ActionButton>
          <ActionButton ref={dragRef}>
            <MdDragIndicator size={20} />
          </ActionButton>
        </HStack>
      </Flex>
    </Item>
  );
}

export default DashboardContainer;
