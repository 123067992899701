import { isEmpty, omit } from 'lodash';
import { transformLinkParameters } from 'utils/linkCreator';

const PARSED_VALUES: string[] = ['activeFrom, activeTo'];

const parseDefaultValues = (announcement: any) => {
  const result: Record<string, any> = {
    ...omit(announcement, PARSED_VALUES),
  };
  const { activeFrom, activeTo, linkParameters } = announcement;

  if (activeFrom) {
    result.dateFrom = new Date(activeFrom);
    result.timeFrom = new Date(activeFrom);
  }

  if (activeTo) {
    result.dateTo = new Date(activeTo);
    result.timeTo = new Date(activeTo);
  }

  if (isEmpty(linkParameters)) {
    result.linkParametersAttributes = [{ linkType: null }];
  } else {
    result.linkParametersAttributes = announcement.linkParameters;
  }

  return transformLinkParameters(result);
};

export default parseDefaultValues;
