/* eslint-disable react/no-array-index-key */
import * as yup from 'yup';
import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Category/Form/Create/components/ContainerWrapper';
import { useCreateCategoryMutation } from 'app/services/categoryApi';
import ROUTES from 'app/routes';
import { transformErrors } from 'utils/api';
import AddButton from 'components/AddButton';
import { FORM_MAX_WIDTH } from 'utils/constants';
import DndWrapper from 'components/DndWrapper';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Category/Form/constants';
import useRoute from 'utils/useRoute';

const schema = yup.object({
  containers: yup.array().of(BODY_SCHEMA),
});

function CategoryCreate() {
  const backPath = useRoute(ROUTES.category.base);
  let debounce: ReturnType<typeof setTimeout>;
  const [createCategory, { isLoading: isCreating }] =
    useCreateCategoryMutation();

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: { containers: [DEFAULT_VALUES] },
  });

  const {
    fields: containers,
    append,
    remove: removeContainerHandler,
    move,
  } = useFieldArray({
    control: methods.control,
    name: 'containers',
  });

  const addContainerHandler = useCallback(() => {
    return append(DEFAULT_VALUES as any);
  }, [append]);

  const navigate = useNavigate();

  const { handleSubmit, setError } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await Promise.all(
        data
          .containers!.filter((v: any) => v)
          .map(async (formValues, index) => {
            const response = await createCategory(formValues);
            return { ...response, index };
          }),
      );

      let errorOccured = false;
      result.forEach((response: any) => {
        const hasError = Object.hasOwnProperty.call(response, 'error');
        if (hasError) {
          errorOccured = true;
          const {
            error: { data: errors },
            index,
          } = response;
          const transformedErrors = transformErrors(errors, index);
          Object.keys(transformedErrors).forEach((field: string) => {
            setError(`containers[${index}].${field}` as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        }
      });

      if (!errorOccured) {
        navigate(backPath);
        toast.success(
          containers.length > 1
            ? `Kategorie zostały pomyślnie utworzone!`
            : `Kategoria został pomyślnie utworzona!`,
        );
      }
    } catch (err) {
      // eslint-disable-next-line
      console.debug(err);
    }
  });

  const reorderContainers = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      clearTimeout(debounce);
      // eslint-disable-next-line
      debounce = setTimeout(() => move(dragIndex, hoverIndex), 500);
    },
    [move],
  );

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{ label: 'Kategorie', to: backPath }}
        crumbs={[{ to: '', label: 'Dodaj kategorię' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="category-creator"
          onSubmit={onSubmit}
          pb="120px"
          maxW={FORM_MAX_WIDTH}
        >
          {containers.map((container, index) => (
            <DndWrapper
              key={`tags-creator-container_${container.id}`}
              id={container.id}
              isLoading={isCreating}
              index={index}
              reorderContainers={reorderContainers}
              multipleContainers={containers.length > 1}
              removeContainer={removeContainerHandler}
              ContainerWrapper={ContainerWrapper}
            />
          ))}
          <AddButton addHandler={addContainerHandler} mt="8px" />
        </Box>
      </FormProvider>
      <FooterButtons
        isLoading={isCreating}
        isCreate
        formId="category-creator"
        backPath={backPath}
      />
    </Box>
  );
}

export default CategoryCreate;
