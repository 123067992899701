import { useMemo } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import Lottie from 'react-lottie';
import Card from 'components/Card';
import useImage from 'utils/useImage';

const isValidJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

function SplashScreenPreview() {
  const { control } = useFormContext();
  const formValues = useWatch({
    control,
  });

  const imagePreview = useImage(formValues?.background);
  const animationPreview = isValidJson(formValues?.animation)
    ? JSON.parse(formValues?.animation)
    : null;

  const options = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animationPreview,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [animationPreview],
  );

  return (
    <Card
      padding="32px"
      maxW="452px"
      style={{ position: 'sticky', top: '65px' }}
    >
      <Heading as="h4" fontSize={16} mb={8} pt={2}>
        PODGLĄD
      </Heading>
      <Box
        bgColor={
          formValues?.animationBackgroundColor || 'complementary.whiteGrey'
        }
        bgImage={animationPreview ? 'none' : imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
      >
        <Lottie options={options} width="100%" height="512px" />
      </Box>
    </Card>
  );
}

export default SplashScreenPreview;
