import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { BoxProps } from '@chakra-ui/react';
import TabElement from 'components/Table/Table';
import Thead from 'components/Table/Thead';
import Tr from 'components/Table/Tr';
import Tbody from 'components/Table/Tbody';
import Th from 'components/Table/Th';
import columns from 'pages/CategoryTags/Preview/components/MainTable/columns';
import TableWrapper from 'components/Table/TableWrapper';
import {
  useDeleteTagMutation,
  useReorderTagsMutation,
  useTagsQuery,
} from 'app/services/categoryTags';
import DraggableRow from './DraggableRow';

type TableProps = BoxProps & {
  categoryId: number;
};

function Table({ categoryId, ...props }: TableProps) {
  const [deleteTag] = useDeleteTagMutation();
  const [reorderTag] = useReorderTagsMutation();

  const { data: { data: tags } = { data: [] } } = useTagsQuery({
    categoryId,
  });

  const handleReorder = async (id: number, targetRowIndex: number) => {
    try {
      await reorderTag({
        id,
        categoryId,
        body: {
          placement: targetRowIndex,
        },
      }).unwrap();
    } catch (error) {
      toast.error('Failed to reorder');
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteTag({ id, categoryId }).unwrap();
      toast.success('Pomyślnie usunięto tag');
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania tagu');
    }
  };

  const table = useReactTable({
    data: tags,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <TableWrapper {...props}>
      <TabElement isEmpty={false}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`header_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`header_cell_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <DraggableRow
              key={row.id}
              row={row}
              handleReorder={handleReorder}
              handleDelete={handleDelete}
              categoryId={categoryId}
            />
          ))}
        </Tbody>
      </TabElement>
    </TableWrapper>
  );
}

export default Table;
