import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Regulations/Form/Edit/components/ContainerWrapper';
import parseDefaultValues from 'pages/Regulations/Form/Edit/parseDefaultValues';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA } from 'pages/Regulations/Form/constants';
import {
  useRegulationQuery,
  useUpdateRegulationMutation,
} from 'app/services/regulationsApi';
import useRoute from 'utils/useRoute';

function RegulationEdit() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.regulations.base);

  const { data: { data: regulation } = {}, isFetching } =
    useRegulationQuery(id);
  const [updateRegulation, { isLoading: updateRunning }] =
    useUpdateRegulationMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateRegulation({ ...data, id }).unwrap();
      navigate(backPath);
      toast.success(`Zmiany w regulaminie zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (regulation) reset(parseDefaultValues(regulation));
  }, [regulation, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Regulamin',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj regulamin' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="regulation-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="regulation-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default RegulationEdit;
