import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import get from 'lodash/get';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import styled from '@emotion/styled';
import pl from 'date-fns/locale/pl';
import Input from 'components/Form/Time/input';

registerLocale('pl', pl);

const Popper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
`;

type TimeProps = Omit<ReactDatePickerProps, 'onChange'> & {
  label?: string;
  name: string;
  showError?: boolean;
  isRequired?: boolean;
  hidden?: boolean;
  errorKey?: string;
};

const Wrapper = styled(FormControl)`
  position: relative;
  .react-datepicker__close-icon {
    position: absolute;
    left: 65px;

    &:after {
      background-color: #000;
      font-size: 14px;
      width: 12px;
      height: 12px;
      display: block;
      line-height: 6px;
    }
  }
`;

function Time({
  label,
  name,
  showError,
  isRequired,
  hidden,
  errorKey,
  ...props
}: TimeProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const errKey = errorKey || name;

  const errorMessage = get(errors, errKey)?.message as string;

  return (
    <Wrapper
      hidden={hidden}
      isInvalid={Boolean(errorMessage)}
      isRequired={isRequired}
    >
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <ReactDatePicker
            onChange={onChange}
            popperContainer={Popper}
            portalId="root-portal"
            onBlur={onBlur}
            selected={value}
            customInput={React.createElement(React.forwardRef(Input))}
            showTimeSelect
            showTimeSelectOnly
            dateFormat="p"
            locale="pl"
            strictParsing
            isClearable
            popperModifiers={[
              {
                name: 'applyStyles',
                options: {
                  zIndex: 100,
                },
              },
            ]}
            {...props}
          />
        )}
      />
      {showError && (
        <FormErrorMessage pos="absolute">{errorMessage}</FormErrorMessage>
      )}
    </Wrapper>
  );
}

Time.defaultProps = {
  hidden: false,
  isRequired: false,
  showError: false,
  label: '',
  errorKey: '',
};

export default Time;
