import { omit } from 'lodash';
import { transformLinkParameters } from 'utils/linkCreator';

const PARSED_VALUES: string[] = ['activeFrom', 'activeTo'];

const parseDefaultValues = (notification: Notification) => {
  const result: Record<string, any> = {
    ...omit(notification, PARSED_VALUES),
  };
  const { activeFrom, activeTo } = notification;

  if (activeFrom) {
    result.dateFrom = new Date(activeFrom);
    result.timeFrom = new Date(activeFrom);
  }

  if (activeTo) {
    result.dateTo = new Date(activeTo);
    result.timeTo = new Date(activeTo);
  }

  result.notificationCategoryId =
    notification.notificationCategoryId?.toString();

  return transformLinkParameters(result);
};

export default parseDefaultValues;
