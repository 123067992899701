import { DeepPartial, Theme } from '@chakra-ui/react';

const Menu: DeepPartial<Theme['components']['Menu']> = {
  parts: ['item', 'list'],
  baseStyle: {
    item: {
      fontSize: 16,
      p: '8px 16px',
      _hover: {
        bg: 'complementary.lightGrey',
      },
      _focus: {
        bg: 'complementary.lightGrey',
      },
    },
    list: {
      minW: '160px',
      borderRadius: 0,
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    },
  },
};

export default Menu;
