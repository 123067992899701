import { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/SplashScreens/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/SplashScreens/Form/constants';
import { transformErrors } from 'utils/api';
import {
  useSplashScreenQuery,
  useUpdateSplashScreenMutation,
} from 'app/services/splashScreensApi';
import SplashScreenPreview from 'components/Container/Previews/SplashScreenPreview';
import useRoute from 'utils/useRoute';
import parseDefaultValues from './parseDefaultValues';

function SplashScreenEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.splashScreens.base);

  const { data: { data: splashScreen } = {}, isFetching } =
    useSplashScreenQuery(id);
  const [updateSplashScreen, { isLoading: updateRunning }] =
    useUpdateSplashScreenMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateSplashScreen(data)
        .unwrap()
        .then(() => {
          navigate(backPath);
          toast.success(`Zmiany w splash screenie zostały zastosowane`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (splashScreen)
      reset({ ...DEFAULT_VALUES, ...parseDefaultValues(splashScreen) });
  }, [splashScreen, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Splash screen',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj splash screen' }]}
      />
      <FormProvider {...methods}>
        <Flex gap={4} mb={4} alignItems="flex-start">
          <Box
            as="form"
            id="splash-screen-editor"
            onSubmit={onSubmit}
            pb="120px"
          >
            {isFetching && <LoadingIndicator />}
            {!isFetching && (
              <ContainerWrapper isLoading={updateRunning || isFetching} />
            )}
          </Box>
          <SplashScreenPreview />
        </Flex>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="splash-screen-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default SplashScreenEdit;
