import { FormControl, Stack } from '@chakra-ui/react';
import React from 'react';
import SectionTitle from 'components/Form/SectionTitle';
import DropzoneField from 'components/Form/Dropzone';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import LinkCreatorSection from 'components/LinkCreator';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  isEditMode?: boolean;
}

function FormBody({ prepareFieldName, isEditMode }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Input name={prepareFieldName('title')} label="Tytuł" />
      <SectionTitle as="legend" mb="0">
        Przycisk
      </SectionTitle>
      <Input name={prepareFieldName('buttonText')} label="Treść przycisku" />
      <ColorPicker
        name={prepareFieldName('buttonTextColor')}
        label="Kolor tekstu przycisku"
      />
      <ColorPicker
        name={prepareFieldName('buttonBackgroundColor')}
        label="Kolor tła przycisku"
      />

      <SectionTitle as="legend" mb="0">
        Link
      </SectionTitle>
      <Input name={prepareFieldName('linkText')} label="Treść linku" />
      <ColorPicker
        name={prepareFieldName('linkTextColor')}
        label="Kolor treści linku"
      />
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />

      <FormControl as="fieldset">
        <SectionTitle as="legend">Zdjęcie tła</SectionTitle>
        <DropzoneField
          name={prepareFieldName('background')}
          title="Dodaj zdjęcie w formacie .png, .jpg"
        />
      </FormControl>

      <ToggleCheckbox label="Ostatni ekran" name="isFinal" />
    </Stack>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
