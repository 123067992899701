import { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  FormControl,
  FormControlProps,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { GrCircleQuestion } from 'react-icons/gr';
import SectionTitle from 'components/Form/SectionTitle';
import FormSwitch from 'components/Form/Switch';

interface OptionalFieldsetProps extends FormControlProps {
  children: React.ReactNode;
  title: string;
  isActive?: boolean;
  withFormSwitch?: string;
  headerWidth?: number | string;
  sectionWidth?: number | string;
  hint?: string;
}

function OptionalFieldset({
  children,
  title,
  isActive,
  withFormSwitch,
  headerWidth,
  sectionWidth,
  hint,
  ...rest
}: OptionalFieldsetProps) {
  const [active, setActive] = useState(isActive);

  const toggleActivity = useCallback(() => {
    setActive(!active);
  }, [active]);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <FormControl as="fieldset" maxW={sectionWidth} {...rest}>
      <Flex justifyContent="space-between" maxW={headerWidth}>
        <SectionTitle as="legend" display="flex" gap={3} alignItems="center">
          {title}
          {hint ? (
            <Tooltip placement="auto" label={hint}>
              <span>
                <GrCircleQuestion size={20} />
              </span>
            </Tooltip>
          ) : null}
        </SectionTitle>
        {withFormSwitch ? (
          <FormSwitch name={withFormSwitch} />
        ) : (
          <Switch onChange={toggleActivity} isChecked={!!active} />
        )}
      </Flex>

      <motion.div
        initial={{
          height: active ? 'auto' : 0,
          opacity: active ? 1 : 0,
          overflow: active ? 'visible' : 'hidden',
        }}
        animate={{
          height: active ? 'auto' : 0,
          opacity: active ? 1 : 0,
          overflow: active ? 'visible' : 'hidden',
        }}
      >
        {children}
      </motion.div>
    </FormControl>
  );
}

OptionalFieldset.defaultProps = {
  isActive: false,
  withFormSwitch: null,
  headerWidth: '100%',
  sectionWidth: '100%',
  hint: null,
};

export default OptionalFieldset;
