import * as yup from 'yup';
import { linkParametersSchema } from 'components/Container/schema';

export const BODY_SCHEMA = yup.object({
  title: yup.string().nullable(),
  background: yup.mixed().test({
    test(file, context) {
      if (!file) {
        return context.createError({ message: 'Zdjęcie tła jest wymagane' });
      }
      return true;
    },
  }),
  buttonBackgroundColor: yup.string().nullable(),
  buttonText: yup.string(),
  buttonTextColor: yup.string().nullable(),
  linkParametersAttributes: yup.array().of(
    yup.object().shape({
      linkType: yup.string().nullable(),
      linkParamValue: linkParametersSchema.linkParamValue,
    }),
  ),
  linkText: yup.string(),
  linkTextColor: yup.string().nullable(),
  isFinal: yup.boolean(),
});

export const DEFAULT_VALUES = {
  title: '',
  background: null,
  buttonBackgroundColor: '#000000',
  buttonText: '',
  buttonTextColor: '#FFFFFF',
  linkParametersAttributes: [{ linkType: undefined }],
  linkText: '',
  linkTextColor: '#000000',
  isFinal: false,
};
