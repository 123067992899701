import { AccordionItem, AccordionPanel, FormControl } from '@chakra-ui/react';
import { useCallback } from 'react';
import { UseFieldArrayMove } from 'react-hook-form';
import DropzoneField from 'components/Form/Dropzone';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import SectionTitle from 'components/Form/SectionTitle';
import AccordionButton from 'components/Collapsible/AccordionButton';
import Textarea from 'components/Form/Textarea';

interface AnnouncementElementSectionProps {
  id: string;
  itemIndex: number;
  prepareFieldName: (name: string) => string;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
}

function AnnouncementsElementSection({
  id,
  itemIndex,
  prepareFieldName,
  move,
  remove,
}: AnnouncementElementSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`elementsAttributes[${itemIndex}].${name}`),
    [itemIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0} overflow="visible">
      <AccordionButton
        id={id}
        index={itemIndex}
        move={move}
        label={`ELEMENT ${itemIndex + 1}`}
        remove={remove}
        isDraggable={false}
      />
      <AccordionPanel pl={0} pr={0} pt={12} pb={0} overflow="visible">
        <FormControl as="fieldset" mb={8}>
          <Textarea
            name={fieldname('description')}
            placeholder="Opis"
            label="Opis"
          />
        </FormControl>
        <FormControl as="fieldset" mb={4} maxW={FORM_BODY_MAX_WIDTH}>
          <SectionTitle as="legend">Logo</SectionTitle>
          <DropzoneField
            name={fieldname('image')}
            title="Dodaj zdjęcie w formacie .png, .jpg"
          />
        </FormControl>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default AnnouncementsElementSection;
