import { useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import {
  Accordion,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import get from 'lodash/get';

import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';
import { DEFAULT_FORM_VALUES } from 'components/Container/constants';
import TitledCarouselSection from 'components/Container/Sections/TitledCarouselSection';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import Input from 'components/Form/Input';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import {
  BUTTON_TYPES,
  BUTTON_TYPE_LABEL,
} from 'components/Container/Forms/ProductsCarouselForm';
import LinkCreatorSection from 'components/LinkCreator';

// kind: titled_carousel
function TitledCarouselForm({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const dispatch = useAppDispatch();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'fieldId',
  });

  const [selectedButtonType, withHeader, withButton] = useWatch({
    control,
    name: [
      prepareFieldName('buttonKind'),
      prepareFieldName('withHeader'),
      prepareFieldName('withButton'),
    ],
  });

  const buttonKindError = get(errors, prepareFieldName('buttonKind'))
    ?.message as string;

  useEffect(() => {
    if (!withHeader) {
      setValue(prepareFieldName('title'), '');
    }
    if (!withButton) {
      setValue(prepareFieldName('buttonKind'), '');
      setValue(prepareFieldName('buttonText'), '');
      setValue(prepareFieldName('linkParametersAttributes'), [
        { linkType: null },
      ]);
    }
  }, [prepareFieldName, setValue, withButton, withHeader]);

  const removeElement = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [isEditMode, remove, dispatch, fields],
  );

  const addSlide = useCallback(() => {
    append({
      ...DEFAULT_FORM_VALUES.titled_carousel.elementsAttributes[0],
      placement: fields.length,
    });
  }, [append, fields]);

  return (
    <Box as="fieldset">
      <OptionalFormFieldset
        title="Tytuł (opcjonalnie)"
        mb="20px"
        isActive={withHeader}
        name={prepareFieldName('withHeader')}
      >
        <Input label="Wpisz tytuł" name={prepareFieldName('title')} mb="20px" />
      </OptionalFormFieldset>

      <OptionalFormFieldset
        title="Przycisk (opcjonalnie)"
        mb="20px"
        isActive={withButton}
        name={prepareFieldName('withButton')}
      >
        Wybierz rodzaj przycisku
        <FormControl isInvalid={Boolean(buttonKindError)}>
          <HStack mt={6} mb={9} position="relative">
            {Object.values(BUTTON_TYPES).map((bt: string) => (
              <RadioBoxControl
                key={bt}
                label={BUTTON_TYPE_LABEL[bt]}
                name={prepareFieldName('buttonKind')}
                id={prepareFieldName(`buttonKind_${bt}`)}
                value={bt}
                group
              />
            ))}
            <FormErrorMessage position="absolute" top="100%" pl={0} m={0}>
              {buttonKindError}
            </FormErrorMessage>
          </HStack>
        </FormControl>
        {selectedButtonType === BUTTON_TYPES.LABEL && (
          <Box mb={6} mt={-3}>
            <Input label="Wpisz tytuł" name={prepareFieldName('buttonText')} />
          </Box>
        )}
        <LinkCreatorSection
          prepareFieldName={prepareFieldName}
          isEditMode={isEditMode || false}
        />
      </OptionalFormFieldset>
      <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple mb={10}>
        {fields.map((field: any, index) => (
          <TitledCarouselSection
            id={field.fieldId}
            elementIndex={index}
            prepareFieldName={prepareFieldName}
            isEditMode={isEditMode || false}
            key={field.fieldId}
            move={move}
            remove={removeElement}
          />
        ))}
        <Button
          type="button"
          variant="solid"
          mt={4}
          w="100%"
          onClick={addSlide}
          fontSize="12px"
          maxW={FORM_BODY_MAX_WIDTH}
        >
          DODAJ SLAJD
          <Box as="span" ml={2}>
            <BiPlus size={24} />
          </Box>
        </Button>
      </Accordion>
    </Box>
  );
}

export default TitledCarouselForm;
