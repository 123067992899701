import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import StatusBox from 'components/StatusBox';

function GetColumns(): ColumnDef<Faq, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'PYTANIE',
      accessorKey: 'question',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ODPOWIEDŹ',
      accessorKey: 'answer',
      cell: (info) => (
        <Box
          dangerouslySetInnerHTML={{
            __html: info.getValue(),
          }}
        />
      ),
    },
    {
      header: 'STATUS',
      accessorKey: 'active',
      cell: (info) => (
        <StatusBox status={info.getValue() ? 'active' : 'unactive'} />
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
