import { VStack, Text, Box, HStack } from '@chakra-ui/react';
import { useMemo } from 'react';
import { FiInstagram } from 'react-icons/fi';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from '@emotion/styled';
import { FreeMode } from 'swiper';

import { PreviewComponentProps } from 'types/dashboardCreator';
import Headers from 'components/Container/Previews/Headers';
import useGroupsWithFallback from 'utils/useGroupsWithFallback';
import Image from 'components/Container/Previews/PlayWithColorsPreview/Image';

import 'swiper/css';
import 'swiper/css/free-mode';
import { ContainerElement } from 'types/dashboard';

const StyledSlide = styled(SwiperSlide)<{ p: string | number }>`
  padding: 0 16px;

  &:last-child {
    padding: ${(props) => props.p};
  }
`;

function InstaFeedPreview({ formValues }: PreviewComponentProps) {
  const elements = useMemo(() => {
    if (!formValues.elementsAttributes) {
      return [...Array(1)];
    }

    return formValues.elementsAttributes.filter(
      (el: ContainerElement) => el.active,
    );
  }, [formValues]);

  const { groupsProps } = useGroupsWithFallback({ elements, itemsPerGroup: 4 });

  return (
    <Box py={6} bgColor="complementary.lightGrey" overflow="hidden" maxW={388}>
      <Box px={4} pb={4}>
        <Headers
          position="static"
          subtitle="styled by you"
          subtitleFontSize={12}
          title="powered by 4f"
          titleFontSize={24}
          textBackground="transparent"
        />
      </Box>
      <Swiper
        className="mySwiper"
        spaceBetween={12}
        modules={[FreeMode]}
        freeMode
      >
        {groupsProps.map((group: any, idx: number) => {
          const mainImg = group[0];
          const smallImgs = group.slice(1);

          return (
            <StyledSlide
              key={`insta_image_${+idx}`}
              p={elements.length > 1 ? '0' : '0 16px'}
            >
              <HStack spacing={3} w="full">
                <Image
                  element={mainImg.elementAttributes}
                  props={{ minW: 272, minH: 336 }}
                />
                <VStack spacing={3} justifyContent="flex-start" w="full">
                  {smallImgs.map((img: any) => (
                    <Image
                      key={`insta_image_small_${img.number}`}
                      props={{ minW: 104, minH: 104 }}
                      element={img.elementAttributes}
                    />
                  ))}
                </VStack>
              </HStack>
            </StyledSlide>
          );
        })}
      </Swiper>
      <HStack spacing={2} pt={2} px={4}>
        <FiInstagram size={12} />
        <Text fontWeight={600} fontSize={12} transform="uppercase">
          4F OFFICIAL ACCOUNT
        </Text>
      </HStack>
    </Box>
  );
}

export default InstaFeedPreview;
