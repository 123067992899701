import React, { useMemo, useState } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { MdKeyboardArrowUp, MdOutlineEdit } from 'react-icons/md';
import { IconButton, Tooltip } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link, generatePath, useParams } from 'react-router-dom';
import Tr from 'components/Table/Tr';
import Td from 'pages/AppStoriesInspirations/Listing/components/Table/components/Td';
import NestedTable from 'pages/AppStoriesInspirations/Listing/components/NestedTable/index';
import { CategoryContainer } from 'types/categoryContainer';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';

const MotionTr = motion(Tr);

interface TableRowProps {
  row: Row<CategoryContainer>;
}
function TableRow({ row }: TableRowProps) {
  const { queryString } = useQueryParams();
  const { store } = useParams();
  const { id } = row.original;
  const [isExpanded, setIsExpanded] = useState(false);

  const editPath = useMemo(
    () =>
      generatePath(ROUTES.appStoriesInspirations.edit, {
        id,
        store,
      }) + queryString,
    [id, queryString, store],
  );

  return (
    <React.Fragment key={`group_row_${row.id}`}>
      <Tr key={id}>
        {row.getVisibleCells().map((cell, idx, { length }) => {
          if (idx + 1 === length) return null;
          return (
            <Td key={`row_cell_${cell.id}_${cell}`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
        <Td>
          <Tooltip label="Edytuj" openDelay={1000}>
            <IconButton
              icon={<MdOutlineEdit size={20} />}
              aria-label="visiblity"
              variant="ghost"
              as={Link}
              to={editPath}
            />
          </Tooltip>
          <IconButton
            aria-label="Toggle row"
            variant="ghost"
            icon={
              <MdKeyboardArrowUp
                size={24}
                style={{
                  transition: 'transform .3s ease-in-out',
                  transform: `rotate(${isExpanded ? 0 : 180}deg)`,
                }}
              />
            }
            onClick={() => setIsExpanded((prevState) => !prevState)}
          />
        </Td>
      </Tr>
      <MotionTr
        key={`table_row_${id}`}
        animate={{
          height: isExpanded ? 'auto' : 0,
          opacity: isExpanded ? 1 : 0,
        }}
      >
        {isExpanded && <NestedTable elements={row.original.elements} />}
      </MotionTr>
    </React.Fragment>
  );
}

export default TableRow;
