import { ColumnDef } from '@tanstack/react-table';
import { ADMINISTRATOR_ROLE } from 'pages/Administration/Form/constants';

function GetColumns(): ColumnDef<Administrator, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'E-MAIL',
      accessorKey: 'email',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ROLA',
      accessorKey: 'role',
      cell: (info) => ADMINISTRATOR_ROLE[info.getValue()],
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
