import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import {
  convertKeysToSnakeCase,
  transformLinksAttributesForPayload,
} from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import {
  SubscriptionRunupScreenResponse,
  SubscriptionRunupScreensResponse,
  TransformedSubscriptionRunupScreensResponse,
  ReorderParams,
  ReorderElementParams,
  SubscriptionRunupScreen,
  SubscriptionRunupScreenElementResponse,
  DeleteParams,
} from 'types/subscriptionRunupScreens';

export const subscriptionRunupScreensApi = api.injectEndpoints({
  endpoints: (build) => ({
    subscriptionRunupScreens: build.query<
      TransformedSubscriptionRunupScreensResponse,
      string
    >({
      query: (queryString) => `subscription_runup_screens${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: SubscriptionRunupScreensResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['SubscriptionRunupScreens'],
    }),
    deleteSubscriptionRunupScreen: build.mutation<void, number>({
      query: (id) => ({
        url: `subscription_runup_screens/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SubscriptionRunupScreens'],
    }),
    createSubscriptionRunupScreen: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(
          transformLinksAttributesForPayload({ ...payload }),
        );

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'subscription_runup_screen',
        );
        return {
          url: 'subscription_runup_screens',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['SubscriptionRunupScreens'],
    }),
    subscriptionRunupScreen: build.query<SubscriptionRunupScreenResponse, any>({
      query: (id) => `subscription_runup_screens/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['SubscriptionRunupScreens'],
    }),
    updateSubscriptionRunupScreen: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(
          transformLinksAttributesForPayload({ ...payload }),
        );

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'subscription_runup_screen',
        );

        return {
          url: `subscription_runup_screens/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['SubscriptionRunupScreens'],
    }),
    reorderSubscriptionRunupScreen: build.mutation<void, ReorderParams>({
      query: ({ body, id }) => ({
        url: `subscription_runup_screens/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SubscriptionRunupScreens'],
    }),
    subscriptionRunupScreenElements: build.query<
      SubscriptionRunupScreen,
      string | number
    >({
      query: (id) => `subscription_runup_screens/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: SubscriptionRunupScreenResponse) {
        return response.data;
      },
      providesTags: (result) => [
        { type: 'SubscriptionRunupScreenElements', id: result?.id },
      ],
    }),
    subscriptionRunupScreenElement: build.query<
      SubscriptionRunupScreenElementResponse,
      any
    >({
      query: (payload) => {
        const { id, screenId } = payload;
        return `subscription_runup_screens/${screenId}/elements/${id}`;
      },
      keepUnusedDataFor: 10,
      providesTags: ['SubscriptionRunupScreenElements'],
    }),
    reorderSubscriptionRunupScreenElement: build.mutation<
      void,
      ReorderElementParams
    >({
      query: ({ body, id, screenId }) => ({
        url: `subscription_runup_screens/${screenId}/elements/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['SubscriptionRunupScreenElements'],
    }),
    createSubscriptionRunupScreenElement: build.mutation({
      query: (payload) => {
        const { id, data } = payload;
        const snakePayload = convertKeysToSnakeCase({ ...data });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'subscription_runup_screen_element',
        );

        return {
          url: `subscription_runup_screens/${id}/elements`,
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['SubscriptionRunupScreenElements'],
    }),
    updateSubscriptionRunupScreenElement: build.mutation({
      query: (payload) => {
        const { id, screenId } = payload;
        const snakePayload = convertKeysToSnakeCase({
          ...payload,
        });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'subscription_runup_screen_element',
        );

        return {
          url: `/subscription_runup_screens/${screenId}/elements/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['SubscriptionRunupScreenElements'],
    }),
    deleteSubscriptionRunupScreenElement: build.mutation<void, DeleteParams>({
      query: ({ id, screenId }) => ({
        url: `subscription_runup_screens/${screenId}/elements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SubscriptionRunupScreenElements'],
    }),
  }),
});

export const {
  useSubscriptionRunupScreensQuery,
  useDeleteSubscriptionRunupScreenMutation,
  useCreateSubscriptionRunupScreenMutation,
  useSubscriptionRunupScreenQuery,
  useUpdateSubscriptionRunupScreenMutation,
  useReorderSubscriptionRunupScreenMutation,
  useSubscriptionRunupScreenElementsQuery,
  useSubscriptionRunupScreenElementQuery,
  useReorderSubscriptionRunupScreenElementMutation,
  useCreateSubscriptionRunupScreenElementMutation,
  useUpdateSubscriptionRunupScreenElementMutation,
  useDeleteSubscriptionRunupScreenElementMutation,
} = subscriptionRunupScreensApi;
