import { api } from 'app/services/api';

export const dhlPointsApi = api.injectEndpoints({
  endpoints: (build) => ({
    dhlPoints: build.query<TransformedDhlPointsResponse, string>({
      query: (queryString) => `dhl_points${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: DhlPointsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['DhlPoints'],
    }),
  }),
});

export const { useDhlPointsQuery } = dhlPointsApi;
