import { Box, Flex } from '@chakra-ui/react';

interface BadgePreviewProps {
  text: string | null;
  backgroundColor: string;
  textColor: string;
}

function BadgePreview({ text, backgroundColor, textColor }: BadgePreviewProps) {
  if (!text) return <Box>brak</Box>;
  return (
    <Flex>
      <Box
        bg={backgroundColor}
        color={textColor}
        borderRadius="30px"
        textAlign="center"
        padding="2px 8px"
        fontWeight={700}
        fontSize="12px"
        lineHeight="16px"
      >
        {text}
      </Box>
    </Flex>
  );
}

export default BadgePreview;
