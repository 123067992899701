import { Checkbox, Flex, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { StatusType } from 'types/common';
import StatusBox from 'components/StatusBox';
import { LandingPage } from 'types/landingPage';
import ShowStats from 'components/Stats/ShowElementStats';

function getColumns(): ColumnDef<LandingPage, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => (
        <Flex align="center" direction="column">
          {info.getValue()}
          <Checkbox
            isChecked={info.row.getIsSelected()}
            onChange={info.row.getToggleSelectedHandler()}
            pt="23px"
          />
        </Flex>
      ),
    },
    {
      header: 'TYTUŁ',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'SLUG',
      accessorKey: 'slug',
      cell: (info) => info.getValue(),
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'STATYSTYKI',
      cell: (info) => {
        return (
          <ShowStats data={info.row.original} type="landing_page_click">
            <Text
              color="complementary.blue"
              fontSize="16px"
              textAlign="center"
              textDecoration="underline"
              cursor="pointer"
            >
              Wyświetl
            </Text>
          </ShowStats>
        );
      },
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default getColumns;
