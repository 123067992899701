import { useCallback, useEffect, useRef, useState } from 'react';
import {
  AccordionButton as ChakraAccordionButton,
  AccordionIcon,
  Box,
  HStack,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { MdDragIndicator } from 'react-icons/md';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { UseFieldArrayMove } from 'react-hook-form';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { DragItem } from 'types/dashboard';
import { ActionButton } from 'pages/Dashboard/Edit/components/DashboardContainer';

const DropItem = styled.div<{ isDragging: boolean; maxWidth: number }>`
  width: ${({ maxWidth }) => `${maxWidth}px`};
  ${({ isDragging }) =>
    isDragging
      ? css`
          > div {
            opacity: 0;
          }
        `
      : null}
`;

interface AccordionButtonProps {
  id: string;
  index: number;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
  label: string;
  isRemovable?: boolean;
  isDraggable?: boolean;
}

function AccordionButton({
  id,
  index,
  move,
  remove,
  label,
  isRemovable,
  isDraggable,
}: AccordionButtonProps) {
  const dragRef = useRef<HTMLButtonElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [originalIndex, setOriginalIndex] = useState(index);

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      move(dragIndex, hoverIndex);
    },
    [move],
  );

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(element: DragItem) {
      setOriginalIndex(element.index);
    },
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 5;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (element: any, monitor) => {
      const { index: currentIndex } = element;
      const didDrop = monitor.didDrop();
      // reset to previous state if not dropped correctly
      if (!didDrop) {
        moveItem(currentIndex, originalIndex);
      }
    },
  });

  useEffect(() => {
    if (!isDragging) {
      setOriginalIndex(index);
    }
  }, [isDragging, index]);

  drag(dragRef);
  drop(preview(previewRef));

  return (
    <DropItem
      ref={previewRef}
      key={id}
      data-handler-id={handlerId}
      isDragging={isDragging}
      maxWidth={FORM_BODY_MAX_WIDTH}
    >
      <ChakraAccordionButton
        as={Box}
        cursor="pointer"
        pl={0}
        borderBottom="1px solid"
        borderColor="complementary.grey"
      >
        <Text flex={1} textAlign="left" fontWeight={700} fontSize={16}>
          {label}
        </Text>
        <HStack>
          <AccordionIcon />
          {isRemovable && (
            <IconButton
              variant="unstyled"
              fontSize={16}
              onClick={() => remove(index)}
              aria-label="remove"
            >
              <FaRegTrashAlt />
            </IconButton>
          )}
          {isDraggable && (
            <ActionButton ref={dragRef}>
              <MdDragIndicator size={20} />
            </ActionButton>
          )}
        </HStack>
      </ChakraAccordionButton>
    </DropItem>
  );
}

AccordionButton.defaultProps = {
  isRemovable: true,
  isDraggable: true,
};

export default AccordionButton;
