import { ColumnDef } from '@tanstack/react-table';
import StatusBox from 'components/StatusBox';

function GetColumns(): ColumnDef<About, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TYTUŁ',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'LINK WEWNĘTRZNY',
      accessorKey: 'queryLink',
      cell: (info) => info.getValue(),
    },
    {
      header: 'LINK ZEWNĘTRZNY',
      accessorKey: 'externalUrl',
      cell: (info) => info.getValue(),
    },
    {
      header: 'STATUS',
      accessorKey: 'active',
      cell: (info) => (
        <StatusBox status={info.getValue() ? 'active' : 'unactive'} />
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
