import { Button } from '@chakra-ui/react';
import {
  useImportMutation,
  useLoadByDashboardQuery,
} from 'app/services/weatherCategoriesApi';

interface HeaderButtonProps {
  kind: string;
  id: string;
}

function ImportButton({ kind, id }: HeaderButtonProps) {
  const [categoryImport, { isLoading: isImporting }] = useImportMutation();
  const { data: { data: weatherCategory } = {} } = useLoadByDashboardQuery(
    kind,
    {
      skip: !kind,
    },
  );
  const documentUrl = weatherCategory?.document?.url;

  if (!documentUrl) {
    return null;
  }

  return (
    <Button
      fontWeight={700}
      fontSize="12px"
      lineHeight="20px"
      paddingX="32px"
      textTransform="uppercase"
      isLoading={isImporting}
      onClick={() => categoryImport({ id, documentUrl })}
    >
      Importuj
    </Button>
  );
}

export default ImportButton;
