import { Button, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPENED_WIDTH } from 'slices/ui/uiSlice';
import { useAppSelector } from 'utils/reduxHooks';
import { getSidebarState } from 'selectors/uiSelectors';

export const FOOTER_HEIGHT = '88px';

interface FooterButtonsProps {
  onCancel: () => void;
  isLoading: boolean;
}

function FooterButtons({ onCancel, isLoading }: FooterButtonsProps) {
  const isSidebarOpened = useAppSelector(getSidebarState);

  return (
    <motion.div
      hidden={false}
      initial={false}
      animate={{
        left: isSidebarOpened ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH,
      }}
      style={{
        width: '100%',
        background: 'white',
        overflow: 'hidden',
        position: 'fixed',
        zIndex: 100,
        left: 0,
        height: FOOTER_HEIGHT,
        bottom: 0,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderTop: '1px solid #000000',
      }}
    >
      <Flex w="100%" h="100%" alignItems="center" p={4}>
        <Button
          type="submit"
          fontSize={12}
          fontWeight={700}
          minWidth={218}
          form="dashboard-creator"
          isLoading={isLoading}
        >
          UTWÓRZ DASHBOARD
        </Button>
        <Button
          type="button"
          variant="outlined"
          ml={4}
          fontSize={12}
          fontWeight={700}
          minWidth={120}
          onClick={onCancel}
        >
          ANULUJ
        </Button>
      </Flex>
    </motion.div>
  );
}

export default FooterButtons;
