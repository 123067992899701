import * as yup from 'yup';
import { activityDatesSchema } from 'components/Container/schema';

export const BODY_SCHEMA = yup.object({
  animationBackgroundColor: yup.string().required('Pole jest wymagane'),
  animation: yup.string().nullable(),
  background: yup.mixed().test({
    test(file, context) {
      if (!file) {
        return context.createError({ message: 'Zdjęcie tła jest wymagane' });
      }
      return true;
    },
  }),
  ...activityDatesSchema,
  dateFrom: yup.date().required('Pole jest wymagane'),
});

export const DEFAULT_VALUES = {
  animationBackgroundColor: '#000000',
  animation: null,
  background: null,
  active: true,
  timeFrom: null,
  dateFrom: new Date(),
  timeTo: null,
  dateTo: null,
};
