import { Box, Flex } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/RegulationElements/Form/components/FormBody';
import Input from 'components/Form/Input';
import FormSpinner from 'components/FormSpinner';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <FormSpinner />}
        <Box w="100%">
          <Input name="title" label="Tytuł" placeholder="Wpisz tytuł" mb={8} />
          <FormBody prepareFieldName={(name: string) => name} />
        </Box>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
