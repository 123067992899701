import { ColumnDef } from '@tanstack/react-table';
import { Box } from '@chakra-ui/react';
import StatusBox from 'components/StatusBox';
import { datePrettier } from 'utils/date';
import { StatusType } from 'types/common';
import ColorPreview from 'components/ColorPreview';
import ImagePreview from 'components/ImagePreview';

function GetColumns(): ColumnDef<Minibanner, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TYTUŁ',
      accessorKey: 'title',
      cell: (info) => (
        <Box
          dangerouslySetInnerHTML={{
            __html: info.getValue(),
          }}
        />
      ),
    },
    {
      header: 'ZDJĘCIE',
      accessorKey: 'image.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'KOLOR TŁA',
      accessorKey: 'backgroundColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'KOLOR TEKSTU',
      accessorKey: 'textColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: 'activeFrom',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: 'activeTo',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
