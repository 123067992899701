import { Box } from '@chakra-ui/react';
import { generatePath, useParams } from 'react-router-dom';
import HeaderNavigation from 'components/HeaderNavigation';
import MainTable from 'pages/CategoryTags/Preview/components/MainTable';
import { useCategoryQuery } from 'app/services/categoryApi';
import { useTagsQuery } from 'app/services/categoryTags';
import LoadingIndicator from 'components/LoadingIndicator';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';

function Preview() {
  const { id = '', store } = useParams();
  const { queryString } = useQueryParams();

  const { data: { data: category } = {}, isFetching: isCategoryFetching } =
    useCategoryQuery(id!);

  const { isFetching: isTagsFetching } = useTagsQuery({
    categoryId: Number(id),
  });

  const isLoading = isCategoryFetching || isTagsFetching;

  return (
    <Box pt="36px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Tagi taksonomii',
          to:
            generatePath(ROUTES.categoryTag.base, {
              store,
            }) + queryString,
        }}
        crumbs={[{ to: '', label: category?.name || 'Loading...' }]}
      />
      {isLoading && <LoadingIndicator />}
      {!isLoading && <MainTable categoryId={category!.id} />}
    </Box>
  );
}

export default Preview;
