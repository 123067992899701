import {
  memo,
  FunctionComponent,
  useCallback,
  useMemo,
  FormEvent,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex, Box, Heading, FormControl, FormLabel } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import Card from 'components/Card';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import { GENDER, FormComponents } from 'components/Container/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import ComponentPreview from 'pages/Dashboard/Containers/components/ComponentPreview';
import ActivityFormSection from 'components/Container/Sections/ActivityFormSection';
import ExternalError from 'components/Form/ExternalError';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';
import { useComponentTypesQuery } from 'app/services/commonApi';
import MarginFormSection from 'components/Container/Sections/MarginSection';
import UserKindSection from 'components/Container/Sections/UserKindSection';
import AppVersionSection from 'components/Container/Sections/AppVersionSection';

interface ComponentWrapperProps {
  kind: string;
  isLoading: boolean;
  componentName: string;
}

function ComponentWrapper({
  kind,
  isLoading,
  componentName,
}: ComponentWrapperProps) {
  const FormComponent: FunctionComponent<FormComponentProps> =
    FormComponents[kind];

  const { data: { data: componentTypes } = { data: [] } } =
    useComponentTypesQuery({});
  const { setValue } = useFormContext();

  const titleChange = useMemo(
    () =>
      debounce((event: FormEvent<HTMLHeadingElement>) => {
        const { target } = event;
        setValue('name', (target as HTMLInputElement).innerText);
      }, 300),
    [setValue],
  );

  const onTitleChangeHandler = useCallback(titleChange, [titleChange]);

  const fieldnameProxy: (name: string) => string = useCallback(
    (name) => name,
    [],
  );

  const isInstagramFeed = useMemo(() => kind === 'instagram_feed', [kind]);

  return (
    <Flex gap={4} mb={4} alignItems="flex-start">
      <Box flex={1} maxW={FORM_MAX_WIDTH} minW={650}>
        <Card>
          {isLoading && <FormSpinner />}

          <Box w="100%" pt={2}>
            <Heading
              as="h3"
              fontSize={16}
              borderBottom="1px solid"
              borderColor="complementary.grey"
              paddingBottom={2}
              maxW={FORM_BODY_MAX_WIDTH}
              contentEditable
              onInput={onTitleChangeHandler}
              suppressContentEditableWarning
              sx={{
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              {componentName} {kind}
            </Heading>

            <motion.div
              initial={{
                height: 'auto',
                overflow: 'visible',
                paddingTop: '36px',
              }}
              animate={{
                height: 'auto',
                overflow: 'visible',
                paddingTop: '36px',
              }}
            >
              {FormComponent && (
                <>
                  <FormControl maxW={FORM_BODY_MAX_WIDTH} mb={9}>
                    <FormLabel
                      htmlFor="gender-selector"
                      fontWeight={700}
                      fontSize={16}
                      lineHeight={6}
                    >
                      {isInstagramFeed
                        ? 'Dashboard, na którym znajduje się kontener'
                        : 'Wybierz dashboard, na którym ma zostać dodany kontener'}
                    </FormLabel>

                    <Flex mt={4}>
                      {(Object.keys(GENDER) as Array<keyof typeof GENDER>).map(
                        (key) => (
                          <RadioBoxControl
                            key={key}
                            id={`gender-${key}`}
                            label={GENDER[key]}
                            name="gender"
                            value={key}
                            isReadOnly={isInstagramFeed}
                          />
                        ),
                      )}
                    </Flex>
                    <ExternalError name="gender" />
                    <UserKindSection prepareFieldName={fieldnameProxy} mt={9} />
                  </FormControl>

                  {FormComponent && (
                    <FormComponent
                      prepareFieldName={fieldnameProxy}
                      isEditMode
                    />
                  )}

                  <AppVersionSection prepareFieldName={fieldnameProxy} />
                  <MarginFormSection prepareFieldName={fieldnameProxy} />
                  <ActivityFormSection prepareFieldName={fieldnameProxy} />
                </>
              )}
            </motion.div>
          </Box>
        </Card>
        <Box id="left-column-below" mt={4} />
      </Box>
      <ComponentPreview kind={kind} componentTypes={componentTypes} />
    </Flex>
  );
}

export default memo(ComponentWrapper);
