import { Box, Stack } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import TeamPageSelect from 'pages/Basketball/Teams/Form/components/TeamPageSelect';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Box>
        <SectionTitle as="legend">Logo</SectionTitle>
        <DropzoneField name={prepareFieldName('logo')} />
      </Box>
      <TeamPageSelect prepareFieldName={prepareFieldName} />
    </Stack>
  );
}

export default FormBody;
