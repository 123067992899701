import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import {
  BenefitsScreen,
  BenefitsScreenResponse,
  BenefitsScreensResponse,
  TransformedBenefitsScreensResponse,
  ReorderElementParams,
  DeleteParams,
  BenefitsScreenElementResponse,
} from 'types/benefitsScreens';
import { transformActivityDates } from 'utils/date';

export const benefitsScreensApi = api.injectEndpoints({
  endpoints: (build) => ({
    benefitsScreens: build.query<TransformedBenefitsScreensResponse, string>({
      query: (queryString) => `benefits_screens${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: BenefitsScreensResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['BenefitsScreens'],
    }),
    deleteBenefitsScreen: build.mutation<void, number>({
      query: (id) => ({
        url: `benefits_screens/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BenefitsScreens'],
    }),
    createBenefitsScreen: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase(payload);

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'benefits_screen',
        );
        return {
          url: 'benefits_screens',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['BenefitsScreens'],
    }),
    benefitsScreen: build.query<BenefitsScreenResponse, any>({
      query: (id) => `benefits_screens/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['BenefitsScreens'],
      transformResponse(response: BenefitsScreenResponse) {
        const data = {
          ...response.data,
          elementsAttributes: response.data?.elements,
        };
        delete data?.elements;
        return { data };
      },
    }),
    updateBenefitsScreen: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'benefits_screen',
        );

        return {
          url: `benefits_screens/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['BenefitsScreens'],
    }),
    benefitsScreenElements: build.query<BenefitsScreen, string | number>({
      query: (id) => `benefits_screens/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: BenefitsScreenResponse) {
        return response.data;
      },
      providesTags: (result) => [
        { type: 'BenefitsScreenElements', id: result?.id },
      ],
    }),
    reorderBenefitsScreenElement: build.mutation<void, ReorderElementParams>({
      query: ({ body, id, benefitsScreenId }) => ({
        url: `benefits_screens/${benefitsScreenId}/elements/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['BenefitsScreenElements'],
    }),
    deleteBenefitsScreenElement: build.mutation<void, DeleteParams>({
      query: ({ id, benefitsScreenId }) => ({
        url: `benefits_screens/${benefitsScreenId}/elements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BenefitsScreenElements'],
    }),
    benefitsScreenElement: build.query<BenefitsScreenElementResponse, any>({
      query: (payload) => {
        const { id, benefitsScreenId } = payload;
        return `benefits_screens/${benefitsScreenId}/elements/${id}`;
      },
      keepUnusedDataFor: 10,
      providesTags: ['BenefitsScreenElements'],
    }),
    createBenefitsScreenElement: build.mutation({
      query: (payload) => {
        const { benefitsScreenId, body } = payload;
        const snakePayload = convertKeysToSnakeCase({
          ...transformActivityDates(body),
        });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'benefits_screen_element',
        );

        return {
          url: `benefits_screens/${benefitsScreenId}/elements`,
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['BenefitsScreenElements'],
    }),
    updateBenefitsScreenElement: build.mutation({
      query: (payload) => {
        const { id, benefitsScreenId } = payload;
        const snakePayload = convertKeysToSnakeCase({
          ...transformActivityDates(payload),
        });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'benefits_screen_element',
        );

        return {
          url: `/benefits_screens/${benefitsScreenId}/elements/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['BenefitsScreenElements'],
    }),
  }),
});

export const {
  useBenefitsScreensQuery,
  useDeleteBenefitsScreenMutation,
  useCreateBenefitsScreenMutation,
  useBenefitsScreenQuery,
  useUpdateBenefitsScreenMutation,
  useBenefitsScreenElementsQuery,
  useReorderBenefitsScreenElementMutation,
  useDeleteBenefitsScreenElementMutation,
  useBenefitsScreenElementQuery,
  useCreateBenefitsScreenElementMutation,
  useUpdateBenefitsScreenElementMutation,
} = benefitsScreensApi;
