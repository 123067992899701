import { Flex, Stack } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/Regulations/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import Input from 'components/Form/Input';
import FormSpinner from 'components/FormSpinner';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <FormSpinner />}
        <Stack maxW={FORM_BODY_MAX_WIDTH} w="100%" spacing={8}>
          <Input
            name="title"
            label="Nazwa"
            placeholder="Wpisz nazwę regulaminu"
          />
          <FormBody prepareFieldName={(name: string) => name} />
        </Stack>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
