import { useEffect, useState } from 'react';
import { useParams, generatePath } from 'react-router-dom';

const useRoute = (
  route: string,
  params?: { [key: string]: string | number } | undefined,
) => {
  const [path, setPath] = useState<string>('');
  const { store } = useParams();

  useEffect(() => {
    setPath(generatePath(route, { store, ...(params || {}) }));

    return () => {
      setPath('');
    };
  }, [params, route, store]);

  return path;
};

export default useRoute;
