import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<Faq, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KATEGORIA',
      accessorKey: 'question',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
