import { ColumnDef } from '@tanstack/react-table';
import { SIZING_KIND } from 'pages/Sizings/Form/constants';

function GetColumns(): ColumnDef<Sizing, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'RODZAJ',
      accessorKey: 'kind',
      cell: (info) => SIZING_KIND[info.getValue()],
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
