import { ColumnDef } from '@tanstack/react-table';
import { SubscriptionRunupScreenElement } from 'types/subscriptionRunupScreens';

function GetColumns(): ColumnDef<SubscriptionRunupScreenElement, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TYTUŁ',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'PODTYTUŁ',
      accessorKey: 'subtitle',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
