import { VStack, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { BiImage } from 'react-icons/bi';
import isEmpty from 'lodash/isEmpty';
import Headers from 'components/Container/Previews/Headers';
import Button from 'components/Container/Previews/Button';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import TaggedProductsPreview from 'components/Container/Previews/TaggedProductsPreview';

function PhotoBannerMediumPreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.image);

  const shouldRenderHeaders = useMemo(
    () => Boolean(formValues.subtitle || formValues.title),
    [formValues.subtitle, formValues.title],
  );

  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      w="100%"
      h={388}
      bgColor="complementary.whiteGrey"
      bgImage={imagePreview}
      bgSize="cover"
      bgRepeat="no-repeat"
      bgPosition="50% 50%"
      position="relative"
      spacing={0}
    >
      {shouldRenderHeaders && <Headers {...formValues} />}
      {!imagePreview ? (
        <>
          <BiImage size={30} />
          <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
            Tu pojawi się dodane zdjęcie
          </Text>
        </>
      ) : null}
      {showStats && <ShowStatsIcon data={formValues} />}
      {(formValues?.withTags || !isEmpty(formValues?.photoTags)) && (
        <TaggedProductsPreview formValues={formValues} showStats={showStats} />
      )}
      {formValues.buttonText ? <Button {...formValues} /> : null}
    </VStack>
  );
}

export default PhotoBannerMediumPreview;
