import { FunctionComponent, useMemo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Flex, Heading, Text, Spinner, Box } from '@chakra-ui/react';
import { BsImage } from 'react-icons/bs';
import isEmpty from 'lodash/isEmpty';
import { PreviewComponentProps } from 'types/dashboardCreator';
import {
  CART_PADDING,
  FIT_CONTENT,
  Previews,
} from 'components/Container/constants';

import Card from 'components/Card';

import 'swiper/css/navigation';
import 'swiper/css';

interface ComponentPreviewProps {
  kind?: string;
  componentIndex?: number;
  componentTypes?: { value: string; label: string }[];
}

function ComponentPreview({
  kind = '',
  componentIndex,
  componentTypes,
}: ComponentPreviewProps) {
  const PreviewComponent: FunctionComponent<PreviewComponentProps> =
    Previews[kind];

  const { control } = useFormContext();
  const watchOptions = {
    control,
    ...(typeof componentIndex === 'number'
      ? { name: `components[${componentIndex}]` }
      : {}),
  };

  const formValues = useWatch(watchOptions);

  const getLabel = useCallback(
    (key: string) => componentTypes?.find((c) => c.value === key)?.label || key,
    [componentTypes],
  );

  const maxWidth = useMemo(() => {
    if (isEmpty(formValues) || !FIT_CONTENT[kind]) {
      return 452;
    }

    return FIT_CONTENT[kind];
  }, [formValues, kind]);

  return (
    <Card
      maxW={maxWidth}
      padding={CART_PADDING[kind] ?? '32px'}
      style={{ position: 'sticky', top: '65px' }}
    >
      <Text fontSize={14}>{getLabel(kind)}</Text>
      {!kind ? (
        <Flex direction="column" minHeight={268} justifyContent="center">
          <Flex mb={10} alignItems="center" justifyContent="center">
            <BsImage size={26} />
          </Flex>
          <Text fontSize="md" fontWeight={700} textAlign="center">
            Wybierz typ kontenera, aby rozpocząć pracę
            <br />i wygenerować podgląd
          </Text>
        </Flex>
      ) : (
        <>
          <Heading as="h4" fontSize={16} mb={8} pt={2}>
            PODGLĄD
          </Heading>
          {isEmpty(formValues) && (
            <Flex w="100%" justify="center">
              <Spinner size="md" />
            </Flex>
          )}
          {!isEmpty(formValues) && PreviewComponent && (
            <PreviewComponent formValues={formValues} />
          )}
          {formValues.margined && (
            <Box h="16px" backgroundColor={formValues.marginColor} />
          )}
        </>
      )}
    </Card>
  );
}

ComponentPreview.defaultProps = {
  kind: '',
  componentIndex: null,
  componentTypes: [],
};

export default ComponentPreview;
