import { DeepPartial, Theme } from '@chakra-ui/react';

const Drawer: DeepPartial<Theme['components']['Drawer']> = {
  parts: ['dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle: {
    footer: {
      padding: '32px',
    },
    header: {
      paddingX: '32px',
      paddingTop: '56px',
      paddingBottom: '26px',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.004em',
      textAlign: 'left',
    },
    body: {
      paddingX: '32px',
      paddingY: 0,
    },
    closeButton: { top: '51px' },
  },
  sizes: {
    xs: {
      dialog: {
        maxW: 'xs',
      },
    },
    sm: {
      dialog: {
        maxW: 'md',
      },
    },
    md: {
      dialog: {
        maxW: '516px',
      },
    },
    lg: {
      dialog: {
        maxW: '2xl',
      },
    },
    xl: {
      dialog: {
        maxW: '4xl',
      },
    },
    full: {
      dialog: {
        maxW: '100vw',
        h: '100vh',
      },
    },
  },
};

export default Drawer;
