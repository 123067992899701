import { DeepPartial, Theme } from '@chakra-ui/react';

const Modal: DeepPartial<Theme['components']['Modal']> = {
  parts: [
    'dialogContainer',
    'dialog',
    'header',
    'closeButton',
    'body',
    'footer',
  ],
  baseStyle: {
    dialog: {
      borderRadius: 0,
    },
    body: {
      paddingX: '32px',
    },
    header: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      letterspacing: '0.004em',
      textAlign: 'left',
      padding: '32px',
    },
    closeButton: {
      right: '20px',
      top: '20px',
    },
  },
  sizes: {
    xs: {
      dialog: {
        maxW: 'xs',
      },
    },
    sm: {
      dialog: {
        maxW: 'sm',
      },
    },
    md: {
      dialog: {
        maxW: 'md',
      },
    },
    lg: {
      dialog: {
        maxW: 'lg',
      },
    },
    xl: {
      dialog: {
        maxW: 'xl',
      },
    },
    '2xl': {
      dialog: {
        maxW: '2xl',
      },
    },
    '3xl': {
      dialog: {
        maxW: '3xl',
      },
    },
    '4xl': {
      dialog: {
        maxW: '4xl',
      },
    },
    '5xl': {
      dialog: {
        maxW: '5xl',
      },
    },
    '6xl': {
      dialog: {
        maxW: '1270px',
      },
    },
    full: {
      dialog: {
        maxW: '100vw',
        minH: '100vh',
        '@supports(min-height: -webkit-fill-available)': {
          minH: '-webkit-fill-available',
        },
        my: 0,
        borderRadius: 0,
      },
    },
  },
};

export default Modal;
