import { Stack } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';
import LinkCreatorSection from 'components/LinkCreator';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  isEditMode?: boolean;
}

function FormBody({ prepareFieldName, isEditMode }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Input
        label="Adres URL linku"
        type="text"
        name={prepareFieldName('externalUrl')}
      />
      <ToggleCheckbox name={prepareFieldName('active')} label="Aktywny" />
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </Stack>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
