import styled from '@emotion/styled';

const Thead = styled.thead`
  z-index: 1;
  background-color: #fafbfc;
  position: sticky;
  top: 0;
  border-top: none;
  border-bottom: none;
  box-shadow: inset 0 1px 0 black, 0;
  padding: 2px 0;
`;

export default Thead;
