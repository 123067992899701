import { Box, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormComponentProps } from 'types/dashboardCreator';
import Input from 'components/Form/Input';
import AlignmentSection from 'components/Container/Sections/AlignmentSection';
import ColorPicker from 'components/Form/Color';
import LinkCreatorSection from 'components/LinkCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import OptionalFormFieldset from 'components/OptionalFormFieldset';

export const DEFAULT_CONTENT_BY_STORE: {
  [key: string]: string;
} = {
  default: 'Promocja kończy się za',
  four_fstore_sk: 'Akcia končí o',
  four_fstore_lt: 'Akcija baigiasi už',
  four_fstore_lv: 'Promoakcija beidzas pēc',
  four_fstore_com: 'The promo ends in',
  four_fstore_de: 'Promotion endet für',
  four_fstore_cz: 'Akce končí o',
  four_fstore_ro: 'Promoția se încheie în',
  four_fstore_ua: 'до кінця акції',
  four_fstore_hr: 'Promocja kończy się za',
};

// kind: timer_banner
function TimerBannerForm({ prepareFieldName, isEditMode }: FormComponentProps) {
  const { store = 'default' } = useParams();
  const { control, setValue, resetField } = useFormContext();

  const [withContent, content] = useWatch({
    control,
    name: [prepareFieldName('withContent'), prepareFieldName('content')],
  });

  useEffect(() => {
    if (withContent) {
      if (!content) {
        setValue(
          prepareFieldName('content'),
          `${DEFAULT_CONTENT_BY_STORE[store]}:`,
        );
      }
    } else {
      setValue(prepareFieldName('content'), '');
      setValue(prepareFieldName('contentTextColor'), null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prepareFieldName, resetField, setValue, store, withContent]);

  return (
    <Box maxW={FORM_BODY_MAX_WIDTH}>
      <AlignmentSection
        fieldName={prepareFieldName('titleAlignment')}
        label="Wyrównanie tekstu"
      />
      <OptionalFormFieldset
        title="Nagłówek licznika"
        name={prepareFieldName('withContent')}
        headerWidth={FORM_BODY_MAX_WIDTH}
        isActive={withContent}
        hint="Nagłówek jest edytowalny. Pamiętaj, że frazy “Promocja kończy się za:” lub “Wyprzedaż kończy się za:” posiada tłumaczenia, wprowadzając zmiany, pamiętaj o uzupełnieniu tłumaczeń."
      >
        <Input
          label="Nagłówek licznika"
          name={prepareFieldName('content')}
          mb="26px"
        />
        <ColorPicker
          name={prepareFieldName('contentTextColor')}
          label="Kolor nagłówka licznika"
          showError
          mb="26px"
        />
      </OptionalFormFieldset>
      <VStack pb="44px" spacing="26px">
        <Input
          label="Rozmiar tekstu (w pt)"
          defaultValue={12}
          type="number"
          name={prepareFieldName('titleFontSize')}
        />
        <ColorPicker
          name={prepareFieldName('textColor')}
          label="Kolor tekstu"
        />
        <ColorPicker
          name={prepareFieldName('backgroundColor')}
          label="Kolor tła"
        />
        <ColorPicker
          label="Kolor tła licznika"
          name={prepareFieldName('textBackground')}
        />
      </VStack>
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </Box>
  );
}

export default TimerBannerForm;
