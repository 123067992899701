import { Flex, VStack, Text } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';

interface ProductPlaceholderProps {
  idx: number;
}

function ProductPlaceholder({ idx }: ProductPlaceholderProps) {
  return (
    <Flex
      key={`blank_product_image${+idx}`}
      bgColor="complementary.lightGrey"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      w="full"
    >
      <VStack
        bgColor="complementary.whiteGrey"
        justifyContent="center"
        alignItems="center"
        position="relative"
        h={128}
        w={104}
      >
        <BiImage size={32} />
        <Text textAlign="center" fontWeight={700} fontSize="12px" maxW={36}>
          Zdjęcie <br /> produktu
        </Text>
      </VStack>
      <Text
        fontSize="12px"
        fontWeight={600}
        textDecor="underline"
        position="absolute"
        bottom={0}
        right={2}
      >
        KUP
      </Text>
    </Flex>
  );
}

export default ProductPlaceholder;
