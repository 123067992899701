import Slide from 'components/Container/Previews/PhotoBannerCarouselPreview/Slide';
import { PreviewComponentProps } from 'types/dashboardCreator';
import ImagesSlider from 'components/ImagesSlider';

function PhotoBannerCarouselPreview({
  formValues: { elementsAttributes: elements },
  showStats,
}: PreviewComponentProps) {
  return elements?.length ? (
    <ImagesSlider
      elements={elements}
      imageRatio={15 / 22}
      SlideComponent={Slide}
      showStats={showStats}
    />
  ) : (
    <Slide element={{}} />
  );
}

export default PhotoBannerCarouselPreview;
