import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { CategoryTag } from 'types/categoryTags';
import { transformLinkParameters } from 'utils/linkCreator';

const PARSED_VALUES: string[] = ['activeFrom, activeTo'];

const parseDefaultValues = (categoryTag: CategoryTag) => {
  const result: Record<string, any> = { ...omit(categoryTag, PARSED_VALUES) };
  const { activeFrom, activeTo, linkParameters } = categoryTag;

  if (activeFrom) {
    result.dateFrom = new Date(activeFrom);
    result.timeFrom = new Date(activeFrom);
  }

  if (activeTo) {
    result.dateTo = new Date(activeTo);
    result.timeTo = new Date(activeTo);
  }

  if (isEmpty(linkParameters)) {
    result.linkParametersAttributes = [{ linkType: undefined }];
  } else {
    result.linkParametersAttributes = linkParameters;
  }

  return transformLinkParameters(result);
};

export default parseDefaultValues;
