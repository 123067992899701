import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Abouts/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Abouts/Form/constants';
import { transformErrors } from 'utils/api';
import { useAboutQuery, useUpdateAboutMutation } from 'app/services/aboutsApi';
import useRoute from 'utils/useRoute';

function AboutEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const backPath = useRoute(ROUTES.abouts.base);
  useQueryParams();
  const { data: about = {}, isFetching } = useAboutQuery(id);
  const [updateAbout, { isLoading: updateRunning }] = useUpdateAboutMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateAbout(data)
        .unwrap()
        .then(() => {
          navigate(backPath);
          toast.success(`Zmiany zostały zastosowane`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (about) reset({ ...DEFAULT_VALUES, ...about });
  }, [about, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'O nas',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="about-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="about-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default AboutEdit;
