import { useFormContext, useWatch } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import Dropzone from 'components/Form/Dropzone';
import { OptionType } from 'types/common';
import LinkParameters from './LinkParameters';

interface ListingHeaderSectionProps {
  linkTypes: OptionType[];
  prepareFieldName: (name: string) => string;
  isFetching: boolean;
}

function ListingHeaderSection({
  prepareFieldName,
  linkTypes,
  isFetching,
}: ListingHeaderSectionProps) {
  const { control } = useFormContext();

  const isActive: boolean = useWatch({
    control,
    name: prepareFieldName('withListingHeader'),
  });

  return (
    <OptionalFormFieldset
      title="Nagłówek listingu (opcjonalnie)"
      mt="44px"
      isActive={isActive}
      name={prepareFieldName('withListingHeader')}
    >
      <Box
        borderBottom="1px solid"
        borderColor="complementary.grey"
        pb={6}
        mb={4}
      >
        <Box mb={4}>
          <Dropzone
            name={prepareFieldName('bannerListingsAttributes[0].banner')}
            title="Dodaj zdjęcie w formacie .png, .jpg (min. 360 px x 120 px)"
          />
        </Box>

        <LinkParameters
          prepareFieldName={prepareFieldName}
          fieldNamePrefix="bannerListingsAttributes[0]"
          index={0}
          linkTypes={linkTypes}
          isFetching={isFetching}
          isRemovable={false}
        />
      </Box>
    </OptionalFormFieldset>
  );
}

export default ListingHeaderSection;
