import * as yup from 'yup';
import { Box, Button, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import Input from 'components/Form/Input';
import { useFormWithSchema } from 'utils/formHooks';
import PasswordInput from 'components/Form/PasswordInput';
import Checkbox from 'components/Form/Checkbox';
import Link from 'components/Link';
import { useLoginMutation } from 'app/services/authApi';
import { parseApiError } from 'utils/helpers';
import { ApiError } from 'types/api';
import ROUTES from 'app/routes';
import useRoute from 'utils/useRoute';

const schema = yup.object({
  email: yup
    .string()
    .email('Niepoprawny format adresu email')
    .required('Adres email jest wymagany'),
  password: yup.string().required('Hasło jest wymagane'),
  remember: yup.bool().required(),
});

function LoginForm() {
  const [login] = useLoginMutation();
  const remindPasswordPath = useRoute(ROUTES.auth.reset);

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: {
      remember: false,
    },
  });

  const { handleSubmit, formState, setError } = methods;

  const { isDirty, isValid, isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login(data).unwrap();
    } catch (err) {
      const error = parseApiError(err as ApiError);
      setError('email', { message: error.msg });
    }
  });

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={onSubmit} w="452px">
        <VStack spacing="15px">
          <Input name="email" label="E-mail" autoComplete="email" />
          <PasswordInput
            name="password"
            label="Hasło"
            autoComplete="current-password"
          />
          <Checkbox name="remember" label="Zapamiętaj mnie" />
        </VStack>

        <Button
          isLoading={isSubmitting}
          isDisabled={!isDirty || !isValid}
          type="submit"
          w="100%"
          mt="40px"
        >
          ZALOGUJ SIĘ
        </Button>
        <Box w="100%" pt="32px" textAlign="center">
          <Link to={remindPasswordPath}> Przypomnij hasło</Link>
        </Box>
      </Box>
    </FormProvider>
  );
}

export default LoginForm;
