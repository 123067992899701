import { useCallback } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { FaRegTrashAlt } from 'react-icons/fa';
import Input from 'components/Form/Input';
import { FormComponentProps } from 'types/dashboardCreator';
// eslint-disable-next-line import/no-cycle
import ActivityFormSection from 'components/Container/Sections/ActivityFormSection';

interface TextElementProps extends FormComponentProps {
  allowDelete: boolean;
  index: number;
  onDelete: (index: number) => void;
}

function TextElement({
  allowDelete,
  prepareFieldName,
  index,
  onDelete,
}: TextElementProps) {
  const removeItem = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  const nestedFieldName = useCallback(
    (name: string) => prepareFieldName(`elementsAttributes[${index}].${name}`),
    [prepareFieldName, index],
  );

  return (
    <Box
      position="relative"
      mb={9}
      borderBottom="1px solid"
      borderBottomColor="complementary.grey"
    >
      {allowDelete && (
        <IconButton
          aria-label="remove input"
          icon={<FaRegTrashAlt size={16} />}
          variant="unstyled"
          size="sm"
          onClick={removeItem}
          position="absolute"
          right={-2}
          top={-2}
          zIndex={2}
        />
      )}
      <Input
        name={prepareFieldName(`elementsAttributes[${index}].title`)}
        label={`Tekst ${index + 1}`}
        mb={6}
      />

      <ActivityFormSection
        prepareFieldName={nestedFieldName}
        title={`Aktywność tekstu ${index + 1}`}
      />
    </Box>
  );
}

export default TextElement;
