import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<Nutrition, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'SKU',
      accessorKey: 'sku',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
