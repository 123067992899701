import { useEffect } from 'react';
import {
  Navigate,
  Outlet,
  useNavigate,
  useParams,
  useLocation,
  generatePath,
} from 'react-router-dom';
import ROUTES from 'app/routes';
import { selectIsAuthenticated } from 'selectors/authSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import { useLazyGetUserQuery } from 'app/services/authApi';
import { useLazyCountriesQuery } from 'app/services/commonApi';

function PrivateRoutes() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [fetchUser] = useLazyGetUserQuery();

  const { store } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [fetchStores, { isSuccess }] = useLazyCountriesQuery();

  // check if store is on stores list, redirect to defaukt if not
  useEffect(() => {
    (async () => {
      const { stores } = await fetchStores().unwrap();
      if (stores.length && !(stores as string[]).includes(store!)) {
        const newPath = `${pathname.replace(`${store}`, 'default')}${search}`;
        navigate(newPath, { replace: true });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        await fetchUser();
      }
    })();
  }, [fetchUser, isAuthenticated]);

  if (!isSuccess) {
    return null;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={generatePath(ROUTES.auth.login, { store })} />
  );
}

export default PrivateRoutes;
