import { FormProvider, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { HStack } from '@chakra-ui/react';
import { useFormWithSchema } from 'utils/formHooks';
import useQueryParams from 'utils/useQueryParams';
import DatePicker, { DateRangePickerWrapper } from 'components/Form/Date';
import { isValidDate } from 'utils/date';
import { schema } from 'pages/Statistics/TopBannersComparison/components/Form/constants';
import { DEFAULT_PARAMS } from 'pages/Statistics/TopBannersComparison';

function Search() {
  const { search, setSearch } = useQueryParams();

  const defaultValues = useMemo(() => {
    const {
      timeFrom: defaultTimeFrom,
      timeTo: defaultTimeTo,
      secondTimeFrom: defaultSecondTimeFrom,
      secondTimeTo: defaultSecondTimeTo,
    } = DEFAULT_PARAMS;

    const timeFrom = search.get('timeFrom');
    const timeTo = search.get('timeTo');
    const secondTimeFrom = search.get('secondTimeFrom');
    const secondTimeTo = search.get('secondTimeTo');

    const result = {
      firstDate: {
        start: defaultTimeFrom,
        end: defaultTimeTo,
      },
      secondDate: {
        start: defaultSecondTimeFrom,
        end: defaultSecondTimeTo,
      },
    };

    if (timeFrom && isValidDate(timeFrom)) {
      result.firstDate.start = new Date(timeFrom);
    }

    if (timeTo && isValidDate(timeTo)) {
      result.firstDate.end = new Date(timeTo);
    }

    if (secondTimeFrom && isValidDate(secondTimeFrom)) {
      result.secondDate.start = new Date(secondTimeFrom);
    }

    if (secondTimeTo && isValidDate(secondTimeTo)) {
      result.secondDate.end = new Date(secondTimeTo);
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
  });
  const { control, reset } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const [firstDate, secondDate] = useWatch({
    control,
    name: ['firstDate', 'secondDate'],
  });

  useEffect(() => {
    if (firstDate?.start && firstDate?.end) {
      search.set('timeFrom', firstDate.start.toISOString());
      search.set('timeTo', firstDate.end.toISOString());
      setSearch(search);
    }
  }, [firstDate, search, setSearch]);

  useEffect(() => {
    if (secondDate?.start && secondDate?.end) {
      search.set('secondTimeFrom', secondDate.start.toISOString());
      search.set('secondTimeTo', secondDate.end.toISOString());
      setSearch(search);
    }
  }, [search, secondDate, setSearch]);

  return (
    <FormProvider {...methods}>
      <form>
        <HStack spacing={4} mb={4}>
          <DateRangePickerWrapper>
            <DatePicker
              name="firstDate"
              label="Wybierz pierwszy zakres dat"
              showError
            />
          </DateRangePickerWrapper>
          <DateRangePickerWrapper>
            <DatePicker
              name="secondDate"
              label="Wybierz drugi zakres dat"
              showError
            />
          </DateRangePickerWrapper>
        </HStack>
      </form>
    </FormProvider>
  );
}

export default Search;
