import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ColorPicker from 'components/Form/Color';
import Input from 'components/Form/Input';
import LinkCreatorSection from 'components/LinkCreator';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import AlignmentSection from 'components/Container/Sections/AlignmentSection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

export enum COMPONENTS {
  BUTTON_COLOR = 'buttonBackground',
}

const fieldNames = ['buttonText', 'alignment'];

interface ButtonFormSectionProps {
  prepareFieldName: (name: string) => string;
  withLinkCreator?: boolean;
  components?: COMPONENTS[];
  isEditMode: boolean;
}

function ButtonFormSection({
  prepareFieldName,
  withLinkCreator,
  components,
  isEditMode,
}: ButtonFormSectionProps) {
  const { control, setValue } = useFormContext();
  const withButton: boolean = useWatch({
    control,
    name: prepareFieldName('withButton'),
  });

  useEffect(() => {
    if (!withButton) {
      fieldNames.forEach((field) => setValue(prepareFieldName(field), ''));
    }
  }, [prepareFieldName, setValue, withButton]);

  return (
    <OptionalFormFieldset
      title="Przycisk (opcjonalnie)"
      sectionWidth={FORM_BODY_MAX_WIDTH}
      isActive={withButton}
      name={prepareFieldName('withButton')}
    >
      <AlignmentSection
        fieldName={prepareFieldName('alignment')}
        label="Wyrównanie tekstu przycisku"
      />

      <Input
        name={prepareFieldName('buttonText')}
        defaultValue=""
        label="Treść przycisku"
        mb={6}
      />

      <ColorPicker
        name={prepareFieldName('buttonTextColor')}
        label="Kolor tekstu"
        mb={9}
      />

      {components?.includes(COMPONENTS.BUTTON_COLOR) && (
        <ColorPicker
          name={prepareFieldName('buttonColor')}
          label="Kolor tła przycisku"
          mb={9}
        />
      )}

      {withLinkCreator && (
        <LinkCreatorSection
          prepareFieldName={prepareFieldName}
          isEditMode={isEditMode || false}
        />
      )}
    </OptionalFormFieldset>
  );
}

ButtonFormSection.defaultProps = {
  withLinkCreator: false,
  components: [COMPONENTS.BUTTON_COLOR],
};

export default ButtonFormSection;
