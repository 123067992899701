import { FormControl, Stack } from '@chakra-ui/react';
import SectionTitle from 'components/Form/SectionTitle';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import DropzoneField from 'components/Form/Dropzone';
import LinkCreatorSection from 'components/LinkCreator';

function BasketballBannerForm({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  return (
    <Stack spacing={8} mb={8} maxW={FORM_BODY_MAX_WIDTH}>
      <FormControl as="fieldset">
        <SectionTitle as="legend">Ikona</SectionTitle>
        <DropzoneField
          name={prepareFieldName('image')}
          title="Dodaj ikonę w formacie .png, .jpg"
        />
      </FormControl>

      <Input name={prepareFieldName('title')} label="Treść" />

      <ColorPicker
        name={prepareFieldName('backgroundColor')}
        label="Kolor tła"
      />

      <ColorPicker label="Kolor tekstu" name={prepareFieldName('textColor')} />

      <FormControl as="fieldset">
        <SectionTitle as="legend">Badge</SectionTitle>
        <Stack spacing={8}>
          <Input name={prepareFieldName('buttonText')} label="Treść" />
          <ColorPicker
            label="Kolor tła"
            name={prepareFieldName('buttonColor')}
          />
          <ColorPicker
            label="Kolor tekstu"
            name={prepareFieldName('buttonTextColor')}
          />
        </Stack>
      </FormControl>
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </Stack>
  );
}

export default BasketballBannerForm;
