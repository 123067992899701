import { FormControl, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import SectionTitle from 'components/Form/SectionTitle';
import DropzoneField from 'components/Form/Dropzone';
import Input from 'components/Form/Input';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  const { store } = useParams();

  return (
    <Stack spacing={8}>
      <Input label="Nazwa" name={prepareFieldName('name')} />
      <Input label="Cena" name={prepareFieldName('price')} />
      {store === 'four_fstore_com' && (
        <>
          <Input label="Cena USD" name={prepareFieldName('priceUsd')} />
          <Input label="Cena GBP" name={prepareFieldName('priceGbp')} />
        </>
      )}

      <FormControl as="fieldset">
        <SectionTitle as="legend">Logo</SectionTitle>

        <DropzoneField
          name={prepareFieldName('logo')}
          title="Dodaj zdjęcie w formacie .png, .jpg"
        />
      </FormControl>
      <ToggleCheckbox label="Aktywność" name={prepareFieldName('active')} />
    </Stack>
  );
}

export default FormBody;
