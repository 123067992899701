import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  question: yup.string().required('Pole jest wymagane'),
  answer: yup.string().nullable(),
  active: yup.boolean(),
});

export const DEFAULT_VALUES = {
  question: '',
  answer: '',
  active: true,
};
