import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  sku: yup.string().required('Pole jest wymagane'),
  body: yup.string().nullable(),
});

export const DEFAULT_VALUES = {
  sku: '',
  body: '',
};
