import { Box } from '@chakra-ui/react';
import FormSpinner from 'components/FormSpinner';

export interface StatsTooltipProps {
  isFetching: boolean;
  children: React.ReactNode;
}

function StatsTooltip({ isFetching, children }: StatsTooltipProps) {
  return (
    <Box
      boxShadow="0 4px 10px 0 rgba(0, 0, 0, 0.25)"
      maxH="fit-content"
      minW="221px"
      maxW="300px"
      bg="white"
      p={8}
      overflow="hidden"
      textAlign="left"
      cursor="pointer"
    >
      {isFetching ? <FormSpinner /> : children}
    </Box>
  );
}

export default StatsTooltip;
