import { serialize } from 'object-to-formdata';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { api } from 'app/services/api';
import {
  transformLinksAttributesForPayload,
  convertKeysToSnakeCase,
} from 'utils/helpers';

const preparePayload = (values: WeatherCondition) => {
  const data = { ...values };

  if (data.weatherCategoryConditionHintsAttributes) {
    data.weatherCategoryConditionHintsAttributes =
      data.weatherCategoryConditionHintsAttributes.map(
        (element) =>
          transformLinksAttributesForPayload(
            element,
          ) as WeatherCategoryConditionHint,
      );
  }

  const transformedPayload = convertKeysToSnakeCase(data);

  return serialize(
    transformedPayload,
    SERIALIZER_OPTIONS,
    new FormData(),
    'weather_category_condition',
  );
};

export const weatherCategoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    weatherCategories: build.query<WeatherCategoriesResponse, void>({
      query: () => 'weather_categories',
      keepUnusedDataFor: 10,
      providesTags: ['WeatherCategories'],
    }),
    weatherCategory: build.query<WeatherCategoryResponse, string>({
      query: (id) => `weather_categories/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['WeatherCategory'],
    }),
    weatherCondition: build.query<any, { categoryId: string; id: string }>({
      query: ({ categoryId, id }) =>
        `weather_categories/${categoryId}/conditions/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['WeatherCategory'],
    }),
    updateWeatherCondition: build.mutation({
      query: (payload) => {
        const { categoryId, id } = payload;
        return {
          url: `weather_categories/${categoryId}/conditions/${id}`,
          method: 'PUT',
          body: preparePayload(payload),
        };
      },
      invalidatesTags: ['WeatherCategory'],
    }),
    updateWeatherCategory: build.mutation({
      query: (payload) => {
        const { id } = payload;

        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'weather_category',
        );

        return {
          url: `weather_categories/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['WeatherCategory'],
    }),
    loadByDashboard: build.query<WeatherCategoryResponse, string>({
      query: (kind) => {
        return {
          url: `weather_categories/${kind}/load_by_dashboard`,
          method: 'GET',
          headers: { store: 'default' },
        };
      },
      keepUnusedDataFor: 10,
    }),
    synchronize: build.mutation({
      query: (id) => {
        return {
          url: `weather_categories/${id}/synchronize`,
          method: 'GET',
        };
      },
      invalidatesTags: ['WeatherCategory'],
    }),
    import: build.mutation({
      query: ({ id, documentUrl }) => {
        const payload = convertKeysToSnakeCase({ documentUrl });

        const formData = serialize(
          payload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'weather_category',
        );

        return {
          url: `weather_categories/${id}/import`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['WeatherCategory'],
    }),
  }),
});

export const {
  useWeatherCategoriesQuery,
  useWeatherCategoryQuery,
  useWeatherConditionQuery,
  useUpdateWeatherConditionMutation,
  useUpdateWeatherCategoryMutation,
  useLoadByDashboardQuery,
  useSynchronizeMutation,
  useImportMutation,
} = weatherCategoriesApi;
