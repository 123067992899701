import styled from '@emotion/styled';

const NameInput = styled.input`
  width: 100%;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 600;

  &:focus {
    outline: none;
  }
`;

export default NameInput;
