import { Box, DrawerBody as ChakraBody, Stack } from '@chakra-ui/react';
import * as yup from 'yup';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import useQueryParams from 'utils/useQueryParams';
import { useFormWithSchema } from 'utils/formHooks';
import Input from 'components/Form/Input';
import { EMAIL, EXTERNAL_ID } from 'pages/Users/Listing/constants';

const schema = yup.object({
  email: yup.string().nullable(),
  externalId: yup.string().nullable(),
});

interface FilterBodyProps {
  onClose: () => void;
}

function FilterBody({ onClose }: FilterBodyProps) {
  const { search, setSearch } = useQueryParams();

  const getDefaultValues = () => {
    const email = search.get(EMAIL);
    const externalId = search.get(EXTERNAL_ID);

    const result: { [key: string]: any } = {};
    result.email = email || undefined;
    result.externalId = externalId || undefined;

    return result;
  };

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { email: newEmail, externalId: newExternalId } = data;

    if (newEmail) search.set(EMAIL, newEmail);
    else search.delete(EMAIL);

    if (newExternalId) search.set(EXTERNAL_ID, newExternalId);
    else search.delete(EXTERNAL_ID);

    search.set('page', '1');
    setSearch(search);
    onClose();
  });

  useEffect(() => {
    reset(getDefaultValues());
    // eslint-disable-next-line
  }, []);

  return (
    <ChakraBody overflowX="hidden" overflowY="auto">
      <FormProvider {...methods}>
        <Box as="form" id="filter-form" onSubmit={onSubmit} w="452px">
          <Stack spacing={8}>
            <Input
              name="email"
              label="E-mail"
              placeholder="Wpisz adres e-mail"
            />
            <Input
              name="externalId"
              label="Magento ID"
              placeholder="Wpisz id"
            />
          </Stack>
        </Box>
      </FormProvider>
    </ChakraBody>
  );
}

export default FilterBody;
