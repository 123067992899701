import Editor from 'components/Form/Editor';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return <Editor label="Treść" name={prepareFieldName('body')} />;
}

export default FormBody;
