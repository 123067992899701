import { ColumnDef } from '@tanstack/react-table';
import { Image } from '@chakra-ui/react';
import ColorPreview from 'components/ColorPreview';
import { ContainerElement } from 'types/dashboard';

function Columns(): ColumnDef<ContainerElement, string>[] {
  return [
    {
      header: 'NAZWA',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KOLOR TEKSTU',
      accessorKey: 'textColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'ZDJĘCIE',
      accessorKey: 'image.url',
      cell: (info) => (
        <Image
          w="64px"
          h="64px"
          borderRadius="full"
          overflow="hidden"
          src={info.getValue()}
          backgroundSize="cover"
        />
      ),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default Columns;
