import { VStack, Text, Heading, HStack, Box } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { BiImage } from 'react-icons/bi';
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode } from 'swiper';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useGroupsWithFallback from 'utils/useGroupsWithFallback';
import useImage from 'utils/useImage';
import { VideoPlayer } from 'components/Container/Previews/VideoBannerPreview';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { Dashboard } from 'types/dashboard';

interface ImageProps {
  elementAttributes: { [key: string]: any };
  number: number;
  w: number;
  h: number;
  showStats?: boolean;
}

export interface ImageGalleryPreviewProps extends PreviewComponentProps {
  maxW?: number;
}

const StyledSlide = styled(SwiperSlide)`
  padding: 0 16px;
  width: auto;

  &:last-child {
    padding: 0;
  }
`;

const RotateHeading = styled(Heading)`
  writing-mode: vertical-lr;
  transform: rotate(180deg);
`;

function Image({ elementAttributes, showStats, number, w, h }: ImageProps) {
  const imagePreview = useImage(elementAttributes.image);
  const videoPreview =
    elementAttributes.video?.url || elementAttributes.video?.path;

  return (
    <HStack alignItems="flex-start">
      <RotateHeading as="h4" fontSize={14} fontWeight={600} w={4}>
        {elementAttributes.title}
      </RotateHeading>
      <VStack
        alignItems="center"
        justifyContent="center"
        w={w}
        h={h}
        bgColor="complementary.whiteGrey"
        bgImage={videoPreview ? 'none' : imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
        position="relative"
      >
        {showStats && elementAttributes.createdAt && (
          <ShowStatsIcon
            data={elementAttributes as Dashboard}
            type="appstory_click"
          />
        )}
        {videoPreview ? (
          <VideoPlayer src={elementAttributes.video} />
        ) : (
          !imagePreview && (
            <>
              <Text
                position="absolute"
                fontSize={14}
                fontWeight={600}
                top={2}
                left={4}
              >
                {number + 1}
              </Text>
              <BiImage size={30} />
            </>
          )
        )}
      </VStack>
    </HStack>
  );
}

Image.defaultProps = {
  showStats: false,
};

Image.defaultValue = {
  elementAttributes: { image: '', title: '' },
  number: 0,
  w: 0,
  h: 0,
};

function ImageGalleryPreview({
  formValues,
  showStats,
  maxW,
}: ImageGalleryPreviewProps) {
  const elements = useMemo(() => {
    return formValues?.elementsAttributes ?? formValues?.elements;
  }, [formValues?.elementsAttributes, formValues?.elements]);

  const { groupsProps } = useGroupsWithFallback({ elements, itemsPerGroup: 3 });

  const elementsStyles = useCallback(
    (idx: number) => {
      if (groupsProps.length < 2) {
        return {
          justifyContent: 'flex-start',
        };
      }

      const isLastElement = groupsProps.length === idx + 1;
      return {
        justifyContent: isLastElement ? 'flex-end' : 'flex-start',
      };
    },
    [groupsProps],
  );

  return (
    <Box
      bgColor="complementary.lightGrey"
      pl={groupsProps.length > 1 ? 0 : 4}
      py={12}
      maxW={maxW || 536}
      alignSelf="flex-start"
    >
      <Swiper
        spaceBetween={45}
        modules={[FreeMode]}
        freeMode
        slidesPerView="auto"
      >
        {groupsProps.map((props, idx) => {
          return (
            <StyledSlide key={`img_gallery_slide_${+idx}`}>
              <HStack spacing={9} alignItems="center" {...elementsStyles(idx)}>
                <VStack spacing={12}>
                  <Image {...props[0]} w={160} h={200} showStats={showStats} />
                  <Image {...props[1]} w={160} h={200} showStats={showStats} />
                </VStack>
                <Image {...props[2]} w={280} h={400} showStats={showStats} />
              </HStack>
            </StyledSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}

ImageGalleryPreview.defaultProps = {
  maxW: undefined,
};

export default ImageGalleryPreview;
