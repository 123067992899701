import { Stack } from '@chakra-ui/react';
import ActivityFieldset from 'components/ActivityFieldset';
import Editor from 'components/Form/Editor';
import Input from 'components/Form/Input';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Editor label="Treść" name={prepareFieldName('content')} />
      <Input label="URL do PDF" name={prepareFieldName('inAppUrl')} />
      <ActivityFieldset prepareFieldName={prepareFieldName} fullWidth />
    </Stack>
  );
}

export default FormBody;
