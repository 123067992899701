import { useCallback, useMemo } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import Card from 'components/Card';
import FormBody from 'pages/CategoryContainer/Elements/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';
import ExternalError from 'components/Form/ExternalError';
import { useCategoryContainersQuery } from 'app/services/categoryContainerApi';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import ContainerActions from 'components/ContainerActions';

interface ContainerWrapperProps {
  index: number;
  isLoading: boolean;
  multipleContainers: boolean;
  isOpen: boolean;
  dragRef: ConnectDragSource;
  previewRef: ConnectDragPreview;
  removeContainer: (index: number) => void;
  toggleSectionHandler: () => void;
}

function ContainerWrapper({
  index,
  isLoading,
  multipleContainers,
  removeContainer,
  isOpen,
  dragRef,
  previewRef,
  toggleSectionHandler,
}: ContainerWrapperProps) {
  const { data: { data: containers } = { data: [] } } =
    useCategoryContainersQuery('');

  const fieldName = useMemo(() => `containers[${index}]`, [index]);
  const prepareFieldName = useCallback(
    (name: string) => `${fieldName}.${name}`,
    [fieldName],
  );

  const removeContainerHandler = useCallback(() => {
    removeContainer(index);
  }, [index, removeContainer]);

  return (
    <Flex alignItems="flex-start">
      <Card ref={previewRef}>
        {isLoading && <FormSpinner />}
        <ContainerActions
          showTrashButton={multipleContainers}
          toggleAction={toggleSectionHandler}
          removeAction={removeContainerHandler}
          isOpen={isOpen}
          dragRef={dragRef}
        />
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Text variant="boldSectionTitle">Kategoria specjalna</Text>
          <motion.div
            initial={{
              height: isOpen ? 'auto' : 0,
              overflow: isOpen ? 'visible' : 'hidden',
              paddingTop: isOpen ? '36px' : 0,
            }}
            animate={{
              height: isOpen ? 'auto' : 0,
              overflow: isOpen ? 'visible' : 'hidden',
              paddingTop: isOpen ? '36px' : 0,
            }}
          >
            <Box mb={9}>
              <Text variant="sectionTitle">Wybierz gdzie dodać kategorię</Text>
              <Flex mt={4}>
                {containers.map((option, idx) => (
                  <RadioBoxControl
                    key={option.id}
                    id={`${prepareFieldName(
                      'taxonomyContainerId',
                    )}-option-${idx}`}
                    label={option.name}
                    name={prepareFieldName('taxonomyContainerId')}
                    value={option.id.toString()}
                    group
                  />
                ))}
              </Flex>
              <ExternalError name={prepareFieldName('taxonomyContainerId')} />
            </Box>
            <FormBody prepareFieldName={prepareFieldName} />
          </motion.div>
        </Box>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
