import { VStack, Text, Box } from '@chakra-ui/react';

interface HeadersProps {
  title?: string;
  subtitle?: string;
  subtitleFontSize?: string | number;
  titleFontSize?: string | number;
  titleAlignment?: 'left' | 'center' | 'right';
  textColor?: string;
  textBackground?: string;
  showCounter?: boolean;
  position?: 'static' | 'relative' | 'absolute';
  isRich?: boolean;
  halfWidth?: boolean;
}

function Headers({
  title,
  subtitle,
  subtitleFontSize,
  titleFontSize,
  titleAlignment,
  textColor,
  textBackground,
  showCounter,
  position,
  isRich,
  halfWidth,
}: HeadersProps) {
  return (
    <VStack
      position={position}
      left="24px"
      top="24px"
      spacing={0}
      alignItems="flex-start"
      maxW={halfWidth ? '50%' : '100%'}
      wordBreak="break-all"
    >
      allaa
      {subtitle ? (
        <Text fontSize={subtitleFontSize} color={textColor} lineHeight="unset">
          {isRich ? (
            <Box
              as="span"
              boxDecorationBreak="clone"
              p="1px 8px"
              bgColor={textBackground}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          ) : (
            <Box
              as="span"
              boxDecorationBreak="clone"
              p="0px 8px"
              bgColor={textBackground}
            >
              {subtitle}
            </Box>
          )}
        </Text>
      ) : null}
      {title ? (
        <Text
          color={textColor}
          fontWeight={700}
          fontSize={titleFontSize}
          lineHeight="unset"
          textAlign={titleAlignment}
        >
          {isRich ? (
            <Box
              as="span"
              textTransform="uppercase"
              boxDecorationBreak="clone"
              p="1px 8px"
              bgColor={textBackground}
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          ) : (
            <Box
              as="span"
              textTransform="uppercase"
              boxDecorationBreak="clone"
              p="1px 8px"
              bgColor={textBackground}
            >
              {title}
            </Box>
          )}
        </Text>
      ) : null}
      {showCounter ? (
        <Text
          as="span"
          bgColor={textBackground}
          color={textColor}
          fontWeight={700}
          fontSize={titleFontSize}
          p="1px 8px"
          textTransform="uppercase"
        >
          {showCounter ? 'DD: MM: SS' : title}
        </Text>
      ) : null}
    </VStack>
  );
}

Headers.defaultProps = {
  title: null,
  subtitle: null,
  subtitleFontSize: 12,
  titleFontSize: 12,
  titleAlignment: 'left',
  textColor: '#000',
  textBackground: '#fff',
  showCounter: false,
  position: 'absolute',
  isRich: false,
  halfWidth: false,
};

export default Headers;
