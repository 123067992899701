import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
} from '@chakra-ui/react';
import { generatePath, Link, useParams } from 'react-router-dom';
import ROUTES from 'app/routes';
import BackNavigationButton from 'components/BackNavigationButton';
import { GENDER } from 'components/Container/constants';
import SynchronizeButton from 'pages/WeatherCategories/Preview/components/SynchronizeButton';
import ImportButton from 'pages/WeatherCategories/Preview/components/ImportButton';

interface HeaderNavigationProps {
  kind: string;
  id: string;
  documentUrl: string;
}

function HeaderNavigation({ kind, id, documentUrl }: HeaderNavigationProps) {
  const { store } = useParams();
  const isDefaultStore = store === 'default';

  return (
    <Flex mb={6} align="center" justify="space-between">
      <Box>
        <BackNavigationButton />
        <Breadcrumb mt={4}>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={generatePath(ROUTES.weatherCategories.base, { store })}
              fontWeight={700}
            >
              Kategoria pogodowa
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>
              {kind ? GENDER[kind as keyof typeof GENDER] : 'Loading...'}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Box>
      {isDefaultStore ? (
        <SynchronizeButton id={id} documentUrl={documentUrl} />
      ) : (
        <ImportButton id={id} kind={kind} />
      )}
    </Flex>
  );
}

export default HeaderNavigation;
