import { DEFAULT_FORM_VALUES } from 'components/Container/constants';
import { LandingPage, LandingPageTransformed } from 'types/landingPage';
import { parseDefaultValues as parseContainer } from 'utils/containers';

const parseDefaultValues = (landingPage: LandingPage) => {
  const result: LandingPageTransformed = {
    ...landingPage,
    containersAttributes: [],
    countries: [landingPage.language],
  };
  const { containers } = result;

  result.containersAttributes =
    containers?.map((container: any) => {
      const defaultValues = DEFAULT_FORM_VALUES[container.kind];

      const values = {
        ...defaultValues,
        ...container,
      };

      return parseContainer(values);
    }) || [];

  delete result.containers;

  return result;
};

export default parseDefaultValues;
