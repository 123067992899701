import { ColumnDef } from '@tanstack/react-table';
import ImagePreview from 'components/ImagePreview';
import { SubscriptionRunupScreen } from 'types/subscriptionRunupScreens';

function GetColumns(): ColumnDef<SubscriptionRunupScreen, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TREŚĆ PRZYCISKU',
      accessorKey: 'buttonText',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TREŚĆ LINKU',
      accessorKey: 'linkText',
      cell: (info) => info.getValue(),
    },
    {
      header: 'TŁO',
      accessorKey: 'background.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'SUMA ELEMENTÓW EKRANU',
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
