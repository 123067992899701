import omit from 'lodash/omit';
import { Subcategory } from 'types/subcategory';

const PARSED_VALUES = ['logo', 'image', 'linkParameters'];

const parseDefaultValues = (subCategory: Subcategory) => {
  const result: Record<string, any> = { ...omit(subCategory, PARSED_VALUES) };

  result.linkParametersAttributes = subCategory.linkParameters;
  result.withBadge = !!subCategory?.badge;
  result.withImage = !!subCategory?.image?.url;
  result.withLogo = !!subCategory?.logo?.url;
  result.withIcon = !!subCategory?.icon?.url;

  return result;
};

export default parseDefaultValues;
