import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import ListingLayout from 'components/Layout/ListingLayout';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';
import Table from 'pages/InPostPoints/Listing/components/Table';
import useQueryParams from 'utils/useQueryParams';
import { useInPostPointsQuery } from 'app/services/inPostPointsApi';

function InPostPoints() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const {
    isFetching,
    data: { data: inPostPoints, totals } = { data: [], totals: 0 },
  } = useInPostPointsQuery(queryString);

  return (
    <ListingLayout
      title="Punkty InPost"
      hideSearch
      hideFilter
      headerButtons={[]}
    >
      {isFetching && <LoadingIndicator />}
      {!isFetching && (
        <Table
          data={inPostPoints}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default InPostPoints;
