import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface ReactPortalProps {
  children: ReactNode;
  wrapperId: string;
}

function ReactPortal({ children, wrapperId }: ReactPortalProps) {
  if (document.getElementById(wrapperId)) {
    return createPortal(
      children,
      document.getElementById(wrapperId) as HTMLElement,
    );
  }

  return null;
}
export default ReactPortal;
