import * as yup from 'yup';
import {
  activityDatesSchema,
  validateImage,
} from 'components/Container/schema';

export const BODY_SCHEMA = yup.object({
  title: yup.string().nullable(),
  places: yup.array().of(yup.string()).nullable(),
  backgroundColor: yup.string().nullable().required('Pole jest wymagane'),
  textColor: yup
    .string()
    .when('type', (type) =>
      type === 'text'
        ? yup.string().nullable().required('Pole jest wymagane')
        : yup.mixed().nullable().notRequired(),
    ),
  image: yup.mixed().test({
    test(file) {
      return validateImage(
        file,
        'text_banner_slim',
        this,
        this.parent.type === 'image',
      );
    },
  }),
  ...activityDatesSchema,
  dateFrom: yup.date().required('Pole jest wymagane').nullable(),
});

export const DEFAULT_VALUES = {
  title: '',
  places: [],
  backgroundColor: '#FFFFFF',
  textColor: '#000000',
  active: true,
  timeFrom: null,
  dateFrom: new Date(),
  timeTo: null,
  dateTo: null,
  type: 'text',
};

export const OPTIONS = [
  {
    label: 'Karta produktu',
    value: 'product',
  },
  {
    label: 'Koszyk',
    value: 'cart',
  },
  {
    label: 'Taksonomia',
    value: 'taxonomy',
  },
  {
    label: 'Lista produktów',
    value: 'listing',
  },
];
