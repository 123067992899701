import { Flex } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/SurveyQuestions/Form/components/FormBody';
import FormSpinner from 'components/FormSpinner';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <FormSpinner />}
        <FormBody prepareFieldName={(name: string) => name} />
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
