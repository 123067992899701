import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';

export const nutritionsApi = api.injectEndpoints({
  endpoints: (build) => ({
    nutritions: build.query<TransformedNutritionsResponse, string>({
      query: (queryString) => `nutritions${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: NutritionsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Nutritions'],
    }),
    deleteNutrition: build.mutation<void, number>({
      query: (id) => ({
        url: `nutritions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Nutritions'],
    }),
    createNutrition: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'nutrition',
        );
        return {
          url: 'nutritions',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Nutritions'],
    }),
    nutrition: build.query<NutritionResponse, any>({
      query: (id) => `nutritions/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Nutritions'],
    }),
    updateNutrition: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'nutrition',
        );

        return {
          url: `nutritions/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Nutritions'],
    }),
  }),
});

export const {
  useNutritionsQuery,
  useDeleteNutritionMutation,
  useCreateNutritionMutation,
  useNutritionQuery,
  useUpdateNutritionMutation,
} = nutritionsApi;
