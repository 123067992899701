import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectedRows, UiState } from 'slices/ui/ui';

export const SIDEBAR_CLOSED_WIDTH = 133;
export const SIDEBAR_OPENED_WIDTH = 383;

const initialState: UiState = {
  isSidebarOpened: false,
  selectedRows: [],
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpened = !state.isSidebarOpened;
    },
    setSelectedRows: (state, action: PayloadAction<SelectedRows>) => {
      state.selectedRows = action.payload;
    },
    resetSelectedRows: (state) => {
      state.selectedRows = [];
    },
  },
});

export const { toggleSidebar, setSelectedRows, resetSelectedRows } =
  uiSlice.actions;

export default uiSlice.reducer;
