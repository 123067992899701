import { Button, Flex } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { getSidebarState } from 'selectors/uiSelectors';
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPENED_WIDTH } from 'slices/ui/uiSlice';
import { useAppSelector } from 'utils/reduxHooks';

export const FOOTER_HEIGHT = '88px';

interface FooterButtonsProps {
  isLoading: boolean;
  isCreate?: boolean;
  formId: string;
  okButtonText?: string;
  cancelButtonText?: string;
  backPath: string;
}

function FooterButtons({
  isLoading,
  isCreate,
  formId,
  okButtonText,
  cancelButtonText,
  backPath,
}: FooterButtonsProps) {
  const isSidebarOpened = useAppSelector(getSidebarState);

  return (
    <motion.div
      hidden={false}
      initial={false}
      animate={{
        left: isSidebarOpened ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH,
      }}
      style={{
        width: '100%',
        background: 'white',
        overflow: 'hidden',
        position: 'fixed',
        zIndex: 100,
        left: 0,
        height: FOOTER_HEIGHT,
        bottom: 0,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderTop: '1px solid #000000',
      }}
    >
      <Flex w="100%" h="100%" alignItems="center" p={4}>
        <Button
          type="submit"
          fontSize={12}
          fontWeight={700}
          minWidth={218}
          form={formId}
          isLoading={isLoading}
        >
          {okButtonText || (isCreate ? 'UTWÓRZ' : 'ZAPISZ')}
        </Button>
        <Button
          variant="outlined"
          ml={4}
          fontSize={12}
          fontWeight={700}
          minWidth={120}
          as={Link}
          to={backPath}
        >
          {cancelButtonText}
        </Button>
      </Flex>
    </motion.div>
  );
}

FooterButtons.defaultProps = {
  isCreate: false,
  okButtonText: '',
  cancelButtonText: 'ANULUJ',
};

export default FooterButtons;
