import { Flex, Image } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { datePrettier } from 'utils/date';
import { StatusType } from 'types/common';
import StatusBox from 'components/StatusBox';
import { ComponentItem, Dashboard } from 'types/dashboard';

interface ColumnProps {
  componentTypes: ComponentItem[];
}

function GetColumns({
  componentTypes,
}: ColumnProps): ColumnDef<Dashboard, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => (
        <Flex align="center" direction="column">
          {info.getValue()}
        </Flex>
      ),
    },
    {
      header: 'TYTUŁ',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'POZYCJA',
      accessorKey: 'position',
      cell: (info) => info.getValue(),
    },

    {
      header: 'ZDJĘCIE',
      accessorKey: 'image.thumb.url',
      cell: (info) => <Image src={info.getValue()} alt="container-image" />,
    },
    {
      header: 'STATUS',
      accessorKey: 'status',
      cell: (info) => <StatusBox status={info.getValue() as StatusType} />,
    },
    {
      header: 'AKTYWNY OD',
      accessorKey: 'activeFrom',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'AKTYWNY DO',
      accessorKey: 'activeTo',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'LINK WEWNĘTRZNY',
      accessorKey: 'content',
      cell: 'link wewnętrzny',
    },
    {
      header: 'KONTENER',
      accessorKey: 'kind',
      cell: (info) =>
        componentTypes.find((el) => el.value === info.getValue())?.label,
    },
    {
      header: '',
      accessorKey: 'action',
      cell: () => '',
    },
  ];
}

export default GetColumns;
