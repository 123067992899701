import { Box, FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import RichText from 'components/Form/RichText';
import ColorPicker from 'components/Form/Color';
import Input from 'components/Form/Input';
import LinkCreatorSection from 'components/LinkCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';

function TextBannerImage({ prepareFieldName, isEditMode }: FormComponentProps) {
  return (
    <FormControl mb={9}>
      <Box as="fieldset" pb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Zdjęcie</SectionTitle>
        <DropzoneField
          name={prepareFieldName('image')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 360 px x 248 px)"
        />
      </Box>
      <Box as="fieldset" pb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Opis slajdu</SectionTitle>
        <RichText
          name={prepareFieldName('content')}
          placeholder="Tu dodaj opis, możesz skorzystać ze znaczników html, które znajdziesz powyżej"
        />
      </Box>
      <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH} pb={9}>
        <ColorPicker
          label="Kolor tekstu"
          name={prepareFieldName('textColor')}
        />
      </Box>
      <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH} pb={9}>
        <SectionTitle as="legend">Przycisk</SectionTitle>
        <Input
          name={prepareFieldName('buttonText')}
          defaultValue=""
          label="Treść przycisku"
          mb={6}
        />

        <ColorPicker
          name={prepareFieldName('buttonTextColor')}
          label="Kolor tekstu przycisku"
        />
      </Box>
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </FormControl>
  );
}

export default TextBannerImage;
