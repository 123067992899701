import {
  Box,
  Image,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { MdOutlineCancel } from 'react-icons/md';
import { VideoPlayer } from 'components/Container/Previews/VideoBannerPreview';

function VideoPreview({ thumb, url }: BannerVideo) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!url || url === '-') return <Box pt={4}>brak</Box>;

  return (
    <>
      <Image
        src={thumb}
        fit="scale-down"
        alt="image"
        onClick={onOpen}
        cursor="pointer"
        fallback={
          <Text
            color="complementary.blue"
            fontSize="16px"
            textDecoration="underline"
            cursor="pointer"
            onClick={onOpen}
          >
            Podgląd
          </Text>
        }
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={6}>
          <ModalHeader p={0}>PODGLĄD</ModalHeader>
          <ModalCloseButton>
            <MdOutlineCancel size="19px" />
          </ModalCloseButton>
          <ModalBody>
            <VideoPlayer src={{ url }} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default VideoPreview;
