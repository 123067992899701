import omit from 'lodash/omit';

const PARSED_VALUES: string[] = ['activeFrom, activeTo'];

const parseDefaultValues = (regulationElement: RegulationElement) => {
  const result: Record<string, any> = {
    ...omit(regulationElement, PARSED_VALUES),
  };
  const { activeFrom, activeTo } = regulationElement;

  if (activeFrom) {
    result.dateFrom = new Date(activeFrom);
    result.timeFrom = new Date(activeFrom);
  }

  if (activeTo) {
    result.dateTo = new Date(activeTo);
    result.timeTo = new Date(activeTo);
  }

  return result;
};

export default parseDefaultValues;
