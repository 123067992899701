import { Box } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<Rating, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'UTWORZONO',
      accessorKey: 'createdAt',
      cell: (info) => <Box minW="104px">{datePrettier(info.getValue())}</Box>,
    },
    {
      header: 'OCENA',
      accessorKey: 'value',
      cell: (info) => info.getValue(),
    },
  ];
}

export default GetColumns;
