import { Box, Heading, HStack } from '@chakra-ui/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BiImage } from 'react-icons/bi';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';

function BasketballBannerPreview({ formValues }: PreviewComponentProps) {
  const iconPreview = useImage(formValues?.image);

  return (
    <HStack
      backgroundColor={formValues?.backgroundColor || '#000000'}
      color={formValues?.textColor || '#FFFFFF'}
      padding="8px 4px 8px 12px"
      spacing={2}
    >
      {iconPreview ? (
        <Box
          width="40px"
          height="40px"
          bgImage={iconPreview}
          bgPosition="50% 50%"
          bgRepeat="no-repeat"
          bgSize="contain"
        />
      ) : (
        <BiImage size={40} />
      )}
      <Heading
        as="h4"
        fontSize={14}
        textTransform="uppercase"
        flex={1}
        noOfLines={1}
      >
        {formValues?.title || 'Tu pojawi się treść'}
      </Heading>
      <Box
        padding="0px 8px"
        backgroundColor={formValues?.buttonColor || '#E5253A'}
        color={formValues?.buttonTextColor || '#FFFFFF'}
        textTransform="uppercase"
        fontWeight={700}
        textAlign="center"
        fontSize={12}
      >
        {formValues?.buttonText || 'Treść'}
      </Box>
      <Box padding={2}>
        <AiOutlineArrowRight size={24} />
      </Box>
    </HStack>
  );
}

export default BasketballBannerPreview;
