import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/SurveyQuestions/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import LoadingIndicator from 'components/LoadingIndicator';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/SurveyQuestions/Form/constants';
import useRoute from 'utils/useRoute';
import {
  useSurveyQuestionQuery,
  useUpdateSurveyQuestionMutation,
} from 'app/services/surveysApi';
import { FORM_MAX_WIDTH } from 'utils/constants';
import parseDefaultValues from 'pages/SurveyQuestions/Form/Edit/parseDefaultValues';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';
import getRemovedElements from 'selectors/formSelectors';
import { clearRemovedElements } from 'slices/form/formSlice';
import { transformRemoved } from 'utils/helpers';

function SurveyQuestionEdit() {
  const { id = '', surveyId = '' } = useParams();
  const backPath = useRoute(ROUTES.surveys.questions.base, { surveyId });
  const navigate = useNavigate();
  const removedElements = useAppSelector(getRemovedElements);
  const dispatch = useAppDispatch();

  const { data: { data: question } = {}, isFetching } = useSurveyQuestionQuery({
    id,
    surveyId,
  });
  const [updateQuestion, { isLoading: updateRunning }] =
    useUpdateSurveyQuestionMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data: SurveyQuestion) => {
    try {
      const payload = {
        ...data,
        id,
        surveyId,
        answersAttributes: [
          ...(data?.answersAttributes || []),
          ...transformRemoved(removedElements),
        ],
      };
      await updateQuestion(payload).unwrap();
      navigate(backPath);
      toast.success(`Zmiany w pytaniu zostały zastosowane`);
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (question) reset({ ...DEFAULT_VALUES, ...parseDefaultValues(question) });
  }, [question, reset]);

  useEffect(() => {
    return () => {
      dispatch(clearRemovedElements());
    };
  }, [dispatch]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'Quizy',
          to: backPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj pytanie' }]}
      />
      <FormProvider {...methods}>
        <Box
          as="form"
          id="survey-question-editor"
          onSubmit={onSubmit}
          maxW={FORM_MAX_WIDTH}
          pb="120px"
        >
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="survey-question-editor"
        backPath={backPath}
      />
    </Box>
  );
}

export default SurveyQuestionEdit;
