import {
  Box,
  FormControl,
  Textarea,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import get from 'lodash/get';
import { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TagButtonList from 'components/Form/RichText/TagButtonList';

interface RichTextProps {
  tags?: string[];
  rows?: number;
  placeholder: string;
  name: string;
  label?: string;
}

export default function RichText({
  name,
  tags,
  rows,
  label,
  placeholder,
}: RichTextProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;

  const ref = useRef<HTMLTextAreaElement | null>(null);

  return (
    <FormControl isInvalid={Boolean(errorMessage)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          const onClick = (tag: string) => {
            if (
              ref?.current &&
              (ref.current.selectionStart || ref.current.selectionStart === 0)
            ) {
              const startPos = ref.current.selectionStart;
              onChange(
                `${ref.current.value.substring(
                  0,
                  startPos,
                )}${tag}${ref.current.value.substring(
                  startPos,
                  ref.current.value.length,
                )}`,
              );

              setTimeout(() => {
                const focusPlacement = startPos + tag.length;
                ref.current?.setSelectionRange(focusPlacement, focusPlacement);
                ref.current?.focus();
              }, 0);
            }
          };
          return (
            <Box
              border="1px solid"
              p="16px"
              borderColor={errorMessage ? 'red.500' : 'black'}
            >
              {tags && tags.length ? (
                <TagButtonList tags={tags} onClick={onClick} />
              ) : null}
              <Textarea
                variant="unstyled"
                _placeholder={{ color: '#A9A9A9', fontSize: 14 }}
                ref={ref}
                py="0px"
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                resize="none"
                rows={rows}
              />
            </Box>
          );
        }}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

RichText.defaultProps = {
  label: '',
  rows: 4,
  tags: ['i', 'b', 'br', 'u'],
};
