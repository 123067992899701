import { FormControl } from '@chakra-ui/react';
import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import HeaderFormSection from 'components/Container/Sections/HeaderFormSection';
import ButtonFormSection from 'components/Container/Sections/ButtonFormSection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import LinkCreatorSection from 'components/LinkCreator';
import TagsFieldset from 'components/TagsFieldset';

// photo_banner_medium
function PhotoBannerMedium({
  prepareFieldName,
  isEditMode,
}: FormComponentProps) {
  return (
    <>
      <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Zdjęcie</SectionTitle>

        <DropzoneField
          name={prepareFieldName('image')}
          title="Dodaj zdjęcie w formacie .png, .jpg (min. 360px x 396px)"
        />
      </FormControl>

      <HeaderFormSection prepareFieldName={prepareFieldName} />
      <ButtonFormSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
      <TagsFieldset prepareFieldName={prepareFieldName} />
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
    </>
  );
}

export default PhotoBannerMedium;
