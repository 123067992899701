import { Row, flexRender } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import {
  MdOutlineEdit,
  MdOutlineDragIndicator,
  MdOutlineVisibility,
} from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { generatePath, Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCallback } from 'react';
import Tr from 'components/Table/Tr';
import DndSpacer from 'components/Table/DndSpacer';
import ROUTES from 'app/routes';
import DeleteModal from 'components/DeleteModal';
import { DragItem } from 'types/common';
import { usePreviewRegulationElementMutation } from 'app/services/regulationsApi';
import Td from './components/Td';

const isProduction = process.env.REACT_APP_ENV === 'production';

interface DraggableRowProps {
  row: Row<RegulationElement>;
  handleReorder: (draggedRowIndex: number, targetRowIndex: number) => void;
  handleDelete: (id: number) => void;
  regulationId: number;
}

function DraggableRow({
  row,
  handleReorder,
  handleDelete,
  regulationId,
}: DraggableRowProps) {
  const { store } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [previewRegulationElement, { isLoading }] =
    usePreviewRegulationElementMutation();

  const handlePreview = useCallback(async () => {
    try {
      previewRegulationElement({
        regulationId,
        id: row.original.id,
        name: row.original.title,
      }).unwrap();
    } catch (error) {
      toast.error('Wystąpił problem podczas pobierania regulaminu');
    }
  }, [previewRegulationElement, regulationId, row]);

  const [{ isOver, targetOffset }, dropRef] = useDrop({
    accept: 'row',
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        targetOffset: monitor.getDifferenceFromInitialOffset()?.y,
      };
    },
    drop: (item: DragItem) => {
      const sourcePlacement = item.placement;
      const targetPlacement = row.original.position;
      if (sourcePlacement === targetPlacement) return;
      handleReorder(item.id, targetPlacement);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'row',
    item: () => {
      return { id: row.original.id, placement: row.original.position };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const showDrop = isOver && !isDragging && targetOffset;

  return (
    <>
      {showDrop && targetOffset < 0 && <DndSpacer />}
      <Tr ref={previewRef} key={`body_row_${row.original.id}_${row.id}`}>
        {row.getVisibleCells().map((cell, idx, { length }) => {
          if (idx + 1 === length) return null;
          return (
            <Td key={`body_column_${row.original.id}_${cell.id}`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
        <Td ref={dropRef}>
          <>
            <DeleteModal
              onAccept={() => handleDelete(row.original.id)}
              onClose={onClose}
              isOpen={isOpen}
            >
              Czy na pewno chcesz trwale usunąć ten element?
            </DeleteModal>
            {!isProduction && (
              <Tooltip label="Podgląd" openDelay={1000}>
                <IconButton
                  width="72px"
                  height="56px"
                  icon={<MdOutlineVisibility size={20} />}
                  aria-label="visiblity"
                  variant="ghost"
                  onClick={handlePreview}
                  isLoading={isLoading}
                />
              </Tooltip>
            )}
            <Tooltip label="Edytuj" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<MdOutlineEdit size={20} />}
                aria-label="visiblity"
                variant="ghost"
                as={Link}
                to={generatePath(ROUTES.regulationElements.edit, {
                  regulationId,
                  id: row.original.id,
                  store,
                })}
              />
            </Tooltip>
            <Tooltip label="Usuń" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<RiDeleteBin6Line size={20} />}
                aria-label="visiblity"
                variant="ghost"
                onClick={onOpen}
              />
            </Tooltip>
            <IconButton
              width="72px"
              height="56px"
              icon={<MdOutlineDragIndicator size={20} />}
              aria-label="visiblity"
              variant="ghost"
              ref={dragRef}
            />
          </>
        </Td>
      </Tr>
      {showDrop && targetOffset > 0 && <DndSpacer />}
    </>
  );
}

export default DraggableRow;
