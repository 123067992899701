import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import {
  ShipmentResponse,
  ShipmentsResponse,
  TransformedShipmentsResponse,
} from 'types/shipments';

export const shipmentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    shipments: build.query<TransformedShipmentsResponse, string>({
      query: (queryString) => `shipments${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: ShipmentsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Shipments'],
    }),
    deleteShipment: build.mutation<void, number>({
      query: (id) => ({
        url: `shipments/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Shipments'],
    }),
    reorderShipment: build.mutation<void, ReorderParams>({
      query: ({ body, id }) => ({
        url: `shipments/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Shipments'],
    }),
    createShipment: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'shipment',
        );
        return {
          url: 'shipments',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Shipments'],
    }),
    shipment: build.query<ShipmentResponse, any>({
      query: (id) => `shipments/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Shipments'],
    }),
    updateShipment: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'shipment',
        );

        return {
          url: `shipments/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Shipments'],
    }),
  }),
});

export const {
  useShipmentsQuery,
  useDeleteShipmentMutation,
  useReorderShipmentMutation,
  useCreateShipmentMutation,
  useShipmentQuery,
  useUpdateShipmentMutation,
} = shipmentsApi;
