import { generatePath, useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { MdOutlineCheck } from 'react-icons/md';
import { useAppDispatch } from 'utils/reduxHooks';
import { ICON_DICT, LABEL_DICT } from 'components/CountryChange/dictionary';
import { changeStore } from 'slices/auth/authSlice';
import { api } from 'app/services/api';
import { Country } from 'types/common';
import ROUTES from 'app/routes';

type CountryButtonProps = Country & {
  closeModal: () => void;
  isChecked: boolean;
};

function CountryButton({
  isChecked,
  countryCode,
  store,
  closeModal,
}: CountryButtonProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch(api.util.resetApiState());
    dispatch(changeStore(store));

    // redirect to listings
    navigate(generatePath(ROUTES.dashboard.base, { store }));
    closeModal();
  };

  return (
    <Box
      px="5px"
      py="10px"
      as="button"
      maxW="208px"
      onClick={handleClick}
      _hover={{ bg: 'complementary.lightGrey' }}
    >
      <Flex direction="row" h="48px" w="208px" align="center">
        {ICON_DICT[countryCode]}
        <Text
          w="100%"
          textAlign="left"
          fontSize="16px"
          paddingLeft="16px"
          fontWeight={isChecked ? 600 : 400}
        >
          {LABEL_DICT[countryCode]}
        </Text>
        {isChecked && (
          <Box marginRight="12px">
            <MdOutlineCheck />
          </Box>
        )}
      </Flex>
    </Box>
  );
}

export default CountryButton;
