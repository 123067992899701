/* eslint-disable react-hooks/rules-of-hooks */
import { Box, FormControl, VStack } from '@chakra-ui/react';
import SectionTitle from 'components/Form/SectionTitle';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import LinkCreatorSection from 'components/LinkCreator';
import AlignmentSection from 'components/Container/Sections/AlignmentSection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';

interface ButtonFormProps {
  advanced: boolean;
}

function ButtonForm({ advanced }: ButtonFormProps) {
  // eslint-disable-next-line func-names
  return function ({ prepareFieldName, isEditMode }: FormComponentProps) {
    return (
      <Box as="fieldset" maxW={FORM_BODY_MAX_WIDTH}>
        <FormControl as="fieldset" mb={9}>
          <SectionTitle as="legend">Przycisk</SectionTitle>
          {advanced && (
            <AlignmentSection
              fieldName={prepareFieldName('alignment')}
              label="Wyrównanie tekstu przycisku"
            />
          )}
          <VStack pb="52px" spacing="26px">
            <Input
              name={prepareFieldName('title')}
              placeholder="Wpisz tekst"
              label="Treść przycisku"
            />

            <ColorPicker
              name={prepareFieldName('buttonTextColor')}
              label="Kolor tekstu"
            />
            {!advanced && (
              <ColorPicker
                name={prepareFieldName('backgroundColor')}
                label="Kolor przycisku"
              />
            )}
            <ColorPicker
              name={prepareFieldName('insideBoxBackgroundColor')}
              label="Kolor tła przycisku"
            />
          </VStack>
          <LinkCreatorSection
            prepareFieldName={prepareFieldName}
            isEditMode={isEditMode || false}
          />
        </FormControl>
      </Box>
    );
  };
}
export default ButtonForm;
