import styled from '@emotion/styled';
import { HStack } from '@chakra-ui/react';

const Bullet = styled.div<{ isActive: boolean; color?: string }>`
  transition: background-color 0.3s ease;
  background-color: ${({ isActive, color }) =>
    isActive ? color : 'transparent'};
  border: ${({ color }) => `1px solid ${color}`};
  width: 8px;
  aspect-ratio: 1;
  cursor: pointer;
`;

Bullet.defaultProps = {
  color: '#ffffff',
};

interface SliderPaginatorProps {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  totalElements: number;
  align?: 'left' | 'right';
  color?: string;
}

function SliderPaginator({
  activeIndex,
  setActiveIndex,
  align,
  totalElements,
  color,
}: SliderPaginatorProps) {
  return (
    <HStack
      spacing={1}
      alignSelf="flex-end"
      position="absolute"
      zIndex={10}
      bottom={7}
      left={align === 'left' ? 6 : 'auto'}
      right={align === 'right' ? 6 : 'auto'}
    >
      {[...Array(totalElements)].map((_v: any, idx: number) => (
        <Bullet
          key={`bullet_${+idx}`}
          onClick={() => {
            setActiveIndex(idx);
          }}
          isActive={idx === activeIndex}
          color={color}
        />
      ))}
    </HStack>
  );
}

SliderPaginator.defaultProps = {
  align: 'right',
  color: '',
};

export default SliderPaginator;
