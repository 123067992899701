import { Box, Flex } from '@chakra-ui/react';

interface ColorPreviewProps {
  hex?: string;
}
function ColorPreview({ hex }: ColorPreviewProps) {
  if (!hex) return null;
  return (
    <Flex justify="space-between">
      {hex}{' '}
      <Box
        h="19px"
        w="19px"
        borderRadius="50%"
        borderWidth="1px"
        borderColor="black"
        bg={hex}
      />
    </Flex>
  );
}
ColorPreview.defaultProps = {
  hex: '',
};
export default ColorPreview;
