import { useEffect, useMemo } from 'react';
import { Box, Flex, VStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import ColorPicker from 'components/Form/Color';
import Input from 'components/Form/Input';
import RichText from 'components/Form/RichText';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import AlignmentSection from 'components/Container/Sections/AlignmentSection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

export enum COMPONENTS {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  ALIGNMENT = 'alignment',
  COLORTXT = 'colorTxt',
  COLORBG = 'colorBg',
}

const fieldNames = ['subtitle', 'titleAlignment', 'title'];

interface HeaderFormSectionProps {
  prepareFieldName: (fieldName: string) => string;
  children?: React.ReactNode;
  components?: COMPONENTS[];
  titleLabel?: string;
  subtitleLabel?: string;
  isRich?: boolean;
}

function HeaderFormSection({
  prepareFieldName,
  children,
  components,
  titleLabel,
  subtitleLabel,
  isRich,
}: HeaderFormSectionProps) {
  const { control, setValue } = useFormContext();
  const withHeader: boolean = useWatch({
    control,
    name: prepareFieldName('withHeader'),
  });

  const shouldRenderTitle = useMemo(
    () => components?.includes(COMPONENTS.TITLE),
    [components],
  );
  const shouldRenderSubtitle = useMemo(
    () => components?.includes(COMPONENTS.SUBTITLE),
    [components],
  );
  const shouldRenderAlignment = useMemo(
    () => components?.includes(COMPONENTS.ALIGNMENT),
    [components],
  );
  const shouldRenderColorTxt = useMemo(
    () => components?.includes(COMPONENTS.COLORTXT),
    [components],
  );
  const shouldRenderColorBg = useMemo(
    () => components?.includes(COMPONENTS.COLORBG),
    [components],
  );

  useEffect(() => {
    if (!withHeader) {
      fieldNames.forEach((field) => setValue(prepareFieldName(field), ''));
    }
  }, [withHeader, setValue, prepareFieldName]);

  return (
    <OptionalFormFieldset
      title="Nagłówek (opcjonalnie)"
      headerWidth={FORM_BODY_MAX_WIDTH}
      isActive={withHeader}
      name={prepareFieldName('withHeader')}
    >
      {shouldRenderAlignment && (
        <AlignmentSection
          fieldName={prepareFieldName('titleAlignment')}
          label="Wyrównanie tekstu"
        />
      )}

      <VStack as="fieldset" spacing="16px" align="left" w="100%" pb={9}>
        {shouldRenderSubtitle && (
          <Flex gap={4}>
            {isRich ? (
              <RichText
                label={subtitleLabel}
                name={prepareFieldName('subtitle')}
                placeholder="Tu dodaj treść nagłówka, możesz skorzystać ze znaczników html, które znajdziesz powyżej"
              />
            ) : (
              <Input
                label={subtitleLabel}
                type="text"
                name={prepareFieldName('subtitle')}
              />
            )}
            <Box minW={136}>
              <Input
                label="Rozmiar tekstu (w pt)"
                defaultValue={12}
                type="number"
                name={prepareFieldName('subtitleFontSize')}
              />
            </Box>
          </Flex>
        )}

        {shouldRenderTitle && (
          <Flex gap={4}>
            {isRich ? (
              <RichText
                label={titleLabel}
                name={prepareFieldName('title')}
                placeholder="Tu dodaj treść nagłówka, możesz skorzystać ze znaczników html, które znajdziesz powyżej"
              />
            ) : (
              <Input
                label={titleLabel}
                type="text"
                name={prepareFieldName('title')}
              />
            )}
            <Box minW={136}>
              <Input
                label="Rozmiar tekstu (w pt)"
                defaultValue={16}
                type="number"
                name={prepareFieldName('titleFontSize')}
              />
            </Box>
          </Flex>
        )}

        {children}

        {shouldRenderColorTxt && (
          <ColorPicker
            name={prepareFieldName('textColor')}
            label="Kolor tekstu"
            mb={6}
            maxW={FORM_BODY_MAX_WIDTH}
          />
        )}

        {shouldRenderColorBg && (
          <ColorPicker
            name={prepareFieldName('textBackground')}
            label="Kolor tła tekstu"
            mb={9}
            maxW={FORM_BODY_MAX_WIDTH}
          />
        )}
      </VStack>
    </OptionalFormFieldset>
  );
}

HeaderFormSection.defaultProps = {
  children: null,
  components: [
    COMPONENTS.TITLE,
    COMPONENTS.SUBTITLE,
    COMPONENTS.COLORTXT,
    COMPONENTS.COLORBG,
  ],
  titleLabel: 'Nagłówek dół',
  subtitleLabel: 'Nagłówek góra',
  isRich: false,
};
export default HeaderFormSection;
