import { Flex, Box } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/Shipments/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <FormSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <FormBody prepareFieldName={(name: string) => name} />
        </Box>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
