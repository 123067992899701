import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { Button, useDisclosure } from '@chakra-ui/react';
import DeleteModal from 'components/DeleteModal';
import { useAppSelector } from 'utils/reduxHooks';
import { getSelectedRows } from 'selectors/uiSelectors';
import { useLandingBulkDeleteMutation } from 'app/services/landingPageApi';

function FooterButtons() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const selectedRows = useAppSelector(getSelectedRows);
  const isMenuDisabled = isEmpty(selectedRows);
  const [bulkDelete] = useLandingBulkDeleteMutation();

  const onDelete = () => {
    try {
      bulkDelete({
        landingPageIds: selectedRows,
      }).unwrap();
      toast.success('Pomyślnie usunięto kontenery');
      onClose();
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania kontenerów');
    }
  };

  return (
    <>
      <DeleteModal isOpen={isOpen} onClose={onClose} onAccept={onDelete}>
        Czy na pewno chcesz usunąć wybrane komponenty?
      </DeleteModal>
      <Button disabled={isMenuDisabled} variant="link" onClick={onOpen}>
        USUŃ
      </Button>
    </>
  );
}

export default FooterButtons;
