import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { MdOutlineCancel } from 'react-icons/md';
import CountryButton from 'components/CountryChange/CountryButton';
import { useAppSelector } from 'utils/reduxHooks';
import { getCountryCode } from 'selectors/appSelectors';
import { useCountriesQuery } from 'app/services/commonApi';

interface CountrySelectProps {
  isOpen: boolean;
  onClose: () => void;
}

function CountrySelect({ isOpen, onClose }: CountrySelectProps) {
  const pickedCountryCode = useAppSelector(getCountryCode);

  const { data: { data: countries } = { data: [] } } = useCountriesQuery();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent p={0}>
        <ModalHeader>
          Wybierz kraj aplikacji, w której chcesz pracować
        </ModalHeader>
        <ModalCloseButton>
          <MdOutlineCancel size="19px" />
        </ModalCloseButton>
        <ModalBody pt="42px" pb="50px">
          <Flex
            wrap="wrap"
            maxH="408px"
            direction="column"
            w="min-content"
            columnGap="150px"
          >
            {countries.map((country) => (
              <CountryButton
                key={`country_button_${country.countryCode}`}
                countryCode={country.countryCode}
                store={country.store}
                closeModal={onClose}
                isChecked={country.countryCode === pickedCountryCode}
              />
            ))}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CountrySelect;
