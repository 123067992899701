import { ColumnDef } from '@tanstack/react-table';
import { GENDER } from 'components/Container/constants';
import { LABEL_DICT } from 'components/CountryChange/dictionary';
import ImagePreview from 'components/ImagePreview';
import { CountryCodes } from 'types/common';
import VideoPreview from 'pages/Statistics/TopBanners/components/Table/components/VideoPreview';

function GetColumns(): ColumnDef<BannerItem, string>[] {
  return [
    {
      header: 'POZYCJA',
      accessorKey: 'position',
      cell: (info) => info.getValue(),
    },
    {
      header: 'ID',
      accessorKey: 'analyticsId',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'MASKA',
      accessorKey: 'store.lang',
      cell: (info) => LABEL_DICT[info.getValue() as CountryCodes],
    },
    {
      header: 'ZDJĘCIE',
      accessorKey: 'mainImage',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'VIDEO',
      cell: ({
        row: {
          original: { video },
        },
      }) => <VideoPreview {...video} />,
    },
    {
      header: 'DASHBOARD',
      accessorKey: 'genderType',
      cell: (info) => GENDER[info.getValue() as keyof typeof GENDER],
    },
    {
      header: 'DZISIAJ',
      accessorKey: 'todayValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'WCZORAJ',
      accessorKey: 'yesterdayValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'OSTATNIE 7 DNI',
      accessorKey: 'weekValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'OKRES',
      accessorKey: 'rangeValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'POPRZEDNI OKRES',
      accessorKey: 'shadowValue',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
