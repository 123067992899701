import { Tooltip, IconButton, useDisclosure } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import {
  MdOutlineVisibility,
  MdOutlineEdit,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link, generatePath, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import ROUTES from 'app/routes';
import DeleteModal from 'components/DeleteModal';
import Tr from 'components/Table/Tr';
import Td from 'pages/CategoryTags/Listing/components/TableComponents/Td';
import { useDeleteCategoryMutation } from 'app/services/categoryApi';
import NestedTable from 'pages/CategoryTags/Listing/components/NestedTable';
import { Category } from 'types/category';
import useQueryParams from 'utils/useQueryParams';

const MotionTr = motion(Tr);

interface TableRowProps {
  row: Row<Category>;
}
function TableRow({ row }: TableRowProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { store } = useParams();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { id } = row.original;
  const { queryString } = useQueryParams();
  const [deleteCategory] = useDeleteCategoryMutation({
    fixedCacheKey: 'categories',
  });

  const editPath = useMemo(
    () =>
      generatePath(ROUTES.category.edit, {
        id: id.toString(),
        store,
      }) + queryString,
    [id, queryString, store],
  );

  const handleDelete = async () => {
    try {
      await deleteCategory(id).unwrap();
      toast.success('Pomyślnie usunięto kategorie');
      onClose();
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania kategorii');
    }
  };

  return (
    <React.Fragment key={`group_row_${row.id}`}>
      <Tr key={`row_${id}`}>
        {row.getVisibleCells().map((cell, idx, { length }) => {
          if (idx + 1 === length) return null;
          return (
            <Td key={`row_cell_${cell.id}_${cell}`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          );
        })}
        <Td>
          <>
            <DeleteModal
              onAccept={handleDelete}
              onClose={onClose}
              isOpen={isOpen}
            >
              Czy na pewno chcesz trwale usunąć tą kategorie?
            </DeleteModal>
            <Tooltip label="Podgląd" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<MdOutlineVisibility size={20} />}
                aria-label="visiblity"
                variant="ghost"
                as={Link}
                to={generatePath(ROUTES.categoryTag.preview, {
                  id: id.toString(),
                  store,
                })}
              />
            </Tooltip>
            <Tooltip label="Edytuj" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<MdOutlineEdit size={20} />}
                aria-label="visiblity"
                variant="ghost"
                as={Link}
                to={editPath}
              />
            </Tooltip>
            <Tooltip label="Usuń" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={<RiDeleteBin6Line size={20} />}
                aria-label="visiblity"
                variant="ghost"
                onClick={onOpen}
              />
            </Tooltip>
            <Tooltip label="Zwiń/rozwiń" openDelay={1000}>
              <IconButton
                width="72px"
                height="56px"
                icon={
                  <MdKeyboardArrowUp
                    size={20}
                    style={{
                      transition: 'transform .3s ease-in-out',
                      transform: `rotate(${isExpanded ? 0 : 180}deg)`,
                    }}
                  />
                }
                aria-label="Toggle row"
                variant="ghost"
                onClick={() => setIsExpanded((prevState) => !prevState)}
              />
            </Tooltip>
          </>
        </Td>
      </Tr>
      <MotionTr
        key={`table_row_${id}`}
        animate={{
          height: isExpanded ? 'auto' : 0,
          opacity: isExpanded ? 1 : 0,
        }}
      >
        {isExpanded && (
          <NestedTable categoryId={row.original.id} rowId={row.id} />
        )}
      </MotionTr>
    </React.Fragment>
  );
}

export default TableRow;
