import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';

export const administrationAPI = api.injectEndpoints({
  endpoints: (build) => ({
    administrators: build.query<TransformedAdministratorsResponse, string>({
      query: (queryString) => `admin_users${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: AdministratorsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Administration'],
    }),
    deleteAdministrator: build.mutation<void, number>({
      query: (id) => ({
        url: `admin_users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Administration'],
    }),
    createAdministrator: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'admin_user',
        );
        return {
          url: 'admin_users',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Administration'],
    }),
    administrator: build.query<AdministratorResponse, any>({
      query: (payload) => {
        const { id } = payload;
        return `admin_users/${id}/edit`;
      },
      keepUnusedDataFor: 10,
      providesTags: ['Administration'],
    }),
    updateAdministrator: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'admin_user',
        );

        return {
          url: `admin_users/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Administration'],
    }),
  }),
});

export const {
  useAdministratorsQuery,
  useDeleteAdministratorMutation,
  useCreateAdministratorMutation,
  useAdministratorQuery,
  useUpdateAdministratorMutation,
} = administrationAPI;
