/* eslint-disable no-underscore-dangle */
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Accordion, Box, Button, Flex, FormControl } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';

import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';
import { DEFAULT_FORM_VALUES } from 'components/Container/constants';
import StylizationItemSection from 'components/Container/Sections/StylizationItemSection';
import SectionTitle from 'components/Form/SectionTitle';
import Input from 'components/Form/Input';

// kind: stylization
function StylizationForm({ prepareFieldName, isEditMode }: FormComponentProps) {
  const { control } = useFormContext();
  const dispatch = useAppDispatch();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: prepareFieldName('elementsAttributes'),
    keyName: 'fieldId',
  });

  const removeElement = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [isEditMode, remove, dispatch, fields],
  );

  const addSlide = useCallback(() => {
    append({
      ...DEFAULT_FORM_VALUES.stylization.elementsAttributes[0],
      placement: fields.length,
    });
  }, [append, fields]);

  return (
    <Box as="fieldset">
      <FormControl as="fieldset" mb={4}>
        <SectionTitle>Nagłówek</SectionTitle>
        <Flex gap={4}>
          <Input
            label="Nagłówek"
            type="text"
            name={prepareFieldName('title')}
          />

          <Box minW={136}>
            <Input
              label="Rozmiar tekstu (w pt)"
              defaultValue={12}
              type="number"
              name={prepareFieldName('titleFontSize')}
            />
          </Box>
        </Flex>
      </FormControl>
      <Accordion defaultIndex={[0, 1, 2, 3]} allowMultiple mb={10}>
        {fields.map((field: any, index) => (
          <StylizationItemSection
            id={field.fieldId}
            itemIndex={index}
            prepareFieldName={prepareFieldName}
            key={field.fieldId}
            move={move}
            remove={removeElement}
          />
        ))}
        <Button
          type="button"
          variant="solid"
          mt={4}
          w="100%"
          onClick={addSlide}
          fontSize="12px"
          maxW={FORM_BODY_MAX_WIDTH}
        >
          DODAJ STYLIZACJĘ
          <Box as="span" ml={2}>
            <BiPlus size={24} />
          </Box>
        </Button>
      </Accordion>
    </Box>
  );
}

export default StylizationForm;
