import * as yup from 'yup';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';
import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'utils/reduxHooks';
import { useFormWithSchema } from 'utils/formHooks';
import { selectStore } from 'selectors/authSelectors';
import { getSelectedRows } from 'selectors/uiSelectors';
import ExternalError from 'components/Form/ExternalError';
import { useCountriesQuery } from 'app/services/commonApi';
import CountryItem from 'components/CountryCopy/CountryItem';
import { useCloneToCountryMutation } from 'app/services/dashboardApi';
import { resetSelectedRows } from 'slices/ui/uiSlice';

const schema = yup.object({
  targetStore: yup.array().of(yup.string()).min(1, 'Wybierz kraj').required(),
  currentStore: yup.string().required('Wybierz kraj'),
  dashboardContainerIds: yup.array().of(yup.number()).required(),
});

interface FormProps {
  onClose: () => void;
}

function Form({ onClose }: FormProps) {
  const [cloneToCountry] = useCloneToCountryMutation();
  const selectedRows: number[] = useAppSelector(getSelectedRows);
  const currentStore: string = useAppSelector(selectStore);
  const dispatch = useAppDispatch();

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: {
      targetStore: [],
      currentStore,
    },
  });

  const { handleSubmit, formState, setValue } = methods;

  const { data: { data: countries } = { data: [] } } = useCountriesQuery();

  const { isDirty, isSubmitting, isValid } = formState;

  useEffect(() => {
    selectedRows.forEach((containerId, index) =>
      setValue(`dashboardContainerIds.${index}`, containerId, {
        shouldDirty: true,
      }),
    );
  }, [selectedRows, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await Promise.all(
        data.targetStore.map(async (targetStore) => {
          return cloneToCountry({
            ...data,
            targetStore: targetStore!,
          }).unwrap();
        }),
      );
      toast.success(
        `Kontener${selectedRows.length > 1 ? 'y' : ''} ${selectedRows.join(
          ', ',
        )} został${selectedRows.length > 1 ? 'y' : ''} pomyślnie przekopiowan${
          selectedRows.length > 1 ? 'e' : 'y'
        }`,
      );
      dispatch(resetSelectedRows());
      onClose();
    } catch (err) {
      toast.error('Coś poszło nie tak');
      // eslint-disable-next-line no-console
      console.log(err);
    }
  });

  return (
    <FormProvider {...methods}>
      <HStack as="form" onSubmit={onSubmit} mt="50px" alignItems="flex-end">
        <Box
          mr="40px"
          display="flex"
          flexWrap="wrap"
          maxW={544}
          mb="-28px"
          justifyContent="space-between"
        >
          {countries?.map((country) => (
            <CountryItem key={country.countryCode} country={country} />
          ))}
          <ExternalError name="targetStore" />
        </Box>
        <VStack w="100%" spacing="24px" pt="67px" maxW="452px">
          <Button
            isLoading={isSubmitting}
            type="submit"
            w="100%"
            isDisabled={!isDirty || !isValid}
          >
            KOPIUJ
          </Button>
          <Button variant="outlined" w="100%" onClick={onClose}>
            ANULUJ
          </Button>
        </VStack>
      </HStack>
    </FormProvider>
  );
}

export default Form;
