import { motion, AnimatePresence } from 'framer-motion';

const zoomInAnimation = {
  start: { height: 333, width: 304 },
  onZoom: {
    height: 494,
    width: 264,
    transition: {
      height: {
        duration: 0.4,
      },
    },
  },
  ofZoom: {
    height: 414,
    width: 223,
    transition: {
      height: {
        duration: 0.2,
      },
    },
  },
};
interface ProductWrapperProps {
  children: React.ReactNode;
  isEffectActive: boolean;
}

function ProductWrapper({ children, isEffectActive }: ProductWrapperProps) {
  return (
    <AnimatePresence initial={false}>
      <motion.div
        variants={zoomInAnimation}
        initial="start"
        animate={isEffectActive ? 'onZoom' : 'ofZoom'}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export default ProductWrapper;
