import { Stack } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { ADMINISTRATOR_ROLE_OPTIONS } from 'pages/Administration/Form/constants';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Select
        label="Rola"
        name={prepareFieldName('role')}
        placeholder="Wybierz"
        options={ADMINISTRATOR_ROLE_OPTIONS}
        isLoading={false}
      />
      <Input
        label="Hasło"
        type="password"
        name={prepareFieldName('password')}
      />
      <Input
        label="Powtórz hasło"
        type="password"
        name={prepareFieldName('passwordConfirmation')}
      />
    </Stack>
  );
}

export default FormBody;
