import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { MdOutlineCancel } from 'react-icons/md';
import Form from 'components/CountryCopy/Form';

interface CountryCopyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function CountryCopyModal({ isOpen, onClose }: CountryCopyModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent p="76px 58px 66px">
        <ModalHeader p={0}>KOPIUJ DO KRAJU</ModalHeader>
        <ModalCloseButton>
          <MdOutlineCancel size="19px" />
        </ModalCloseButton>
        <ModalBody p="8px 0 0 0">
          <Text fontSize="16px">
            Wybierz kraj(e), do którego przekopiujesz wybrane kontenery
          </Text>

          <Form onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CountryCopyModal;
