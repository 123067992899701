import { FC } from 'react';
import { Box, BoxProps, useDisclosure } from '@chakra-ui/react';
import HeaderFilters, {
  ListingActionButton,
} from 'components/Layout/ListingLayout/HeaderFilters';

interface FilterDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}
interface ListingLayoutProps {
  children: React.ReactNode;
  title: string;
  headerButtons: ListingActionButton[];
  filterDrawer?: ({ isOpen, onClose }: FilterDrawerProps) => React.ReactNode;
  placement?: string;
  searchKey?: string;
  hideSearch?: boolean;
  hideFilter?: boolean;
  FilterBox?: FC<BoxProps>;
}

function ListingLayout({
  title,
  children,
  headerButtons,
  filterDrawer,
  searchKey = '',
  placement = '',
  hideSearch = false,
  hideFilter = false,
  FilterBox,
}: ListingLayoutProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box pt="56px">
      <HeaderFilters
        title={title}
        buttons={headerButtons}
        onFiltersOpen={onOpen}
        searchKey={searchKey}
        placement={placement}
        hideSearch={hideSearch}
        hideFilter={hideFilter}
        FilterBox={FilterBox}
      />
      {filterDrawer && filterDrawer({ isOpen, onClose })}
      {children}
    </Box>
  );
}
ListingLayout.defaultProps = {
  hideSearch: false,
  searchKey: '',
  placement: '',
  filterDrawer: null,
  hideFilter: false,
  FilterBox: Box,
};

export default ListingLayout;
