import { useCallback, useMemo, Fragment } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, BoxProps, Button } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';
import styled from '@emotion/styled';
import { useLinkTypesQuery } from 'app/services/commonApi';
import SectionTitle from 'components/Form/SectionTitle';
import LinkParameters from 'components/LinkCreator/LinkParameters';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import ListingHeaderSection from 'components/LinkCreator/ListingHeader';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

//   "Kategoria" => "categoryId=param_value", ++ listing banner
//   "Lista kategorii" => "categoryIds[]=param_value", ++ listing banner
//   "Cecha specjalna - ID" => "badge[]=param_value", ++ listing banner
//   "Cecha specjalna - nazwa" => "badge_label=param_value", ++ listing banner
//   "Lista produktów" => "skus[]=param_value", ++ listing banner
//   "Płeć" => "gender[]=param_value", ++ listing banner
//   "SKU" => "sku=param_value", ++ listing banner

// LINK_TYPES = {
//   "Nowości" => "is_new=true",
//   "Bestellery" => "bestseller=true",
//   "Promocje" => "special_price=true",
//   "Kategoria" => "categoryId=param_value",
//   "Lista kategorii" => "categoryIds[]=param_value",
//   "SKU" => "sku=param_value",
//   "Cecha specjalna - ID" => "badge[]=param_value",
//   "Cecha specjalna - nazwa" => "badge_label=param_value",
//   "Lista produktów" => "skus[]=param_value",
//   "Płeć" => "gender[]=param_value",
//   "Dołącz do zalogowanych" => "join_app_screen=true",
//   "Mapa sklepów" => "search_store=true",
//   "Konfigurator gogli" => "configuratorId=gogle-configurator",
//   "Ubraniomat" => "clothing_machine=true",
//   "Powiadomienia i zgody" => "notifications=true",
//   "Regulamin aplikacji" => "regulation_type=general",
//   "Polityka prywatnosci" => "regulation_type=privacy_policy"
// }

const Wrapper = styled(Box)`
  > div {
    margin-top: 30px;

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const SUPPORTS_HEADER_LISTING = [
  'categoryId=param_value',
  'categoryIds[]=param_value',
  'badge[]=param_value',
  'badge_label=param_value',
  'skus[]=param_value',
  'gender[]=param_value',
  'special_price=true',
];

type LinkCreatorSectionProps = BoxProps & {
  prepareFieldName: (name: string) => string;
  optionalField?: boolean;
  isEditMode: boolean;
  placement?: string;
  hideListingHeader?: boolean;
};

function LinkCreatorSection({
  prepareFieldName,
  optionalField,
  isEditMode,
  placement,
  hideListingHeader,
  ...rest
}: LinkCreatorSectionProps) {
  const { data: { data: linkTypes } = { data: [] }, isFetching } =
    useLinkTypesQuery({ placement });
  const { control } = useFormContext();
  const {
    fields: parameters,
    append,
    remove: removeParameter,
    update: updateParameter,
  } = useFieldArray({
    control,
    name: prepareFieldName('linkParametersAttributes'),
    keyName: 'fieldId',
  });

  const linkType = useWatch({
    control,
    name: prepareFieldName('linkParametersAttributes'),
  });

  const withLinkParameters: boolean = useWatch({
    control,
    name: prepareFieldName('withLinkParameters'),
  });

  const shouldRenderAddButton = useMemo(() => {
    const idx = parameters.length !== 0 ? parameters.length - 1 : 0;
    return Boolean(
      linkType &&
        linkType[idx]?.linkType &&
        !linkType[idx]?.linkType?.includes('landing_page'),
    );
  }, [parameters, linkType]);

  const addLinkParameter = useCallback(() => {
    append({ linkType: null });
  }, [append]);

  const withListingHeaderIndex: number = useMemo(
    () =>
      linkType?.findIndex((lt: { linkType: string }) =>
        SUPPORTS_HEADER_LISTING.includes(lt?.linkType),
      ),
    [linkType],
  );

  const isRemovable: boolean = useMemo(
    () =>
      isEditMode
        ? // eslint-disable-next-line no-underscore-dangle
          parameters.filter((param: any) => !param._destroy).length > 1
        : parameters.length > 1,
    [parameters, isEditMode],
  );

  const handleRemove = useCallback(
    (index: number) => {
      if (!isEditMode) {
        removeParameter(index);
      } else {
        // if edit mode, set _destroy param true, element visibility is based on _destory value
        updateParameter(index, { ...parameters[index], _destroy: true });
      }
    },
    [parameters, removeParameter, updateParameter, isEditMode],
  );

  const content = (
    <>
      <Wrapper as="fieldset">
        {parameters.map((param, index: number) => (
          <Fragment key={param.fieldId}>
            <LinkParameters
              prepareFieldName={prepareFieldName}
              isFetching={isFetching}
              linkTypes={linkTypes}
              index={index}
              handleRemove={handleRemove}
              isRemovable={isRemovable}
            />

            {withListingHeaderIndex === index &&
            !hideListingHeader &&
            // eslint-disable-next-line no-underscore-dangle
            !(param as { _destroy?: boolean })._destroy ? (
              <ListingHeaderSection
                prepareFieldName={prepareFieldName}
                linkTypes={linkTypes}
                isFetching={isFetching}
              />
            ) : null}
          </Fragment>
        ))}
      </Wrapper>

      {shouldRenderAddButton && (
        <Button
          type="button"
          variant="solid"
          mt={4}
          w="100%"
          onClick={addLinkParameter}
          fontSize="12px"
        >
          DODAJ PARAMETR
          <Box as="span" ml={2}>
            <BiPlus size={24} />
          </Box>
        </Button>
      )}
    </>
  );

  if (optionalField) {
    return (
      <OptionalFormFieldset
        title="Parametry linku (opcjonalnie)"
        maxW={FORM_BODY_MAX_WIDTH}
        mb="44px"
        isActive={withLinkParameters}
        name={prepareFieldName('withLinkParameters')}
        {...rest}
      >
        {content}
      </OptionalFormFieldset>
    );
  }

  return (
    <Box maxW={FORM_BODY_MAX_WIDTH} mb="44px" w="100%" {...rest}>
      <SectionTitle as="legend">Parametry linku</SectionTitle>
      {content}
    </Box>
  );
}

LinkCreatorSection.defaultProps = {
  optionalField: false,
  placement: null,
  hideListingHeader: false,
};

export default LinkCreatorSection;
