import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { TeamResponse, TeamsResponse } from 'types/basketball';

export const basketballApi = api.injectEndpoints({
  endpoints: (build) => ({
    teams: build.query<TeamsResponse, void>({
      query: () => `pzkosz/teams`,
      keepUnusedDataFor: 10,
      providesTags: ['Teams'],
    }),
    team: build.query<TeamResponse, string>({
      query: (id) => `pzkosz/teams/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Teams'],
    }),
    updateTeam: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'pzkosz_team',
        );

        return {
          url: `pzkosz/teams/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['Teams'],
    }),
  }),
});

export const { useTeamsQuery, useTeamQuery, useUpdateTeamMutation } =
  basketballApi;
