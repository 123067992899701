import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useLazyCountriesQuery } from 'app/services/commonApi';

interface LayoutProps {
  children: React.ReactNode;
}

function PublicLayout({ children }: LayoutProps) {
  const { store } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [fetchStores, { isSuccess }] = useLazyCountriesQuery();

  // check if store is on stores list, redirect to defaukt if not
  useEffect(() => {
    (async () => {
      const { stores } = await fetchStores().unwrap();
      if (stores.length && !(stores as string[]).includes(store!)) {
        const newPath = `${pathname.replace(`${store}`, 'default')}${search}`;
        navigate(newPath, { replace: true });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  if (!isSuccess) {
    return <div>loading</div>;
  }

  return (
    <Box w="100%" h="100vh">
      {children}
    </Box>
  );
}

export default PublicLayout;
