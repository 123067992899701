import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  name: yup.string().nullable().required('Pole jest wymagane'),
  price: yup.string().nullable().required('Pole jest wymagane'),
  logo: yup.mixed().test({
    test(file, context) {
      if (!file) {
        return context.createError({
          message: 'Logo jest wymagane',
        });
      }
      return true;
    },
  }),
  priceGbp: yup.string().nullable(),
  priceUsd: yup.string().nullable(),
  active: yup.boolean(),
});

export const DEFAULT_VALUES = {
  name: '',
  price: '',
  logo: null,
  priceGbp: null,
  priceUsd: null,
  active: true,
};
