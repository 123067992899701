import { useState, FC, useEffect } from 'react';
import { AspectRatio, Box } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import ImagePlaceholder from 'components/ImagePlaceholder';
import SliderPaginator from 'components/SliderPaginator';
import Image from 'components/ImagesSlider/Image';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { Dashboard } from 'types/dashboard';

type Element = {
  [key: string]: any;
  image: File | {};
  magentoImageUrl?: string;
};

interface ImagesSliderProps {
  elements: Element[];
  imageRatio?: number;
  SlideComponent: FC<{ element: Element; showStats?: boolean }>;
  showStats?: boolean;
  isPlayWithColorsPreview?: boolean;
}

function ImagesSlider({
  elements,
  imageRatio,
  SlideComponent,
  showStats,
  isPlayWithColorsPreview,
}: ImagesSliderProps) {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [activeIndex, setActiveIndex] = useState(0);
  const currentSlide = elements?.[activeIndex];

  useEffect(() => {
    swiper?.slideTo(activeIndex);
  }, [activeIndex, swiper]);

  if (!elements.length) {
    return <ImagePlaceholder imageRatio={imageRatio} />;
  }

  return (
    <Box pos="relative" width={388}>
      <Swiper
        className="mySwiper"
        onSwiper={setSwiper}
        onSlideChange={(slide) => {
          setActiveIndex(slide.activeIndex);
        }}
        onSlidesLengthChange={(slide) => {
          slide.update();
        }}
      >
        {elements.map((element, idx) => (
          <SwiperSlide key={`slide-preview–${+idx}`}>
            <AspectRatio w="100%" ratio={imageRatio}>
              <SlideComponent element={element} showStats={showStats} />
            </AspectRatio>
            {showStats && !isPlayWithColorsPreview && (
              <ShowStatsIcon data={element as Dashboard} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {elements.length > 1 && (
        <SliderPaginator
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          totalElements={elements.length}
          color={currentSlide?.navigationColor}
        />
      )}
    </Box>
  );
}

ImagesSlider.defaultProps = {
  imageRatio: 3 / 4,
  showStats: false,
  isPlayWithColorsPreview: false,
};

export default ImagesSlider;

export { Image };
