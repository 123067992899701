import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { BoxProps } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import TabElement from 'components/Table/Table';
import Thead from 'components/Table/Thead';
import Tr from 'components/Table/Tr';
import Tbody from 'components/Table/Tbody';
import Th from 'components/Table/Th';
import columns from 'pages/Notifications/Listing/components/Table/columns';
import EmptyBody from 'components/Table/EmptyBody';
import TableWrapper from 'components/Table/TableWrapper';
import TableRow from 'pages/Notifications/Listing/components/Table/TableRow';
import { useAppDispatch } from 'utils/reduxHooks';
import { resetSelectedRows, setSelectedRows } from 'slices/ui/uiSlice';
import useQueryParams from 'utils/useQueryParams';

type TableProps = BoxProps & {
  data: Notification[];
};

function Table({ data, ...props }: TableProps) {
  const [rowSelection, setRowSelection] = useState({});
  const { search } = useQueryParams();
  const dispatch = useAppDispatch();

  const table = useReactTable({
    data,
    columns: columns(),
    state: {
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
  });

  useEffect(() => {
    setRowSelection({});
  }, [search]);

  useEffect(() => {
    dispatch(
      setSelectedRows(
        table.getSelectedRowModel().flatRows.map((item) => item.original.id),
      ),
    );
    return () => {
      dispatch(resetSelectedRows());
    };
    // eslint-disable-next-line
  }, [rowSelection]);

  const noTableData = isEmpty(data);

  return (
    <TableWrapper {...props}>
      <TabElement isEmpty={noTableData}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`header_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`header_cell_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {noTableData ? (
            <EmptyBody
              columnAmount={table.getAllColumns().length}
              queryParams={[]}
            />
          ) : (
            table
              .getRowModel()
              .rows.map((row) => (
                <TableRow key={`table_row_${row.original.id}`} row={row} />
              ))
          )}
        </Tbody>
      </TabElement>
    </TableWrapper>
  );
}

export default Table;
