import { useCallback } from 'react';
import { Accordion, Box, Button, FormControl } from '@chakra-ui/react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { BiPlus } from 'react-icons/bi';
import { DEFAULT_VALUES } from 'pages/WeatherConditions/Form/constants';
import { useAppDispatch } from 'utils/reduxHooks';
import { addRemovedElement } from 'slices/form/formSlice';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import WeatherConditionSection from 'components/Container/Sections/WeatherConditionSection';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  isEditMode?: boolean;
}

function FormBody({ prepareFieldName, isEditMode }: FormBodyProps) {
  const { control } = useFormContext();
  const dispatch = useAppDispatch();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: prepareFieldName('weatherCategoryConditionHintsAttributes'),
    keyName: 'fieldId',
  });

  const removeElement = useCallback(
    (index: number) => {
      const element = fields[index] as any;
      if (isEditMode && element?.id) {
        dispatch(addRemovedElement(element));
      }
      remove(index);
    },
    [isEditMode, remove, dispatch, fields],
  );

  const addElement = useCallback(() => {
    append(DEFAULT_VALUES.weatherCategoryConditionHintsAttributes[0]);
  }, [append]);

  return (
    <FormControl as="fieldset">
      <Accordion defaultIndex={[0]} allowMultiple overflow="visible">
        {fields.map((field: any, index) => (
          <WeatherConditionSection
            id={field.fieldId}
            itemIndex={index}
            isEditMode={isEditMode || false}
            prepareFieldName={prepareFieldName}
            key={field.fieldId}
            move={move}
            remove={removeElement}
          />
        ))}
        <Button
          type="button"
          variant="solid"
          mt={4}
          w="100%"
          onClick={addElement}
          fontSize="12px"
          maxW={FORM_BODY_MAX_WIDTH}
        >
          DODAJ SUGESTIĘ
          <Box as="span" ml={2}>
            <BiPlus size={24} />
          </Box>
        </Button>
      </Accordion>
    </FormControl>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
