import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from 'slices/app/app';
import { CountryCodes } from 'types/common';

const initialState: AppState = {
  countryCode: 'pl',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCountry: (state, action: PayloadAction<CountryCodes>) => {
      state.countryCode = action.payload;
    },
  },
});

export const { setCountry } = appSlice.actions;

export default appSlice.reducer;
