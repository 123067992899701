import { DeepPartial, Theme } from '@chakra-ui/react';
import colors from 'theme/colors';

const Tabs: DeepPartial<Theme['components']['Tabs']> = {
  parts: ['tab', 'tablist'],
  baseStyle: {},
  variants: {
    line: {
      tablist: {
        border: 'none',
      },
      tab: {
        px: '16px',
        py: '5px',
        fontSize: '14px',
        lineHeight: '14px',
        textTransform: 'uppercase',
        border: 'none',
        _active: {
          bg: 'transparent',
        },
        _hover: {
          boxShadow: `0 4px 0 0 rgba(11, 36, 251, 0.15)`,
        },
        _selected: {
          fontWeight: 600,
          border: 'none',
          boxShadow: `0 4px 0 0 ${colors.complementary.blue}`,
          color: 'black',
          _hover: {
            cursor: 'default',
            boxShadow: `0 4px 0 0 ${colors.complementary.blue}`,
          },
        },
      },
    },
  },
};

export default Tabs;
