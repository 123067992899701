import { Navigate } from 'react-router-dom';
import ROUTES from 'app/routes';
import useRoute from 'utils/useRoute';
import { useAppSelector } from 'utils/reduxHooks';
import { selectIsAuthenticated } from 'selectors/authSelectors';

function Index() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const loginRoute = useRoute(ROUTES.auth.login, { store: 'default' });
  const dashboardRoute = useRoute(ROUTES.dashboard.base, { store: 'default' });

  if (isAuthenticated && dashboardRoute) {
    return <Navigate to={dashboardRoute} replace />;
  }

  return <Navigate to={loginRoute} replace />;
}

export default Index;
