import { flexRender, Row } from '@tanstack/react-table';
import { toast } from 'react-toastify';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Tr from 'components/Table/Tr';
import Td from 'pages/Users/Listing/components/Table/components/Td';
import DeleteModal from 'components/DeleteModal';
import { useDeleteUserMutation } from 'app/services/usersApi';

interface TableRowProps {
  row: Row<User>;
}
function TableRow({ row }: TableRowProps) {
  const { id } = row.original;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleteUser] = useDeleteUserMutation();

  const handleDelete = async () => {
    try {
      await deleteUser(id).unwrap();
      toast.success('Pomyślnie usunięto użytkownika');
      onClose();
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania użytkownika');
    }
  };

  return (
    <Tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <DeleteModal
            onAccept={handleDelete}
            onClose={onClose}
            isOpen={isOpen}
          >
            Czy na pewno chcesz trwale usunąć tego użytkownika?
          </DeleteModal>
          <Tooltip label="Usuń" openDelay={1000}>
            <IconButton
              width="72px"
              height="56px"
              icon={<RiDeleteBin6Line size={20} />}
              aria-label="visiblity"
              variant="ghost"
              onClick={onOpen}
            />
          </Tooltip>
        </>
      </Td>
    </Tr>
  );
}

export default TableRow;
