/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import {
  useCreateComponentMutation,
  useLazyImportInstaFeedQuery,
} from 'app/services/dashboardApi';
import ROUTES from 'app/routes';
import WizardLayout from 'components/Layout/WizardLayout';
import ComponentWrapper from 'pages/Dashboard/Containers/Create/components/ComponentWrapper';
import { transformErrors } from 'utils/api';
import { schema, hasInvalidElementsLength } from 'components/Container/schema';
import { EMPTY_COMPONENT } from 'components/Container/constants';
import useQueryParams from 'utils/useQueryParams';

function DashboardCreate() {
  const { store } = useParams();
  const { queryString, search } = useQueryParams();
  const isArchive = search.get('q[status_eq]') === 'archived';
  const dashboardPath =
    generatePath(isArchive ? ROUTES.dashboard.archive : ROUTES.dashboard.base, {
      store,
    }) + queryString;

  const [createComponent, { isLoading }] = useCreateComponentMutation();
  const [importInstagramFeed, result] = useLazyImportInstaFeedQuery();
  const navigate = useNavigate();
  const methods = useFormWithSchema(schema, {
    mode: 'onTouched',
    defaultValues: { components: [{ ...EMPTY_COMPONENT, name: 'Kontener 1' }] },
  });

  const {
    control,
    formState: { errors: formErrors },
  } = methods;

  const {
    fields: components,
    append,
    remove: removeComponentHandler,
  } = useFieldArray({
    control,
    name: 'components',
    keyName: 'fieldId',
    shouldUnregister: true,
  });

  const addComponentHandler = useCallback(() => {
    append({
      ...EMPTY_COMPONENT,
      name: `Kontener ${components ? components.length + 1 : 1}`,
      active: false,
    });
  }, [append, components]);

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      toast.error(
        'Formularz zawiera błędy. Sprawdź poprawność i spróbuj ponownie.',
      );
    }
  }, [formErrors]);

  const onSubmit = async (formData: any) => {
    try {
      if (formData.components?.some(hasInvalidElementsLength)) return;

      const responses = await Promise.all(
        formData.components.map(async (formValues: any, index: number) => {
          if (formValues.kind === 'play_with_colors') {
            if (formValues.elementsAttributes) {
              formValues.elementsAttributes = formValues.elementsAttributes.map(
                (element: any) => {
                  if (!element.image) {
                    delete element.image;
                  }
                  return element;
                },
              );
            }
            if (formValues.specialElementsAttributes) {
              formValues.specialElementsAttributes =
                formValues.specialElementsAttributes.map((element: any) => {
                  if (!element.image) {
                    delete element.image;
                  }
                  return element;
                });
            }
          }

          if (formValues.linkParametersAttributes) {
            formValues.linkParametersAttributes.map((element: any) => {
              if (!element.linkType) {
                delete element.linkType;
              }
              return element;
            });
          }

          if (!formValues.withListingHeader) {
            formValues.bannerListingsAttributes =
              formValues.bannerListingsAttributes?.map((attribute: any) => ({
                ...attribute,
                _destroy: true,
              }));
          }

          const response: any = await createComponent(formValues);

          // import instagram feed if needed
          if (formValues.kind === 'instagram_feed') {
            toast.info(
              'Trwa importowanie insta feedu, nie odświeżaj przeglądarki.',
            );
            importInstagramFeed({
              containerId: response.data.data.id,
              gender: formValues.gender,
            })
              .unwrap()
              .then(() => {
                toast.success(
                  'Importowanie instagram feeda zakończyło się sukcesem.',
                );
              })
              .catch(() => {
                toast.error(
                  'Coś poszło nie tak. Feed instagramowy nie został zaimportowany.',
                );
              });
          }
          return { ...response, index };
        }),
      );
      const hasErrors = [];
      const createdIds: number[] = [];
      responses.forEach((response) => {
        const hasError = Object.hasOwnProperty.call(response, 'error');
        if (hasError) {
          hasErrors.push(true);
          const {
            error: { data: errors },
            index,
          } = response;
          const transformedErrors = transformErrors(errors, index);
          // eslint-disable-next-line no-console
          console.debug({ transformedErrors, index });
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(`components[${index}].${field}` as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        } else {
          const {
            data: { data },
            index,
          } = response;
          createdIds.push(data.id);
          removeComponentHandler(index);
        }
      });

      if (createdIds.length > 0) {
        toast.success(
          responses.length > 1
            ? `Kontenery o id: ${createdIds.join(
                ', ',
              )} zostały pomyślnie utworzone!`
            : `Kontener o id: ${createdIds.join(
                '',
              )} został pomyślnie utworzony!`,
        );
      }

      if (!hasErrors.length) {
        navigate(dashboardPath);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log({ error });
    }
  };

  return (
    <WizardLayout
      backLinkUrl={dashboardPath}
      isLoading={isLoading}
      crumbs={[{ label: 'Dodaj kontener' }]}
    >
      <FormProvider {...methods}>
        <form id="dashboard-creator" onSubmit={methods.handleSubmit(onSubmit)}>
          {components.map((component, index) => (
            <ComponentWrapper
              key={component.fieldId}
              index={index}
              isLoading={isLoading || result.isLoading}
              multipleComponents={components.length > 1}
              addComponent={addComponentHandler}
              removeComponent={removeComponentHandler}
              showAddButton={components.length === index + 1}
            />
          ))}
        </form>
      </FormProvider>
    </WizardLayout>
  );
}

export default DashboardCreate;
