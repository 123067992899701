import { isEmpty } from 'lodash';
import { CategoryContainer } from 'types/categoryContainer';
import { transformLinkParameters } from 'utils/linkCreator';
import { transformStringDatesToDateObject } from 'utils/date';
import { DEFAULT_FORM_VALUE } from 'pages/AppStoriesInspirations/Form/constants';

const parseDefaultValues = (container: CategoryContainer) => {
  const result: Record<string, any> = { ...container };

  if (isEmpty(container?.elements)) {
    result.elementsAttributes = DEFAULT_FORM_VALUE.elementsAttributes;
  } else {
    result.elementsAttributes = container?.elements?.map((element) => ({
      ...transformLinkParameters({ ...element }),
      ...transformStringDatesToDateObject(element),
    }));
  }

  result.withHeader = container?.elementsHeader;
  result.withIcon = container?.elementsIcon?.url;

  return result;
};

export default parseDefaultValues;
