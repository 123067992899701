import { useEffect, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormWithSchema } from 'utils/formHooks';
import FooterButtons from 'components/FooterButtons';
import HeaderNavigation from 'components/HeaderNavigation';
import ContainerWrapper from 'pages/Faqs/Form/Edit/components/ContainerWrapper';
import ROUTES from 'app/routes';
import useQueryParams from 'utils/useQueryParams';
import LoadingIndicator from 'components/LoadingIndicator';
import { BODY_SCHEMA, DEFAULT_VALUES } from 'pages/Faqs/Form/constants';
import { transformErrors } from 'utils/api';
import { useFaqQuery, useUpdateFaqMutation } from 'app/services/faqsApi';

function FaqEdit() {
  const { id, store, parentId } = useParams();
  const navigate = useNavigate();
  const { queryString } = useQueryParams();

  const faqsPath = useMemo(() => {
    const route = parentId ? ROUTES.faqs.preview : ROUTES.faqs.base;
    return (
      generatePath(route, {
        store,
        parentId,
      }) + queryString
    );
  }, [parentId, queryString, store]);

  const { data: { data: faq } = {}, isFetching } = useFaqQuery(id);
  const [updateFaq, { isLoading: updateRunning }] = useUpdateFaqMutation();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await updateFaq(data)
        .unwrap()
        .then(() => {
          navigate(faqsPath);
          toast.success(`Zmiany w FAQ zostały zastosowane`);
        })
        .catch((error) => {
          const transformedErrors = transformErrors(error.data, null);
          if (error.status === 404) {
            toast.error(error.data.error);
          }
          Object.keys(transformedErrors).forEach((field: string) => {
            methods.setError(field as never, {
              type: 'custom',
              message: transformedErrors[field],
            });
          });
        });
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  });

  useEffect(() => {
    if (faq) reset({ ...DEFAULT_VALUES, ...faq });
  }, [faq, reset]);

  return (
    <Box pt="32px">
      <HeaderNavigation
        baseCrumb={{
          label: 'FAQ',
          to: faqsPath,
        }}
        crumbs={[{ to: '', label: 'Edytuj FAQ' }]}
      />
      <FormProvider {...methods}>
        <Box as="form" id="faq-editor" onSubmit={onSubmit} pb="120px">
          {isFetching && <LoadingIndicator />}
          {!isFetching && (
            <ContainerWrapper isLoading={updateRunning || isFetching} />
          )}
        </Box>
      </FormProvider>

      <FooterButtons
        isLoading={updateRunning || isFetching}
        formId="faq-editor"
        backPath={faqsPath}
      />
    </Box>
  );
}

export default FaqEdit;
