import { Link } from 'react-router-dom';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/NotificationCategories/Listing/components/Table';
import ROUTES from 'app/routes';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import useQueryParams from 'utils/useQueryParams';
import LoadingIndicator from 'components/LoadingIndicator';
import useRoute from 'utils/useRoute';
import {
  useDeleteNotificationCategoryMutation,
  useNotificationCategoriesQuery,
} from 'app/services/notificationsApi';

function NotificationCategories() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });
  const newNotificationCategoryPath = useRoute(
    ROUTES.notificationCategories.create,
  );

  const [, state] = useDeleteNotificationCategoryMutation({
    fixedCacheKey: 'notificationCategories',
  });

  const {
    isFetching: notificationCategoriesLoading,
    data: { data: notificationCategories, totals } = { data: [], totals: 0 },
  } = useNotificationCategoriesQuery(queryString);

  const { isLoading } = state;
  const showLoading = notificationCategoriesLoading || isLoading;

  return (
    <ListingLayout
      title="Kategorie wiadomości"
      hideSearch
      hideFilter
      headerButtons={[
        {
          label: 'NOWA KATEGORIA',
          as: Link,
          to: newNotificationCategoryPath,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={notificationCategories}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + 170px));`}
        />
      )}
      <Footer totals={totals} footerButtons={null} />
    </ListingLayout>
  );
}

export default NotificationCategories;
