import { Button } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { getSelectedRows } from 'selectors/uiSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import { useBulkCloneMutation } from 'app/services/notificationsApi';

function FooterButtons() {
  const [bulkClone] = useBulkCloneMutation();

  const selectedRows = useAppSelector(getSelectedRows);
  const isMenuDisabled = isEmpty(selectedRows);

  const handleBulkClone = async () => {
    try {
      await bulkClone({
        notificationIds: selectedRows,
      }).unwrap();
      toast.success('Wiadomości zostały skopiowane.');
    } catch (err) {
      toast.error('Wystąpił problem');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <Button
      variant="link"
      onClick={handleBulkClone}
      isDisabled={isMenuDisabled}
    >
      KOPIUJ
    </Button>
  );
}

export default FooterButtons;
