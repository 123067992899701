import { HStack, VStack } from '@chakra-ui/react';
import Input from 'components/Form/Input';
import ColorPicker from 'components/Form/Color';
import DatePicker from 'components/Form/Date';
import Time from 'components/Form/Time';
import SectionTitle from 'components/Form/SectionTitle';
import LinkCreatorSection from 'components/LinkCreator';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
  isEditMode?: boolean;
}

function FormBody({ prepareFieldName, isEditMode }: FormBodyProps) {
  return (
    <>
      <Input name={prepareFieldName('name')} label="Nazwa" />
      <Input name={prepareFieldName('title')} label="Unikalna nazwa" />
      <ColorPicker
        name={prepareFieldName('gradientColorTop')}
        label="Górny kolor gradientu"
        showError
      />
      <ColorPicker
        name={prepareFieldName('gradientColorBottom')}
        label="Dolny kolor gradientu"
        showError
      />
      <ColorPicker
        name={prepareFieldName('textColor')}
        label="Kolor tekstu"
        showError
      />
      <Input name={prepareFieldName('poolName')} label="Nazwa puli" />
      <Input name={prepareFieldName('chancesCount')} label="Liczba szans" />
      <Input name={prepareFieldName('couponValidity')} label="Ważność kuponu" />
      <Input name={prepareFieldName('couponValue')} label="Wartość kuponu" />
      <Input name={prepareFieldName('daysCount')} label="Liczba dni" />
      <Input name={prepareFieldName('surveysCount')} label="Liczba quizów" />
      <Input name={prepareFieldName('finalDate')} label="Data zakończenia" />
      <LinkCreatorSection
        prepareFieldName={prepareFieldName}
        isEditMode={isEditMode || false}
      />
      <SectionTitle>Przycisk</SectionTitle>
      <Input name={prepareFieldName('buttonText')} label="Treść przycisku" />
      <ColorPicker
        name={prepareFieldName('buttonTextColor')}
        label="Kolor tekstu przycisku"
      />
      <ColorPicker
        name={prepareFieldName('buttonBackgroundColor')}
        label="Kolor tła przycisku"
      />
      <ToggleCheckbox name={prepareFieldName('active')} label="Aktywny" />
      <VStack w="100%" spacing={8}>
        <HStack w="100%" spacing={4}>
          <DatePicker
            name="dateFrom"
            label="Aktywny od"
            showError
            rangeSelect={false}
          />
          <DatePicker
            name="dateTo"
            label="Aktywny do"
            showError
            rangeSelect={false}
          />
        </HStack>
        <HStack w="100%" spacing={4}>
          <Time
            name="timeFrom"
            label="Godzina rozpoczęcia"
            errorKey="timeFrom"
            showError
          />
          <Time
            name="timeTo"
            label="Godzina zakończenia"
            errorKey="timeTo"
            showError
          />
        </HStack>
      </VStack>
    </>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
