import styled from '@emotion/styled';
import useImage from 'utils/useImage';

const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

function Image({
  element: { image, magentoImageUrl },
}: {
  element: {
    image?: File | {};
    magentoImageUrl?: string;
  };
}) {
  const source = useImage(image) || magentoImageUrl;

  return <Img src={source} alt="" />;
}

export default Image;
