import {
  Box,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';

interface StatBoxProps {
  totals: number;
  average: number;
}

function StatBox({ totals, average }: StatBoxProps) {
  return (
    <Box bgColor="white" border="solid 1px #000" mb={4} px={4} py={2}>
      <Text variant="h1" mb={2}>
        Statystyki
      </Text>
      <StatGroup>
        <Stat>
          <StatNumber>{totals}</StatNumber>
          <StatLabel>Liczba wszystkich ocen</StatLabel>
        </Stat>
        <Stat>
          <StatNumber>{average}</StatNumber>
          <StatLabel>Średnia</StatLabel>
        </Stat>
      </StatGroup>
    </Box>
  );
}

export default StatBox;
