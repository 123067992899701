import { Flex, Box } from '@chakra-ui/react';
import Card from 'components/Card';
import FormBody from 'pages/LeaderCategories/Form/components/FormBody';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import FormSpinner from 'components/FormSpinner';
import { LEADER_CATEGORY_KIND_OPTIONS } from 'pages/LeaderCategories/Listing/components/Table/columns';
import Select from 'components/Form/Select';

interface ContainerWrapperProps {
  isLoading: boolean;
}

function ContainerWrapper({ isLoading }: ContainerWrapperProps) {
  return (
    <Flex gap={4} alignItems="flex-start">
      <Card>
        {isLoading && <FormSpinner />}
        <Box maxW={FORM_BODY_MAX_WIDTH} w="100%">
          <Box mb={8}>
            <Select
              name="kind"
              options={LEADER_CATEGORY_KIND_OPTIONS}
              placeholder="Wpisz lub wybierz z listy"
              label="Rodzaj kategorii"
            />
          </Box>
          <FormBody prepareFieldName={(name: string) => name} />
        </Box>
      </Card>
    </Flex>
  );
}

export default ContainerWrapper;
