import { Flex } from '@chakra-ui/react';
import { ImCancelCircle } from 'react-icons/im';

interface FilterLabelProps {
  name: string;
  onClick: () => void;
}

function FilterLabel({ name, onClick }: FilterLabelProps) {
  return (
    <Flex
      py="4px"
      px="16px"
      gap="15px"
      align="center"
      cursor="pointer"
      justify="center"
      bg="complementary.specialGreen"
      wordBreak="break-word"
      onClick={onClick}
    >
      {name}
      <ImCancelCircle size={13} />
    </Flex>
  );
}

export default FilterLabel;
