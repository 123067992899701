import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import {
  LeaderCategoriesResponse,
  LeaderCategoryResponse,
  TransformedLeaderCategoriesResponse,
} from 'types/leaderCategories';

export const leaderCategoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    leaderCategories: build.query<TransformedLeaderCategoriesResponse, string>({
      query: (queryString) => `leader_categories${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: LeaderCategoriesResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['LeaderCategories'],
    }),
    deleteLeaderCategory: build.mutation<void, number>({
      query: (id) => ({
        url: `leader_categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['LeaderCategories'],
    }),
    createLeaderCategory: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'leader_category',
        );
        return {
          url: 'leader_categories',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['LeaderCategories'],
    }),
    leaderCategory: build.query<LeaderCategoryResponse, any>({
      query: (id) => `leader_categories/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['LeaderCategories'],
    }),
    updateLeaderCategory: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'leader_category',
        );

        return {
          url: `leader_categories/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['LeaderCategories'],
    }),
  }),
});

export const {
  useLeaderCategoriesQuery,
  useDeleteLeaderCategoryMutation,
  useCreateLeaderCategoryMutation,
  useLeaderCategoryQuery,
  useUpdateLeaderCategoryMutation,
} = leaderCategoriesApi;
