/* eslint-disable import/no-cycle */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { logout } from 'slices/auth/authSlice';
import { convertKeysToSnakeCase, convertKeysToCamelCase } from 'utils/helpers';
import { RootState } from 'app/store';

const { REACT_APP_API_URL: API_URL } = process.env;

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const {
      auth: { authHeaders, store },
      app: { countryCode },
    } = getState() as RootState;
    if (!headers.get('store')) {
      headers.set('store', store);
    }
    headers.set('accept-language', countryCode || 'pl');
    if (authHeaders.accessToken) {
      headers.set('access-token', authHeaders.accessToken);
      headers.set('client', authHeaders.client || '');
      headers.set('uid', authHeaders.uid || '');
      headers.set('token-type', authHeaders.tokenType || '');
    }

    return headers;
  },
});

const enhancedBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
> = async (args, store, extraOptions) => {
  if (typeof args === 'object') {
    args.body = convertKeysToSnakeCase(args.body);
  }

  const baseResult = await baseQuery(args, store, extraOptions);

  if (
    baseResult.error &&
    (baseResult.error.status === 401 || baseResult.error.status === 403)
  ) {
    store.dispatch(logout());
  }

  if (baseResult.error) {
    baseResult.error = convertKeysToCamelCase(baseResult.error);
  }

  if (baseResult.data) {
    baseResult.data = convertKeysToCamelCase(baseResult.data);
  }

  return baseResult;
};

export const api = createApi({
  reducerPath: 'cmsApi',
  baseQuery: enhancedBaseQuery,
  endpoints: () => ({}),
  tagTypes: [
    'Dashboards',
    'LandingPage',
    'Category',
    'CategoryContainer',
    'CategoryElement',
    'Subcategory',
    'CategoryTags',
    'User',
    'Regulations',
    'RegulationElement',
    'Minibanners',
    'Administration',
    'OnboardScreens',
    'Faqs',
    'Abouts',
    'Announcements',
    'SplashScreens',
    'LeaderCategories',
    'BenefitsScreens',
    'BenefitsScreenElements',
    'Sizings',
    'Nutritions',
    'ClothingMachines',
    'Users',
    'Ratings',
    'InPostPoints',
    'DhlPoints',
    'BlogPost',
    'NotificationCategories',
    'Notifications',
    'WeatherCategories',
    'WeatherCategory',
    'SubscriptionRunupScreens',
    'SubscriptionRunupScreenElements',
    'TaxonomyContainer',
    'Shipments',
    'Teams',
    'Surveys',
    'SurveyQuestions',
  ],
});

export default {};
