import { useMemo } from 'react';
import styled from '@emotion/styled';
import { VStack, Text, Box } from '@chakra-ui/react';
import { BiImage } from 'react-icons/bi';
import isEmpty from 'lodash/isEmpty';
import { IoVolumeMuteOutline } from 'react-icons/io5';
import Headers from 'components/Container/Previews/Headers';
import Button from 'components/Container/Previews/Button';
import { PreviewComponentProps } from 'types/dashboardCreator';
import useImage from 'utils/useImage';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import TaggedProductsPreview from 'components/Container/Previews/TaggedProductsPreview';

const Video = styled.video`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #eeeeee;
`;

function LastChanceBannerPreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  const imagePreview = useImage(formValues?.image);

  const isVideoBanner = useMemo(
    () => !!formValues?.video?.url || !!formValues?.video?.path,
    [formValues?.video],
  );

  return (
    <Box position="relative">
      {isVideoBanner && (
        <Video loop muted autoPlay src={formValues?.video?.url} />
      )}
      <VStack
        alignItems="center"
        justifyContent="center"
        w="100%"
        h={388}
        bgColor={isVideoBanner ? 'transparent' : 'complementary.whiteGrey'}
        bgImage={isVideoBanner ? undefined : imagePreview}
        bgSize="cover"
        bgRepeat="no-repeat"
        bgPosition="50% 50%"
        position="relative"
        spacing={0}
        overflow="hidden"
        zIndex={5}
      >
        {formValues?.hasSound && (
          <Box position="absolute">
            <IoVolumeMuteOutline size={31} color="#FFFFFF" />
          </Box>
        )}
        {showStats && <ShowStatsIcon data={formValues} />}
        <Headers showCounter {...formValues} />
        {!imagePreview && !isVideoBanner && (
          <>
            <BiImage size={30} />
            <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
              Tu pojawi się dodane zdjęcie
            </Text>
          </>
        )}
        {(formValues?.withTags || !isEmpty(formValues?.photoTags)) && (
          <TaggedProductsPreview
            formValues={formValues}
            showStats={showStats}
          />
        )}
        {formValues?.buttonText ? <Button {...formValues} /> : null}
      </VStack>
    </Box>
  );
}

export default LastChanceBannerPreview;
