import { Icon, IconProps } from '@chakra-ui/react';

function BrandLogo(props: IconProps) {
  return (
    <Icon
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0476 15.0024C26.6203 15.0024 25.9777 15.5537 25.8786 16.5381L24.6234 29.8561H18.8018L19.9906 17.4224C20.4141 12.7312 22.75 10.2773 27.0012 10.062H39.9656C39.5135 13.1705 37.8309 14.9788 35.1241 15.0024H28.0476Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 17.5C34.5578 20.8691 32.9941 22.4681 30.3193 22.5H30.3061H26.25L26.6861 17.5H35Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.43921 26.3903C0.759585 26.3903 -0.143698 25.5141 0.0186883 23.7844C0.100098 22.8966 0.432219 22.2962 1.87828 21.2071L15.3598 10.6128C16.4062 9.90377 17.2932 10.005 17.7773 10.005C18.7456 10.005 20.1006 10.6113 19.9941 11.9834L18.2702 30H13.1724L13.51 26.3903L13.9459 21.73L14.4106 17.0251L8.30616 21.73H13.14L12.6998 26.3903H2.43921Z"
        fill="black"
      />
    </Icon>
  );
}

export default BrandLogo;
