import { Checkbox, Flex } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { Category } from 'types/category';
import ColorPreview from 'components/ColorPreview';
import ImagePreview from 'components/ImagePreview';
import BadgePreview from 'components/BadgePreview';
import Badge from 'components/Badge';

function GetColumns(): ColumnDef<Category, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => (
        <Flex align="center" direction="column">
          {info.getValue()}
          <Checkbox
            isChecked={info.row.getIsSelected()}
            onChange={info.row.getToggleSelectedHandler()}
            pt="23px"
          />
        </Flex>
      ),
    },
    {
      header: 'NAZWA',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KATEGORIA W MAGENTO',
      accessorKey: 'externalCategoryId',
      cell: (info) => info.getValue(),
    },
    {
      header: 'KOLOR TŁA',
      accessorKey: 'backgroundColor',
      cell: (info) => <ColorPreview hex={info.getValue()} />,
    },
    {
      header: 'ZDJĘCIE SPECJALNE',
      accessorKey: 'image.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'LOGO',
      accessorKey: 'logo.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'IKONA',
      accessorKey: 'icon.url',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'BADGE SPECJALNY',
      accessorKey: 'badge',
      cell: ({
        row: {
          original: { badgeTextColor, badgeColor, badge },
        },
      }) => (
        <BadgePreview
          backgroundColor={badgeColor || 'black'}
          text={badge}
          textColor={badgeTextColor || 'white'}
        />
      ),
    },
    {
      header: 'CZY UKRYTA',
      accessorKey: 'hidden',
      cell: (info) => <Badge flag={!!info.getValue()} />,
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
