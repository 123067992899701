import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useMemo } from 'react';
import { datePrettierNoTime } from 'utils/date';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
);

const OPTIONS = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
};

interface ChartProps {
  clicks: ElementClickItem[];
}

function Chart({ clicks }: ChartProps) {
  const data = useMemo(
    () => ({
      labels: clicks?.map((stat) => datePrettierNoTime(stat?.fromTs)),
      datasets: [
        {
          label: 'Suma wyświetleń',
          data: clicks?.map((stat) => stat?.value),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    }),
    [clicks],
  );

  return <Line options={OPTIONS} data={data} />;
}
export default Chart;
