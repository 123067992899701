import { Button, ButtonProps } from '@chakra-ui/react';

export default function TagButton(props: ButtonProps) {
  return (
    <Button
      variant="unstyled"
      bg="#DDDDDD"
      borderRadius="4px"
      _hover={{
        opacity: 0.7,
      }}
      h="auto"
      px="4px"
      py="4px"
      {...props}
    />
  );
}
