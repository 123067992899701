import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { getSelectedRows } from 'selectors/uiSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import { useBulkDeleteSubcategoryMutation } from 'app/services/subcategoryApi';
import DeleteModal from 'components/DeleteModal';

interface FooterButtonsProps {
  categoryContainerId: string;
  taxonomyId: string;
}

function FooterButtons({
  categoryContainerId,
  taxonomyId,
}: FooterButtonsProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [bulkDeleteSubcategory] = useBulkDeleteSubcategoryMutation();
  const selectedRows = useAppSelector(getSelectedRows);
  const isDisabled = isEmpty(selectedRows);

  const handleBulkDelete = async () => {
    try {
      await bulkDeleteSubcategory({
        taxonomyContainerId: taxonomyId,
        categoryContainerId,
        body: { taxonomyContainerElementSubcategoryIds: selectedRows },
      }).unwrap();
      onClose();
      toast.success('Podkategorie zostały usunięte');
    } catch (err) {
      toast.error('Wystąpił problem podczas usuwania podkategorii');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <HStack>
      <DeleteModal
        onAccept={handleBulkDelete}
        onClose={onClose}
        isOpen={isOpen}
      >
        Czy na pewno chcesz trwale usunąć wybrane podkategorie?
      </DeleteModal>
      <Button variant="link" disabled={isDisabled} onClick={onOpen}>
        USUŃ
      </Button>
    </HStack>
  );
}

export default FooterButtons;
