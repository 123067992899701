import { useEffect, useState } from 'react';
import {
  FormControl,
  InputProps as ChakraInputProps,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import get from 'lodash/get';

import { MultiValueInputWrapper } from 'components/Form/TagInput/style';

const Keys = {
  TAB: 9,
  SPACE: 32,
  ENTER: 13,
  COMMA: 188,
};

type InputProps = ChakraInputProps & {
  name: string;
  label?: string | React.ReactNode;
  defaultValue?: Tag[];
  suggestions?: Tag[];
};

function SpecialTagInput({
  label,
  name,
  isRequired,
  defaultValue,
  suggestions,
}: InputProps) {
  const {
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();
  const [tags, setTags] = useState<Tag[]>(defaultValue || []);

  const errorMessage = get(errors, name)?.message as string;

  const handleAddition = (tag: Tag) => {
    const convertedTag = { id: tag.id, text: tag.id };
    setTags([...tags, convertedTag]);
  };
  const handleDelete = (i: number) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  useEffect(() => {
    const value = getValues(name);

    if (value && typeof value === 'string') {
      const t: Tag[] = value
        .split(', ')
        .map((v: string) => ({ id: v, text: v }));

      setTags(t);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tags.length) {
      const t = tags.map((tag) => tag.text);

      setValue(name, t.join(', '));
    }
  }, [tags, name, setValue]);

  return (
    <FormControl isRequired={isRequired} isInvalid={Boolean(errorMessage)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <MultiValueInputWrapper>
        <ReactTags
          allowDragDrop={false}
          tags={tags}
          handleAddition={handleAddition}
          handleDelete={handleDelete}
          delimiters={[Keys.COMMA, Keys.ENTER]}
          placeholder=""
          autocomplete
          suggestions={suggestions}
        />
      </MultiValueInputWrapper>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

SpecialTagInput.defaultProps = {
  label: '',
  defaultValue: null,
  suggestions: null,
};

export default SpecialTagInput;
