import { VStack, Text, Box, Flex, Button, AspectRatio } from '@chakra-ui/react';
import { LegacyRef, useEffect, useMemo, RefObject } from 'react';
import { BiImage } from 'react-icons/bi';
import { useParallax, useParallaxController } from 'react-scroll-parallax';
import { PreviewComponentProps } from 'types/dashboardCreator';
import Headers from 'components/Container/Previews/Headers';
import useImage from 'utils/useImage';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';

export interface ParallaxBannerPreviewProps extends PreviewComponentProps {
  innerRef?:
    | RefObject<HTMLDivElement>
    | { current: HTMLDivElement | HTMLElement | null };
}

function ParallaxBannerPreview({
  formValues,
  showStats,
  innerRef,
}: ParallaxBannerPreviewProps) {
  const imagePreview = useImage(formValues?.image);
  const imageBgPreview = useImage(formValues?.backgroundImage);

  const shouldRenderHeaders = useMemo(
    () => Boolean(formValues.subtitle || formValues.title),
    [formValues.subtitle, formValues.title],
  );

  const parallax = useParallax({
    speed: 16,
    shouldAlwaysCompleteAnimation: Boolean(innerRef),
  });
  const parallaxController = useParallaxController();

  useEffect(() => {
    if (!innerRef?.current || parallaxController === null) {
      return;
    }
    parallaxController.updateScrollContainer(innerRef.current);
  }, [innerRef, parallaxController]);

  return (
    <Box position="relative" overflow="hidden" minH={540}>
      <Box
        ref={parallax.ref as LegacyRef<HTMLDivElement>}
        backgroundImage={imageBgPreview}
        position="absolute"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        bgPosition="50% 50%"
        bottom={-160}
        top={-160}
        right={0}
        left={0}
      />
      <VStack
        bgColor={imageBgPreview ? 'transparent' : 'complementary.lightGrey'}
        spacing={4}
        minH={540}
        p={4}
      >
        {showStats && <ShowStatsIcon data={formValues} />}
        <Flex
          bgColor={imageBgPreview ? 'transparent' : 'complementary.lightGrey'}
          justifyContent={shouldRenderHeaders ? 'space-between' : 'flex-end'}
          alignItems="flex-start"
          position="relative"
          w="full"
          p={4}
          h={74}
        >
          {shouldRenderHeaders ? (
            <Box wordBreak="break-all">
              <Headers position="static" {...formValues} />
            </Box>
          ) : null}

          {!formValues.backgroundImage ? (
            <Text fontSize={14} flexBasis={160} textAlign="right">
              Tu pojawi się zdjęcie z efektem parallaxy
            </Text>
          ) : null}
        </Flex>
        <AspectRatio w={356} ratio={0.76 / 1}>
          <VStack
            alignItems="center"
            justifyContent="center"
            bgColor="complementary.whiteGrey"
            bgImage={imagePreview}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPosition="50% 50%"
            position="relative"
          >
            {!imagePreview ? (
              <>
                <BiImage size={30} />
                <Text fontSize="md" fontWeight={700} textAlign="center" pt={3}>
                  Tu pojawi się dodane zdjęcie
                </Text>
              </>
            ) : null}
          </VStack>
        </AspectRatio>
        <Button
          type="button"
          fontWeight={700}
          w="full"
          textTransform="uppercase"
          color={formValues.buttonTextColor}
          backgroundColor={formValues.buttonColor}
        >
          {formValues.buttonText || 'Tu pojawi się Twój tekst'}
        </Button>
      </VStack>
    </Box>
  );
}

ParallaxBannerPreview.defaultProps = {
  innerRef: undefined,
};

export default ParallaxBannerPreview;
