import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/AppStoriesInspirations/Listing/components/Table';
import { useTaxonomyContainersQuery } from 'app/services/categoryContainerApi';
import useQueryParams from 'utils/useQueryParams';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import LoadingIndicator from 'components/LoadingIndicator';

function AppStoriesInspirationsListing() {
  const { queryString } = useQueryParams({
    page_size: '10',
    page: '1',
  });

  const {
    isFetching: categoriesLoading,
    data: { data: containers } = { data: [] },
  } = useTaxonomyContainersQuery(queryString);

  const showLoading = categoriesLoading;

  return (
    <ListingLayout
      title="Inspiracje (app stories)"
      hideSearch
      hideFilter
      headerButtons={[]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Table
          data={containers}
          h={`calc(100vh - (${NAVBAR_HEIGHT} + 128px));`}
        />
      )}
    </ListingLayout>
  );
}

export default AppStoriesInspirationsListing;
