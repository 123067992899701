/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

export const schema = yup.object({
  firstDate: yup.object({
    start: yup.date().nullable(),
    end: yup.date().nullable(),
  }),
  secondDate: yup.object({
    start: yup.date().nullable(),
    end: yup.date().nullable(),
  }),
});
