import { AccordionItem, AccordionPanel, Stack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { UseFieldArrayMove } from 'react-hook-form';
import AccordionButton from 'components/Collapsible/AccordionButton';
import Input from 'components/Form/Input';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import Switch from 'components/Form/Switch';

interface SurveyAnswerSectionProps {
  id: string;
  itemIndex: number;
  prepareFieldName: (name: string) => string;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
}

function SurveyAnswerSection({
  id,
  itemIndex,
  prepareFieldName,
  move,
  remove,
}: SurveyAnswerSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`answersAttributes[${itemIndex}].${name}`),
    [itemIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0} overflow="visible" maxW={FORM_BODY_MAX_WIDTH}>
      <AccordionButton
        id={id}
        index={itemIndex}
        move={move}
        label={`${itemIndex + 1} odpowiedź`}
        remove={remove}
        isDraggable={false}
      />
      <AccordionPanel pl={0} pr={0} pt={8} pb={8} overflow="visible">
        <Stack spacing={8}>
          <Input label="Tytuł" name={fieldname('title')} />
          <Input label="Pozycja" type="number" name={fieldname('option')} />
          <Switch label="Prawidłowa" name={fieldname('correct')} />
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default SurveyAnswerSection;
