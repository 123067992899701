import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  specialElementsHeader: yup.string().required('Pole jest wymagane'),
  icon: yup.mixed().nullable(),
  deleteSpecialElementsIcon: yup.boolean(),
});

export const DEFAULT_FORM_VALUE = {
  specialElementsHeader: '',
  icon: null,
  deleteSpecialElementsIcon: false,
};
