import { Icon, IconProps } from '@chakra-ui/react';

function ArrowX(props: IconProps) {
  return (
    <Icon
      width="12px"
      height="19px"
      viewBox="0 0 11 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.1138 15.5L5.11377 9.5L11.1138 3.5L11.9998 4.38601L6.87025 9.5L11.9998 14.614L11.1138 15.5Z"
        fill="black"
      />
      <path
        d="M9.59717 19L0.0971671 9.5L9.59717 8.30516e-07L11 1.40285L2.87826 9.5L11 17.5972L9.59717 19Z"
        fill="black"
      />
    </Icon>
  );
}

export default ArrowX;
