import { FC, useEffect, useState } from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  useDrag,
  useDrop,
} from 'react-dnd';
import { Box, Flex } from '@chakra-ui/react';

interface DragItem {
  index: number;
  type: string;
}

interface ContainerWrapperProps {
  index: number;
  isLoading: boolean;
  multipleContainers: boolean;
  removeContainer: (index: number) => void;
  dragRef: ConnectDragSource;
  previewRef: ConnectDragPreview;
  isOpen: boolean;
  toggleSectionHandler: () => void;
  isInvalid?: boolean;
  isBlogPost?: boolean;
}

interface DndWrapperProps {
  id: any;
  index: number;
  isLoading: boolean;
  multipleContainers: boolean;
  reorderContainers: (dragIndex: number, hoverIndex: number) => void;
  removeContainer: (index: number) => void;
  ContainerWrapper: FC<ContainerWrapperProps>;
  isInvalid?: boolean;
  isBlogPost?: boolean;
}

function DndWrapper({
  id,
  index,
  reorderContainers,
  isLoading,
  multipleContainers,
  removeContainer,
  ContainerWrapper,
  isInvalid = false,
  isBlogPost = false,
}: DndWrapperProps) {
  const [isOpen, toggleSection] = useState(false);

  const [{ targetOffset, isOver }, dropRef] = useDrop<
    DragItem,
    void,
    { targetOffset: number | undefined; isOver: boolean }
  >({
    accept: 'container',
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
        targetOffset: monitor.getDifferenceFromInitialOffset()?.y,
      };
    },
    drop(item: DragItem) {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      reorderContainers(dragIndex, hoverIndex);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'container',
    item: () => {
      return { index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const showDrop = isOver && !isDragging && targetOffset;

  useEffect(() => {
    if (isInvalid && !isOpen) toggleSection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalid]);

  return (
    <Box ref={dropRef} key={`draggable-container${id}`}>
      <Flex h="8px" paddingInline="7px" align="center">
        {showDrop && targetOffset < 0 && <Box h="2px" bg="#00FB6E" w="100%" />}
      </Flex>
      <ContainerWrapper
        index={index}
        isLoading={isLoading}
        multipleContainers={multipleContainers}
        removeContainer={removeContainer}
        dragRef={dragRef}
        previewRef={previewRef}
        isOpen={isOpen}
        toggleSectionHandler={() => toggleSection(!isOpen)}
        isInvalid={isInvalid}
        isBlogPost={isBlogPost}
      />
      <Flex h="8px" paddingInline="7px" align="center">
        {showDrop && targetOffset > 0 && <Box h="2px" bg="#00FB6E" w="100%" />}
      </Flex>
    </Box>
  );
}

DndWrapper.defaultProps = {
  isInvalid: false,
  isBlogPost: false,
};

export default DndWrapper;
