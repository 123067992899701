import {
  Box,
  DrawerBody as ChakraBody,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { endOfDay, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import FilterLabel from 'components/FilterLabel';
import { GENDER_OPTIONS } from 'utils/constants';
import useQueryParams from 'utils/useQueryParams';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import DatePicker from 'components/Form/Date';
import { useFormWithSchema } from 'utils/formHooks';
import {
  SCHEMA,
  RANGE_OPTIONS,
  GENDER,
  STORE,
  TIME_FROM,
  TIME_TO,
  RANGE,
  RANGE_DICT,
} from 'pages/Statistics/TopBanners/components/FiltersDrawer/constants';
import StoreSelect from 'components/StoreSelect';
import { STORE_DICT } from 'components/Container/constants';
import { datePrettierNoTime } from 'utils/date';
import ExternalError from 'components/Form/ExternalError';

interface FilterBodyProps {
  onClose: () => void;
}

function FilterBody({ onClose }: FilterBodyProps) {
  const { search, setSearch } = useQueryParams();

  const getDefaultValues = () => {
    const store = search.get(STORE);
    const genderType = search.get(GENDER);
    const timeFrom = search.get(TIME_FROM);
    const timeTo = search.get(TIME_TO);
    const range = search.get(RANGE);

    const result: { [key: string]: any } = {};
    result.store = store || undefined;
    result.genderType = genderType || undefined;
    result.range = range || undefined;
    result.timeFrom = timeFrom ? new Date(timeFrom) : undefined;
    result.timeTo = timeTo ? new Date(timeTo) : undefined;

    return result;
  };

  const methods = useFormWithSchema(SCHEMA, {
    mode: 'onChange',
  });
  const { handleSubmit, setValue, reset, control } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const {
      store: newStore,
      genderType: newGenderType,
      range: newRange,
      timeFrom: newTimeFrom,
      timeTo: newTimeTo,
    } = data;

    if (newStore) search.set(STORE, newStore);
    else search.delete(STORE);

    if (newGenderType) search.set(GENDER, newGenderType);
    else search.delete(GENDER);

    if (newRange) search.set(RANGE, newRange);
    else search.delete(RANGE);

    if (newTimeFrom) search.set(TIME_FROM, new Date(newTimeFrom).toISOString());
    else search.delete(TIME_FROM);

    if (newTimeTo) search.set(TIME_TO, new Date(newTimeTo).toISOString());
    else search.delete(TIME_TO);

    setSearch(search);
    onClose();
  });

  useEffect(() => {
    reset(getDefaultValues());
    // eslint-disable-next-line
  }, []);

  const [gender, store, timeFrom, timeTo, range] = useWatch({
    control,
    name: [GENDER, STORE, TIME_FROM, TIME_TO, RANGE],
  });

  useEffect(() => {
    const today = new Date();
    if (range === RANGE_DICT.MONTH) {
      setValue(TIME_FROM, startOfMonth(startOfDay(today)));
      setValue(TIME_TO, endOfDay(today));
    }
    if (range === RANGE_DICT.WEEK) {
      setValue(TIME_FROM, startOfWeek(startOfDay(today)));
      setValue(TIME_TO, endOfDay(today));
    }
  }, [range, setValue]);

  return (
    <ChakraBody overflowX="hidden" overflowY="auto">
      <FormProvider {...methods}>
        <Box as="form" id="filter-form" onSubmit={onSubmit} w="452px">
          <StoreSelect label="Maska" />
          <Flex direction="column" pt="26px">
            <Text fontWeight={400} fontSize="14px">
              Dashboard
            </Text>
            <Flex direction="row" p="8px">
              {GENDER_OPTIONS.map((el, idx) => (
                <RadioBoxControl
                  label={el.label}
                  name={GENDER}
                  id={`gender_option_${idx}`}
                  key={`gender_option_${el.value}`}
                  value={el.value}
                  isClearable
                />
              ))}
            </Flex>
            <Flex direction="column" pt="26px">
              <Text fontWeight={400} fontSize="14px">
                Okres
              </Text>
              <Flex direction="row" p="8px">
                {RANGE_OPTIONS.map((el, idx) => (
                  <RadioBoxControl
                    label={el.label}
                    name={RANGE}
                    id={`range_option_${idx}`}
                    key={`range_option_${el.value}`}
                    value={el.value}
                    group
                  />
                ))}
              </Flex>
              <ExternalError name={RANGE} />
              <HStack pt="8px" spacing="16px">
                <DatePicker
                  name={TIME_FROM}
                  label="Data od"
                  showError
                  rangeSelect={false}
                  onSelect={() => setValue(RANGE, RANGE_DICT.CUSTOM)}
                />
                <DatePicker
                  name={TIME_TO}
                  label="Data do"
                  showError
                  rangeSelect={false}
                  onSelect={() => setValue(RANGE, RANGE_DICT.CUSTOM)}
                />
              </HStack>
            </Flex>
          </Flex>
          <Flex direction="column" pt="26px">
            <Text fontWeight={600} fontSize="16px">
              WYBRANE
            </Text>
            <Flex py="28px" flexWrap="wrap" gap="16px" px="8px">
              {store && (
                <FilterLabel
                  key={`filter_label_${store}`}
                  name={STORE_DICT[store]}
                  onClick={() => setValue(STORE, undefined)}
                />
              )}
              {gender && (
                <FilterLabel
                  key={`filter_label_${gender}`}
                  name={
                    GENDER_OPTIONS.find((el) => el.value === gender)?.label!
                  }
                  onClick={() => setValue(GENDER, undefined)}
                />
              )}
              {timeFrom && timeTo && (
                <FilterLabel
                  key={`filter_label_${timeFrom}_${timeTo}`}
                  name={`${datePrettierNoTime(
                    timeFrom.toISOString(),
                  )} - ${datePrettierNoTime(timeTo.toISOString())}`}
                  onClick={() => {
                    setValue(TIME_FROM, null);
                    setValue(TIME_TO, null);
                  }}
                />
              )}
            </Flex>
          </Flex>
        </Box>
      </FormProvider>
    </ChakraBody>
  );
}

export default FilterBody;
