/* eslint-disable import/prefer-default-export */
import * as yup from 'yup';

export const BODY_SCHEMA = yup.object({
  name: yup.string().required('Pole jest wymagane'),
  externalCategoryId: yup.string().required('Pole jest wymagane'),
  taxonomyContainerId: yup.string().required('Pole jest wymagane'),
  backgroundColor: yup.string().required('Pole jest wymagane'),
  image: yup.mixed().nullable(),
  badgeColor: yup.string().notRequired(),
  badge: yup.string().notRequired(),
  badgeTextColor: yup.string().notRequired(),
  logo: yup.mixed().nullable(),
  hidden: yup.boolean().notRequired(),
  icon: yup.mixed().nullable(),
});

export const DEFAULT_VALUES = {
  backgroundColor: '#ffffff',
  badgeColor: '#ffffff',
  badgeTextColor: '#000000',
  hidden: false,
  icon: null,
};
