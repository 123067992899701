import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';

export const clothingMachinesApi = api.injectEndpoints({
  endpoints: (build) => ({
    clothingMachines: build.query<TransformedClothingMachinesResponse, string>({
      query: (queryString) => `clothing_machines${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: ClothingMachinesResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['ClothingMachines'],
    }),
    deleteClothingMachine: build.mutation<void, number>({
      query: (id) => ({
        url: `clothing_machines/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ClothingMachines'],
    }),
    createClothingMachine: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'clothing_machine',
        );
        return {
          url: 'clothing_machines',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['ClothingMachines'],
    }),
    clothingMachine: build.query<ClothingMachineResponse, any>({
      query: (id) => `clothing_machines/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['ClothingMachines'],
    }),
    updateClothingMachine: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase(payload);

        const formData = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'clothing_machine',
        );

        return {
          url: `clothing_machines/${id}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['ClothingMachines'],
    }),
  }),
});

export const {
  useClothingMachinesQuery,
  useDeleteClothingMachineMutation,
  useCreateClothingMachineMutation,
  useClothingMachineQuery,
  useUpdateClothingMachineMutation,
} = clothingMachinesApi;
