import { serialize } from 'object-to-formdata';
import { api } from 'app/services/api';
import { convertKeysToSnakeCase } from 'utils/helpers';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { transformActivityDates } from 'utils/date';

export const RegulationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    regulations: build.query<TransformedRegulationsResponse, string>({
      query: (queryString) => `regulations${queryString}`,
      keepUnusedDataFor: 10,
      transformResponse(response: RegulationsResponse) {
        return {
          data: response.data,
          totals: response.meta.pagination.objects,
        };
      },
      providesTags: ['Regulations'],
    }),
    regulation: build.query<RegulationResponse, string>({
      query: (id) => `regulations/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['Regulations'],
    }),
    createRegulation: build.mutation({
      query: (payload) => {
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'regulation',
        );

        return {
          url: 'regulations',
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['Regulations'],
    }),
    updateRegulation: build.mutation({
      query: (payload) => {
        const { id } = payload;
        const snakePayload = convertKeysToSnakeCase({ ...payload });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'regulation',
        );

        return {
          url: `/regulations/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['Regulations'],
    }),
    deleteRegulation: build.mutation<void, number>({
      query: (id) => ({
        url: `regulations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Regulations'],
    }),
    reorderRegulation: build.mutation<void, ReorderParams>({
      query: ({ body, id }) => ({
        url: `regulations/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Regulations'],
    }),
    regulationElements: build.query<Regulation, string | number>({
      query: (id) => `regulations/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: RegulationResponse) {
        return response.data;
      },
      providesTags: (result) => [{ type: 'RegulationElement', id: result?.id }],
    }),
    regulationElement: build.query<RegulationElementResponse, any>({
      query: (payload) => {
        const { id, regulationId } = payload;
        return `regulations/${regulationId}/elements/${id}`;
      },
      keepUnusedDataFor: 10,
      providesTags: ['RegulationElement'],
    }),
    reorderRegulationElement: build.mutation<void, ReorderElementParams>({
      query: ({ body, id, regulationId }) => ({
        url: `regulations/${regulationId}/elements/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['RegulationElement'],
    }),
    createRegulationElement: build.mutation({
      query: (payload) => {
        const { regulationId, body } = payload;
        const snakePayload = convertKeysToSnakeCase({
          ...transformActivityDates(body),
        });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'regulation_element',
        );

        return {
          url: `regulations/${regulationId}/elements`,
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['RegulationElement'],
    }),
    updateRegulationElement: build.mutation({
      query: (payload) => {
        const { id, regulationId } = payload;
        const snakePayload = convertKeysToSnakeCase({
          ...transformActivityDates(payload),
        });

        const submitBody = serialize(
          snakePayload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'regulation_element',
        );

        return {
          url: `/regulations/${regulationId}/elements/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['RegulationElement'],
    }),
    deleteRegulationElement: build.mutation<void, DeleteParams>({
      query: ({ id, regulationId }) => ({
        url: `regulations/${regulationId}/elements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RegulationElement'],
    }),
    previewRegulationElement: build.mutation({
      query: ({ id, regulationId, name }) => {
        return {
          url: `regulations/${regulationId}/elements/${id}/preview`,
          method: 'GET',
          responseHandler: async (response) => {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(await response.blob());
            link.setAttribute('download', `${name}.pdf`);
            link.click();
            link.remove();
            return { data: null };
          },
        };
      },
    }),
  }),
});

export const {
  useRegulationsQuery,
  useRegulationQuery,
  useCreateRegulationMutation,
  useUpdateRegulationMutation,
  useDeleteRegulationMutation,
  useReorderRegulationMutation,
  useRegulationElementsQuery,
  useRegulationElementQuery,
  useReorderRegulationElementMutation,
  useCreateRegulationElementMutation,
  useUpdateRegulationElementMutation,
  useDeleteRegulationElementMutation,
  usePreviewRegulationElementMutation,
} = RegulationsApi;
