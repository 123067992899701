import { Text, HStack } from '@chakra-ui/react';
import LinkIcon from 'components/Icons/Link';
import ShowStatsIcon from 'components/Stats/ShowElementStats/Button';
import { PreviewComponentProps } from 'types/dashboardCreator';

function GraphicTileSmallPreview({
  formValues,
  showStats,
}: PreviewComponentProps) {
  return (
    <HStack
      position="relative"
      p={6}
      spacing={2}
      justifyContent="center"
      alignItems="center"
      bgColor={formValues.backgroundColor || '#f9f9f9'}
      color={formValues.textColor || '#000000'}
      width="100%"
    >
      {showStats && <ShowStatsIcon data={formValues} />}
      <Text
        fontSize="md"
        fontWeight={700}
        textAlign="center"
        textDecoration="underline"
        color="inherit"
      >
        {formValues.title || 'Tu pojawi się tekst linku'}
      </Text>
      <LinkIcon />
    </HStack>
  );
}

export default GraphicTileSmallPreview;
