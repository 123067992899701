import * as yup from 'yup';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Box, DrawerBody as ChakraBody, Flex, Text } from '@chakra-ui/react';
import Select from 'components/Form/Select';
import { STATUS_OPTIONS } from 'utils/constants';
import FilterLabel from 'components/FilterLabel';
import useQueryParams from 'utils/useQueryParams';
import { useFormWithSchema } from 'utils/formHooks';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import { useBlogPostsSlugsQuery } from 'app/services/blogPostsApi';
import { SLUG, STATUS } from 'pages/LandingPage/Listing/constants';

const schema = yup.object({
  slug: yup.string(),
  status: yup.string(),
});

interface FilterBodyProps {
  onClose: () => void;
}

function FilterBody({ onClose }: FilterBodyProps) {
  const { search, setSearch } = useQueryParams();

  const { data: { data: slugOptions } = { data: [] }, isFetching } =
    useBlogPostsSlugsQuery({});

  const getDefaultValues = () => {
    const status = search.get(STATUS);
    const slug = search.get(SLUG);

    const result: { [key: string]: any } = {};
    result.slug = slug || undefined;
    result.status = status || undefined;

    return result;
  };

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
  });

  const { handleSubmit, setValue, reset, watch } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { slug: newSlug, status: newStatus } = data;

    if (newStatus) search.set(STATUS, newStatus);
    else search.delete(STATUS);
    if (newSlug) search.set(SLUG, newSlug);
    else search.delete(SLUG);

    search.set('page', '1');
    setSearch(search);
    onClose();
  });

  useEffect(() => {
    reset(getDefaultValues());
    // eslint-disable-next-line
  }, []);

  const status = watch('status');
  const slug = watch('slug');
  return (
    <ChakraBody>
      <FormProvider {...methods}>
        <Box as="form" id="filter-form" onSubmit={onSubmit} w="452px">
          <Select
            label="Slug"
            name="slug"
            options={slugOptions}
            placeholder="Wybierz"
            isLoading={isFetching}
          />
          <Flex direction="column" pt="26px">
            <Text fontWeight={400} fontSize="14px">
              Status
            </Text>
            <Flex direction="row" p="8px">
              {STATUS_OPTIONS.map((el, idx) => (
                <RadioBoxControl
                  label={el.label}
                  name="status"
                  id={`status_option_${idx}`}
                  key={`status_option_${el.value}`}
                  value={el.value}
                  isClearable
                />
              ))}
            </Flex>
          </Flex>

          <Flex direction="column" pt="26px">
            <Text fontWeight={600} fontSize="16px">
              WYBRANE
            </Text>
            <Flex py="28px" flexWrap="wrap" gap="16px" px="8px">
              {status && (
                <FilterLabel
                  key={`filter_label_${status}`}
                  name={
                    STATUS_OPTIONS.find((el) => el.value === status)?.label!
                  }
                  onClick={() => setValue('status', undefined)}
                />
              )}
              {slug && (
                <FilterLabel
                  key={`filter_label_${slug}`}
                  name={slug}
                  onClick={() => setValue('slug', undefined)}
                />
              )}
            </Flex>
          </Flex>
        </Box>
      </FormProvider>
    </ChakraBody>
  );
}

export default FilterBody;
