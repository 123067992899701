import { DeepPartial, Theme } from '@chakra-ui/react';

const Modal: DeepPartial<Theme['components']['Switch']> = {
  baseStyle: {
    container: {
      display: 'flex',
      alignItems: 'center',
      h: '20px',
    },
    thumb: {
      width: '20px',
      height: '20px',
      boxShadow:
        '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: '#fff',

      _checked: {
        bg: 'complementary.blue',
      },
    },
    track: {
      '--switch-thumb-x': '14px',
      width: '34px',
      padding: 0,
      height: '14px',
      alignItems: 'center',
      bg: '#ddd',
      _checked: {
        bg: 'complementary.lightBlue',
      },
    },
  },
};

export default Modal;
