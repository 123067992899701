import { FormControl, Stack } from '@chakra-ui/react';
import Textarea from 'components/Form/Textarea';
import DropzoneField from 'components/Form/Dropzone';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import SectionTitle from 'components/Form/SectionTitle';
import ActivityFormSection from 'components/ActivityFieldset';

interface FormBodyProps {
  prepareFieldName: (name: string) => string;
}

function FormBody({ prepareFieldName }: FormBodyProps) {
  return (
    <Stack spacing={8}>
      <Textarea
        name={prepareFieldName('animation')}
        placeholder="Animacja (JSON z Lottie)"
        label="Animacja (JSON z Lottie)"
      />
      <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
        <SectionTitle as="legend">Tło</SectionTitle>
        <DropzoneField
          name={prepareFieldName('background')}
          title="Dodaj zdjęcie w formacie .png, .jpg"
        />
      </FormControl>
      <ActivityFormSection prepareFieldName={prepareFieldName} />
    </Stack>
  );
}

export default FormBody;
