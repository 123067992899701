import { Link, Stack } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import { REGULATION_KIND } from 'pages/Regulations/Form/constants';

function GetColumns(): ColumnDef<Regulation, string>[] {
  return [
    {
      header: 'ID',
      accessorKey: 'id',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'RODZAJ',
      accessorKey: 'kind',
      cell: (info) => REGULATION_KIND[info.getValue()],
    },
    {
      header: 'ZAŁĄCZNIKI',
      cell: ({
        row: {
          original: { attachment1, attachment2 },
        },
      }) => (
        <Stack>
          {attachment1?.url && <Link href={attachment1.url}>Załącznik 1</Link>}
          {attachment2?.url && <Link href={attachment2.url}>Załącznik 2</Link>}
        </Stack>
      ),
    },
    {
      header: 'SUMA REGULAMINÓW W KATEGORII',
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
