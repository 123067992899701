import * as yup from 'yup';
import { toast } from 'react-toastify';
import { Box, Button, Text } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ROUTES from 'app/routes';
import { useResetPasswordMutation } from 'app/services/authApi';
import Input from 'components/Form/Input';
import Link from 'components/Link';
import { ApiError } from 'types/api';
import { useFormWithSchema } from 'utils/formHooks';
import { parseApiError } from 'utils/helpers';
import useRoute from 'utils/useRoute';

const schema = yup.object({
  email: yup
    .string()
    .email('Niepoprawny format adresu email')
    .required('Adres email jest wymagany'),
});

function ResetForm() {
  const loginPath = useRoute(ROUTES.auth.login);

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
  });
  const { store } = useParams();

  const navigate = useNavigate();

  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();

  const { handleSubmit, formState, setError } = methods;

  const { isDirty, isValid, isSubmitting } = formState;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await resetPassword({
        email: data.email,
        store: store!,
      })
        .unwrap()
        .catch(({ data: { errors } }) => {
          errors.forEach((err: string) => toast.error(err));
        });
    } catch (err) {
      const error = parseApiError(err as ApiError);
      setError('email', { message: error.msg });
    }
  });

  return (
    <FormProvider {...methods}>
      <Box as="form" onSubmit={onSubmit} w="452px">
        {isSuccess ? (
          <Text fontSize={16} lineHeight="24px">
            Na podany e-mail wysłaliśmy link do resetu hasła. Kliknij w link
            i&nbsp;podążaj za wskazówkami, by zmienić hasło.
          </Text>
        ) : (
          <Input name="email" label="E-mail" autoComplete="email" />
        )}

        {isSuccess ? (
          <Button
            type="button"
            w="100%"
            mt="40px"
            onClick={() => navigate(loginPath)}
          >
            ZAMKNIJ
          </Button>
        ) : (
          <Button
            isLoading={isSubmitting || isLoading}
            isDisabled={!isDirty || !isValid || isLoading}
            type="submit"
            w="100%"
            mt="40px"
          >
            WYŚLIJ LINK
          </Button>
        )}
        <Box w="100%" pt="32px" textAlign="center">
          <Link to={loginPath}>Zaloguj się</Link>
        </Box>
      </Box>
    </FormProvider>
  );
}

export default ResetForm;
