import { useCallback } from 'react';
import {
  AccordionItem,
  AccordionPanel,
  Box,
  FormControl,
} from '@chakra-ui/react';
import { UseFieldArrayMove } from 'react-hook-form';

import DropzoneField from 'components/Form/Dropzone';
import SectionTitle from 'components/Form/SectionTitle';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import ActivityFormSection from 'components/Container/Sections/ActivityFormSection';
import AccordionButton from 'components/Collapsible/AccordionButton';
import HeaderFormSection, {
  COMPONENTS,
} from 'components/Container/Sections/HeaderFormSection';
import LinkCreatorSection from 'components/LinkCreator';

interface ImageGalleryItemSectionProps {
  id: string;
  elementIndex: number;
  prepareFieldName: (name: string) => string;
  isEditMode: boolean;
  move: UseFieldArrayMove;
  remove: (index: number) => void;
}

function TitledCarouselSection({
  id,
  elementIndex,
  prepareFieldName,
  isEditMode,
  move,
  remove,
}: ImageGalleryItemSectionProps) {
  const fieldname = useCallback(
    (name: string) =>
      prepareFieldName(`elementsAttributes[${elementIndex}].${name}`),
    [elementIndex, prepareFieldName],
  );

  return (
    <AccordionItem border={0}>
      <AccordionButton
        id={id}
        index={elementIndex}
        move={move}
        label={`SLAJD ${elementIndex + 1}`}
        remove={remove}
      />
      <AccordionPanel pl={0} pr={0} pt={12} pb={0} overflow="visible">
        <FormControl as="fieldset" mb={9} maxW={FORM_BODY_MAX_WIDTH}>
          <Box mb="40px">
            <SectionTitle as="legend">Zdjęcie</SectionTitle>
            <DropzoneField
              name={fieldname('image')}
              title="Dodaj zdjęcie w formacie .png, .jpg<br />(min. 224 px x 304 px)"
            />
          </Box>
          <HeaderFormSection
            prepareFieldName={fieldname}
            components={[
              COMPONENTS.TITLE,
              COMPONENTS.SUBTITLE,
              COMPONENTS.COLORTXT,
              COMPONENTS.COLORBG,
            ]}
          />
        </FormControl>

        <LinkCreatorSection
          prepareFieldName={fieldname}
          isEditMode={isEditMode || false}
        />

        <ActivityFormSection
          prepareFieldName={fieldname}
          title={`Aktywność ${elementIndex + 1} elementu`}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}

export default TitledCarouselSection;
