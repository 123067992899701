import { Icon, IconProps } from '@chakra-ui/react';

function SimpleArrow(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 0L8 6L2 12L0.6 10.6L5.2 6L0.6 1.4L2 0Z" fill="currentColor" />
    </Icon>
  );
}

export default SimpleArrow;
