import { Box, Text, Flex } from '@chakra-ui/react';
import Card from 'components/Card';
import Input from 'components/Form/Input';
import CheckboxBoxControl from 'components/Form/CheckboxBoxControl';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import ExternalError from 'components/Form/ExternalError';
import { useCountriesQuery } from 'app/services/commonApi';
import { LABEL_DICT } from 'components/CountryChange/dictionary';
import { getCountryCode } from 'selectors/appSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import { selectUser } from 'selectors/authSelectors';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface HeaderFormProps {
  header: string;
  isEdit?: boolean;
}

function HeaderForm({ header, isEdit }: HeaderFormProps) {
  const { data: { data: countries } = { data: [] } } = useCountriesQuery();
  const currentCountryCode = useAppSelector(getCountryCode);
  const user = useAppSelector(selectUser);

  return (
    <Box mb="8px">
      <Card>
        {!isEdit && (
          <>
            <Text variant="boldSectionTitle" mb="16px">
              {header}
            </Text>
            <Flex w="100%" wrap="wrap" rowGap="20px" mb="10px">
              {countries.map((country) => {
                if (country.countryCode === currentCountryCode) {
                  return (
                    <Text
                      as="span"
                      padding="4px 16px"
                      fontSize={16}
                      display="block"
                      border="1px complementary.blue solid"
                      backgroundColor="complementary.blue"
                      color="#ffffff"
                      key={country.countryCode}
                      borderColor="complementary.blue"
                      mr="16px"
                      opacity={0.5}
                      cursor="default"
                    >
                      {LABEL_DICT[country.countryCode]}
                    </Text>
                  );
                }

                return (
                  <CheckboxBoxControl
                    key={country.countryCode}
                    id={`country_option_${country.countryCode}`}
                    label={LABEL_DICT[country.countryCode]}
                    name="countries"
                    value={country.countryCode}
                    isDisabled
                    group
                  />
                );
              })}
              <ExternalError name="language" />
            </Flex>
          </>
        )}

        <Box maxW={FORM_BODY_MAX_WIDTH}>
          <Input name="title" label="Tytuł" mb="26px" />
          {isEdit && <Input name="slug" label="Slug" />}

          {user?.role === 'super_admin' ? (
            <ToggleCheckbox name="active" label="Aktywność" mt="32px" />
          ) : null}
        </Box>
      </Card>
    </Box>
  );
}
HeaderForm.defaultProps = {
  isEdit: false,
};
export default HeaderForm;
