import { useMemo } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import FilterBadge from 'components/FilterBadge';
import useQueryParams from 'utils/useQueryParams';
import { SLUG, STATUS } from 'pages/LandingPage/Listing/constants';

type FilterBoxProps = BoxProps;

function FilterBox({ children }: FilterBoxProps) {
  const { search } = useQueryParams();

  const amount = useMemo(() => {
    let counter = 0;
    counter += search.get(STATUS) ? 1 : 0;
    counter += search.get(SLUG) ? 1 : 0;

    return counter;
  }, [search]);

  return (
    <Box position="relative">
      {children}
      {amount > 0 && <FilterBadge amount={amount} />}
    </Box>
  );
}

export default FilterBox;
