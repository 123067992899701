import { Box, Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import Card from 'components/Card';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import { useTabsQuery } from 'app/services/commonApi';
import { useCategoriesOptionsQuery } from 'app/services/categoryTags';
import Select from 'components/Form/Select';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';
import useQueryParams from 'utils/useQueryParams';
import ExternalError from 'components/Form/ExternalError';

interface CategorySelectProps {
  isLoading: boolean;
}

function CategorySelect({ isLoading }: CategorySelectProps) {
  const { search, setSearch } = useQueryParams();
  const currentTab = search.get('q[taxonomy_container_id_eq]');

  const { data: { data: tabsOptions } = { data: [] } } = useTabsQuery();
  const { data: { data: categoriesOptions } = { data: [] }, isFetching } =
    useCategoriesOptionsQuery(currentTab!);

  const { control, setValue } = useFormContext();
  const pickedTab = useWatch({
    control,
    name: 'taxonomyContainerId',
  });

  useEffect(() => {
    setValue('taxonomyContainerId', currentTab);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pickedTab) {
      search.set('q[taxonomy_container_id_eq]', pickedTab);
      setSearch(search);
    }
  }, [pickedTab, search, setSearch]);

  return (
    <Box maxW={FORM_MAX_WIDTH} mb="16px" position="relative" zIndex={3}>
      {isLoading && (
        <Box
          position="absolute"
          left={0}
          top={0}
          right={0}
          bottom={0}
          zIndex={10}
          display="flex"
          bgColor="whiteAlpha.700"
          alignItems="center"
          justifyContent="center"
        />
      )}
      <Card>
        <Box maxW={FORM_BODY_MAX_WIDTH} mb={9}>
          <Text variant="sectionTitle">Wybierz gdzie dodać kategorię</Text>
          <Flex mt={4}>
            {tabsOptions.map((option, idx) => (
              <RadioBoxControl
                key={option.value}
                id={`taxonomyContainerId-option-${idx}`}
                label={option.label}
                name="taxonomyContainerId"
                value={option.value}
                group
              />
            ))}
          </Flex>
          <ExternalError name="taxonomyContainerId" />
        </Box>
        <Box mt={8} maxW={FORM_BODY_MAX_WIDTH}>
          <Text variant="boldSectionTitle" pb="22px">
            Kategoria nadrzędna w Magento
          </Text>
          <Select
            label="Kategoria nadrzędna w Magento"
            name="categoryId"
            options={categoriesOptions}
            isLoading={isFetching}
          />
        </Box>
      </Card>
    </Box>
  );
}

export default CategorySelect;
