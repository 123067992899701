import styled from '@emotion/styled';

const Th = styled.th`
  padding: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  position: sticky;
  top: 0;

  :not(:first-of-type) {
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      border-right-color: #a9a9a9;
      border-right-width: 1px;
    }
  }
`;

export default Th;
