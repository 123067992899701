import { serialize } from 'object-to-formdata';
import { SERIALIZER_OPTIONS } from 'app/constants';
import { api } from 'app/services/api';
import {
  CategoryContainerParams,
  CategoryContainerResponse,
  CategoryContainersResponse,
  CategoryElementsResponse,
  TransformedCategoryContainer,
  ReorderParams,
  CategoryContainer,
} from 'types/categoryContainer';
import {
  transformActivityDates,
  transformStringDatesToDateObject,
} from 'utils/date';
import {
  convertKeysToSnakeCase,
  transformLinksAttributesForPayload,
} from 'utils/helpers';
import { transformLinkParameters } from 'utils/linkCreator';

export const categoryContainerApi = api.injectEndpoints({
  endpoints: (build) => ({
    categoryContainers: build.query<CategoryContainersResponse, string>({
      query: (queryString) => `taxonomy_containers${queryString}`,
      keepUnusedDataFor: 10,
      providesTags: (result) =>
        result
          ? result.data.map(({ id }) => ({ type: 'CategoryContainer', id }))
          : ['CategoryContainer'],
    }),
    categoryElements: build.query<CategoryElementsResponse, number>({
      query: (id) => `taxonomy_containers/${id}/special_elements
      `,
      keepUnusedDataFor: 10,
      providesTags: (result) =>
        result
          ? result.data.map(({ id }) => ({ type: 'CategoryElement', id }))
          : ['CategoryElement'],
    }),
    createCategoryContainer: build.mutation({
      query: (payload) => {
        const { taxonomyContainerId } = payload;
        const data = { ...transformActivityDates(payload) };

        const submitBody = serialize(
          convertKeysToSnakeCase({ ...data }),
          SERIALIZER_OPTIONS,
          new FormData(),
          'taxonomy_container_special_element',
        );

        return {
          url: `taxonomy_containers/${taxonomyContainerId}/special_elements`,
          method: 'POST',
          body: submitBody,
        };
      },
      invalidatesTags: ['CategoryElement'],
    }),
    categoryContainer: build.query<
      TransformedCategoryContainer,
      CategoryContainerParams
    >({
      query: ({ id, taxonomyContainerId }) =>
        `taxonomy_containers/${taxonomyContainerId}/special_elements/${id}`,
      keepUnusedDataFor: 10,
      transformResponse(response: CategoryContainerResponse) {
        const data = {
          ...transformLinkParameters(response.data),
          ...transformStringDatesToDateObject(response.data),
          withBadge: !!response.data?.badge,
          withImage: !!response.data?.image?.url,
          withLogo: !!response.data?.logo?.url,
          withIcon: !!response.data?.icon?.url,
          withListingHeader: response.data.bannerListings?.length > 0,
        };

        return data as TransformedCategoryContainer;
      },
      providesTags: ['CategoryElement'],
    }),
    updateCategoryContainer: build.mutation({
      query: (data) => {
        const { taxonomyContainerId, id } = data;
        const payload = {
          ...convertKeysToSnakeCase(
            transformLinksAttributesForPayload(
              transformActivityDates({
                ...data,
                deleteLogo: +data.deleteLogo,
                deleteImage: +data.deleteImage,
                deleteIcon: +data.deleteIcon,
              }),
            ),
          ),
        };

        const submitBody = serialize(
          payload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'taxonomy_container_special_element',
        );

        return {
          url: `taxonomy_containers/${taxonomyContainerId}/special_elements/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['CategoryElement'],
    }),
    deleteCategoryContainer: build.mutation<void, CategoryContainerParams>({
      query: ({ taxonomyContainerId, id }) => ({
        url: `taxonomy_containers/${taxonomyContainerId}/special_elements/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CategoryElement'],
    }),
    reorderCategoryContainers: build.mutation<void, ReorderParams>({
      query: ({ body, id, taxonomyContainerId }) => ({
        url: `taxonomy_containers/${taxonomyContainerId}/special_elements/${id}/sort`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['CategoryElement'],
    }),
    taxonomyContainers: build.query<CategoryContainersResponse, string>({
      query: (queryString) => `taxonomy_containers${queryString}`,
      keepUnusedDataFor: 10,
      providesTags: (result) =>
        result
          ? result.data.map(({ id }) => ({ type: 'TaxonomyContainer', id }))
          : ['TaxonomyContainer'],
    }),
    taxonomyContainer: build.query<CategoryContainerResponse, string>({
      query: (id) => `taxonomy_containers/${id}`,
      keepUnusedDataFor: 10,
      providesTags: ['TaxonomyContainer'],
    }),
    updateTaxonomyContainer: build.mutation({
      query: (data: Partial<CategoryContainer>) => {
        const { id, deleteSpecialElementsIcon = false } = data;
        const payload = convertKeysToSnakeCase({
          ...data,
          elementsAttributes: data?.elementsAttributes?.map((element, index) =>
            transformLinksAttributesForPayload(
              transformActivityDates({
                ...element,
                placement: index,
              }),
            ),
          ),
          deleteSpecialElementsIcon: +deleteSpecialElementsIcon,
        });

        const submitBody = serialize(
          payload,
          SERIALIZER_OPTIONS,
          new FormData(),
          'taxonomy_container',
        );

        return {
          url: `taxonomy_containers/${id}`,
          method: 'PUT',
          body: submitBody,
        };
      },
      invalidatesTags: ['TaxonomyContainer', 'CategoryContainer'],
    }),
  }),
});

export const {
  useCreateCategoryContainerMutation,
  useCategoryElementsQuery,
  useCategoryContainersQuery,
  useCategoryContainerQuery,
  useUpdateCategoryContainerMutation,
  useDeleteCategoryContainerMutation,
  useReorderCategoryContainersMutation,
  useTaxonomyContainersQuery,
  useTaxonomyContainerQuery,
  useUpdateTaxonomyContainerMutation,
} = categoryContainerApi;
